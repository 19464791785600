import { withFakeData } from 'api/fakedata';
import { axiosDataInstance } from 'api/axiosInstances';
import { GetDeleteAccountRequestResponse } from 'types/clientDeleteAccountRequest/clientDeleteAccountRequest.api.types';
import { AUTH_BASE_URL } from 'constants/appConstants';

const apiName = 'clientDeleteAccountRequest';

export default {
    getDeleteAccountRequest: (filterQueryString: string) =>
        withFakeData<GetDeleteAccountRequestResponse>(
            () =>
                axiosDataInstance.get(
                    `${AUTH_BASE_URL}/app_users/remove-requests?${filterQueryString}`,
                ),
            apiName,
            'clientDeleteAccountRequest',
            false,
            500,
        ),
    acceptDeleteAccountRequest: ({ id }: { id: string }) =>
        withFakeData<{}>(
            () =>
                axiosDataInstance.patch(`${AUTH_BASE_URL}/app_users/remove-requests/${id}/accept`),
            apiName,
            'clientDeleteAccountRequest',
            false,
            500,
        ),
    rejectDeleteAccountRequest: ({ id, body }: { id: string; body: { reason: string } }) =>
        withFakeData<{}>(
            () =>
                axiosDataInstance.patch(
                    `${AUTH_BASE_URL}/app_users/remove-requests/${id}/reject`,
                    body,
                ),
            apiName,
            'clientDeleteAccountRequest',
            false,
            500,
        ),
    deleteAccountRequest: ({ id, data }: { id: string; data: { reason: string } }) =>
        withFakeData<{}>(
            () =>
                axiosDataInstance.delete(`${AUTH_BASE_URL}/app_users/remove-requests/${id}`, {
                    data,
                }),
            apiName,
            'clientDeleteAccountRequest',
            false,
            500,
        ),
};
