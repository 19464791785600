import { styled as styledMui } from '@mui/material/styles';
import { Box, Stack, FormLabel } from '@mui/material';

const UsersBrokerForm = styledMui(Box)(({ theme }) => ({
    padding: '1em',
    borderRadius: '0.5em',
    gap: '8px',
    height: 'auto',
    maxWidth: '100%',
    marginTop: '1em',
}));

const ActionSectionForm = styledMui(Stack)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'end',
    marginTop: '1.3em',
}));

const FormLabelStyled = styledMui(FormLabel)({
    marginRight: '10px',
    alignSelf: 'center',
});

export default {
    UsersBrokerForm,
    ActionSectionForm,
    FormLabelStyled,
};
