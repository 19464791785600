import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import LoginPage from './LoginPage';

const LoginPageContainer = (props: Props) => {
    // const {} = props;

    const childProps = {
        ...props,
    };

    return <LoginPage {...childProps} />;
};

const propTypes = {};

const defaultProps = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
LoginPageContainer.propTypes = propTypes;
LoginPageContainer.defaultProps = defaultProps;

export default LoginPageContainer;
