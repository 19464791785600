import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import * as Yup from 'yup';
import { FormikConfig, FormikValues, useFormik } from 'formik';
import mainLogo from 'assets/img/general/generic_logo.svg';
import SendMailPage from './SendMailForm';
import { useForgotPassword } from 'hooks/api/auth.hooks';
import { useTranslation } from 'react-i18next';
import i18n from 'utils/i18n';
import { tRequiredFieldError } from 'constants/appConstants';

// ---------------------------------------------//
// ------------------- FORMIK ------------------//
// ---------------------------------------------//

const getInitialValues = () => ({
    email: '',
});

const getValidationSchema = () =>
    Yup.lazy(() =>
        Yup.object().shape({
            email: Yup.string()
                .email(i18n.t('required_email', { ns: 'errors' }) as string)
                .required(tRequiredFieldError)
                .trim(),
        }),
    );

// ---------------------------------------------//
// ---------------------------------------------//
// ---------------------------------------------//

const SendMailFormContainer = ({ onSuccess }: Props) => {
    const { t } = useTranslation('auth');
    const { mutate, isLoading, errorMessage } = useForgotPassword();

    const handleSubmit = React.useCallback(async (values: FormikValues) => {
        mutate(
            { username: values.email.trim() },
            {
                onSuccess: () => {
                    onSuccess(values.email);
                },
            },
        );
    }, []);

    const formikInitProps = React.useMemo(
        () =>
            ({
                initialValues: getInitialValues(),
                validateOnChange: false,
                validateOnBlur: false,
                validateOnMount: false,
                validationSchema: getValidationSchema(),
                enableReinitialize: true,
                onSubmit: handleSubmit,
            }) as FormikConfig<FormikValues>,
        [handleSubmit],
    );

    const formik = useFormik(formikInitProps);

    const childProps = {
        t,
        mainLogo,
        isLoading,
        formik,
        errorMessage,
    };

    return <SendMailPage {...childProps} />;
};

const propTypes = {};

const defaultProps = {};

interface extraProps {
    onSuccess: (email: string) => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
SendMailFormContainer.propTypes = propTypes;
SendMailFormContainer.defaultProps = defaultProps;

export default SendMailFormContainer;
