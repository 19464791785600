import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { Tprop } from 'types/common/general.types';
import { ClientVB } from 'types/clientImportClientVB/clientImportClientVB.types';
import { tCommon } from 'constants/appConstants';
import CustomIconSelector from 'components/common/CustomIconSelector';
import St from './ClientVBDetail.styled';

const ClientVBDetail = (props: Props) => {
    const { t, customerCode, description, fullAddress, isClient, email, lastLogin, personType } =
        props;

    return (
        <St.DetailContainer component={St.Paper}>
            <St.FirstBox>
                <St.Avatar>
                    <CustomIconSelector type="person" />
                </St.Avatar>
                <St.Title as="h6">{t('client_detail_title')}</St.Title>
            </St.FirstBox>
            <St.Divider />
            <St.SecondBox>
                <St.InfoLabel label={t('client_detail_customer_number')} text={customerCode} />
                <St.InfoLabel label={t('client_detail_address')} text={fullAddress} />
                <St.InfoLabel label={t('client_detail_person_type')} text={personType} />
                <St.InfoLabel label={t('email', tCommon) + ':'} text={email} />
                <St.InfoLabel label={t('client_detail_description')} text={description} />
                <St.InfoLabel
                    label={t('client_detail_is_client')}
                    Component={
                        <St.CustomIconSelectorWrapper>
                            <CustomIconSelector
                                type={`${isClient ? 'check' : 'close'}`}
                                sx={{ width: '18px', height: '18px' }}
                            />
                        </St.CustomIconSelectorWrapper>
                    }
                />
                <St.InfoLabel label={t('client_detail_last_login')} text={lastLogin ?? '-'} />
            </St.SecondBox>
        </St.DetailContainer>
    );
};

const propTypes = {};

const defaultProps = {};

interface extraProps extends ClientVB, Tprop {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ClientVBDetail.propTypes = propTypes;
ClientVBDetail.defaultProps = defaultProps;

export default ClientVBDetail;
