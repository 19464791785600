import { Box, Typography, styled } from '@mui/material';
import { pxToRem } from 'utils/helpers/stylesHelper';

export default {
    ContainerPage: styled(Box)(() => ({
        width: '100%',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    })),
    LogoContainer: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '1rem',
            marginBottom: '1rem',
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                marginTop: '2rem',
            },
        }),
    ),
    Logo: styled('img')(({ theme }) =>
        theme.unstable_sx({
            maxWidth: '150px',
            width: '100%',
            height: 'auto',
            [theme.breakpoints.up('sm')]: {
                maxWidth: '280px',
            },
        }),
    ),
    Title: styled(Typography)(({ theme }) => ({
        fontSize: '4rem !important',
        textAlign: 'center',
        fontWeight: 'bold',
        color: theme.palette.text.primary,
        marginTop: pxToRem(50),
        marginBottom: pxToRem(20),
    })),
    Subtitle: styled(Typography)(({ theme }) => ({
        fontSize: '2rem !important',
        textAlign: 'center',
        fontWeight: 'medium',
        color: theme.palette.text.secondary,
        marginBottom: pxToRem(20),
    })),
    Message: styled(Typography)(({ theme }) => ({
        fontSize: '1.2rem !important',
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginBottom: pxToRem(40),
    })),
    HomeLink: styled(Box)(({ theme }) => ({
        padding: `${pxToRem(10)} ${pxToRem(20)}`,
        textDecoration: 'none !important',
        width: pxToRem(120),
        textAlign: 'center',
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        borderRadius: pxToRem(4),
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
            cursor: 'pointer',
        },
    })),
};
