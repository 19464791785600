import React, { useEffect, useState } from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ChannelsCollateralDetailActions from './ChannelsCollateralDetailActions';
import { useTranslation } from 'react-i18next';
import {
    ApproveOrEditInformation,
    collaterals,
} from 'types/channelsCollateral/channelsCollateral.types';
import {
    useApproveCollateral,
    useEditCollateral,
    useRejectCollateral,
} from 'hooks/api/channelsCollateral.hooks';
import useSnackBar from 'hooks/common/CommonGrid/snackBar.hooks';
import moment from 'moment';

const ChannelsCollateralDetailActionsContainer = (props: Props) => {
    const { id, rate, term, action, localInstrumentsUnderWarranty } = props;
    const { t } = useTranslation('channelsCollateral');
    const { setSnackBarMessage } = useSnackBar();
    const [approveInformation, setApproveInformation] = useState({} as ApproveOrEditInformation);
    const [reason, setReason] = useState('');
    const [rateIsMKT, setRateIsMKT] = useState(false);
    const { mutate, isError, isLoading, errorMessage, successfulMessage } = useApproveCollateral();
    const {
        mutate: mutateEdit,
        isError: isErrorEdit,
        isLoading: isLoadingEdit,
        errorMessage: errorMessageEdit,
    } = useEditCollateral();
    const {
        mutate: mutateReject,
        isError: isErrorReject,
        isLoading: isLoadingReject,
        errorMessage: errorMessageReject,
    } = useRejectCollateral();

    const handleChange = (name: string, value: string | number) =>
        setApproveInformation({ ...approveInformation, [name]: value });

    const handleApproveRequest = () => {
        const dataToSend = approveInformation;
        delete dataToSend.settlementDay;
        if (rateIsMKT) dataToSend.rate = 0;
        if (localInstrumentsUnderWarranty?.length)
            dataToSend.collaterals = localInstrumentsUnderWarranty.map(
                ({ ticker, quantity, price }: collaterals) => ({
                    tickerId: ticker.id,
                    quantity,
                    price,
                }),
            );
        return dataToSend;
    };

    const handleSubmit = () => {
        switch (action) {
            case 'approve':
                return mutate(
                    { id, body: handleApproveRequest() },
                    {
                        onSuccess: () => {
                            setSnackBarMessage(successfulMessage);
                            props.close();
                        },
                    },
                );
            case 'edit':
                return mutateEdit(
                    { id, body: handleApproveRequest() },
                    {
                        onSuccess: () => {
                            setSnackBarMessage(t('collateral_edited_successfully'));
                            props.close();
                        },
                    },
                );
            case 'reject':
                return mutateReject(
                    { id, body: { reason } },
                    {
                        onSuccess: () => {
                            setSnackBarMessage(t('collateral_rejected_successfully'));
                            props.close();
                        },
                    },
                );

            default:
                return props.close();
        }
    };

    const getTermDays = () => {
        const today = moment();
        const selectedDate = moment(approveInformation?.settlementDay);
        setApproveInformation({
            ...approveInformation,
            term: selectedDate.diff(today, 'days') + 1, // El calculo de un dia menos
        });
    };

    useEffect(() => {
        const errorByAction: { [key: string]: string } = {
            approve: errorMessage,
            reject: errorMessageReject,
            edit: errorMessageEdit,
        };
        if (isError || isErrorEdit) setSnackBarMessage(errorByAction[props.action], 'error');
    }, [isError, isErrorEdit, isErrorReject]);

    useEffect(() => {
        if (approveInformation?.settlementDay) getTermDays();
    }, [approveInformation?.settlementDay]);

    useEffect(() => {
        setApproveInformation({ ...approveInformation, term, rate });
        setRateIsMKT(String(rate) === '0');
    }, []);

    const childProps = {
        ...props,
        isLoading,
        isLoadingEdit,
        isLoadingReject,
        reason,
        approveInformation,
        rateIsMKT,
        setRateIsMKT,
        setReason,
        handleChange,
        handleSubmit,
        t,
    };

    return <ChannelsCollateralDetailActions {...childProps} />;
};

const propTypes = {};

const defaultProps = {};

interface extraProps {
    id: string;
    status: string;
    localInstrumentsUnderWarranty: collaterals[];
    rate: number;
    term: number;
    action: string;
    setAction(action: string): void;
    close(): void;
    handleAcquireOrReleaseOrder({ id, action }: { id: string; action: string }): void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ChannelsCollateralDetailActionsContainer.propTypes = propTypes;
ChannelsCollateralDetailActionsContainer.defaultProps = defaultProps;

export default ChannelsCollateralDetailActionsContainer;
