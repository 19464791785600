import { Box, Typography, styled } from '@mui/material';

export default {
    Container: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            display: 'flex',
            gap: '10px',
            justifyContent: { lg: 'space-between', md: 'flex-start' },
        }),
    ),
    Wrapper: styled(Box)(({ theme }) => ({
        display: 'flex',
    })),

    Label: styled(Typography)(({ theme, color }) =>
        theme.unstable_sx({
            fontSize: '1em',
            fontWeight: 'bold',
            color: color ?? 'black',
            marginRight: '5px',
        }),
    ),
};
