import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './ChannelsAffidavitDetail.styled';
import { ChannnelsAffidavit } from 'types/channelsAffidavit/channelsAffidavit.types';
import { Divider, Grid } from '@mui/material';
import InfoLabel from 'components/common/InfoLabel';
import ChannelsModalActionButton from 'components/common/ChannelsModalActionButton';
import FileUploader from 'components/common/FileUploader';
import RejectForm from 'components/common/RejectForm';
import { getFullName } from 'utils/helpers/commonHelper';
import { formatDateWithHour } from 'utils/helpers/dateHelper';
import ActionButton from 'components/common/ActionButton';
import { ACCEPTED, REJECTED, tChannelsCommon, tCommon } from 'constants/appConstants';

const ChannelsAffidavitDetail = (props: Props) => {
    const {
        row,
        t,
        handleAction,
        action,
        rejectReason,
        setRejectReason,
        errorMessage,
        fileUrl,
        setFileUrl,
        isLoading,
        close,
        handleSubmit,
        canApproveOrReject,
    } = props;
    const { declaration, ticker, createdAt, channel, customerCode } = row;

    return (
        <St.Container>
            <Grid container spacing={2} marginTop={'5px'}>
                <Grid item xs={12} md={5} ml={1.25}>
                    <InfoLabel
                        fontSize={'14px'}
                        withoutSeparation
                        label={t('channels_affidavit_detail_date_label') + ':'}
                        text={formatDateWithHour(createdAt)}
                    />
                    <InfoLabel
                        fontSize={'14px'}
                        withoutSeparation
                        label={t('channels_affidavit_detail_channel_label') + ':'}
                        text={getFullName(channel)}
                    />
                    <InfoLabel
                        fontSize={'14px'}
                        withoutSeparation
                        label={t('channels_affidavit_detail_customer_code_label') + ':'}
                        text={customerCode}
                    />
                </Grid>
                <Grid item xs={12} md={5} ml={1.25}>
                    <InfoLabel
                        fontSize={'14px'}
                        withoutSeparation
                        label={t('channels_affidavit_detail_client_label') + ':'}
                        text={getFullName(declaration?.operator)}
                    />
                    <InfoLabel
                        fontSize={'14px'}
                        withoutSeparation
                        label={t('channels_affidavit_detail_instrument_label') + ':'}
                        text={ticker?.ticker}
                    />
                    <InfoLabel
                        fontSize={'14px'}
                        withoutSeparation
                        label={t('channels_affidavit_detail_currency_label') + ':'}
                        text={ticker.currency}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Divider sx={{ width: '100%', mt: 1.25 }} />
                {canApproveOrReject && (
                    <ChannelsModalActionButton
                        setAction={action => handleAction(action)}
                        status={declaration?.status ?? REJECTED}
                    />
                )}
                <Grid
                    item
                    xs={12}
                    sm={7}
                    display={declaration?.status === REJECTED ? 'block' : 'flex'}
                    justifyContent={canApproveOrReject ? 'center' : 'flex-start'}>
                    {(action === 'approve' || declaration?.status === ACCEPTED) && (
                        <FileUploader
                            values={{ file: fileUrl }}
                            isEdit={declaration?.status === ACCEPTED}
                            displayCleanerFunction={declaration?.status !== ACCEPTED}
                            field="file"
                            setFieldValue={(field, value) => setFileUrl(value)}
                            errors={{ file: errorMessage ?? undefined }}
                        />
                    )}
                    {action === 'reject' && (
                        <RejectForm
                            setReason={setRejectReason}
                            reason={rejectReason}
                            errorMessage={errorMessage}
                        />
                    )}
                </Grid>
                {rejectReason && declaration?.status === REJECTED && (
                    <Grid item xs={12}>
                        <InfoLabel
                            fontSize={'14px'}
                            withoutSeparation
                            label={t('reason', tChannelsCommon) + ':'}
                            text={rejectReason}
                        />
                    </Grid>
                )}
            </Grid>
            {action && (
                <St.FooterButtons direction={'row'} spacing={1}>
                    <ActionButton onClick={close} variant="outlined">
                        {t('cancel', tCommon)}
                    </ActionButton>
                    <ActionButton
                        loading={isLoading}
                        onClick={() => handleSubmit(action)}
                        variant="contained">
                        {t('confirm', tCommon)}
                    </ActionButton>
                </St.FooterButtons>
            )}
        </St.Container>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    handleAction: PropTypes.func.isRequired,
    rejectReason: PropTypes.string.isRequired,
    setRejectReason: PropTypes.func.isRequired,
    setFileUrl: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    canApproveOrReject: PropTypes.bool.isRequired,
};

const defaultProps = {};

interface extraProps {
    row: ChannnelsAffidavit;
    action: 'approve' | 'reject' | null;
    errorMessage: string | null;
    fileUrl: string | null;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ChannelsAffidavitDetail.propTypes = propTypes;
ChannelsAffidavitDetail.defaultProps = defaultProps;

export default ChannelsAffidavitDetail;
