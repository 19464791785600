import { createContext, useEffect, useState } from 'react';
import WebSocket from 'isomorphic-ws';
import { enqueueSnackbar } from 'notistack';
import { V_BO_WS_URL } from 'constants/envVarConstants';

export const NotificationsContext = createContext(
    {} as { connect(token: string): void; disconnect(): void },
);

const NotificationsProvider = ({ children }: any) => {
    const [connection, setConnection] = useState<any>(null);

    const connect = (token: string) => {
        const ws = new WebSocket(`${V_BO_WS_URL}/?auth=${token}`);

        setConnection(ws);

        ws.onmessage = (ev: any) => {
            const { message, icon } = JSON.parse(ev.data);
            enqueueSnackbar(message, {
                variant: icon,
                persist: true,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            });
        };
    };

    const disconnect = () => connection?.close();

    useEffect(() => {
        const token = localStorage.getItem('IdToken');
        if (token) connect(token);
        else disconnect();
        return () => disconnect();
    }, []);

    return (
        <NotificationsContext.Provider
            value={{
                connect,
                disconnect,
            }}>
            {children}
        </NotificationsContext.Provider>
    );
};

export default NotificationsProvider;
