import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { BankAccountExtraData } from 'types/clientExtractions/clientExtractions.types';
import ChannelsModalItemGrid from 'components/common/ChannelsModalItemGrid';
import { BROKER, ICON_CELL_SIZE } from 'constants/appConstants';
import { Box, IconButton, Tooltip } from '@mui/material';
import CustomIconSelector from '../CustomIconSelector';
import ClientBankAccountsEditForm from 'components/pages/ClientBankAccountsPage/ClientBankAccountsEditForm';

const BankAccountDetail = ({
    accountDetail,
    type,
    showEditModal,
    bankAccountId,
    editable,
    setShowEditModal,
    t,
}: Props) => {
    const { appellation, aba, swift, iban, broker, brokerCustomerCode } = accountDetail;
    return (
        <>
            {editable && (
                <Box display="flex" alignItems="center" justifyContent="end" width="100%">
                    <Tooltip title={t('edit')}>
                        <IconButton color="secondary" onClick={() => setShowEditModal(true)}>
                            <CustomIconSelector type="edit" sx={ICON_CELL_SIZE} />
                        </IconButton>
                    </Tooltip>
                </Box>
            )}
            {type === BROKER && (
                <>
                    <ChannelsModalItemGrid label={t('broker_name')} text={broker} />
                    <ChannelsModalItemGrid
                        label={t('broker_customer_code')}
                        text={brokerCustomerCode}
                    />
                </>
            )}
            <ChannelsModalItemGrid label={t('appellation')} text={appellation} />
            <ChannelsModalItemGrid label={t('aba')} text={aba} />
            <ChannelsModalItemGrid label={t('swift')} text={swift} />
            <ChannelsModalItemGrid label={t('iban')} text={iban} />

            {showEditModal && bankAccountId && (
                <ClientBankAccountsEditForm
                    id={bankAccountId}
                    open={showEditModal}
                    bankAccountExterior={accountDetail}
                    type={type}
                    close={() => setShowEditModal(false)}
                />
            )}
        </>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

const defaultProps = {};

interface extraProps {
    type: string;
    accountDetail: BankAccountExtraData;
    showEditModal: boolean;
    bankAccountId?: string;
    editable?: boolean;
    setShowEditModal(showEditModal: boolean): void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
BankAccountDetail.propTypes = propTypes;
BankAccountDetail.defaultProps = defaultProps;

export default BankAccountDetail;
