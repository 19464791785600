import moment from 'moment';

const currentTime = new Date();
const currentYear = currentTime.getFullYear();
const currentMonth = currentTime.getMonth();
const currentDay = currentTime.getDate();

const SECONDS_IN_A_DAY = 24 * 60 * 60 * 1000;

const lastYear = `${currentYear - 1}/${currentMonth}/${currentDay}`;
export const minValue = new Date(lastYear).getTime();

export const getTomorrow = (value: number) => {
    if (value) {
        const tmrwDt = new Date(value);
        const tmrw = tmrwDt.setDate(tmrwDt.getDate() + 1);
        return tmrw;
    }
    return value;
};

export const convertTimeToEpoch = (timeString: string): string | number => {
    // Crear un objeto Date a partir de la cadena de tiempo
    const timeValue = new Date(timeString);

    // Obtener componentes de la hora, minutos y segundos
    const hours = timeValue.getHours();
    const minutes = timeValue.getMinutes();
    const seconds = timeValue.getSeconds();

    // Calcular el epoch correspondiente (en segundos)
    const epochValue = hours * 3600 + minutes * 60 + seconds;

    // Asegurarse de que el epoch esté limitado a 5 dígitos
    const limitedEpoch = Math.min(epochValue, 99999);

    return limitedEpoch;
};

export const calculateHourEpoch = (dateString: string, time: string): number => {
    const dayEpoch = new Date(dateString).getTime();
    const dayEpochForTime = dayEpoch + SECONDS_IN_A_DAY;
    const [hours, minutes] = time.split(':').map(Number);

    return new Date(dayEpochForTime).setHours(hours, minutes);
};

export const disableCurrentDate = (date: any) => date < new Date();

// convert date to format
export const formatDate = (date: string, format?: string) =>
    date ? moment(date).format(format || 'DD/MM/YYYY') : '-';

// convert date to format DD/MM/YYYY HH:MM:SS
export const formatDateWithHour = (date: string) =>
    date ? moment(date).format('DD/MM/YYYY HH:mm:ss') : '-';

export const getDateFromFileUrl = (fileUrl: string) => {
    const actualDate = fileUrl.split('/')[0];
    const [date, time] = actualDate.split('_');

    return date + ' ' + time.replaceAll('-', ':');
};

export default {
    minValue,
    getTomorrow,
    convertTimeToEpoch,
    calculateHourEpoch,
};
