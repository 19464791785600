import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ChannelsModalActionButton from './ChannelsModalActionButton';
import { useTranslation } from 'react-i18next';

const ActionIconButtonContainer = (props: Props) => {
    const { t } = useTranslation('channelsCommon');

    const textByType: { [key: string]: string } = {
        edit: t('edit_info'),
        approve: t('approve'),
        reject: t('reject'),
        approve_api: t('approve_api'),
        approve_manually: t('approve_manually'),
    };

    const childProps = {
        ...props,
        textByType,
        t,
    };

    return <ChannelsModalActionButton {...childProps} />;
};

const propTypes = {};

const defaultProps = {};

interface extraProps {
    status: string;
    setAction(action: string): void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ActionIconButtonContainer.propTypes = propTypes;
ActionIconButtonContainer.defaultProps = defaultProps;

export default ActionIconButtonContainer;
