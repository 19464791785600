import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './FileUploader.styled';
import { IconButton, SvgIcon, Typography, Tooltip } from '@mui/material';
import { Tprop } from 'types/common/general.types';
import CircularSpinner from '../CircularSpinner';
import ErrorText from '../ErrorText';
import PdfReaderModal from '../PdfReaderModal';
import CustomIconSelector from '../CustomIconSelector';
import ImageReaderModal from '../ImageReaderModal';

const FileUploader = (props: Props) => {
    const {
        t,
        displayTitle = true,
        isLoading,
        openDocumentReader,
        handleCloseDocumentReader,
        handleOpenDocumentReader,
        displayCleanerFunction = true,
        cleanerFunction,
        handleFileSelectorChange,
        accept,
        error,
        fileExtension,
        fileIconMapper,
        validExtensions,
        file,
        fileUrl,
        canOpenPdfReader,
        canOpenImgReader,
        filename,
        isEdit,
    } = props;
    return (
        <St.FileUploaderWrapper>
            {displayTitle && (
                <Typography fontSize={'0.9rem'} fontWeight={'bold'}>
                    {isEdit
                        ? t('file_selected')
                        : t('attach_file') + (validExtensions ? `(${validExtensions})` : '')}
                </Typography>
            )}
            {isLoading ? (
                <CircularSpinner size={30} />
            ) : (
                <St.FileUploaderWithContentWrapper>
                    {((file || fileUrl) && (
                        <>
                            {displayCleanerFunction && (
                                <St.RemoveIconButton onClick={cleanerFunction}>
                                    <CustomIconSelector type="disable" />
                                </St.RemoveIconButton>
                            )}
                            <Tooltip title={t('file_uploader_preview_item_tooltip_msn')}>
                                <IconButton
                                    href={''}
                                    target="_blank"
                                    onClick={handleOpenDocumentReader}>
                                    <SvgIcon
                                        fontSize="large"
                                        component={
                                            fileIconMapper[fileExtension] || fileIconMapper.default
                                        }
                                        inheritViewBox
                                    />
                                </IconButton>
                            </Tooltip>
                            <Typography variant="caption">{filename}</Typography>
                        </>
                    )) || (
                        <IconButton
                            aria-label="fingerprint"
                            color="secondary"
                            component="label"
                            size="large">
                            <CustomIconSelector type="fileUpload" />
                            <input
                                type="file"
                                hidden
                                onChange={(e: any) => handleFileSelectorChange(e)}
                                accept={accept}
                            />
                        </IconButton>
                    )}
                    {error && <ErrorText text={error} />}
                </St.FileUploaderWithContentWrapper>
            )}
            {canOpenImgReader && (file || fileUrl) && (
                <ImageReaderModal
                    open={openDocumentReader}
                    handleClose={handleCloseDocumentReader}
                    imgUrl={fileUrl || (file && URL.createObjectURL(file))}
                />
            )}
            {canOpenPdfReader && (file || fileUrl) && (
                <PdfReaderModal
                    open={openDocumentReader}
                    handleClose={handleCloseDocumentReader}
                    pdfUrl={fileUrl || (file && URL.createObjectURL(file))}
                />
            )}
        </St.FileUploaderWrapper>
    );
};

const propTypes = {
    isLoading: PropTypes.bool.isRequired,
    isEdit: PropTypes.bool.isRequired,
    openDocumentReader: PropTypes.bool.isRequired,
    canOpenPdfReader: PropTypes.bool.isRequired,
    canOpenImgReader: PropTypes.bool.isRequired,
    fileExtension: PropTypes.string.isRequired,
    validExtensions: PropTypes.string.isRequired,
    filename: PropTypes.string.isRequired,
};

const defaultProps = {};

interface extraProps extends Tprop {
    displayTitle?: boolean;
    displayCleanerFunction?: boolean;
    error: string | null;
    handleCloseDocumentReader: () => void;
    handleOpenDocumentReader: () => void;
    cleanerFunction: () => void;
    handleFileSelectorChange: (e: any) => Promise<void>;
    accept?: string;
    fileIconMapper: { [key: string]: React.FunctionComponent };
    file: File | null;
    fileUrl?: string;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
FileUploader.propTypes = propTypes;
FileUploader.defaultProps = defaultProps;

export default FileUploader;
