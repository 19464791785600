import { Box, Typography, styled } from '@mui/material';

export default {
    ProfilePage: styled(Box)(({ theme }) => ({
        marginTop: '1rem',
    })),
    ProfileData: styled(Box)(({ theme }) => ({
        display: 'flex',
        fontSize: '1.2rem',
        marginBottom: '1.5rem',
    })),
    Label: styled(Box)(({ theme }) => ({
        marginRight: '0.5em',
        fontWeight: 'bold',
    })),
    ProfilePageTitle: styled(Typography)(({ theme }) => ({
        color: theme.palette.primary.main,
        fontSize: '1.4rem',
        lineHeight: '1.4rem',
        marginBottom: '2.2em',
    })) as typeof Typography,
    ProfileInputsBox: styled(Box)(({ theme }) => ({
        width: '120vh',
    })),
};
