import { withFakeData } from 'api/fakedata';
import { axiosDataInstance } from 'api/axiosInstances';
import {
    CreateMarketnonWorkingDayRequest,
    DeleteMarketnonWorkingDayRequest,
    EditMarketnonWorkingDayRequest,
    GetMarketsResponse,
    GetSettingsNonWorkingDaysResponse,
} from 'types/settingsNonWorkingDays/settingsNonWorking.api.types';

const apiName = 'SettingsNonWorkingDays';

export default {
    getSettingsNonWorkingDays: (param: string) => {
        const API_URL = `/dates/v1_0/bo/holidays/?${param}`;

        return withFakeData<GetSettingsNonWorkingDaysResponse>(
            () => axiosDataInstance.get(API_URL),
            apiName,
            'SettingsNonWorkingDays',
            false,
            500,
        );
    },
    getMarkets: () =>
        withFakeData<GetMarketsResponse>(
            () => axiosDataInstance.get('/dates/v1_0/bo/markets/'),
            apiName,
            'markets',
            false,
            500,
        ),
    createMarketnonWorkingDay: (req: CreateMarketnonWorkingDayRequest) =>
        withFakeData<{}>(
            () => axiosDataInstance.post('/dates/v1_0/bo/holidays/', req),
            apiName,
            'create-marketnonWorkingDay',
            false,
            500,
        ),
    editMarketnonWorkingDay: (req: EditMarketnonWorkingDayRequest) => {
        const { id, ...requestData } = req;

        return withFakeData<{}>(
            () => axiosDataInstance.put(`/dates/v1_0/bo/holidays/${id}`, requestData),
            apiName,
            'edit-marketnonWorkingDay',
            false,
            500,
        );
    },
    deleteMarketnonWorkingDay: (req: DeleteMarketnonWorkingDayRequest) =>
        withFakeData<{}>(
            () => axiosDataInstance.delete(`/dates/v1_0/bo/holidays/${req}`),
            apiName,
            'delete-marketnonWorkingDay',
            false,
            500,
        ),
};
