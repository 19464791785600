import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import UsersRolesPage from './UsersRolesPage';
import { useTranslation } from 'react-i18next';
import { CommonGridColumnsProps } from 'types/common/CommonGrid/CommonGridColumnProps.types';
import { formatDateWithHour } from 'utils/helpers/dateHelper';
import {
    ActionColumn,
    ActionHeader,
    HeaderFiltersProps,
} from 'types/common/CommonGrid/CommonGridFormModal.types';
import CustomIconSelector from 'components/common/CustomIconSelector';
import CustomTableAction from 'components/common/CommonGrid/Extra/CustomTableAction';
import { UsersRolesDefinition } from 'types/usersRoles/usersRoles.types';
import UserRolesForm from './UsersRolesForm';
import UserRolesFormDetail from './UsersRolesFormDetail';
import UsersRolesDeleteModal from './UsersRolesDeleteModal';
import { ADMIN } from 'constants/abilities.constants';

const UsersRolesPageContainer = (props: Props) => {
    const { t } = useTranslation('usersRoles');

    const columns: CommonGridColumnsProps<UsersRolesDefinition>[] = [
        {
            id: 'createdAt',
            name: t('users_roles_list_creation_date_column'),
            selector: row => formatDateWithHour(row.createdAt),
            sortField: 'createdAt',
            sortable: true,
            center: true,
        },
        {
            id: 'updatedAt',
            name: t('users_roles_list_modification_date_column'),
            selector: row => formatDateWithHour(row.updatedAt),
            sortField: 'updatedAt',
            sortable: true,
            center: true,
        },
        {
            id: 'rol',
            name: t('users_roles_list_rol_column'),
            selector: row => row.name,
            sortField: 'description',
            sortable: true,
            center: true,
        },
    ];
    const headerActions: ActionHeader[] = [
        {
            id: 'create',
            component: () => (
                <CustomTableAction
                    type="create"
                    Icon={props => <></>}
                    modalTitle={'roles'}
                    component={props => <UserRolesForm {...props} />}
                />
            ),
        },
    ];

    const actionColumns: ActionColumn[] = [
        {
            id: 'detail',
            icon: (props: any) => <CustomIconSelector type="detail" sx={props.sx} />,
            component: (props: any) => <UserRolesFormDetail isEdit {...props} />,
        },
        {
            id: 'edit',
            icon: (props: any) => <CustomIconSelector type="edit" sx={props.sx} />,
            component: (props: any) => <UserRolesForm isEdit {...props} />,
            isHidden: (row: any) => row.name === ADMIN,
        },
        {
            id: 'delete',
            icon: (props: any) => <CustomIconSelector type="delete" sx={props.sx} />,
            component: (props: any) => <UsersRolesDeleteModal {...props} />,
            isHidden: (row: any) => row.name === ADMIN,
        },
    ];

    const headerFilters: HeaderFiltersProps[] = [
        {
            id: 'name',
            label: t('users_roles_filter_rol_name', 'usersRoles'),
            type: 'text',
            placeholder: t('users_roles_filter_rol_name_placeholder'),
            query: 'name',
        },
        {
            id: 'createdAtFrom',
            label: t('users_roles_filter_created_at_from', 'usersRoles'),
            type: 'date',
            placeholder: t('users_roles_filter_created_at_placeholder_from', 'usersRoles'),
            query: 'createdAtFrom',
        },
        {
            id: 'createdAtTo',
            label: t('users_roles_filter_created_at_to', 'usersRoles'),
            type: 'date',
            placeholder: t('users_roles_filter_created_at_to_placeholder', 'usersRoles'),
            query: 'createdAtTo',
        },
        {
            id: 'updatedAtFrom',
            label: t('users_roles_filter_updated_at_from', 'usersRoles'),
            type: 'date',
            placeholder: t('users_roles_filter_updated_at_from_placeholder', 'usersRoles'),
            query: 'updatedAtFrom',
        },
        {
            id: 'updatedAtTo',
            label: t('users_roles_filter_updated_at_to', 'usersRoles'),
            type: 'date',
            placeholder: t('users_roles_filter_updated_at_to_placeholder', 'usersRoles'),
            query: 'updatedAtTo',
        },
    ];

    const childProps = {
        ...props,
        t,
        columns,
        headerActions,
        actionColumns,
        headerFilters,
    };
    return <UsersRolesPage {...childProps} />;
};

const propTypes = {};

const defaultProps = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
UsersRolesPageContainer.propTypes = propTypes;
UsersRolesPageContainer.defaultProps = defaultProps;

export default UsersRolesPageContainer;
