import { withFakeData } from 'api/fakedata';
import { axiosDataInstance } from 'api/axiosInstances';
import {
    GetRulesResponse,
    CreateInstrumentRuleRequest,
    EditInstrumentRuleRequest,
} from 'types/settingsInstrumentRules/settingsInstrumentRules.api.types';

const apiName = 'SettingsInstrumentRules';

export default {
    getRules: (filterQueryString: string) =>
        withFakeData<GetRulesResponse>(
            () => axiosDataInstance.get(`/rules/v1_0/bo/?${filterQueryString}`),
            apiName,
            'SettingsInstrumentRules',
            false,
            500,
        ),
    createInstrumentRule: (req: CreateInstrumentRuleRequest) =>
        withFakeData<any>(
            () => axiosDataInstance.post(`/rules/v1_0/bo/`, req),
            apiName,
            'SettingsInstrumentRules',
            false,
            500,
        ),
    editInstrumentRule: (req: EditInstrumentRuleRequest) =>
        withFakeData<any>(
            () => axiosDataInstance.put(`/rules/v1_0/bo/${req.id}`, req.body),
            apiName,
            'SettingsInstrumentRules',
            false,
            500,
        ),
    deleteInstrumentRule: (id: number) =>
        withFakeData<any>(
            () => axiosDataInstance.delete(`/rules/v1_0/bo/${id}`),
            apiName,
            'SettingsInstrumentRules',
            false,
            500,
        ),
};
