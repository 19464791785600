import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CommonGrid from 'components/common/CommonGrid';
import { Alerts } from 'types/alerts/alerts.types';
import { CommonGridColumnsProps } from 'types/common/CommonGrid/CommonGridColumnProps.types';
import {
    ActionColumn,
    ActionHeader,
    HeaderFiltersProps,
} from 'types/common/CommonGrid/CommonGridFormModal.types';
import { useDeleteAlert, useAlerts } from 'hooks/api/alerts.hooks';

const SettingsAlertsPage = (props: Props) => {
    const { t, headerActions, headerFilters, actionColumns, columns } = props;
    return (
        <CommonGrid
            title={t('alerts_list_title')}
            columns={columns}
            resource="alerts"
            useGetData={useAlerts}
            defaultSortFieldId="createdAt"
            canExport
            extraActionsInHeader={headerActions}
            customActionColumns={actionColumns}
            headerFilters={headerFilters}
            deleteHook={useDeleteAlert}
        />
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

const defaultProps = {};

interface extraProps {
    columns: CommonGridColumnsProps<Alerts>[];
    headerActions: ActionHeader[];
    actionColumns: ActionColumn[];
    headerFilters: HeaderFiltersProps[];
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
SettingsAlertsPage.propTypes = propTypes;
SettingsAlertsPage.defaultProps = defaultProps;

export default SettingsAlertsPage;
