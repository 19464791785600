import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './HeaderFilters.styled';
import { HeaderFiltersProps } from 'types/common/CommonGrid/CommonGridFormModal.types';
import { Grid, TextField } from '@mui/material';
import ActionButton from 'components/common/ActionButton';
import HeaderFilterDate from './HeaderFilterDate';
import HeadersSelect from './HeadersSelect';
import HeaderFilterText from './HeaderFilterText';
import RequestAutocomplete from 'components/common/RequestAutocomplete';

const FilterComponents: { [key: string]: any } = {
    text: HeaderFilterText,
    number: HeaderFilterText,
    date: HeaderFilterDate,
    select: HeadersSelect,
    multiple: HeadersSelect,
    requestAutocomplete: RequestAutocomplete,
    requestAutocompleteMultiple: RequestAutocomplete,
    default: TextField,
};

const HeaderFilters = (props: Props) => {
    const { t, filterString, filters, onSubmit, setFilterString, onClear } = props;

    return (
        <St.Container>
            <Grid container spacing={1}>
                {filters.map(filter => {
                    const {
                        label,
                        placeholder,
                        query,
                        type,
                        id,
                        options,
                        regex,
                        requestAutocomplete,
                    } = filter;
                    const Component = FilterComponents[type] || FilterComponents.default;

                    return (
                        <Grid key={id} item xs={12} sm={6} md={4} lg={3} xl={2}>
                            <Component
                                type={type}
                                regex={regex}
                                query={query}
                                label={label}
                                placeholder={placeholder}
                                filterstring={filterString}
                                setfilterstring={setFilterString}
                                options={options || []}
                                requestAutocomplete={requestAutocomplete}
                            />
                        </Grid>
                    );
                })}
            </Grid>
            <St.FooterButtons direction={'row'} spacing={1}>
                <ActionButton variant="outlined" onClick={onClear} size="small" type="button">
                    {t('clear')}
                </ActionButton>
                <ActionButton onClick={onSubmit} size="small" type="button">
                    {t('filter')}
                </ActionButton>
            </St.FooterButtons>
        </St.Container>
    );
};

const propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onClear: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

const defaultProps = {};

interface extraProps {
    filters: HeaderFiltersProps[];
    filterString: string;
    setFilterString: React.Dispatch<React.SetStateAction<string>>;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
HeaderFilters.propTypes = propTypes;
HeaderFilters.defaultProps = defaultProps;

export default HeaderFilters;
