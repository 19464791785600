import { EXPIRED, PENDING, ASSIGNED, REJECTED, CANCELLED, COMPLETED } from 'constants/appConstants';
import { t } from 'i18next';

export const statesToShowActions = [ASSIGNED, COMPLETED];

export const statesToShowActionsContainer = [COMPLETED, PENDING, ASSIGNED];

export const statusOptions = [
    { id: ASSIGNED, name: t('ASSIGNED') },
    { id: PENDING, name: t('PENDING') },
    { id: EXPIRED, name: t('EXPIRED') },
    { id: CANCELLED, name: t('CANCELLED') },
    { id: REJECTED, name: t('REJECTED') },
    { id: COMPLETED, name: t('COMPLETED') },
];
