import React, { createContext, useState, ReactNode } from 'react';

interface SidebarProviderProps {
    children: ReactNode;
}

interface IntialState {
    drawerWidth: number;
    updateDrawerWidth: (newWidth: number) => void;
}

export const SidebarContext = createContext({} as IntialState);

const SidebarProvider = ({ children }: SidebarProviderProps) => {
    const [drawerWidth, setDrawerWidth] = useState(240);

    const updateDrawerWidth = (newWidth: React.SetStateAction<number>) => {
        setDrawerWidth(newWidth);
    };

    const memoedValue = React.useMemo(() => {
        const value = {
            drawerWidth,
            updateDrawerWidth,
        };
        return value;
    }, [drawerWidth]);

    return <SidebarContext.Provider value={memoedValue}>{children}</SidebarContext.Provider>;
};

export default SidebarProvider;
