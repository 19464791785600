import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import PasswordField from 'components/common/PasswordField';
import { FormikContextType, FormikProvider } from 'formik';
import St from './SendMailForm.styled';
import FadeIn from 'components/common/FadeIn';
import ErrorText from 'components/common/ErrorText';

const SendMailForm = (props: Props) => {
    const { t, isLoading, formik, errorMessage } = props;

    const { errors, values, setFieldValue } = formik;
    return (
        <FormikProvider value={formik}>
            <St.Form data-testid="SendMail-form">
                <FadeIn>
                    <Stack spacing={1} gap="1rem">
                        <St.Title variant="h5">{t('forgot_password_title')}</St.Title>
                        <TextField
                            fullWidth
                            autoFocus
                            size="small"
                            id="email"
                            label="Email"
                            type="text"
                            value={values.email}
                            onChange={({ target }: any) => {
                                setFieldValue('email', target.value);
                            }}
                            error={errors.email !== undefined}
                            helperText={errors.email?.toString()}
                            placeholder={t('email_placeholder')}
                            variant="outlined"
                        />
                        {errorMessage && <ErrorText text={errorMessage} />}
                        <Stack width={'100%'} rowGap={'0.5rem'}>
                            <LoadingButton
                                fullWidth
                                type="submit"
                                variant="contained"
                                loading={isLoading}>
                                {t('send_code')}
                            </LoadingButton>
                            <Button
                                variant="outlined"
                                color="primary"
                                fullWidth
                                disabled={isLoading}
                                component={RouterLink}
                                to="/login">
                                {t('back')}
                            </Button>
                        </Stack>
                    </Stack>
                </FadeIn>
            </St.Form>
        </FormikProvider>
    );
};

const propTypes = {
    mainLogo: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    t: PropTypes.any.isRequired,
};

const defaultProps = {};

interface extraProps {
    formik: FormikContextType<any>;
    errorMessage?: string;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
SendMailForm.propTypes = propTypes;
SendMailForm.defaultProps = defaultProps;

export default SendMailForm;
