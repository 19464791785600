import { useMutation } from '@tanstack/react-query';
import React, { useContext, useState } from 'react';
import { AuthContext } from 'context/auth.context';
import endpoints from '../../api/auth/auth.api';
import { LoginResponse } from 'types/auth/auth.api.types';
import { useNavigate } from 'react-router-dom';
import { tErrorsContext } from 'constants/appConstants';
import { useTranslation } from 'react-i18next';
import { cleanStorage, setAuthData } from 'storage/auth.storage';
import { errorResponseHelper } from 'utils/helpers/errorsHelper';
import { NotificationsContext } from 'context/notifications.context';
import { useGetFilteredRoutes } from 'hooks/common/roles.hooks';

const USER_NOT_CONFIRMED_ERROR = 'UserNotConfirmedException';
export const useLogin = () => {
    const { connect } = useContext(NotificationsContext);
    const { filterMenuRoutes } = useGetFilteredRoutes();
    const {
        setIsAuthenticated,
        setUserData,
        handleUserAbilities: setUserAbilities,
    } = useContext(AuthContext);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [customErrorMessage, setCustomErrorMessage] = useState<string | null>(null);
    const { mutate, isLoading, error, isError } = useMutation(endpoints.login, {
        onSuccess: async ({ data }: { data: LoginResponse }) => {
            const { authenticationResult, user } = data;
            if (!user.assignedRoles?.length && !user.assignedAbilities?.length) {
                setCustomErrorMessage(t('user_without_roles_error_response', tErrorsContext));
            } else {
                setIsAuthenticated(true);
                setAuthData(
                    authenticationResult.expiresIn,
                    authenticationResult.refreshToken,
                    authenticationResult.idToken,
                );
                // connect to notifications
                connect(authenticationResult.idToken);
                setUserData(user);
                setUserAbilities(user.assignedRoles, user.assignedAbilities);
                navigate(filterMenuRoutes()[0]?.path ?? '/');
            }
        },
        onError: async (err: any) => {
            const body = JSON.parse(err.response.data);
            if (err.response.data.error_messages[0].code === USER_NOT_CONFIRMED_ERROR) {
                navigate('/email-verification', {
                    state: { needEmailVerification: true, username: body.username },
                });
            }
        },
    });
    return {
        mutate,
        isLoading,
        isError: customErrorMessage !== null ? true : isError,
        errorMessage: customErrorMessage ?? errorResponseHelper(error),
    };
};

export const useLogout = () => {
    const { disconnect } = useContext(NotificationsContext);
    const { setIsAuthenticated } = useContext(AuthContext);
    const navigate = useNavigate();

    const { mutate: onLogout, isLoading } = useMutation(endpoints.logout, {
        onSuccess: async () => {
            setIsAuthenticated(false);
            cleanStorage();
            disconnect();
            navigate('/login');
        },
    });

    const mutate = () => {
        setIsAuthenticated(false);
        cleanStorage();
        disconnect();
        navigate('/login');
        // TODO: Definir función en base al proyecto a desarrollar
        // const { email: username } = getUserData();
        // if (username) onLogout({ username });
    };
    return { mutate, isLoading };
};

export const useCheckLogin = () => {
    const response = true;
    if (response) {
        return { address: 'a', hasSigned: true };
    } else {
        throw new Error('Usuario deslogueado');
    }
};

export const useForgotPassword = () => {
    const [errorMessage, setErrorMessage] = React.useState();
    const { mutate, isLoading } = useMutation(endpoints.forgotPassword, {
        onSuccess: () => {},
        onError: (error: any) => {
            setErrorMessage(error.response.data.error_messages[0]?.message ?? '');
        },
    });
    return { mutate, isLoading, errorMessage };
};

export const useForgotPasswordConfirmation = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { mutate, isLoading, error } = useMutation(endpoints.forgotPasswordConfirmation, {
        onSuccess: () => {
            navigate('/login', { state: { isPasswordChanged: true } });
        },
    });
    return {
        mutate,
        isLoading,
        errorMessage: t(
            (error as any)?.response?.data?.error_messages[0].code ?? '',
            tErrorsContext,
        ),
    };
};

export const useResendEmailVerificationCode = () => {
    const { t } = useTranslation('auth');
    const { mutate, isLoading, error, isSuccess } = useMutation(
        endpoints.resendEmailVerificationCode,
    );
    return {
        mutate,
        isLoading,
        successMessage: isSuccess ? t('resend_code_success_message') : '',
        error,
    };
};

export const useEmailVerification = () => {
    const { t } = useTranslation();
    const { mutate, isLoading, error } = useMutation(endpoints.emailVerification);
    return {
        mutate,
        isLoading,
        errorMessage: t(
            (error as any)?.response?.data?.error_messages[0].code ?? '',
            tErrorsContext,
        ),
    };
};
