import { Box, styled } from '@mui/material';

export default {
    UserRolesDetail: styled(Box)(({ theme }) => ({
        marginTop: '1rem',
        padding: '1rem',
    })),
    RoleDescription: styled(Box)(({ theme }) => ({
        display: 'flex',
        fontSize: '1.2rem',
        marginBottom: '1.5rem',
    })),
    Label: styled(Box)(({ theme }) => ({
        marginRight: '0.5em',
        fontWeight: 'bold',
    })),
    RoleTypeValue: styled(Box)(({ theme }) => ({
        marginTop: '0.5em',
        backgroundColor: '#f5f5f5',
        color: 'black',
        border: '1px solid #e0e0e0',
    })),
};
