import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import StatusTable from './StatusTable';
import { StateTableInfo } from 'types/common/general.types';
import { useTranslation } from 'react-i18next';

const StatusTableContainer = (props: Props) => {
    // const {} = props;
    const { t } = useTranslation();

    const childProps = {
        ...props,
        t,
    };

    return <StatusTable {...childProps} />;
};

const propTypes = {};

const defaultProps = {};

interface extraProps {
    content: StateTableInfo<string>[];
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
StatusTableContainer.propTypes = propTypes;
StatusTableContainer.defaultProps = defaultProps;

export default StatusTableContainer;
