import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import RuleTab from './RuleTab';
import { RulesTabValues } from 'types/settingsInstrumentRules/settingsInstrumentRules.types';
import { FormikErrors } from 'formik';
import { useTranslation } from 'react-i18next';

const RuleTabContainer = (props: Props) => {
    // const {} = props;
    const { t } = useTranslation('settingsInstrumentRules');
    const rulesTypesOptions = [
        { id: 1, value: 'BLOCK BUY', name: 'Bloquear Compra' },
        { id: 2, value: 'BLOCK SELL', name: 'Bloquear Venta' },
        { id: 3, value: 'SOFT DELETE', name: 'Ocultar' },
    ];

    const childProps = {
        ...props,
        t,
        rulesTypesOptions,
    };

    return <RuleTab {...childProps} />;
};

const propTypes = {
    setFieldValue: PropTypes.func.isRequired,
};

const defaultProps = {};

interface extraProps {
    values: RulesTabValues;
    errors: FormikErrors<RulesTabValues>;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
RuleTabContainer.propTypes = propTypes;
RuleTabContainer.defaultProps = defaultProps;

export default RuleTabContainer;
