import { paginationAdapter } from 'adapters/common/pagination.adapter';
import {
    Functionality,
    FunctionalityResponse,
} from 'types/settingsBlocks/settingsBlocks.api.types';

export const blocksAdapter = (data: FunctionalityResponse) => {
    return {
        ...paginationAdapter(data),
        data: data.items?.map((functionality: Functionality) => {
            return {
                locked: functionality.locked,
                details: functionality.details,
                id: functionality.id,
                name: functionality.name,
                updatedAt: functionality.updatedAt,
            };
        }),
    };
};
