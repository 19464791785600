import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './PageTitle.styled';

const PageTitle = (props: Props) => {
    const { children } = props;

    return (
        <>
            <St.Title variant="h1" color={'primary'}>
                {children}
            </St.Title>
        </>
    );
};

const propTypes = {
    children: PropTypes.string.isRequired,
};

const defaultProps = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}

PageTitle.propTypes = propTypes;
PageTitle.defaultProps = defaultProps;

export default PageTitle;
