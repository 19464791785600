import i18next from 'i18next';
import { languages } from 'types/common/general.types';

export const getAuthToken = () => {
    return localStorage.getItem('IdToken');
};

export const setAuthToken = (token: string) => {
    return localStorage.setItem('IdToken', token);
};

export const getIsAuthenticated = () => {
    return !!getAuthToken();
};
export const getAuthData = async () => {
    const ExpiresInString = await localStorage.getItem('ExpiresIn');
    const ExpiresIn =
        ExpiresInString != null && ExpiresInString != '' ? parseInt(ExpiresInString) : -1;
    const TokenType = await localStorage.getItem('TokenType');
    const RefreshToken = await localStorage.getItem('RefreshToken');
    const IdToken = await localStorage.getItem('IdToken');
    const timestamp = await localStorage.getItem('timestamp');
    return {
        ExpiresIn,
        TokenType,
        RefreshToken,
        IdToken,
        timestamp,
    };
};

export const setAuthData = async (ExpiresIn: number, RefreshToken: string, IdToken: string) => {
    localStorage.setItem('ExpiresIn', ExpiresIn.toString());
    localStorage.setItem('RefreshToken', RefreshToken);
    localStorage.setItem('IdToken', IdToken);
};

export const removeAuthdata = async () => {
    localStorage.setItem('ExpiresIn', '');
    localStorage.setItem('TokenType', '');
    localStorage.setItem('RefreshToken', '');
    localStorage.setItem('IdToken', '');
    localStorage.setItem('timestamp', '');
};

export const getLanguage = () => languages[i18next.language];
export const cleanStorage = () => localStorage.clear();

export const getUserData = () => {
    const userData = localStorage.getItem('User');
    if (userData) return JSON.parse(userData);
    return null;
};

export const getExpirationToken = () => localStorage.getItem('ExpirationDate');
