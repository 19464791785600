import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import UserRolesFormDetail from './UsersRolesFormDetail';
import { useTranslation } from 'react-i18next';
import { RolesDefinition } from 'types/usersRoles/usersRoles.types';
// ---------------------------------------------//
// ---------------------------------------------//
// ---------------------------------------------//

const UserRolesFormDetailContainer = (props: Props) => {
    const { row, close } = props;
    const { t } = useTranslation('usersRoles');

    const childProps = {
        ...props,
        t,
        row,
        close,
    };

    return <UserRolesFormDetail {...childProps} />;
};

const propTypes = {};

const defaultProps = {};

interface extraProps {
    row: RolesDefinition;
    close: () => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
UserRolesFormDetailContainer.propTypes = propTypes;
UserRolesFormDetailContainer.defaultProps = defaultProps;

export default UserRolesFormDetailContainer;
