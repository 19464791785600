import { AppBar, Box, SvgIcon, Toolbar } from '@mui/material';
import { styled as styledMui } from '@mui/material/styles';

export default {
    FixedAppBar: styledMui(AppBar)(() => ({
        position: 'fixed',
        background: 'white',
    })),
    Toolbar: styledMui(Toolbar)(() => ({
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    })),
    Logo: styledMui(SvgIcon)(({ theme }) =>
    theme.unstable_sx({
        objectFit: 'contain',
        width:'auto',
        color:theme.palette.primary.main,   
    }),
) as unknown as typeof SvgIcon,
};
