import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import HelmetLayout from './HelmetLayout';
import { useTranslation } from 'react-i18next';

const HelmetLayoutContainer = (props: Props) => {
    const { withOutlet } = props;
    const { t } = useTranslation();

    const childProps = {
        ...props,
        withOutlet: withOutlet ?? true,
        t,
    };

    return <HelmetLayout {...childProps} />;
};

const propTypes = {
    withOutlet: PropTypes.bool,
};

const defaultProps = {
    withOutlet: true,
};

interface extraTypes {}

interface Props extends InferPropsExtended<typeof propTypes, extraTypes> {}
HelmetLayoutContainer.propTypes = propTypes;
HelmetLayoutContainer.defaultProps = defaultProps;

export default HelmetLayoutContainer;
