import { styled as styledMui, keyframes } from '@mui/material/styles';
import { Box, Stack, SvgIcon } from '@mui/material';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export default {
    LoginForm: styledMui(Box)(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        background: theme.palette.custom.whiteSmoke,
        padding: '2em',
        borderRadius: '0.5rem',
        gap: '8px',
        left: `-webkit-calc(50% - 135px - 2em)`,
        top: `-webkit-calc(50% - 135px - 2em)`,
        height: 'auto',
        maxWidth: '100%',
        boxShadow: '4px 5px 36px -9px rgba(0, 0, 0, 0.98)',
        animation: fadeIn,
        animationDuration: '1s',
        animationFillMode: 'both',
    })) as typeof Box,
    LogoContainer: styledMui(Box)({
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '0.6rem',
    }),
    Logo: styledMui(SvgIcon)(({ theme }) =>
        theme.unstable_sx({
            maxWidth: '180px',
            objectFit: 'contain',
            width: 'auto',
            height: 'auto',
        }),
    ) as unknown as typeof SvgIcon,
    Stack: styledMui(Stack)(({ theme }) =>
        theme.unstable_sx({
            width: { md: '300px' },
        }),
    ),
};
