import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ClientSectionTaxSituation from './ClientSectionTaxSituation';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const ClientSectionTaxSituationContainer = (props: Props) => {
    const { t } = useTranslation('clients');

    const childProps = {
        ...props,
        t,
    };

    return <ClientSectionTaxSituation {...childProps} />;
};

const propTypes = {
    earningRegistration: PropTypes.string.isRequired,
    taxCondition: PropTypes.string.isRequired,
};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ClientSectionTaxSituationContainer.propTypes = propTypes;

export default ClientSectionTaxSituationContainer;
