import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './HeadersSelect.styled';
import CustomAutoComplete from 'components/common/CustomAutoComplete';

const HeadersSelect = (props: Props) => {
    const { onChange, query, value, label, options, type } = props;
    const multiple = type === 'multiple';
    return (
        <CustomAutoComplete
            id={query}
            options={options ?? []}
            value={multiple ? (Object.keys(value).length === 0 ? [] : value) : value[0] ?? null}
            onChange={(e, value) => {
                onChange(value);
            }}
            filterSelectedOptions
            multiple={multiple}
            placeholder={label}
            renderOption={(props, option, { selected }) => (
                <St.OptionContainer {...props}>
                    <St.OptionTitle variant="subtitle1">{option.name}</St.OptionTitle>
                    {option.description && (
                        <St.OptionDescription variant="body2" color="textSecondary">
                            {option.description}
                        </St.OptionDescription>
                    )}
                </St.OptionContainer>
            )}
        />
    );
};

const propTypes = {
    onChange: PropTypes.func.isRequired,
    query: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired,
};

const defaultProps = {};

interface extraProps {
    value: any | any[] | null;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
HeadersSelect.propTypes = propTypes;
HeadersSelect.defaultProps = defaultProps;

export default HeadersSelect;
