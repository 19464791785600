import { paginationAdapter } from 'adapters/common/pagination.adapter';
import {
    GetAllUsersRolesResponse,
    GetUsersRolesResponse,
    RoleApi,
    RolesApi,
    getUsersAbilitiesResponse,
} from 'types/usersRoles/usersRoles.api.types';
import {
    AbilitiesListDefinition,
    RoleDefinition,
    UsersAbilitiesDefinition,
    UsersRolesDefinition,
} from 'types/usersRoles/usersRoles.types';

export const userRolesAdapter = (data: GetUsersRolesResponse) => {
    return {
        ...paginationAdapter(data),
        data: data.items?.map((userRole: RoleApi) => {
            return {
                id: userRole?.id,
                name: userRole?.name,
                description: userRole?.description,
                enabled: userRole?.enabled,
                createdAt: userRole?.createdAt,
                updatedAt: userRole?.updatedAt,
                abilities: userRole?.assignedAbilities,
            } as UsersRolesDefinition;
        }),
    };
};

export const userAbilitiesAdapter = (data: getUsersAbilitiesResponse[]) => {
    const abilitiesNameGroup: string[] = [];
    const abilitiesList: AbilitiesListDefinition[] = [];

    data?.forEach((userRole: UsersAbilitiesDefinition) => {
        const foundedInList = abilitiesNameGroup?.find(
            (ability: any) => ability === userRole?.name.split('_').pop(),
        );
        if (!foundedInList) {
            const rolObject = {
                name: userRole?.name,
                list: data?.filter((dat: UsersAbilitiesDefinition) => {
                    return dat?.name.split('_').pop() === userRole?.name.split('_').pop();
                }),
            };
            abilitiesNameGroup?.push(rolObject?.name?.split('_')?.pop()!);
            abilitiesList?.push(rolObject);
        }
    });
    return abilitiesList;
};

export const allUserRolesAdapter = (data: GetAllUsersRolesResponse) => {
    return {
        ...paginationAdapter(data),
        data: data?.items?.map((userRole: RolesApi) => {
            return {
                abilities: userRole?.assignedAbilities,
                createdAt: userRole.createdAt,
                description: userRole.description,
                enabled: userRole.enabled,
                id: userRole.id,
                name: userRole.name,
                updatedAt: userRole.updatedAt,
            } as RoleDefinition;
        }),
    };
};
