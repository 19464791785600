import { useMutation } from '@tanstack/react-query';
import endpoints from '../../api/requestAutocomplete/requestAutocomplete.api';

export const useGetAutocompleteInfo = () => {
    const { mutate, isLoading, isError } = useMutation(endpoints.getAutocompleteInfo);

    return {
        mutate,
        isLoading,
        isError,
    };
};
