import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import {
    AlertColor,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
} from '@mui/material';
import CustomIconSelector from 'components/common/CustomIconSelector';
import defaultTheme from 'utils/theme/defaultTheme';
import { GENERAL } from 'constants/appConstants';
import EditClientGeneralForm from '../EditClientGeneralForm';
import EditClientAddressForm from '../EditClientAddressForm';
import {
    InitialContactValuesToEdit,
    InitialGeneralValuesToEdit,
} from 'types/clients/clients.types';

const EditClientModal = ({
    openModalEdit,
    id,
    initialValues,
    isProspect,
    handleClose,
    t,
    setSnackBarMessage,
    refetch,
}: Props) => (
    <Dialog open={openModalEdit.show} onClose={handleClose}>
        <DialogTitle>
            <Typography fontSize={22}>
                {t(`client_edit_title${isProspect ? '_prospect' : ''}`)}
            </Typography>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                }}>
                <CustomIconSelector type="close" sx={{ color: defaultTheme.palette.grey[500] }} />
            </IconButton>
        </DialogTitle>
        <DialogContent>
            {openModalEdit.section === GENERAL ? (
                <EditClientGeneralForm
                    id={id}
                    refetch={refetch}
                    isProspect={isProspect}
                    close={handleClose}
                    initialValues={initialValues}
                    setSnackBarMessage={setSnackBarMessage}
                />
            ) : (
                <EditClientAddressForm
                    id={id}
                    refetch={refetch}
                    isProspect={isProspect}
                    close={handleClose}
                    initialValues={initialValues}
                    setSnackBarMessage={setSnackBarMessage}
                />
            )}
        </DialogContent>
    </Dialog>
);

const propTypes = {
    t: PropTypes.func.isRequired,
};

const defaultProps = {};

interface extraProps {
    id: string;
    initialValues: InitialGeneralValuesToEdit & InitialContactValuesToEdit;
    openModalEdit: { show: boolean; section: string };
    isProspect?: boolean;
    handleClose(): void;
    setSnackBarMessage: (msj: string, sever?: AlertColor) => void;
    refetch: () => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
EditClientModal.propTypes = propTypes;
EditClientModal.defaultProps = defaultProps;

export default EditClientModal;
