import React from 'react';
import {
    ExtraQueryFiltersProps,
    GridHookParams,
} from 'types/common/CommonGrid/CommonGridFormModal.types';
import { exportFromJson } from 'utils/helpers/commonHelper';

const useExportData = (
    resource: string,
    useGetData: (params: GridHookParams) => any,
    columns: Array<any>,
    totalRows: number,
    clientSidePagination: boolean,
    extraFilters?: Array<ExtraQueryFiltersProps>,
    backTableId?: number,
) => {
    const [isExporting, setIsExporting] = React.useState<boolean | null>(null);
    const filterQueryString = React.useMemo(() => {
        let extraQueryFilters = '';
        if (!clientSidePagination) extraQueryFilters = `&page=1&pageSize=${totalRows || 1}`;
        extraFilters?.forEach((element: ExtraQueryFiltersProps) => {
            extraQueryFilters += `&${element.query}=${element.queryValue}`;
        });
        return extraQueryFilters;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [extraFilters, totalRows]);

    const {
        data: dataFromQuery,
        isLoading: loading,
        refetch,
    } = useGetData({
        filterQueryString,
        extraFilters: extraFilters ?? [],
        forceDisable: true,
        backTableId,
    } as GridHookParams);

    React.useEffect(() => {
        if (dataFromQuery && isExporting) {
            try {
                const columnsToShow = columns
                    .filter(x => !x.omitExport)
                    .map(column => ({
                        id: column.id,
                        name: column.name,
                        selector: column.selector || column.cell,
                    }));

                const formattedData = dataFromQuery.data?.map((x: any) => {
                    const row: any = {};
                    columnsToShow.forEach(({ name, selector }: { name: string; selector: any }) => {
                        row[name as keyof typeof row] = selector(x);
                    });
                    return row;
                });

                exportFromJson(
                    formattedData,
                    columnsToShow.map(x => x.name),
                    resource,
                );
            } catch (error) {
                console.log('error', error);
            } finally {
                setIsExporting(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataFromQuery, isExporting, totalRows]);

    const handleExportData = async () => {
        await refetch();
        setIsExporting(true);
    };
    return { handleExportData, loading };
};

export default useExportData;
