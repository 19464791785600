import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ActionButton from 'components/common/ActionButton';
import { ReactElement } from 'react';

const TableHeaderButton = (props: Props) => {
    const { text, variant, handleClick, disabled, icon } = props;

    return (
        <ActionButton disabled={disabled} color="secondary" variant={variant} onClick={handleClick}>
            {icon} &nbsp;
            {text}
        </ActionButton>
    );
};

const propTypes = {
    text: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
};

const defaultProps = {};

interface extraProps {
    icon?: ReactElement;
    handleClick: () => void;
    variant: 'outlined' | 'contained' | 'text';
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
TableHeaderButton.propTypes = propTypes;
TableHeaderButton.defaultProps = defaultProps;

export default TableHeaderButton;
