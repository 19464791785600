import { useMutation, useQuery } from '@tanstack/react-query';
import endpoints from '../../api/clientDeleteAccountRequest/clientDeleteAccountRequest.api';
import { GridHookParams } from 'types/common/CommonGrid/CommonGridFormModal.types';
import { useTranslation } from 'react-i18next';
import { ClientDeleteAccountRequestAdapter } from 'adapters/api/clientDeleteAccountRequest.adapter';
import { errorResponseHelper } from 'utils/helpers/errorsHelper';

export const useGetDeleteAccountRequest = ({ forceDisable, filterQueryString }: GridHookParams) => {
    const enabledCondition = !forceDisable;

    const { data, isLoading, isFetching, isRefetching, refetch } = useQuery(
        ['delete-account-request'],
        () => endpoints.getDeleteAccountRequest(filterQueryString),
        {
            enabled: enabledCondition,
            select: data => {
                return ClientDeleteAccountRequestAdapter(data?.data);
            },
        },
    );

    return {
        data,
        isLoading: (isLoading && isFetching) || isRefetching,
        refetch,
    };
};

export const useRejectDeleteAccountRequest = (close: Function, setSnackbarMessage: Function) => {
    const { t } = useTranslation('clientDeleteAccountRequest');
    const { mutate, isLoading } = useMutation(endpoints.rejectDeleteAccountRequest, {
        onSuccess: () => {
            setSnackbarMessage(t('delete_account_request_modal_reject_success'));
            close();
        },
        onError: error => {
            setSnackbarMessage(errorResponseHelper(error), 'error');
            close();
        },
    });

    return {
        mutate,
        isLoading,
    };
};

export const useAcceptDeleteAccountRequest = (close: Function, setSnackbarMessage: Function) => {
    const { t } = useTranslation('clientDeleteAccountRequest');
    const { mutate, isLoading } = useMutation(endpoints.acceptDeleteAccountRequest, {
        onSuccess: () => {
            setSnackbarMessage(t('delete_account_request_modal_accept_success'));
            close();
        },
        onError: error => {
            setSnackbarMessage(errorResponseHelper(error), 'error');
            close();
        },
    });

    return {
        mutate,
        isLoading,
    };
};

export const useDeleteAccountRequest = (close: Function, setSnackbarMessage: Function) => {
    const { t } = useTranslation('clientDeleteAccountRequest');
    const { mutate, isLoading } = useMutation(endpoints.deleteAccountRequest, {
        onSuccess: () => {
            setSnackbarMessage(t('delete_account_request_modal_delete_success'));
            close();
        },
        onError: error => {
            setSnackbarMessage(errorResponseHelper(error), 'error');
            close();
        },
    });

    return {
        mutate,
        isLoading,
    };
};
