import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { useGetBlocks } from 'hooks/api/settingsBlocks.hooks';
import CommonGrid from 'components/common/CommonGrid';
import { Functionality } from 'types/settingsBlocks/settingsBlocks.api.types';
import { CommonGridColumnsProps } from 'types/common/CommonGrid/CommonGridColumnProps.types';
import { ActionColumn } from 'types/common/CommonGrid/CommonGridFormModal.types';

const SettingsBlocksPage = ({ t, actionColumns, columns }: Props) => (
    <CommonGrid
        title={t('settings_blocks_list_title')}
        columns={columns}
        resource="blocks"
        useGetData={useGetBlocks}
        defaultSortFieldId="updatedAt"
        canExport
        customActionColumns={actionColumns}
    />
);

const propTypes = {
    t: PropTypes.func.isRequired,
};

const defaultProps = {};

interface extraProps {
    columns: CommonGridColumnsProps<Functionality>[];
    actionColumns: ActionColumn[];
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
SettingsBlocksPage.propTypes = propTypes;
SettingsBlocksPage.defaultProps = defaultProps;

export default SettingsBlocksPage;
