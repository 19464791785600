import { withFakeData } from 'api/fakedata';
import { axiosDataInstance } from 'api/axiosInstances';

const apiName = 'requestAutocomplete';

export default {
    getAutocompleteInfo: ({ url, param }: { url: string; param: string }) =>
        withFakeData<any>(
            () => axiosDataInstance.get(`${url}?${param}`),
            apiName,
            'requestAutocompleteCall',
            false,
            2000,
        ),
};
