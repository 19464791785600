import * as React from 'react';
import DataTable from 'react-data-table-component';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { transientOptions } from 'utils/helpers/stylesHelper';

const Styled = styled(DataTable)`
    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    .rdt_TableBody {
    }
    .rdt_TableHeadRow {
        background-color: #6ea5e7;
        color: #ffff;
        border-color: currentcolor #ebe9f1 #ebe9f1;
        font-weight: 600;
    }
    .rdt_TableRow {
        &:hover {
            opacity: 0.8;
        }
        min-height: 32px;
    }
    .rdt_TableCol {
        padding-right: 0;
        padding-left: 0;
        min-width: fit-content;
        justify-content: center;
    }

    .rdt_TableCell {
    }
`;

const DataTableStyled = (props: any) => <Styled {...props} />;

export default {
    DataTableStyled,
    GridWrapper: styled(
        Box,
        transientOptions,
    )<{ $drawerWidth: number }>(({ theme, $drawerWidth }) =>
        theme.unstable_sx({
            width: {
                xs: `calc(${window.innerWidth}px - 2rem)`,
                md: `calc(${window.innerWidth}px - ${$drawerWidth === 260 ? '20rem' : '7rem'})`,
            },
        }),
    ),
};
