import { paginationAdapter } from 'adapters/common/pagination.adapter';
import {
    ExternalInstrumentApi,
    GetExternalInstrumentResponse,
} from 'types/marketExteriorInstruments/marketExteriorInstruments.api.types';
import { ExternalInstrument } from 'types/marketExteriorInstruments/marketExteriorInstruments.types';

export const externalInstrumentsAdapter = (data: GetExternalInstrumentResponse) => {
    return {
        ...paginationAdapter(data),
        data: data.items?.map((externalInstrument: ExternalInstrumentApi) => {
            return {
                id: externalInstrument.id,
                creationDate: externalInstrument.createdAt,
                modifiedDate: externalInstrument.updatedAt,
                description: externalInstrument.description,
                tickers: externalInstrument.tickers,
                type: externalInstrument.type,
                profile: externalInstrument.profile,
                name: externalInstrument.name,
                extraData: externalInstrument.extraData,
                icon: externalInstrument.icon,
            } as ExternalInstrument;
        }),
    };
};
