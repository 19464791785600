import { useMutation, useQuery } from '@tanstack/react-query';
import { userBusinessAssistantAdapter } from 'adapters/api/usersBusinessAssistant.adapter';
import { GridHookParams } from 'types/common/CommonGrid/CommonGridFormModal.types';
import endpoints from '../../api/usersBusinessAssistant/usersBusinessAssistant.api';
import { useTranslation } from 'react-i18next';
import { errorResponseHelper } from 'utils/helpers/errorsHelper';

export const useBusinessAssistant = ({ forceDisable, filterQueryString }: GridHookParams) => {
    const enabledCondition = !forceDisable;

    const { data, isLoading, isFetching, isRefetching, refetch } = useQuery(
        ['users-business-assistant', filterQueryString],
        () => endpoints.getBusinessAssistants(filterQueryString),
        {
            enabled: enabledCondition,
            select: data => {
                return userBusinessAssistantAdapter(data?.data);
            },
        },
    );

    return {
        data,
        isLoading: (isLoading && isFetching) || isRefetching,
        refetch,
    };
};

export const useCreateBusinessAssistant = (close: Function, setSnackBarMessage: Function) => {
    const { t } = useTranslation('usersBusinessAssistant');
    const { mutate, isLoading } = useMutation(endpoints.createBusinessAssistant, {
        onSuccess: () => {
            setSnackBarMessage(t('business_assistant_users_created_successfully'));
            close();
        },
        onError: error => {
            setSnackBarMessage(errorResponseHelper(error as any), 'error');
        },
    });
    return {
        mutate,
        isLoading,
    };
};

export const useEditBusinessAssistant = (close: Function, setSnackBarMessage: Function) => {
    const { t } = useTranslation('usersBusinessAssistant');
    const { mutate, isLoading } = useMutation(endpoints.editBusinessAssistant, {
        onSuccess: () => {
            setSnackBarMessage(t('business_assistant_users_edited_successfully'));
            close();
        },
        onError: error => {
            setSnackBarMessage(errorResponseHelper(error as any), 'error');
        },
    });
    return {
        mutate,
        isLoading,
    };
};
