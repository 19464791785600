import { withFakeData } from 'api/fakedata';
import { axiosDataInstance } from 'api/axiosInstances';
import {
    CreateExternalInstrumentRequest,
    EditExternalInstrumentRequest,
    GetExternalInstrumentResponse,
} from 'types/marketExteriorInstruments/marketExteriorInstruments.api.types';

const apiName = 'marketExteriorInstruments';

export default {
    getInstruments: (filterQueryString: string) =>
        withFakeData<GetExternalInstrumentResponse>(
            () => axiosDataInstance.get(`/instruments/v1_0/bo/?${filterQueryString}`),
            apiName,
            'marketExteriorInstruments',
            false,
            500,
        ),
    createExternalInstrument: (req: CreateExternalInstrumentRequest) =>
        withFakeData<GetExternalInstrumentResponse>(
            () => axiosDataInstance.post('/instruments/v1_0/bo/', req),
            apiName,
            'marketExteriorInstruments',
            false,
            500,
        ),
    editExternalInstrument: (req: EditExternalInstrumentRequest) =>
        withFakeData<GetExternalInstrumentResponse>(
            () => axiosDataInstance.put(`/instruments/v1_0/bo/${req.id}`, req.body),
            apiName,
            'marketExteriorInstruments',
            false,
            500,
        ),
    deleteExternalInstrument: (id: number) =>
        withFakeData<GetExternalInstrumentResponse>(
            () => axiosDataInstance.delete(`/instruments/v1_0/bo/${id}`),
            apiName,
            'marketExteriorInstruments',
            false,
            500,
        ),
};
