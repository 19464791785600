import { withFakeData } from 'api/fakedata';
import { axiosDataInstance } from 'api/axiosInstances';
import { GetFundingResponse } from 'types/channelsFundings/channelsFundings.api.types';
import { ORDERS_BASE_URL } from 'constants/appConstants';

const apiName = 'channelsFundings';

export default {
    getFundings: (filterQueryString: string) =>
        withFakeData<GetFundingResponse>(
            () => axiosDataInstance.get(`${ORDERS_BASE_URL}/funds/?${filterQueryString}`),
            apiName,
            'fundings',
            false,
            500,
        ),
    lockOrUnlockFunds: ({ id, action }: { id: string; action: string }) =>
        withFakeData(
            () => axiosDataInstance.patch(`${ORDERS_BASE_URL}/funds/${id}/${action}`),
            apiName,
            'fundings',
            false,
            500,
        ),
    approveFunds: ({ id, body }: { id: string; body: { approvedApi: boolean } }) =>
        withFakeData(
            () => axiosDataInstance.patch(`${ORDERS_BASE_URL}/funds/${id}/accept`, body),
            apiName,
            'fundings',
            false,
            500,
        ),
    rejectFunds: ({ id, body }: { id: string; body: { reason: string } }) =>
        withFakeData(
            () => axiosDataInstance.patch(`${ORDERS_BASE_URL}/funds/${id}/reject`, body),
            apiName,
            'fundings',
            false,
            500,
        ),
};
