import { Box, styled, IconButton } from '@mui/material';

export default {
    FileUploaderWithContentWrapper: styled(Box)(({ theme }) => ({
        marginTop: '1rem',
        position: 'relative',
        display: 'flex',
        flexDirection: 'inherit',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '0.5rem',
    })),
    FileUploaderWrapper: styled(Box)(() => ({
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    })),
    RemoveIconButton: styled(IconButton)(() => ({
        position: 'absolute',
        left: '50%',
        top: '-1.5rem',
    })),
};
