import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ClientDeleteOrRejectAccontRequestForm from './ClientDeleteOrRejectAccountRequestForm';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { tRequiredFieldError } from 'constants/appConstants';
import {
    useDeleteAccountRequest,
    useRejectDeleteAccountRequest,
} from 'hooks/api/clientDeleteAccountRequest.hooks';
import { ModalActionProps } from 'types/common/CommonGrid/CommonGridFormModal.types';

// ---------------------------------------------//
// ------------------- FORMIK ------------------//
// ---------------------------------------------//

const getInitialValues = () => ({
    reason: '',
});

const getValidationSchema = () =>
    Yup.object().shape({
        reason: Yup.string().required(tRequiredFieldError),
    });

// ---------------------------------------------//
// ---------------------------------------------//
// ---------------------------------------------//

const ClientDeleteOrRejectAccontRequestFormContainer = ({
    row: { id },
    close,
    setSnackBarMessage,
    isDelete,
}: Props) => {
    const { t } = useTranslation('clientDeleteAccountRequest');
    const { mutate: mutateReject, isLoading: isLoadingReject } = useRejectDeleteAccountRequest(
        close,
        setSnackBarMessage,
    );
    const { mutate: mutateDelete, isLoading: isLoadingDelete } = useDeleteAccountRequest(
        close,
        setSnackBarMessage,
    );

    const handleSubmit = React.useCallback(async (data: any) => {
        isDelete ? mutateDelete({ id, data }) : mutateReject({ id, body: data });
    }, []);

    const formikInitProps = React.useMemo(
        () => ({
            initialValues: getInitialValues(),
            validateOnChange: false,
            validateOnBlur: false,
            validateOnMount: false,
            validationSchema: getValidationSchema(),
            enableReinitialize: true,
            onSubmit: handleSubmit,
        }),
        [handleSubmit],
    );

    const formik = useFormik(formikInitProps);

    const childProps = {
        isDelete,
        isLoading: isLoadingDelete || isLoadingReject,
        formik,
        close,
        t,
    };

    return <ClientDeleteOrRejectAccontRequestForm {...childProps} />;
};

const propTypes = {};

const defaultProps = {};

interface extraProps extends ModalActionProps {
    isDelete?: boolean;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ClientDeleteOrRejectAccontRequestFormContainer.propTypes = propTypes;
ClientDeleteOrRejectAccontRequestFormContainer.defaultProps = defaultProps;

export default ClientDeleteOrRejectAccontRequestFormContainer;
