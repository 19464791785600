import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './InfoLabel.styled';
import { SXBreakpoint } from 'types/common/general.types';

const InfoLabel = (props: Props) => {
    const { label, text, withoutSeparation, fontSize, Component } = props;

    return (
        <St.Wrapper data-testid="InfoLabel-default">
            <St.Label fontSize={fontSize} $withoutSeparation={withoutSeparation}>
                {label}
            </St.Label>
            {Component ? { ...Component } : <St.Data fontSize={fontSize}>{text}</St.Data>}
        </St.Wrapper>
    );
};

const propTypes = {
    label: PropTypes.string.isRequired,
    withoutSeparation: PropTypes.bool.isRequired,
};

const defaultProps = {};

interface extraProps {
    text?: string | number;
    fontSize?: SXBreakpoint<string>;
    Component?: React.ReactElement;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
InfoLabel.propTypes = propTypes;
InfoLabel.defaultProps = defaultProps;

export default InfoLabel;
