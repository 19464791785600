import * as React from 'react';
// import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import UsersRolesDeleteModal from './UsersRolesDeleteModal';
import { useTranslation } from 'react-i18next';
import { useDeleteRol } from 'hooks/api/usersRoles.hooks';
import { RolesDefinition } from 'types/usersRoles/usersRoles.types';
import { errorResponseHelper } from 'utils/helpers/errorsHelper';
import { AlertColor } from '@mui/material';

const UsersRolesDeleteModalContainer = (props: Props) => {
    const { row, setSnackBarMessage, close } = props;
    const { t } = useTranslation('usersRoles');
    const [deleteModal, setDeleteModal] = React.useState<boolean>(false);
    const { isLoading, mutate } = useDeleteRol();

    const handleDelete = () => {
        mutate(
            { id: row.id },
            {
                onSuccess: () => {
                    setSnackBarMessage(t('users_roles_modal_delete_success'));
                    close();
                },
                onError: error => {
                    setSnackBarMessage(errorResponseHelper(error), 'error');
                },
            },
        );
    };

    const childProps = {
        ...props,
        t,
        deleteModal,
        setDeleteModal,
        isLoading,
        handleDelete,
    };

    return <UsersRolesDeleteModal {...childProps} />;
};

const propTypes = {};

const defaultProps = {};

interface extraProps {
    row: RolesDefinition;
    close: () => void;
    setSnackBarMessage: (msj: string, sever?: AlertColor) => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
UsersRolesDeleteModalContainer.propTypes = propTypes;
UsersRolesDeleteModalContainer.defaultProps = defaultProps;

export default UsersRolesDeleteModalContainer;
