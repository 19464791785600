import { withFakeData } from 'api/fakedata';
import { axiosDataInstance } from 'api/axiosInstances';
import { AUTH_BASE_URL } from 'constants/appConstants';
import {
    ChangePasswordRequest,
    GetBackofficeUserProfileResponse,
    GetBackofficeUsersResponse,
    CreateUsersBackofficeRequest,
    EditUsersBackofficeRequest,
} from 'types/usersBackoffice/usersBackoffice.api.types';

const apiName = 'usersBackoffice';

export default {
    getBackofficeUserProfile: () => {
        return withFakeData<GetBackofficeUserProfileResponse>(
            () => axiosDataInstance.get(`${AUTH_BASE_URL}/me`),
            apiName,
            'userBackofficeProfilePage',
            false,
            500,
        );
    },
    getBackofficeUsers: (param: string) => {
        return withFakeData<GetBackofficeUsersResponse>(
            () => axiosDataInstance.get(`${AUTH_BASE_URL}/bo_users/search?${param}`),
            apiName,
            'usersBackoffice',
            false,
            500,
        );
    },
    enableBackoffice: ({ id }: { id: string }) =>
        withFakeData<{}>(
            () => axiosDataInstance.patch(`${AUTH_BASE_URL}/bo_users/${id}/enable/`),
            apiName,
            'change-state-usersBackofficeUsers',
            false,
            500,
        ),
    changePassword: (payload: ChangePasswordRequest) =>
        withFakeData<{}>(
            () => axiosDataInstance.post(`${AUTH_BASE_URL}/password`, payload),
            apiName,
            'passwordBackofficeUsers',
            false,
            500,
        ),
    deleteBackoffice: ({ id }: { id: string }) =>
        withFakeData<{}>(
            () => axiosDataInstance.delete(`${AUTH_BASE_URL}/bo_users/${id}`),
            apiName,
            'delete-usersBackofficeUsers',
            false,
            500,
        ),
    createUsersBackoffice: (req: CreateUsersBackofficeRequest) =>
        withFakeData<{}>(
            () => axiosDataInstance.post(`${AUTH_BASE_URL}/bo_users`, req),
            apiName,
            'create-usersBackoffice',
            false,
            500,
        ),
    editUsersBackoffice: (req: EditUsersBackofficeRequest) => {
        const { id, ...requestData } = req;
        return withFakeData<{}>(
            () => axiosDataInstance.patch(`${AUTH_BASE_URL}/bo_users/${id}`, requestData),
            apiName,
            'edit-usersBackoffice',
            false,
            500,
        );
    },
};
