import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CanExport from './CanExport';
import useExportData from 'hooks/common/CommonGrid/exportData.hooks';
import {
    ExtraQueryFiltersProps,
    GridHookParams,
} from 'types/common/CommonGrid/CommonGridFormModal.types';
import { useTranslation } from 'react-i18next';

const CanExportContainer = (props: Props) => {
    const {
        columns,
        useGetData,
        resource,
        totalRows,
        clientSidePagination,
        extraFilters,
        backTableId,
    } = props;
    const { loading, handleExportData } = useExportData(
        resource,
        useGetData,
        columns,
        totalRows,
        clientSidePagination,
        extraFilters,
        backTableId && backTableId,
    );
    const { t } = useTranslation('commonGrid');

    const childProps = {
        ...props,
        t,
        loading,
        handleExportData,
    };

    return <CanExport {...childProps} />;
};

const propTypes = {
    resource: PropTypes.string.isRequired,
    columns: PropTypes.arrayOf(PropTypes.any).isRequired,
    pageLoading: PropTypes.bool.isRequired,
    totalRows: PropTypes.number.isRequired,
    clientSidePagination: PropTypes.bool.isRequired,
    backTableId: PropTypes.number,
};

const defaultProps = {};

interface extraProps {
    extraFilters?: Array<ExtraQueryFiltersProps>;
    useGetData: (params: GridHookParams) => any;
    backTableId?: number;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CanExportContainer.propTypes = propTypes;
CanExportContainer.defaultProps = defaultProps;

export default CanExportContainer;
