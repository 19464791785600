import { Box, Stack, styled } from '@mui/material';

export default {
    Container: styled(Box)(({ theme }) => ({})),
    ButtonsContainer: styled(Stack)(({ theme }) => ({
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
        marginTop: '20px',
    })),
};
