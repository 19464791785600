import { Avatar, Box, Divider, Paper, Typography, styled } from '@mui/material';
import InfoLabel from 'components/common/InfoLabel';

export default {
    Paper: styled(Paper)(({ theme }) => ({})),
    DetailContainer: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            display: 'flex',
            flexDirection: 'column',
            width: { xs: 'calc(100% - 2rem)', sm: 'fit-content' }, // xs(2rem) of paddingX
            height: 'fit-content',
            padding: '1rem',
            gap: '1rem',
            borderRadius: '6px',
        }),
    ),
    FirstBox: styled(Box)(({ theme }) => ({})),
    Title: styled(Typography)(({ theme }) => ({
        lineHeight: '1.6',
        fontSize: '18px',
        fontWeight: '500',
        textAlign: 'center',
        margin: '10px 0 0',
    })),
    Avatar: styled(Avatar)(({ theme }) => ({
        background: theme.palette.background.default,
        width: '60px',
        height: '60px',
        margin: 'auto',
    })),
    SecondBox: styled(Box)(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        gap: '0.3rem',
        div: { margin: 0 },
        'p:nth-of-type(1)': {
            lineHeight: '1.57',
            fontSize: '13px',
            fontWeight: '600',
            margin: '0px 3.5px 0px 0px',
        },
        'p:nth-of-type(2)': {
            lineHeight: '1.43',
            fontSize: '14px',
            fontWeight: '400',
        },
    })),
    InfoLabel: styled(InfoLabel)(({ theme }) => ({})),
    Divider: styled(Divider)(({ theme }) => ({})),
    CustomIconSelectorWrapper: styled(Box)(({ theme }) => ({
        display: 'flex',
        alignSelf: 'center',
        margin: '0 0 3px 4px !important',
        svg: {
            fill: 'currentColor',
        },
    })),
};
