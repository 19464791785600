import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import * as Yup from 'yup';
import { FormikConfig, FormikValues, useFormik } from 'formik';
import mainLogo from 'assets/img/general/generic_logo.svg';
import SendMailPage from './EmailVerificationForm';
import { useEmailVerification, useResendEmailVerificationCode } from 'hooks/api/auth.hooks';
import { useTranslation } from 'react-i18next';
import i18n from 'utils/i18n';
import { tErrorsContext, tRequiredFieldError } from 'constants/appConstants';
import { useLocation, useNavigate } from 'react-router-dom';

// ---------------------------------------------//
// ------------------- FORMIK ------------------//
// ---------------------------------------------//

const getInitialValues = () => ({
    code: '',
});

const getValidationSchema = () =>
    Yup.lazy(() =>
        Yup.object().shape({
            code: Yup.string()
                .required(tRequiredFieldError)
                .min(
                    6,
                    i18n.t(
                        'change_password_screen_code_field_min_length_validation',
                        tErrorsContext,
                    ) as string,
                )
                .trim(),
        }),
    );

// ---------------------------------------------//
// ---------------------------------------------//
// ---------------------------------------------//

const EmailVerificationForm = () => {
    const { t } = useTranslation('auth');
    const {
        mutate,
        isLoading: isLoadingEmailVerification,
        errorMessage: emailVerificationError,
    } = useEmailVerification();
    const {
        mutate: resendEmailVerificationCode,
        isLoading: isLoadingResendEmailVerificationCode,
        successMessage: resendVerificationCodeSuccessMessage,
    } = useResendEmailVerificationCode();
    const navigate = useNavigate();
    const location = useLocation();

    const resentVerificationCode = React.useCallback(() => {
        resendEmailVerificationCode({ email: location.state.username });
    }, [resendEmailVerificationCode, location.state]);
    React.useEffect(() => {
        if (!location?.state || !location.state.username) navigate('/login');
        return () => {
            window.history.replaceState({}, document.title);
        };
    }, [location, navigate]);

    const handleSubmit = React.useCallback(
        async (values: FormikValues) => {
            mutate(
                { email: String(location.state.username.trim()), code: values.code },
                {
                    onSuccess: () => {
                        navigate('/login', { state: { verifiedEmail: true } });
                    },
                },
            );
        },
        [mutate, navigate, location.state],
    );

    const formikInitProps = React.useMemo(
        () =>
            ({
                initialValues: getInitialValues(),
                validateOnChange: false,
                validateOnBlur: false,
                validateOnMount: false,
                validationSchema: getValidationSchema(),
                enableReinitialize: true,
                onSubmit: handleSubmit,
            }) as FormikConfig<FormikValues>,
        [handleSubmit],
    );

    const formik = useFormik(formikInitProps);

    const childProps = {
        t,
        mainLogo,
        isLoading: isLoadingResendEmailVerificationCode || isLoadingEmailVerification,
        formik,
        email: location?.state?.username,
        resentVerificationCode,
        resendVerificationCodeSuccessMessage,
        emailVerificationError,
    };
    return <SendMailPage {...childProps} />;
};

const propTypes = {};

const defaultProps = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
EmailVerificationForm.propTypes = propTypes;
EmailVerificationForm.defaultProps = defaultProps;

export default EmailVerificationForm;
