import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { Form, FormikProvider } from 'formik';
import ActionButton from 'components/common/ActionButton';
import { BROKER } from 'constants/appConstants';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material';
import CustomIconSelector from 'components/common/CustomIconSelector';
import defaultTheme from 'utils/theme/defaultTheme';
import CustomInput from 'components/common/CustomInput';

const ClientBankAccountsEditForm = ({
    formik,
    disabledForm,
    open,
    type,
    isLoading,
    close,
    handleSubmit,
    t,
}: Props) => {
    const { errors, values, setFieldValue } = formik;

    return (
        <Dialog onClose={close} open={open} maxWidth="xs">
            <DialogTitle>
                {t('edit_bank_account_title', { ns: 'clientBankAccounts' })}
                <IconButton
                    aria-label="close"
                    onClick={close}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}>
                    <CustomIconSelector
                        type="close"
                        sx={{ color: defaultTheme.palette.grey[500] }}
                    />
                </IconButton>
            </DialogTitle>
            <FormikProvider value={formik} data-testid="ClientBankAccountsEditForm-form">
                <Form>
                    <DialogContent>
                        <Grid container spacing={2}>
                            {type === BROKER && (
                                <>
                                    <Grid item xs={12} lg={6}>
                                        <CustomInput
                                            required
                                            field="broker"
                                            label={t('broker_name')}
                                            placeholder={t('broker_name')}
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            errors={errors}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <CustomInput
                                            required
                                            field="brokerCustomerCode"
                                            label={t('broker_customer_code')}
                                            placeholder={t('broker_customer_code')}
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            errors={errors}
                                        />
                                    </Grid>
                                </>
                            )}
                            <Grid item xs={12}>
                                <CustomInput
                                    required
                                    field="appellation"
                                    label={t('appellation')}
                                    placeholder={t('appellation')}
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    errors={errors}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CustomInput
                                    field="aba"
                                    label={t('aba')}
                                    placeholder={t('aba')}
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    errors={errors}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CustomInput
                                    field="swift"
                                    label={t('swift')}
                                    placeholder={t('swift')}
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    errors={errors}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CustomInput
                                    field="iban"
                                    label={t('iban')}
                                    placeholder={t('iban')}
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    errors={errors}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <ActionButton onClick={close} variant="outlined">
                            {t('cancel')}
                        </ActionButton>
                        <ActionButton
                            size="medium"
                            type="submit"
                            onClick={() => handleSubmit(values)}
                            disabled={disabledForm || isLoading}
                            loading={isLoading}
                            color="primary"
                            variant="contained">
                            {t('edit')}
                        </ActionButton>
                    </DialogActions>
                </Form>
            </FormikProvider>
        </Dialog>
    );
};

const propTypes = {
    isEdit: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    disabledForm: PropTypes.bool.isRequired,
};

const defaultProps = {};

interface extraProps {
    open: boolean;
    type: string;
    formik: any;
    isLoading: boolean;
    close: () => void;
    handleSubmit(values: any): void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ClientBankAccountsEditForm.propTypes = propTypes;
ClientBankAccountsEditForm.defaultProps = defaultProps;

export default ClientBankAccountsEditForm;
