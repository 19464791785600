import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import PdfReaderModal from './PdfReaderModal';
import useBreakpoints from 'hooks/common/breakpoints.hooks';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

const PdfReaderModalContainer = (props: Props) => {
    // const {} = props;
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const { matchesXl } = useBreakpoints();

    const childProps = {
        matchesXl,
        defaultLayoutPluginInstance,
        ...props,
    };

    return <PdfReaderModal {...childProps} />;
};

const propTypes = {};

const defaultProps = {};

interface extraProps {
    open: boolean;
    handleClose: () => void;
    pdfUrl: string | null;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
PdfReaderModalContainer.propTypes = propTypes;
PdfReaderModalContainer.defaultProps = defaultProps;

export default PdfReaderModalContainer;
