import { paginationAdapter } from 'adapters/common/pagination.adapter';
import {
    DeleteAccountRequestApi,
    GetDeleteAccountRequestResponse,
} from 'types/clientDeleteAccountRequest/clientDeleteAccountRequest.api.types';
import { DeleteAccountRequest } from 'types/clientDeleteAccountRequest/clientDeleteAccountRequest.types';

export const ClientDeleteAccountRequestAdapter = (data: GetDeleteAccountRequestResponse) => {
    return {
        ...paginationAdapter(data),
        data: data.items?.map((clientDeleteAccountRequest: DeleteAccountRequestApi) => {
            return {
                id: clientDeleteAccountRequest.id,
                requestDate: clientDeleteAccountRequest.requestDate,
                motive: clientDeleteAccountRequest.motive,
                comment: clientDeleteAccountRequest.comment,
                status: clientDeleteAccountRequest.status,
                reason: clientDeleteAccountRequest.reason,
                user: clientDeleteAccountRequest.user,
            } as DeleteAccountRequest;
        }),
    };
};
