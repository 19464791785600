import { TFunction } from 'i18next';

export interface Dummy {
    text: string;
}

export type TLanguagesOptions = {
    [key: string]: string;
};
// TODO: Revisar estos tipos y ver donde los dividimos.
export interface ITranslate extends TFunction {}
export interface Tprop {
    t: ITranslate;
}

export const languages: TLanguagesOptions = {
    en: 'en-US',
    es: 'es-ES',
};

export interface ObjectMapper {
    [key: string]: any;
}

export type SXBreakpoint<T> =
    | T
    | {
          xs?: T;
          sm?: T;
          md?: T;
          lg?: T;
          xl?: T;
      };

export interface Pagination {
    total_count: number;
}
export interface DeleteHook {
    mutate: any;
    isError: boolean;
    isLoading: boolean;
    successfulMessage: string;
    errorMessage: string;
}

// Tipo genérico para las opciones del selector
export type OptionAutocompleteType<T> =
    | T
    | {
          id: number | string;
          name: string;
          description: string;
      };

export type CurrencyTypes = 'ARS' | 'USD' | 'USD-C';

export interface StateTableInfo<T> {
    id: number;
    name: string;
    status: T;
}
