import { Box } from '@mui/material';
import { styled as styledMui } from '@mui/material/styles';

export default {
    SideBarLayoutContainer: styledMui(Box)(({ width }) => ({
        display: 'flex',
        transition: 'width 1s',
        width: `${width}`,
        backgroundColor: '#F2F2F2 !important',
    })),
    SidebarLayoutNav: styledMui(Box)(({ theme }) => ({
        width: `inherit`,
        transition: 'width 1s',
        flexShrink: 0,
        position: 'static',
        [theme.breakpoints.down('md')]: {
            position: 'absolute',
        },
    })),
    SidebarMobileLayoutMain: styledMui(Box)(({ width, theme }) =>
        theme.unstable_sx({
            flexGrow: 1,
            width: `${width}`,
            transition: 'width 1s',
            padding: { xs: '6px', sm: '24px', md: '24px', lg: '24px', xl: '24px' },
            marginTop: { xs: '60px', sm: '60px', md: '0px', lg: '0px', xl: '0px' },
        }),
    ),
};
