import { withFakeData } from 'api/fakedata';
import { axiosAnonInstance, axiosDataInstance } from 'api/axiosInstances';
import {
    EmailVerificationCodeRequest,
    ForgotPasswordConfirmationRequest,
    ForgotPasswordRequest,
    LoginRequest,
    LoginResponse,
    LogoutRequest,
    LogoutResponse,
    ResendEmailVerificationCodeRequest,
} from '../../types/auth/auth.api.types';
import { AUTH_BASE_URL } from 'constants/appConstants';

const apiName = 'auth';

export default {
    logout: (body: LogoutRequest) =>
        withFakeData<LogoutResponse>(
            () => axiosDataInstance.post('/v1/auth/admins/logout', body),
            apiName,
            'logout',
            true,
            500,
        ),
    login: (argument: LoginRequest) =>
        withFakeData<LoginResponse>(
            () => axiosAnonInstance.post(`${AUTH_BASE_URL}/login`, argument),
            apiName,
            'login',
            false,
            500,
        ),
    forgotPassword: (req: ForgotPasswordRequest) =>
        withFakeData<{}>(
            () => axiosAnonInstance.post('/v1/admins/forgot-password', req),
            apiName,
            'forgot-password',
            true,
            500,
        ),
    forgotPasswordConfirmation: (req: ForgotPasswordConfirmationRequest) =>
        withFakeData<{}>(
            () => axiosAnonInstance.post('/v1/admins/forgot-password/confirmation', req),
            apiName,
            'forgot-password-confirmation',
            true,
            500,
        ),
    resendEmailVerificationCode: (req: ResendEmailVerificationCodeRequest) =>
        withFakeData<{}>(
            () =>
                axiosAnonInstance.post(
                    '/v1/admins/email-verification/verification-code-resend',
                    req,
                ),
            apiName,
            'resend-email-verification-code',
            true,
            500,
        ),
    emailVerification: (req: EmailVerificationCodeRequest) =>
        withFakeData<{}>(
            () => axiosAnonInstance.post('/v1/admins/email-verification', req),
            apiName,
            'email-verification',
            true,
            500,
        ),
};
