import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import SidebarLayout from './SidebarLayout';
import { useLocation } from 'react-router-dom';
import useBreakpoints from 'hooks/common/breakpoints.hooks';
import { SidebarContext } from 'context/sidebar.context';

const SidebarLayoutContainer = (props: Props) => {
    // const {} = props;
    const { matchesSm, matchesMd } = useBreakpoints();
    const [menuCollapsed, setMenuCollapsed] = React.useState(matchesMd);

    const { updateDrawerWidth } = React.useContext(SidebarContext);

    const [isOpen, setIsOpen] = React.useState(false);
    const [drawerWidth, setDrawerWidth] = React.useState(!isOpen ? 56 : 240);
    const [selectedMenu, setSelectedMenu] = React.useState('');
    const location = useLocation();

    React.useEffect(() => {
        setDrawerWidth(!menuCollapsed || isOpen ? 260 : 56);
        updateDrawerWidth(!menuCollapsed || isOpen ? 260 : 56);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, menuCollapsed]);

    React.useEffect(() => {
        const currentPage = location.pathname?.slice(1) ?? '';
        setSelectedMenu(currentPage);
    }, [location]);

    const childProps = {
        ...props,
        isOpen,
        drawerWidth,
        matchesMd,
        matchesSm,
        menuCollapsed,
        setIsOpen,
        setMenuCollapsed,
        selectedMenu,
    };

    return <SidebarLayout {...childProps} />;
};

const propTypes = {};

const defaultProps = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
SidebarLayoutContainer.propTypes = propTypes;
SidebarLayoutContainer.defaultProps = defaultProps;

export default SidebarLayoutContainer;
