import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import HeadersSelect from './HeadersSelect';

const HeadersSelectContainer = (props: Props) => {
    const { filterstring: filterString, query, setfilterstring: setFilterString, options } = props;

    const value = React.useMemo(() => {
        const queries = filterString.split('&');

        const selectedOptions = queries.reduce((acc: any[], queryParam: string) => {
            const [param, value] = queryParam.split('=');

            if (param === query) {
                const matchingOptions = options.filter(option => {
                    return String(option.id) === value || option.name == value;
                });

                return [...acc, ...matchingOptions];
            }

            return acc;
        }, []);

        return selectedOptions;
    }, [filterString, query, options]);

    const onChange = (selectedOptions: any | any[]) => {
        if (!selectedOptions) {
            return setFilterString((prev: string) =>
                prev.replace(new RegExp(`&${query}=[^&]*`, 'g'), ''),
            );
        }

        const selectedValues = Array.isArray(selectedOptions) ? selectedOptions : [selectedOptions];
        const updatedQueryString = selectedValues.map(option => `&${query}=${option.id}`).join('');
        setFilterString(
            (prev: string) =>
                prev.replace(new RegExp(`&${query}=[^&]*`, 'g'), '') + updatedQueryString,
        );
    };

    const childProps = {
        ...props,
        value,
        onChange,
    };

    return <HeadersSelect {...childProps} />;
};

const propTypes = {
    query: PropTypes.string.isRequired,
    filterstring: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    multiple: PropTypes.bool,
    type: PropTypes.string.isRequired,
};

const defaultProps = {};

interface extraProps {
    setfilterstring: React.Dispatch<React.SetStateAction<string>>;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
HeadersSelectContainer.propTypes = propTypes;
HeadersSelectContainer.defaultProps = defaultProps;

export default HeadersSelectContainer;
