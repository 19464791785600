import { useQuery, useMutation } from '@tanstack/react-query';
import endpoints from '../../api/channelsCollateral/channelsCollateral.api';
import { GridHookParams } from 'types/common/CommonGrid/CommonGridFormModal.types';
import { collateralDefinitionsAdapter } from 'adapters/api/channelsCollateral.adapter';
import { useTranslation } from 'react-i18next';
import { tErrorsContext } from 'constants/appConstants';

export const useCollateralDefinitions = ({ forceDisable, filterQueryString }: GridHookParams) => {
    const enabledCondition = !forceDisable;
    const { data, isLoading, isFetching, isRefetching, refetch } = useQuery(
        ['collateral-definitions', filterQueryString],
        () => endpoints.getCollateralDefinitions(filterQueryString),
        {
            enabled: enabledCondition,
            select: data => {
                return collateralDefinitionsAdapter(data?.data);
            },
        },
    );
    return {
        data,
        isLoading: (isLoading && isFetching) || isRefetching,
        refetch,
    };
};

export const useApproveCollateral = () => {
    const { t } = useTranslation('channelsCollateral');
    const { mutate, isLoading, error, isError } = useMutation(endpoints.approveCollateral);

    return {
        mutate,
        isLoading,
        isError,
        errorMessage: t(
            (error as any)?.response?.data?.error_messages[0].code ?? '',
            tErrorsContext,
        ),
        successfulMessage: t('collateral_approved_successfully'),
    };
};

export const useRejectCollateral = () => {
    const { t } = useTranslation('channelsCollateral');
    const { mutate, isLoading, error, isError } = useMutation(endpoints.rejectCollateral);
    return {
        mutate,
        isLoading,
        isError,
        errorMessage: t(
            (error as any)?.response?.data?.error_messages[0].code ?? '',
            tErrorsContext,
        ),
    };
};

export const useEditCollateral = () => {
    const { t } = useTranslation('channelsCollateral');
    const { mutate, isLoading, error, isError } = useMutation(endpoints.editCollateral);
    return {
        mutate,
        isLoading,
        isError,
        errorMessage: t(
            (error as any)?.response?.data?.error_messages[0].code ?? '',
            tErrorsContext,
        ),
    };
};

export const useLockOrUnlockCollateral = () => {
    const { t } = useTranslation('channelsCollateral');
    const { mutate, isLoading, error, isError } = useMutation(endpoints.lockOrUnlockCollateral);
    return {
        mutate,
        isLoading,
        isError,
        errorMessage: t(
            (error as any)?.response?.data?.error_messages[0].code ?? '',
            tErrorsContext,
        ),
    };
};
