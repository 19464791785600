import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import TableHeaderButton from './TableHeaderButton';
import { ReactElement } from 'react';

const TableHeaderButtonContainer = (props: Props) => {
    // const {} = props;

    const childProps = {
        ...props,
    };

    return <TableHeaderButton {...childProps} />;
};

const propTypes = {
    text: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
};

const defaultProps = {
    variant: 'contained',
    disabled: false,
};

interface extraProps {
    icon?: ReactElement;
    handleClick: () => void;
    variant: 'outlined' | 'contained' | 'text';
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
TableHeaderButtonContainer.propTypes = propTypes;
TableHeaderButtonContainer.defaultProps = defaultProps;

export default TableHeaderButtonContainer;
