import { paginationAdapter } from 'adapters/common/pagination.adapter';
import { getAlertsResponse, AlertsApi } from 'types/alerts/alerts.api.types';
import { Alerts } from 'types/alerts/alerts.types';

export const alertsAdapter = (data: getAlertsResponse) => {
    return {
        ...paginationAdapter(data),
        data: data.items?.map((alerts: AlertsApi) => {
            return {
                id: alerts.id,
                createdAt: alerts.createdAt,
                updatedAt: alerts.updatedAt,
                name: alerts.name,
                active: alerts.active,
                blocking: alerts.blocking,
                recipients: alerts.recipients,
                platforms: alerts.platforms,
                exceptions: alerts.exceptions,
                message: alerts.message,
            } as Alerts;
        }),
    };
};
