import { withFakeData } from 'api/fakedata';
import { axiosDataInstance } from 'api/axiosInstances';
import { GetDepositsResponse } from 'types/clientDeposits/clientDeposits.api.types';

const apiName = 'clientDeposits';

export default {
    getDepositsDefinitions: (filterQueryString: string) =>
        withFakeData<GetDepositsResponse>(
            () => axiosDataInstance.get(`/cashflow/v1_0/bo/deposit/?${filterQueryString}`),
            apiName,
            'clientDeposits',
            false,
            500,
        ),
};
