import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './SettingsNonWorkingDaysForm.styled';
import { Form, FormikProvider } from 'formik';
import CustomDatePicker from 'components/common/CustomDatePicker';
import { minValue } from '../../../../utils/helpers/dateHelper';
import CustomAutoComplete from 'components/common/CustomAutoComplete';
import Stack from '@mui/material/Stack';
import { tCommon } from 'constants/appConstants';
import ActionButton from 'components/common/ActionButton';

const SettingsNonWorkingDaysForm = (props: Props) => {
    const { t, formik, isEdit, marketOptions, disabledForm, close } = props;
    const { errors, values, setFieldValue } = formik;

    return (
        <FormikProvider value={formik} data-testid="nonWorkingDaysForm-form">
            <Form>
                <St.MarketnonWorkingDayForm>
                    <Stack spacing={4}>
                        <CustomAutoComplete
                            id="markets"
                            value={values?.markets ?? []}
                            onChange={(e, value) => {
                                setFieldValue('markets', value);
                            }}
                            error={errors.markets}
                            options={marketOptions ?? []}
                            filterSelectedOptions
                            multiple
                            label={t('market_non_working_days_modal_create_market_input_title')}
                            placeholder={t(
                                'market_non_working_days_modal_create_market_input_placeholder',
                            )}
                            renderOption={(props, option, { selected }) => (
                                <St.OptionContainer {...props}>
                                    <div>
                                        <St.OptionTitle variant="subtitle1">
                                            {option.name}
                                        </St.OptionTitle>
                                        <St.OptionDescription variant="body2" color="textSecondary">
                                            {option.description}
                                        </St.OptionDescription>
                                    </div>
                                </St.OptionContainer>
                            )}
                        />

                        <CustomDatePicker
                            fieldValue="dateFrom"
                            value={values?.dateFrom}
                            setFieldValue={setFieldValue}
                            placeholder={t(
                                'market_non_working_days_modal_create_day_from_input_placeholder',
                            )}
                            errorMessage={errors?.dateFrom}
                            minDate={minValue}
                            required
                        />

                        <CustomDatePicker
                            fieldValue="dateTo"
                            value={values?.dateTo}
                            setFieldValue={setFieldValue}
                            placeholder={t(
                                'market_non_working_days_modal_create_day_to_input_placeholder',
                            )}
                            errorMessage={errors?.dateTo}
                            minDate={minValue}
                            required
                        />
                    </Stack>

                    <St.ActionSectionForm direction="row" spacing={1}>
                        <ActionButton onClick={close} variant="outlined">
                            {t('cancel', tCommon)}
                        </ActionButton>
                        <ActionButton
                            size="medium"
                            type="submit"
                            disabled={isEdit ? disabledForm : false}
                            color="primary"
                            variant="contained">
                            {isEdit ? t('edit', tCommon) : t('add', tCommon)}
                        </ActionButton>
                    </St.ActionSectionForm>
                </St.MarketnonWorkingDayForm>
            </Form>
        </FormikProvider>
    );
};

const propTypes = {
    isEdit: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    marketOptions: PropTypes.array.isRequired,
    disabledForm: PropTypes.bool.isRequired,
};

const defaultProps = {};

interface extraProps {
    formik: any;
    close: () => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
SettingsNonWorkingDaysForm.propTypes = propTypes;
SettingsNonWorkingDaysForm.defaultProps = defaultProps;

export default SettingsNonWorkingDaysForm;
