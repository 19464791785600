import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import InfoLabel from './InfoLabel';
import { SXBreakpoint } from 'types/common/general.types';

const InfoLabelContainer = (props: Props) => {
    // const {} = props;

    const childProps = {
        ...props,
    };

    return <InfoLabel {...childProps} />;
};

const propTypes = {
    label: PropTypes.string.isRequired,
    withoutSeparation: PropTypes.bool.isRequired,
};

const defaultProps = {
    withoutSeparation: false,
};

interface extraProps {
    text?: string | number;
    fontSize?: SXBreakpoint<string>;
    Component?: React.ReactElement;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
InfoLabelContainer.propTypes = propTypes;
InfoLabelContainer.defaultProps = defaultProps;

export default InfoLabelContainer;
