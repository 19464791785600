import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './UsersBrokerForm.styled';
import { Form, FormikContextType, FormikProvider } from 'formik';
import { tCommon } from 'constants/appConstants';
import ActionButton from 'components/common/ActionButton';
import { Grid, FormControlLabel, Checkbox, FormGroup } from '@mui/material';
import CustomInput from 'components/common/CustomInput';
import PasswordField from 'components/common/PasswordField';
import { LoadingButton } from '@mui/lab';

const UsersBrokerForm = (props: Props) => {
    const { t, close, formik, isEdit, showButton, isLoadingSubmit } = props;
    const { errors, values, setFieldValue } = formik;

    const handleUserTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFieldValue('isInternal', !values.isInternal);
    };

    return (
        <FormikProvider value={formik} data-testid="UsersBrokerForm-form">
            <Form>
                <St.UsersBrokerForm>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={12}>
                            <CustomInput
                                required
                                field="name"
                                label={t('broker_users_form_name_input')}
                                placeholder={t('broker_users_form_name_placeholder') as string}
                                values={values}
                                errors={errors}
                                setFieldValue={setFieldValue}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <CustomInput
                                field="lastName"
                                label={t('broker_users_form_last_name_input')}
                                placeholder={t('broker_users_form_last_name_placeholder') as string}
                                values={values}
                                errors={errors}
                                setFieldValue={setFieldValue}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <CustomInput
                                required
                                field="email"
                                label={t('broker_users_form_email_input')}
                                placeholder={t('broker_users_form_email_placeholder') as string}
                                values={values}
                                errors={errors}
                                setFieldValue={setFieldValue}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <CustomInput
                                required
                                field="username"
                                label={t('broker_users_form_user_name_input')}
                                placeholder={t('broker_users_form_user_name_placeholder') as string}
                                values={values}
                                errors={errors}
                                setFieldValue={setFieldValue}
                            />
                        </Grid>
                        {!isEdit && (
                            <>
                                <Grid item xs={12} md={12}>
                                    <PasswordField
                                        name="password"
                                        error={errors.password !== undefined}
                                        helperText={errors.password?.toString()}
                                        label={t('broker_users_form_password_input')}
                                        placeholder={t('broker_users_form_password_placeholder')}
                                        onChange={({ target }: any) => {
                                            setFieldValue('password', target.value);
                                        }}
                                        value={values.password}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <PasswordField
                                        name="repeatPassword"
                                        error={errors.repeatPassword !== undefined}
                                        helperText={errors.repeatPassword?.toString()}
                                        label={t('broker_users_form_repeat_password_input')}
                                        placeholder={t(
                                            'broker_users_form_repeat_password_placeholder',
                                        )}
                                        onChange={({ target }: any) => {
                                            setFieldValue('repeatPassword', target.value);
                                        }}
                                        value={values.repeatPassword}
                                        fullWidth
                                    />
                                </Grid>
                            </>
                        )}
                        <Grid item xs={12} md={12}>
                            <FormGroup row>
                                <St.FormLabelStyled>
                                    {t('broker_users_form_vb_internal_input') + ':'}
                                </St.FormLabelStyled>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={values.isInternal}
                                            onChange={handleUserTypeChange}
                                            name="isInternal"
                                            color="primary"
                                        />
                                    }
                                    label={t('broker_users_form_user_internal')}
                                />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <CustomInput
                                required
                                field="vbCode"
                                label={t('broker_users_form_vb_code_input')}
                                placeholder={t('broker_users_form_vb_code_placeholder') as string}
                                values={values}
                                errors={errors}
                                setFieldValue={setFieldValue}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <CustomInput
                                required
                                field="referralCode"
                                label={t('broker_users_form_referral_code_input')}
                                placeholder={
                                    t('broker_users_form_referral_code_placeholder') as string
                                }
                                values={values}
                                errors={errors}
                                setFieldValue={setFieldValue}
                            />
                        </Grid>
                    </Grid>
                    <St.ActionSectionForm direction="row" spacing={1}>
                        <ActionButton onClick={close} variant="outlined">
                            {t('cancel', tCommon)}
                        </ActionButton>
                        <LoadingButton
                            size="medium"
                            type="submit"
                            disabled={!showButton}
                            color="primary"
                            variant="contained"
                            loading={isLoadingSubmit}>
                            {isEdit ? t('edit', tCommon) : t('add', tCommon)}
                        </LoadingButton>
                    </St.ActionSectionForm>
                </St.UsersBrokerForm>
            </Form>
        </FormikProvider>
    );
};

const propTypes = {
    isEdit: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
};

const defaultProps = {};

interface extraProps {
    formik: FormikContextType<any>;
    close: () => void;
    showButton: boolean;
    isLoadingSubmit: boolean;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}

UsersBrokerForm.propTypes = propTypes;
UsersBrokerForm.defaultProps = defaultProps;

export default UsersBrokerForm;
