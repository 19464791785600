import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import {
    ActionColumn,
    ActionHeader,
    HeaderFiltersProps,
} from 'types/common/CommonGrid/CommonGridFormModal.types';
import { CommonGridColumnsProps } from 'types/common/CommonGrid/CommonGridColumnProps.types';
import { ChannnelsAffidavit } from 'types/channelsAffidavit/channelsAffidavit.types';
import { useChannnelsAffidavit } from 'hooks/api/channelsAffidavit.hooks';
import CommonGrid from 'components/common/CommonGrid';

const ChannelsAffidavitPage = (props: Props) => {
    const { t, columns, actionColumns, headerFilters, headerActions } = props;
    return (
        <CommonGrid
            title={t('channels_affidavit_list_title')}
            columns={columns}
            resource="channels-affidavit"
            useGetData={useChannnelsAffidavit}
            defaultSortFieldId="createdAt"
            canExport
            customActionColumns={actionColumns}
            headerFilters={headerFilters}
            extraActionsInHeader={headerActions}
            extraFilters={[{ query: 'hasDeclaration', queryValue: true }]}
        />
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

const defaultProps = {};

interface extraProps {
    columns: CommonGridColumnsProps<ChannnelsAffidavit>[];
    actionColumns: ActionColumn[];
    headerFilters: HeaderFiltersProps[];
    headerActions: ActionHeader[];
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ChannelsAffidavitPage.propTypes = propTypes;
ChannelsAffidavitPage.defaultProps = defaultProps;

export default ChannelsAffidavitPage;
