import { Box } from '@mui/material';
import { styled as styledMui } from '@mui/material/styles';
export default {
    MainWrapper: styledMui(Box)(({ theme }) => ({
        display: 'flex',
    })),
    PageWrapper: styledMui(Box)(({ theme }) =>
        theme.unstable_sx({
            width: '100vw',
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            padding: { xs: '5rem 1rem', sm: '5rem 1rem', md: '2rem 1rem' },
        }),
    ) as typeof Box,
};
