import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
// import St from './ClientProspectRelateAccountModal.styled';
import { ProspectMarketAccounts } from 'types/clients/clients.types';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Paper,
    Radio,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import ActionButton from 'components/common/ActionButton';
import { tCommon } from 'constants/appConstants';

const ClientProspectRelateAccountModal = (props: Props) => {
    const {
        t,
        open,
        close,
        onSubmit,
        isLoading,
        marketAccounts,
        selectedCustomer,
        setSelectedCustomer,
    } = props;

    return (
        <Dialog open={open} onClose={close}>
            <DialogTitle>{t('prospect_relate_account_title')}</DialogTitle>
            <DialogContent>
                {marketAccounts.length >= 2 && (
                    <DialogContentText marginBottom={'20px'}>
                        {t('prospect_relate_account_hint_text')}
                    </DialogContentText>
                )}

                <TableContainer component={Paper} sx={{ width: { xs: '90%', sm: '500px' } }}>
                    <Table aria-label="steps">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    {t('client_prospect_relate_acount_modal_customer_code')}
                                </TableCell>
                                <TableCell>
                                    {t('client_prospect_relate_acount_modal_email')}
                                </TableCell>
                                {marketAccounts.length >= 2 && <TableCell></TableCell>}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {marketAccounts.map(item => (
                                <TableRow key={item.customerCode}>
                                    <TableCell>{item.customerCode}</TableCell>
                                    <TableCell>{item.email}</TableCell>
                                    {marketAccounts.length >= 2 && (
                                        <TableCell>
                                            <Radio
                                                checked={
                                                    selectedCustomer.customerCode ===
                                                    item.customerCode
                                                }
                                                onChange={() => setSelectedCustomer(item)}
                                                value={item.customerCode}
                                                name="radio-buttons"
                                            />
                                        </TableCell>
                                    )}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <DialogActions>
                    <ActionButton variant="outlined" disabled={isLoading} onClick={close}>
                        {t('cancel', tCommon)}
                    </ActionButton>
                    <ActionButton loading={isLoading} onClick={onSubmit}>
                        {t('relate_account')}
                    </ActionButton>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

const defaultProps = {};

interface extraProps {
    selectedCustomer: ProspectMarketAccounts;
    setSelectedCustomer: (value: ProspectMarketAccounts) => void;
    close: () => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {
    marketAccounts: ProspectMarketAccounts[];
}
ClientProspectRelateAccountModal.propTypes = propTypes;
ClientProspectRelateAccountModal.defaultProps = defaultProps;

export default ClientProspectRelateAccountModal;
