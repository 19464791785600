import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CommonGrid from 'components/common/CommonGrid';
import { CommonGridColumnsProps } from 'types/common/CommonGrid/CommonGridColumnProps.types';
import {
    ActionColumn,
    HeaderFiltersProps,
} from 'types/common/CommonGrid/CommonGridFormModal.types';
import { useExchangeDefinitions } from 'hooks/api/channelsExchange.hooks';

const ChannelsExchangePage = ({
    t,
    columns,
    refreshTable,
    actionColumns,
    headerFilters,
    SnackBar,
}: Props) => (
    <>
        <CommonGrid
            title={t('exchange_list_title')}
            columns={columns}
            resource="exchanges"
            useGetData={useExchangeDefinitions}
            defaultSortFieldId="customerCode"
            canExport
            customActionColumns={actionColumns}
            headerFilters={headerFilters}
            refreshTable={refreshTable}
        />
        <SnackBar />
    </>
);

const propTypes = {
    t: PropTypes.func.isRequired,
};

const defaultProps = {};

interface extraProps {
    columns: CommonGridColumnsProps<any>[];
    actionColumns: ActionColumn[];
    refreshTable: boolean;
    headerFilters?: HeaderFiltersProps[];
    SnackBar: any;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ChannelsExchangePage.propTypes = propTypes;
ChannelsExchangePage.defaultProps = defaultProps;

export default ChannelsExchangePage;
