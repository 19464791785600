import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ClientProspectRejectModalForm from './ClientProspectRejectModalForm';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { tRequiredFieldError } from 'constants/appConstants';
import { useRejectProspect } from 'hooks/api/clients.hooks';
import { ClientRiskProfile, ProspectStateInfo } from 'types/clients/clients.types';
import { RejectProspectSteps } from 'types/clients/clients.api.types';

// ---------------------------------------------//
// ------------------- FORMIK ------------------//
// ---------------------------------------------//

const getInitialValues = () => ({});

const getValidationSchema = () =>
    Yup.object().shape({
        reason: Yup.string().required(tRequiredFieldError),
        steps: Yup.array()
            .of(
                Yup.object().shape({
                    id: Yup.number(),
                    name: Yup.string(),
                    status: Yup.string(),
                }),
            )
            .required(tRequiredFieldError),
    });

// ---------------------------------------------//
// ---------------------------------------------//
// ---------------------------------------------//

const ClientProspectRejectModalFormContainer = (props: Props) => {
    const { close, setSnackBarMessage, id, refetch, riskProfile, steps } = props;
    const { t } = useTranslation('clients');
    const { mutate, isLoading } = useRejectProspect(setSnackBarMessage, close, refetch);

    const validSteps = React.useMemo(() => {
        if (!steps) return [];
        const filteredSteps =
            riskProfile === 'LOW' ? steps?.filter(s => s.name != t('PROOF_OF_INCOME')) : steps;
        return filteredSteps.filter(
            s => s.name !== t('VALIDATE_PERSON') && s.name !== t('INVESTMENT_TEST'),
        );
    }, [steps, riskProfile, t]);

    const handleSubmit = React.useCallback(async (data: any) => {
        const { reason, steps } = data;
        const body = {
            rejectSteps: steps.map(
                (s: ProspectStateInfo) =>
                    ({
                        reason,
                        stepNumber: s.id,
                    }) as RejectProspectSteps,
            ),
        };
        mutate({ id, body });
    }, []);

    const formikInitProps = React.useMemo(
        () => ({
            initialValues: getInitialValues(),
            validateOnChange: false,
            validateOnBlur: false,
            validateOnMount: false,
            validationSchema: getValidationSchema(),
            enableReinitialize: true,
            onSubmit: handleSubmit,
        }),
        [handleSubmit],
    );

    const formik = useFormik(formikInitProps);

    const childProps = {
        ...props,
        formik,
        t,
        isLoading,
        steps: validSteps,
    };

    return <ClientProspectRejectModalForm {...childProps} />;
};

const propTypes = {
    open: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    setSnackBarMessage: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    docNumber: PropTypes.string.isRequired,
    refetch: PropTypes.func.isRequired,
    fullName: PropTypes.string.isRequired,
};

const defaultProps = {};

interface extraProps {
    close: () => void;
    riskProfile?: ClientRiskProfile;
    steps?: ProspectStateInfo[];
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ClientProspectRejectModalFormContainer.propTypes = propTypes;
ClientProspectRejectModalFormContainer.defaultProps = defaultProps;

export default ClientProspectRejectModalFormContainer;
