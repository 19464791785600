import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ErrorPage from './ErrorPage';
import { useTranslation } from 'react-i18next';

const ErrorPageContainer = (props: Props) => {
    // const {} = props;

    const { t } = useTranslation();

    const childProps = {
        ...props,
        t,
    };

    return <ErrorPage {...childProps} />;
};

const propTypes = {};

const defaultProps = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ErrorPageContainer.propTypes = propTypes;
ErrorPageContainer.defaultProps = defaultProps;

export default ErrorPageContainer;
