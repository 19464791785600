import { Box, styled } from '@mui/material';

export default {
    Container: styled(Box)(({ theme }) => ({})),
    FormContainer: styled(Box)(({ theme }) => ({
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    })),
    AutocompleteWrapper: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            width: { md: '50%', xs: '100%' },
            marginBottom: { xs: '20px', md: 0 },
            marginTop: '10px',
        }),
    ),
    InstrumentsTableContainer: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            marginTop: '20px',
        }),
    ),
};
