import { withFakeData } from 'api/fakedata';
import { axiosDataInstance } from 'api/axiosInstances';
import { GetCollateralDefinitionsResponse } from 'types/channelsCollateral/channelsCollateral.api.types';
import { ORDERS_BASE_URL } from 'constants/appConstants';
import { ApproveOrEditInformation } from 'types/channelsCollateral/channelsCollateral.types';
const apiName = 'channelsCollateral';

export default {
    getCollateralDefinitions: (filterQueryString: string) =>
        withFakeData<GetCollateralDefinitionsResponse>(
            () => axiosDataInstance.get(`${ORDERS_BASE_URL}/collateral/?${filterQueryString}`),
            apiName,
            'collateral',
            false,
            500,
        ),
    approveCollateral: ({ id, body }: { id: string; body: ApproveOrEditInformation }) =>
        withFakeData(
            () => axiosDataInstance.patch(`${ORDERS_BASE_URL}/collateral/${id}/accept`, body),
            apiName,
            'collateral',
            false,
            500,
        ),
    rejectCollateral: ({ id, body }: { id: string; body: { reason: string } }) =>
        withFakeData(
            () => axiosDataInstance.patch(`${ORDERS_BASE_URL}/collateral/${id}/reject`, body),
            apiName,
            'collateral',
            false,
            500,
        ),
    lockOrUnlockCollateral: ({ id, action }: { id: string; action: string }) =>
        withFakeData(
            () => axiosDataInstance.patch(`${ORDERS_BASE_URL}/collateral/${id}/${action}`),
            apiName,
            'collateral',
            false,
            500,
        ),
    editCollateral: ({ id, body }: { id: string; body: ApproveOrEditInformation }) =>
        withFakeData<{}>(
            () => axiosDataInstance.patch(`${ORDERS_BASE_URL}/collateral/${id}`, body),
            apiName,
            'collateral',
            false,
            500,
        ),
};
