import React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { Grid } from '@mui/material';
import { SimpleTreeView, TreeItem } from '@mui/x-tree-view';
import {
    AbilitiesListDefinition,
    UsersAbilitiesDefinition,
} from 'types/usersRoles/usersRoles.types';
import CustomLoader from 'components/common/CustomLoader';
import { FormikValues } from 'formik';

const AbilitiesComponent = (props: Props) => {
    const {
        t,
        isAbilitiesLoading,
        abilitiesList,
        handleSelectedItemsChange,
        values,
        abilitiesSubtitle,
        handleExpandedItemsChange,
        editValuesIsLoading,
    } = props;

    return (
        <Grid item xs={12} md={10}>
            {isAbilitiesLoading || editValuesIsLoading ? (
                <CustomLoader />
            ) : (
                <SimpleTreeView
                    expandedItems={abilitiesSubtitle}
                    onExpandedItemsChange={handleExpandedItemsChange}>
                    {abilitiesList?.map((abilities: AbilitiesListDefinition) => (
                        <TreeItem
                            key={t(abilities?.name)}
                            itemId={t(abilities?.name)}
                            label={t(`users_abilities_${abilities?.name?.toLowerCase()}_title`)}>
                            <SimpleTreeView
                                key={t(abilities?.name)}
                                multiSelect
                                checkboxSelection
                                onSelectedItemsChange={handleSelectedItemsChange}
                                selectedItems={values?.abilities?.map(
                                    (ability: UsersAbilitiesDefinition) => ability?.toString(),
                                )}>
                                {abilities?.list?.map((ability: UsersAbilitiesDefinition) => {
                                    return (
                                        <TreeItem
                                            key={ability?.id.toString()}
                                            itemId={ability?.id.toString()}
                                            label={t(
                                                `users_abilities_${ability?.name?.toLowerCase()}`,
                                            )}
                                        />
                                    );
                                })}
                            </SimpleTreeView>
                        </TreeItem>
                    ))}
                </SimpleTreeView>
            )}
        </Grid>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    isAbilitiesLoading: PropTypes.bool.isRequired,
    abilitiesList: PropTypes.array.isRequired,
    handleSelectedItemsChange: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
    abilitiesSubtitle: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const defaultProps = {};

interface extraProps {
    isAbilitiesLoading: boolean;
    abilitiesList: AbilitiesListDefinition[] | undefined;
    handleSelectedItemsChange: (event: React.SyntheticEvent, ids: string[]) => void;
    values: FormikValues;
    abilitiesSubtitle: string[];
    handleExpandedItemsChange: (event: React.SyntheticEvent, itemIds: string[]) => void;
    editValuesIsLoading: boolean;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
AbilitiesComponent.propTypes = propTypes;
AbilitiesComponent.defaultProps = defaultProps;

export default AbilitiesComponent;
