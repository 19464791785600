import { Box, styled } from '@mui/material';

export default {
    MarketnonWorkingDayDetail: styled(Box)(({ theme }) => ({
        borderRadius: '0.5em',
        boxShadow: '4px 5px 36px -9px rgba(0, 0, 0, 0.6)',
        marginTop: '1em',
        padding: '1em',
    })),
    DateContainer: styled(Box)(({ theme }) => ({
        display: 'flex',
        justifyContent: 'center',
        gap: '10em',
    })),
    DateTitle: styled(Box)(({ theme }) => ({
        fontSize: '1.2em',
        fontWeight: 'bold',
    })),
    DateValue: styled(Box)(({ theme }) => ({
        // backgroundColor: theme.palette.primary.main,
        // color: theme.palette.primary.contrastText,
        backgroundColor: '#f5f5f5',
        border: '1px solid #e0e0e0',
        borderRadius: '0.5em',
        fontSize: '1em',
        fontWeight: 'bold',
        padding: '0.5em 2em',
    })),
    DateBox: styled(Box)(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        textAlign: 'center',
    })),

    MarketContainer: styled(Box)(({ theme }) => ({
        marginTop: '2em',
        borderTop: '2px solid #e0e0e0',
        paddingTop: '1em',
    })),
    MarketTitle: styled(Box)(({ theme }) => ({
        fontSize: '1.2em',
        fontWeight: 'bold',
        marginBottom: '1em',
    })),
    MarketBox: styled(Box)(({ theme }) => ({
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        gap: '5px',
        padding: '0.5rem',
        textAlign: 'center',
    })),
    MarketName: styled(Box)(({ theme }) => ({
        backgroundColor: theme.palette.primary.main,
        borderRadius: '0.5em',
        color: 'white',
        fontSize: '0.9em',
        // fontWeight: 'bold',
        margin: '0rem 0.3rem',
        padding: '0.5em 1.2em',
        width: 'auto',
    })),
};
