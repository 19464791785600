import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ClientSectionAddress from './ClientSectionAddress';
import { useTranslation } from 'react-i18next';
import { UserAddress } from 'types/users/users.api.types';

const ClientSectionAddressContainer = (props: Props) => {
    const { t } = useTranslation('clients');

    const addressTitle: { [key: string]: string } = {
        PERSONAL: t('clients_address_personal'),
        REAL: t('clients_address_real'),
        TAX: t('clients_address_tax'),
    };

    const childProps = {
        ...props,
        addressTitle,
        t,
    };

    return <ClientSectionAddress {...childProps} />;
};

const propTypes = {};

const defaultProps = {};

interface extraProps {
    contacts: UserAddress[];
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ClientSectionAddressContainer.propTypes = propTypes;
ClientSectionAddressContainer.defaultProps = defaultProps;

export default ClientSectionAddressContainer;
