import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import {
    Button,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import CustomIconSelector from 'components/common/CustomIconSelector';
import { tCommon, tCommonGrid } from 'constants/appConstants';
import defaultTheme from 'utils/theme/defaultTheme';

const UsersBusinessAssistantReferralList = ({
    referralCode,
    referralCodes,
    error,
    handleAddReferral,
    setReferralCode,
    deleteUser,
    t,
}: Props) => (
    <Grid container item xs={12} spacing={1}>
        <Grid item xs={12}>
            <Typography>{t('referral_codes_title')}</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
            <TextField
                fullWidth
                size="small"
                placeholder={t('referral_code_placeholder')}
                value={referralCode}
                onChange={({ target: { value } }) => setReferralCode(value)}
                error={!!error}
                helperText={error}
            />
        </Grid>
        <Grid item xs={12} md={6}>
            <Button
                fullWidth
                variant="contained"
                color="secondary"
                disabled={!referralCode}
                onClick={handleAddReferral}>
                {t('add', tCommon)}
            </Button>
        </Grid>
        {referralCodes.length > 0 && (
            <Grid item xs={12} mt={1}>
                <TableContainer component={Paper}>
                    <Table aria-label="referral codes table">
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('code')}</TableCell>
                                <TableCell align="right" sx={{ pr: 5 }}>
                                    {t('actions', tCommonGrid)}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {referralCodes.map((code: string, index: number) => (
                                <TableRow key={index}>
                                    <TableCell>{code}</TableCell>
                                    <TableCell align="right" sx={{ pr: 4 }}>
                                        <Tooltip title={t('delete_tooltip', tCommonGrid)}>
                                            <IconButton onClick={() => deleteUser(index)}>
                                                <CustomIconSelector
                                                    type="delete"
                                                    sx={{
                                                        color: defaultTheme.palette.error.main,
                                                    }}
                                                />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        )}
    </Grid>
);

const propTypes = {
    t: PropTypes.func.isRequired,
};

const defaultProps = {};

interface extraProps {
    referralCode: string;
    referralCodes: string[];
    error?: string;
    setReferralCode(referralCode: string): void;
    handleAddReferral(): void;
    deleteUser(index: number): void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
UsersBusinessAssistantReferralList.propTypes = propTypes;
UsersBusinessAssistantReferralList.defaultProps = defaultProps;

export default UsersBusinessAssistantReferralList;
