import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CustomCheckbox from './CustomCheckbox';
import { ChangeEvent } from 'react';

const CustomCheckboxContainer = (props: Props) => <CustomCheckbox {...props} />;
const propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
};

const defaultProps = {};

interface extraProps {
    name: string;
    checked: boolean;
    handleChange(event: ChangeEvent<HTMLInputElement>): void;
    label?: string;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CustomCheckboxContainer.propTypes = propTypes;
CustomCheckboxContainer.defaultProps = defaultProps;

export default CustomCheckboxContainer;
