import endpoints from '../../api/users/users.api';
import { useMutation, useQuery } from '@tanstack/react-query';
import { GridHookParams } from 'types/common/CommonGrid/CommonGridFormModal.types';
import { usersDataAdapter } from 'adapters/api/users.adapter';
import { tErrorsContext } from 'constants/appConstants';
import { useTranslation } from 'react-i18next';
import { errorResponseHelper } from 'utils/helpers/errorsHelper';

export const useUsers = ({ forceDisable }: GridHookParams) => {
    const enabledCondition = !forceDisable;
    const { data, isLoading, isFetching, isRefetching, refetch } = useQuery(
        ['users'],
        () => endpoints.getUsers(),
        {
            enabled: enabledCondition,
            select: data => usersDataAdapter(data?.data),
        },
    );
    return {
        data,
        isLoading: (isLoading && isFetching) || isRefetching,
        refetch,
    };
};

export const useSearchUser = () => {
    const { t } = useTranslation();
    const { mutate, isLoading, error, isError } = useMutation(endpoints.searchUser);
    return {
        mutate,
        isLoading,
        isError,
        errorMessage: t(
            (error as any)?.response?.data?.error_messages[0].code ?? '',
            tErrorsContext,
        ),
    };
};

export const useGetUserBankAccounts = (setSnackBarMessage: Function) => {
    const { mutate, isLoading } = useMutation(endpoints.getUserBankAccounts, {
        onError: error => setSnackBarMessage(errorResponseHelper(error), 'error'),
    });
    return {
        mutate,
        isLoading,
    };
};
