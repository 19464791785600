import { withFakeData } from 'api/fakedata';
import { axiosAnonInstance, axiosDataInstance } from 'api/axiosInstances';
import { AUTH_BASE_URL, CASHFLOW_BASE_URL } from 'constants/appConstants';
import { GetUsersResponse, SearchUserResponse } from 'types/users/users.api.types';

const apiName = 'users';

export default {
    getUsers: () =>
        withFakeData<GetUsersResponse>(
            () => axiosAnonInstance.get(AUTH_BASE_URL),
            apiName,
            'users?page=1',
            false,
            500,
        ),
    searchUser: (filterQueryString: string) =>
        withFakeData<SearchUserResponse>(
            () => axiosDataInstance.get(`${AUTH_BASE_URL}/app_users/search?${filterQueryString}`),
            apiName,
            'users',
            false,
            500,
        ),
    getUserBankAccounts: ({ pagination, filter }: { pagination: string; filter?: string }) =>
        withFakeData(
            () =>
                axiosDataInstance.get(
                    `${CASHFLOW_BASE_URL}/users/bank-accounts?${pagination}${filter}`,
                ),
            apiName,
            'cashflow',
            false,
            500,
        ),
};
