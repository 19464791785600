import { styled as styledMui } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import defaultTheme from 'utils/theme/defaultTheme';

export default {
    SectionWrapper: styledMui(Box)(() => ({
        color: 'white',
        flex: 1,
        margin: '8rem 4rem',
    })) as typeof Box,
    ArticleWrapper: styledMui(Box)(() => ({
        color: 'black',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    })) as typeof Box,
    AsideWrapper: styledMui(Box)(() => ({
        flex: 1,
    })) as typeof Box,
    ParagraphWrapper: styledMui(Typography)(() => ({
        margin: ' 1rem 0',
        whiteSpace: 'pre-line',
        textAlign: 'center',
    })) as typeof Typography,
};
