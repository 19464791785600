import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import MarketInstrumentRulePage from './SettingsInstrumentRulesPage';
import { useTranslation } from 'react-i18next';
import { CommonGridColumnsProps } from 'types/common/CommonGrid/CommonGridColumnProps.types';
import {
    ActionColumn,
    ActionHeader,
    HeaderFiltersProps,
} from 'types/common/CommonGrid/CommonGridFormModal.types';
import CustomTableAction from 'components/common/CommonGrid/Extra/CustomTableAction';
import SettingsInstrumentRulesForm from './SettingsInstrumentRulesForm';
import { formatDateWithHour } from 'utils/helpers/dateHelper';
import CustomIconSelector from 'components/common/CustomIconSelector';
import { useGetActionsReestrictions } from 'hooks/common/roles.hooks';
import { UPDATE_RULES } from 'constants/abilities.constants';
import { InstrumentRule } from 'types/settingsInstrumentRules/settingsInstrumentRules.types';

const MarketInstrumentRulePageContainer = (props: Props) => {
    const { t } = useTranslation('settingsInstrumentRules');
    const { isHiddenByAbility } = useGetActionsReestrictions();

    const columns: CommonGridColumnsProps<InstrumentRule>[] = [
        {
            id: 'createdAt',
            name: t('rules_list_creation_date_column'),
            selector: row => formatDateWithHour(row.createdAt),
            sortField: 'createdAt',
            sortable: true,
            center: true,
        },
        {
            id: 'updatedAt',
            name: t('rules_list_modified_date_column'),
            selector: row => formatDateWithHour(row.updatedAt),
            sortField: 'updatedAt',
            sortable: true,
            center: true,
        },
        {
            id: 'action',
            name: t('rules_list_rule_type_column'),
            selector: row => t(row.action.replace(' ', '_')) as string,
            sortField: 'action',
            sortable: true,
            center: true,
        },
        {
            id: 'description',
            name: t('rules_list_description_column'),
            selector: row => row.description,
            sortField: 'description',
            sortable: true,
            center: true,
        },
    ];

    const headerActions: ActionHeader[] = [
        {
            id: 'create',
            component: () => (
                <CustomTableAction
                    type="create"
                    Icon={props => <div>Asd</div>}
                    modalTitle={'market-instrument-rules'}
                    width={{ xs: '100%', md: '80%', lg: '70%', xl: '50%' }}
                    component={(props: any) => <SettingsInstrumentRulesForm {...props} />}
                />
            ),
            isHidden: isHiddenByAbility(UPDATE_RULES),
        },
    ];

    const actionColumns: ActionColumn[] = [
        {
            id: 'edit',
            icon: (props: any) => <CustomIconSelector type="edit" sx={props.sx} />,
            component: (props: any) => <SettingsInstrumentRulesForm isEdit {...props} />,
            width: { xs: '100%', md: '80%', lg: '70%', xl: '50%' },
            isHidden: () => isHiddenByAbility(UPDATE_RULES),
        },
    ];

    const headerFilters: HeaderFiltersProps[] = [
        {
            id: 'action',
            label: t('market_instrument_rules_action_label'),
            type: 'select',
            placeholder: t('market_instrument_rules_action_placeholder'),
            query: 'action',
            options: [
                { id: 1, name: t('BLOCK_BUY') },
                { id: 2, name: t('BLOCK_SELL') },
                { id: 3, name: t('SOFT_DELETE') },
            ],
        },
        {
            id: 'from',
            label: t('market_instrument_rules_creation_date_from_filter_label'),
            type: 'date',
            placeholder: t('market_instrument_rules_creation_date_from_filter_placeholder'),
            query: 'dateFrom',
        },
        {
            id: 'to',
            label: t('market_instrument_rules_creation_date_to_filter_label'),
            type: 'date',
            placeholder: t('market_instrument_rules_creation_date_to_filter_placeholder'),
            query: 'dateTo',
        },
    ];

    const childProps = {
        ...props,
        t,
        columns,
        headerActions,
        actionColumns,
        headerFilters,
    };

    return <MarketInstrumentRulePage {...childProps} />;
};

const propTypes = {};

const defaultProps = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
MarketInstrumentRulePageContainer.propTypes = propTypes;
MarketInstrumentRulePageContainer.defaultProps = defaultProps;

export default MarketInstrumentRulePageContainer;
