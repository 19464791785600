import { Box, styled } from '@mui/material';
import loginBackground from 'assets/img/general/loginBackground.jpg';

export default {
    SectionWrapper: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            flex: 1,
            backgroundImage: `url(${loginBackground})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            height: '100%',
        }),
    ),
    ArticleWrapper: styled(Box)(() => ({
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    })) as typeof Box,
};
