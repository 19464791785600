import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import PropTypes from 'prop-types';
import St from './ProfilePage.styled';
import { Dialog, DialogTitle, Grid } from '@mui/material';
import ActionButton from 'components/common/ActionButton';
import { Form, FormikProvider } from 'formik';
import PasswordField from 'components/common/PasswordField';
import { tCommon } from 'constants/appConstants';

const ProfilePageForm = (props: Props) => {
    const { t, handleClose, title, openModal, formik } = props;
    const { errors, values, setFieldValue } = formik;

    return (
        <Dialog
            open={openModal}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <St.DialogContent>
                <FormikProvider value={formik} data-testid="UsersBackofficeForm-form">
                    <Form>
                        <St.ProfilePageForm>
                            <Grid container spacing={1}>
                                <DialogTitle
                                    id="alert-dialog-title"
                                    sx={{ padding: { xs: '0px 10px' } }}>
                                    {title}
                                </DialogTitle>
                                <Grid item xs={12} md={12} mt={2}>
                                    <PasswordField
                                        name="oldPassword"
                                        error={errors.oldPassword !== undefined}
                                        helperText={errors.oldPassword?.toString()}
                                        label={t('profile_page_form_previous_password_input')}
                                        placeholder={t(
                                            'profile_page_form_previous_password_placeholder',
                                        )}
                                        onChange={({ target }: any) => {
                                            setFieldValue('oldPassword', target.value);
                                        }}
                                        value={values.oldPassword}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12} mt={2}>
                                    <PasswordField
                                        name="newPassword"
                                        error={errors.newPassword !== undefined}
                                        helperText={errors.newPassword?.toString()}
                                        label={t('profile_page_form_password_input')}
                                        placeholder={t('profile_page_form_password_placeholder')}
                                        onChange={({ target }: any) => {
                                            setFieldValue('newPassword', target.value);
                                        }}
                                        value={values.newPassword}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12} mt={2}>
                                    <PasswordField
                                        name="newPasswordConfirmation"
                                        error={errors.newPasswordConfirmation !== undefined}
                                        helperText={errors.newPasswordConfirmation?.toString()}
                                        label={t('profile_page_form_repeat_password_input')}
                                        placeholder={t(
                                            'profile_page_form_repeat_password_placeholder',
                                        )}
                                        onChange={({ target }: any) => {
                                            setFieldValue('newPasswordConfirmation', target.value);
                                        }}
                                        value={values.newPasswordConfirmation}
                                    />
                                </Grid>
                            </Grid>
                            <St.DialogActions sx={{ px: 2, pb: 2 }}>
                                <ActionButton
                                    type="button"
                                    size="small"
                                    variant="outlined"
                                    onClick={handleClose}
                                    color="primary">
                                    {t('cancel', tCommon)}
                                </ActionButton>

                                <ActionButton type="submit" size="small" color="primary">
                                    {t('confirm', tCommon)}
                                </ActionButton>
                            </St.DialogActions>
                        </St.ProfilePageForm>
                    </Form>
                </FormikProvider>
            </St.DialogContent>
        </Dialog>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    openModal: PropTypes.bool.isRequired,
};

const defaultProps = {};

interface extraProps {
    handleClose: () => void;
    formik: any;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ProfilePageForm.propTypes = propTypes;
ProfilePageForm.defaultProps = defaultProps;

export default ProfilePageForm;
