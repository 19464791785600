import { Box, Typography, styled } from '@mui/material';

export default {
    MarketBox: styled(Box)(() => ({
        padding: '0.5rem',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        textAlign: 'center',
        gap: '5px',
    })) as typeof Box,
    MarketName: styled(Typography)(({ theme }) => ({
        width: 'auto', // Ancho de la Card
        fontWeight: 'bold',
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        borderRadius: '5px',
        padding: '0.3rem 0.6rem',
        // margin: '0rem 0.5rem',
        fontSize: '0.7rem',
    })) as typeof Typography,

    DateBox: styled(Box)(() => ({
        marginRight: '15px',
    })) as typeof Box,
};
