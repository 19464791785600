import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from 'router/AppRoutes';
// import { checkLogin } from 'store/authSlice/authSlice.slice';
import { SnackbarProvider, closeSnackbar } from 'notistack';
import { useCheckLogin } from 'hooks/api/auth.hooks';
import AppTheme from 'utils/theme/AppTheme';
import AuthContextProvider from 'context/auth.context';
import SidebarProvider from 'context/sidebar.context';
import { IconButton } from '@mui/material';
import CustomIconSelector from 'components/common/CustomIconSelector';
import NotificationsProvider from 'context/notifications.context';

const App = () => {
    useEffect(() => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useCheckLogin();
    }, []);

    return (
        <SnackbarProvider
            maxSnack={5}
            action={snackbarId => (
                <IconButton onClick={() => closeSnackbar(snackbarId)}>
                    <CustomIconSelector type="close" />
                </IconButton>
            )}>
            <AppTheme>
                <BrowserRouter>
                    <AuthContextProvider>
                        <NotificationsProvider>
                            <SidebarProvider>
                                <AppRoutes />
                            </SidebarProvider>
                        </NotificationsProvider>
                    </AuthContextProvider>
                </BrowserRouter>
            </AppTheme>
        </SnackbarProvider>
    );
};

export default App;
