import { paginationAdapter } from 'adapters/common/pagination.adapter';
import { HIDDEN_PROSPECT_STATES, tClient } from 'constants/appConstants';
import { t } from 'i18next';
import {
    ClientsDefinitionApi,
    GetClientsDefinitionsResponse,
    GetProspectDetailResponse,
    GetProspectsDefinitionsResponse,
    ProspectsDefinitionApi,
} from 'types/clients/clients.api.types';
import { ClientOrProspectItem, ClientsDefinition } from 'types/clients/clients.types';
import { UserAccount, UserDocuments } from 'types/users/users.api.types';
import { getFullName } from 'utils/helpers/commonHelper';

export const clientsDefinitionsAdapter = (data: GetClientsDefinitionsResponse) => {
    return {
        ...paginationAdapter(data),
        data: data.items?.map((clientsDefinition: ClientsDefinitionApi) => {
            return {
                id: clientsDefinition.id,
                createdAt: clientsDefinition.createdAt,
                updatedAt: clientsDefinition.updatedAt,
                email: clientsDefinition.email,
                docNumber: clientsDefinition?.info?.docNumber,
                fullName: getFullName(clientsDefinition),
                enabled: clientsDefinition.enabled,
            } as ClientOrProspectItem;
        }),
    };
};
export const prospectsDefinitionsAdapter = (data: GetProspectsDefinitionsResponse) => {
    return {
        ...paginationAdapter(data),
        data: data.items?.map((prospectsDefinition: ProspectsDefinitionApi) => {
            return {
                id: prospectsDefinition.id,
                createdAt: prospectsDefinition.createdAt,
                updatedAt: prospectsDefinition.updatedAt,
                email: prospectsDefinition.email,
                docNumber: prospectsDefinition.document,
                fullName: prospectsDefinition.fullName,
                phone: prospectsDefinition.phone,
                status: prospectsDefinition.status,
            } as ClientOrProspectItem;
        }),
    };
};

export const clientInfoAdapter = (data: ClientsDefinitionApi) => {
    return {
        id: data.id,
        contacts: data.contacts,
        documents: data.documents,
        createdAt: data.createdAt,
        updatedAt: data.updatedAt,
        marketAccounts: [] as UserAccount[],
        info: data.info,
        economicData: data.economicData,
        email: data.email,
        username: data.username,
        enabled: data.enabled,
        lastLogin: data.lastLogin,
        userType: data.userType,
        fullName: getFullName(data),
    } as ClientsDefinition;
};
export const prospectInfoAdapter = (data: GetProspectDetailResponse) => {
    return {
        id: data?.id,
        contacts: data?.contactInfo ? [data.contactInfo] : [],
        documents: data?.documentsInfo
            ? data?.documentsInfo.map(
                  d =>
                      ({
                          fileUrl: d.documentPath,
                          type: d.documentPath?.split('.').pop() ?? 'doc',
                          nameFile: d.documentName,
                      }) as UserDocuments,
              )
            : [],
        createdAt: data?.user.createdAt,
        updatedAt: data?.user.updatedAt,
        marketAccounts: [] as UserAccount[],
        info: {
            ...data?.personalInfo,
            referred: data?.additionalData?.referral,
            investmentProfile: data?.additionalData?.investmentProfile,
            termsAndConditions: data?.additionalData?.acceptContract,
        },
        steps: data?.steps?.steps
            .map(s => ({
                id: s.stepNumber,
                name: t(s.stepName, tClient) as string,
                status: s.status,
            }))
            .filter(s => !HIDDEN_PROSPECT_STATES.includes(s.name)),
        email: data?.user?.email,
        fullName: data?.user.fullName,
        username: data?.username ?? '-',
        riskProfile: data?.riskProfile,
        name: data?.personalInfo?.name ?? '',
        lastName: data?.personalInfo?.lastName ?? '',
        pendingFinalization: data?.user?.pendingFinalization,
        phone_number: data?.user?.phone,
        prospectHistory: data?.statusHistory,
        status: data?.user?.status,
        rejectedPersonalInfo: data?.rejectedPersonalInfo,
        rejectedPersonalReason: data?.rejectedPersonalReason,
        userType: 'APP_PHYSICAL',
        economicData: {
            nse: data?.additionalData?.nse,
            riskProfile: data?.riskProfile,
            transactionalProfile: data?.additionalData?.transactionalProfile,
            workActivity: data?.additionalData?.workActivity,
        },
    } as ClientsDefinition;
};

// {
//     "id": "db83642e-a386-4be3-8678-2c84c6fadcbb",
//     "username": "testrelacionar123",
//     "personalInfo": {
//         "name": "MAIA GERALDINE",
//         "lastName": "FLESCHLER PAZ",
//         "docType": "DNI",
//         "docNumber": "35337495",
//         "taxDocType": "CUIL/CUIT",
//         "taxDocNumber": "27353374953",
//         "nationality": "Argentina",
//         "gender": "F",
//         "birthDate": "1990-10-01",
//         "PEP": false,
//         "isUIF": true,
//         "OCDE": false,
//         "isUSA": false,
//         "taxCondition": "Exento",
//         "earningsRegistration": "Exento",
//         "countryTaxResidence": "",
//         "activityId": 43,
//         "ssn": "",
//         "pepCharge": ""
//     },
//     "contactInfo": {
//         "mobileNumber": "+541199999999",
//         "phoneNumber": "+541199999999",
//         "streetName": "LEOPOLDO MARECHAL",
//         "streetNum": "1374",
//         "addressExtra": null,
//         "floor": "1",
//         "square": "",
//         "apartment": "A",
//         "tower": "",
//         "sector": "",
//         "zipCode": "1414",
//         "city": "CAPITAL FEDERAL",
//         "state": "CABA",
//         "country": "Argentina",
//         "contactType": "REAL",
//         "documentPath": "2024-09-02_19-18-14/db83642e-a386-4be3-8678-2c84c6fadcbb/png_files/bd1796a4-6de5-4a9b-aab8-c94b860f80bf/0_signature.png"
//     },
//     "documentsInfo": [
//         {
//             "documentName": "FILE 1",
//             "documentPath": "2024-09-02_19-11-07/db83642e-a386-4be3-8678-2c84c6fadcbb/pdf_files/ec4b1c79-17d9-4d45-8be7-71e40d72299c/0_metodosdevisualbolsa.pdf"
//         },
//         {
//             "documentName": "SIGNATURE",
//             "documentPath": "2024-09-02_19-18-14/db83642e-a386-4be3-8678-2c84c6fadcbb/png_files/bd1796a4-6de5-4a9b-aab8-c94b860f80bf/0_signature.png"
//         }
//     ],
//     "additionalData": {
//         "workActivity": "Actividades Administrativas Y Servicios De Apoyo",
//         "referral": "referido",
//         "investmentProfile": "MODERATE",
//         "nse": "C1",
//         "transactionalProfile": "4772964"
//     },
//     "user": {
//         "id": "db83642e-a386-4be3-8678-2c84c6fadcbb",
//         "document": "35337495",
//         "phone": "+541199999999",
//         "fullName": "FLESCHLER PAZ MAIA GERALDINE",
//         "email": "lucia.rolon+9@codetria.com",
//         "pendingFinalization": false,
//         "finalized": true,
//         "finalizedErrorMsg": null,
//         "validationStep": "VALIDATED",
//         "validationScore": null,
//         "status": "COMPLETED",
//         "createdAt": "2024-09-02T19:07:11Z",
//         "updatedAt": "2024-09-02T19:29:01Z"
//     },
//     "riskProfile": "LOW",
//     "rejectedPersonalInfo": false,
//     "rejectedPersonalReason": "",
//     "statusHistory": [
//         {
//             "changedByUser": "lucia.rolon+9@codetria.com",
//             "status": "COMPLETED",
//             "updatedAt": "2024-09-02T19:18:15Z",
//             "reason": ""
//         },
//         {
//             "changedByUser": "",
//             "status": "COMPLETED",
//             "updatedAt": "2024-09-02T19:29:02Z",
//             "reason": ""
//         }
//     ]
// }
