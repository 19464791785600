import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './FadeIn.styled';

const FadeIn = (props: Props) => {
    const { duration, children } = props;

    return <St.FadeIn duration={duration || '0.5s'}>{children}</St.FadeIn>;
};
const propTypes = {};

const defaultProps = {};

interface extraProps {
    duration?: string;
    children: React.ReactNode;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
FadeIn.propTypes = propTypes;
FadeIn.defaultProps = defaultProps;

export default FadeIn;
