import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './ErrorPage.styled';
import { Typography } from '@mui/material';
import PageTitle from 'components/common/PageTitle';

const ErrorPage = (props: Props) => {
    const { t } = props;

    return (
        <>
            <St.SectionWrapper component={'section'} data-testid="primary-section">
                <PageTitle>{t('page_error_header')}</PageTitle>
                <PageTitle>{t('page_error_title')}</PageTitle>
                <St.ArticleWrapper
                    component={'article'}
                    sx={{ padding: { xs: '0', md: '1.25rem 2rem 1rem 0' } }}>
                    <St.ParagraphWrapper>{t('page_error_description')}</St.ParagraphWrapper>
                </St.ArticleWrapper>
            </St.SectionWrapper>
        </>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

const defaultProps = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ErrorPage.propTypes = propTypes;
ErrorPage.defaultProps = defaultProps;

export default ErrorPage;
