import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ChannelsAffidavitCounters from './ChannelsAffidavitCounters';
import { useGetChannnelsAffidavitCounters } from 'hooks/api/channelsAffidavit.hooks';
import { useTranslation } from 'react-i18next';

const ChannelsAffidavitCountersContainer = (props: Props) => {
    // const {} = props;
    const { t } = useTranslation('channelsAffidavit');
    const { countPending, countRejected, isLoading } = useGetChannnelsAffidavitCounters();

    const childProps = {
        ...props,
        countPending,
        countRejected,
        isLoading,
        t,
    };

    return <ChannelsAffidavitCounters {...childProps} />;
};

const propTypes = {};

const defaultProps = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ChannelsAffidavitCountersContainer.propTypes = propTypes;
ChannelsAffidavitCountersContainer.defaultProps = defaultProps;

export default ChannelsAffidavitCountersContainer;
