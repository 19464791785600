import { withFakeData } from 'api/fakedata';
import { axiosDataInstance } from 'api/axiosInstances';
import { GetClientBankAccountsDefinitionsResponse } from 'types/clientBankAccounts/clientBankAccounts.api.types';
import { CASHFLOW_BASE_URL } from 'constants/appConstants';
import { BankAccountExtraData } from 'types/clientExtractions/clientExtractions.types';
const apiName = 'clientBankAccounts';
export default {
    getClientBankAccountsDefinition: (filterQueryString: string) =>
        withFakeData<GetClientBankAccountsDefinitionsResponse>(
            () =>
                axiosDataInstance.get(
                    `${CASHFLOW_BASE_URL}/users/bank-accounts/?${filterQueryString}`,
                ),
            apiName,
            'ClientBankAccounts',
            false,
            500,
        ),
    approveAccount: ({ id, body }: { id: string; body: { type: string; currency: string } }) => {
        return withFakeData<{}>(
            () =>
                axiosDataInstance.patch(
                    `${CASHFLOW_BASE_URL}/users/bank-accounts/${id}/accept`,
                    body,
                ),
            apiName,
            'approve-ClientBankAccounts',
            false,
            500,
        );
    },
    rejectAccount: ({ id, body }: { id: string; body: { reason: string } }) => {
        return withFakeData<{}>(
            () =>
                axiosDataInstance.patch(
                    `${CASHFLOW_BASE_URL}/users/bank-accounts/${id}/reject`,
                    body,
                ),
            apiName,
            'reject-ClientBankAccounts',
            false,
            500,
        );
    },
    editAccount: ({
        id,
        body,
    }: {
        id: string;
        body: { type?: string; currency?: string; extraData?: BankAccountExtraData[] };
    }) => {
        return withFakeData<{}>(
            () => axiosDataInstance.patch(`${CASHFLOW_BASE_URL}/users/bank-accounts/${id}`, body),
            apiName,
            'edit-ClientBankAccounts',
            false,
            500,
        );
    },
};
