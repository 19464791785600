import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import NoPermissionsPage from './NoPermissionsPage';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const NoPermissionsPageContainer = (props: Props) => {
    // const {} = props;
    const { t } = useTranslation();
    const navigate = useNavigate();

    const backToHome = () => navigate('/');

    const childProps = {
        ...props,
        t,
        backToHome,
    };

    return <NoPermissionsPage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
NoPermissionsPageContainer.propTypes = propTypes;

export default NoPermissionsPageContainer;
