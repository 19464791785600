import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import BackToBaseButton from './BackToBaseButton';
import { useTranslation } from 'react-i18next';

const BackToBaseButtonContainer = (props: Props) => {
    // const {} = props;
    const { t } = useTranslation();

    const childProps = {
        ...props,
        t,
    };

    return <BackToBaseButton {...childProps} />;
};

const propTypes = {
    disabled: PropTypes.bool,
};

const defaultProps = {};

interface extraProps {
    backToBase: () => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
BackToBaseButtonContainer.propTypes = propTypes;
BackToBaseButtonContainer.defaultProps = defaultProps;

export default BackToBaseButtonContainer;
