import getEnvVariables from 'utils/helpers/envVarsHelper';

const {
    VITE_BASEURL,
    VITE_ENABLE_FIREBASE_LOGS,
    VITE_ENABLE_SENTRY_LOGS,
    VITE_ALLOW_LOGS,
    VITE_OFFLINE_MODE,
    VITE_AXIOS_WITH_CREDENTIALS,
    VITE_PROJECT_HEAD,
    VITE_DEFAULT_THEME,
    VITE_BO_WS_URL,
} = getEnvVariables();

export const V_BASE_URL = VITE_BASEURL;
export const V_ENABLE_FIREBASE_LOGS = VITE_ENABLE_FIREBASE_LOGS === 'true';
export const V_ENABLE_SENTRY_LOGS = VITE_ENABLE_SENTRY_LOGS === 'true';
export const V_ALLOW_LOGS = VITE_ALLOW_LOGS === 'true';
export const V_OFFLINE_MODE = VITE_OFFLINE_MODE === 'true';
export const V_AXIOS_WITH_CREDENTIALS = VITE_AXIOS_WITH_CREDENTIALS === 'true';
export const V_PROJECT_HEAD = VITE_PROJECT_HEAD;
export const V_DEFAULT_THEME = VITE_DEFAULT_THEME;
export const V_BO_WS_URL = VITE_BO_WS_URL;
