import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './ErrorText.styled';

const ErrorText = (props: Props) => {
    const { text } = props;

    return (
        <St.Text data-testid="ErrorText-default">
            <span>{text}</span>
        </St.Text>
    );
};

const propTypes = {
    text: PropTypes.string,
};

const defaultProps = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ErrorText.propTypes = propTypes;
ErrorText.defaultProps = defaultProps;

export default ErrorText;
