import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './SettingsInstrumentRulesForm.styled';
import { Form, FormikProvider } from 'formik';
import ActionButton from 'components/common/ActionButton';
import { tCommon } from 'constants/appConstants';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Grid, Tab } from '@mui/material';
import { RulesTabsTypes } from 'types/settingsInstrumentRules/settingsInstrumentRules.types';
import RuleTab from './RuleTab';
import ConditionsTab from './ConditionsTab';
import UserExceptionsTable from 'components/common/UserExceptionsTable';

const SettingsInstrumentRulesForm = (props: Props) => {
    const { formik, close, isEdit, t, handleSelectTab, tabValue, isLoading } = props;

    return (
        <FormikProvider value={formik} data-testid="SettingsInstrumentRulesForm-form">
            <Form>
                <TabContext value={tabValue}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleSelectTab}>
                            <Tab label={t('tab_panel_rule_option_label')} value="rule" />
                            <Tab
                                label={t('tab_panel_conditions_option_label')}
                                value="conditions"
                            />
                            <Tab
                                label={t('tab_panel_exceptions_option_label')}
                                value="exceptions"
                            />
                        </TabList>
                    </Box>
                    <TabPanel value="rule">
                        <RuleTab
                            values={formik.values}
                            setFieldValue={formik.setFieldValue}
                            errors={formik.errors}
                        />
                    </TabPanel>
                    <TabPanel value="conditions">
                        <ConditionsTab
                            isSoftDelete={formik.values?.action?.value === 'SOFT DELETE'}
                            conditions={formik.values.conditions}
                            handleChange={formik.handleChange}
                        />
                    </TabPanel>
                    <TabPanel value="exceptions">
                        <Grid container>
                            <UserExceptionsTable
                                exceptions={formik.values.exceptions}
                                handleChange={formik.handleChange}
                            />
                        </Grid>
                    </TabPanel>
                </TabContext>
                <St.FooterButtons direction={'row'} spacing={1}>
                    <ActionButton onClick={close} variant="outlined">
                        {t('cancel', tCommon)}
                    </ActionButton>
                    <ActionButton loading={isLoading} type="submit" variant="contained">
                        {t(isEdit ? 'edit' : 'add', tCommon)}
                    </ActionButton>
                </St.FooterButtons>
            </Form>
        </FormikProvider>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    isEdit: PropTypes.bool,
    isLoading: PropTypes.bool.isRequired,
};

const defaultProps = {};

interface extraProps {
    formik: any;
    tabValue: RulesTabsTypes;
    handleSelectTab: (event: React.SyntheticEvent, newValue: RulesTabsTypes) => void;
    close: () => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
SettingsInstrumentRulesForm.propTypes = propTypes;
SettingsInstrumentRulesForm.defaultProps = defaultProps;

export default SettingsInstrumentRulesForm;
