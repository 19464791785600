import * as React from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ClientBankAccountsEditForm from './ClientBankAccountsEditForm';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { tRequiredFieldError } from 'constants/appConstants';
import { BankAccountExtraData } from 'types/clientExtractions/clientExtractions.types';
import { useEditBankAccount } from 'hooks/api/clientBankAccounts.hooks';
import useSnackBar from 'hooks/common/CommonGrid/snackBar.hooks';

// ---------------------------------------------//
// ------------------- FORMIK ------------------//
// ---------------------------------------------//

const getInitialValues = ({
    appellation,
    aba,
    swift,
    iban,
    broker,
    brokerCustomerCode,
}: BankAccountExtraData) => ({
    appellation: appellation || '',
    aba: aba || '',
    swift: swift || '',
    iban: iban || '',
    broker: broker || '',
    brokerCustomerCode: brokerCustomerCode || '',
});

const getValidationSchema = () =>
    Yup.object().shape({
        appellation: Yup.string().required(tRequiredFieldError),
        broker: Yup.string().required(tRequiredFieldError),
        brokerCustomerCode: Yup.string().required(tRequiredFieldError),
    });

// ---------------------------------------------//
// ---------------------------------------------//
// ---------------------------------------------//

const ClientBankAccountsEditFormContainer = (props: Props) => {
    const { id, bankAccountExterior, close } = props;
    const { t } = useTranslation();
    const { setSnackBarMessage } = useSnackBar();
    const { mutate, isLoading } = useEditBankAccount(setSnackBarMessage, close);

    const handleSubmit = React.useCallback(async (data: any) => {
        mutate({ id, body: { extraData: [{ id: bankAccountExterior.id, ...data }] } });
    }, []);

    const formikInitProps = React.useMemo(
        () => ({
            initialValues: getInitialValues(props.bankAccountExterior),
            validateOnChange: false,
            validateOnBlur: false,
            validateOnMount: false,
            validationSchema: getValidationSchema(),
            enableReinitialize: true,
            onSubmit: handleSubmit,
        }),
        [handleSubmit],
    );

    const formik = useFormik(formikInitProps);

    const childProps = {
        ...props,
        formik,
        isLoading,
        disabledForm:
            JSON.stringify(formik.values) ===
            JSON.stringify(getInitialValues(props.bankAccountExterior)),
        handleSubmit,
        t,
    };

    return <ClientBankAccountsEditForm {...childProps} />;
};

const propTypes = {
    isEdit: PropTypes.bool.isRequired,
};

const defaultProps = {
    isEdit: true,
};

interface extraProps {
    id: string;
    open: boolean;
    bankAccountExterior: BankAccountExtraData;
    type: string;
    close: () => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ClientBankAccountsEditFormContainer.propTypes = propTypes;
ClientBankAccountsEditFormContainer.defaultProps = defaultProps;

export default ClientBankAccountsEditFormContainer;
