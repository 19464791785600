import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import SettingsBlocksEdit from './SettingsBlocksEdit';
import { useTranslation } from 'react-i18next';
import { Functionality, FunctionalityToEdit } from 'types/settingsBlocks/settingsBlocks.api.types';
import { useEffect, useState } from 'react';
import { useUpdateBlocksFunctionality } from 'hooks/api/settingsBlocks.hooks';
import { AlertColor } from '@mui/material';

const SettingsBlocksEditContainer = (props: Props) => {
    const { row, setSnackBarMessage, close } = props;
    const { t } = useTranslation('settingsBlocks');
    const [values, setValues] = useState({} as FunctionalityToEdit);
    const { mutate, isLoading } = useUpdateBlocksFunctionality(setSnackBarMessage, close);

    const platformOptions = ['WEB', 'MOBILE', 'ALL'];
    const appliesToOptions = ['CHANNEL', 'APP_USERS', 'ALL'];

    const handleChange = (name: string, value: any) => setValues({ ...values, [name]: value });

    const handleSubmit = () => {
        const { locked, ...dataToEdit } = values;
        mutate({ id: row.id, body: { locked, details: [dataToEdit] } });
    };

    const childProps = {
        ...props,
        title: props.row.name,
        values,
        platformOptions,
        appliesToOptions,
        isLoading,
        handleChange,
        handleSubmit,
        t,
    };

    useEffect(() => {
        if (row?.details && row?.details?.length > 0)
            setValues({ locked: row.locked, ...row.details[0] });
    }, [row]);

    return <SettingsBlocksEdit {...childProps} />;
};

const propTypes = {};

const defaultProps = {};

interface extraProps {
    row: Functionality;
    setSnackBarMessage: (msj: string, sever?: AlertColor) => void;
    close(): void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
SettingsBlocksEditContainer.propTypes = propTypes;
SettingsBlocksEditContainer.defaultProps = defaultProps;

export default SettingsBlocksEditContainer;
