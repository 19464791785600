import { withFakeData } from 'api/fakedata';
import { axiosDataInstance } from 'api/axiosInstances';
import { RULES_BASE_URL } from 'constants/appConstants';
import {
    FunctionalityPayload,
    FunctionalityResponse,
} from 'types/settingsBlocks/settingsBlocks.api.types';

const apiName = 'settingsBlocks';

export default {
    getBlocks: (filterQueryString: string) =>
        withFakeData<FunctionalityResponse>(
            () => axiosDataInstance.get(`${RULES_BASE_URL}/functionality/?${filterQueryString}`),
            apiName,
            'settingsBlocksCall',
            false,
            2000,
        ),
    updateBlocks: ({ id, body }: { id: number; body: FunctionalityPayload }) =>
        withFakeData(
            () => axiosDataInstance.patch(`${RULES_BASE_URL}/functionality/update/${id}`, body),
            apiName,
            'settingsBlocksCall',
            false,
            2000,
        ),
};
