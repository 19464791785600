import { useMutation, useQuery } from '@tanstack/react-query';
import endpoints from '../../api/usersBroker/usersBroker.api';
import { GridHookParams } from 'types/common/CommonGrid/CommonGridFormModal.types';
import { useTranslation } from 'react-i18next';
import { userBrokerAdapter } from 'adapters/api/usersBroker.adapter';
import { errorResponseHelper } from 'utils/helpers/errorsHelper';
import { tErrorsContext } from 'constants/appConstants';

export const useBroker = ({ forceDisable, filterQueryString }: GridHookParams) => {
    const enabledCondition = !forceDisable;

    const { data, isLoading, isFetching, isRefetching, refetch } = useQuery(
        ['users-broker', filterQueryString],
        () => endpoints.getBrokerUsers(filterQueryString),
        {
            enabled: enabledCondition,
            select: data => {
                return userBrokerAdapter(data?.data);
            },
        },
    );

    return {
        data,
        isLoading: (isLoading && isFetching) || isRefetching,
        refetch,
    };
};

export const useEnableUsersBroker = (setSnackBarMessage: Function) => {
    const { t } = useTranslation();
    const { mutate, isLoading } = useMutation(endpoints.enableBroker, {
        onError: error => {
            setSnackBarMessage(t(errorResponseHelper(error as any), tErrorsContext), 'error');
        },
    });

    return {
        mutate,
        isLoading,
    };
};

export const useDisableUsersBroker = (setSnackBarMessage: Function) => {
    const { t } = useTranslation();
    const { mutate, isLoading } = useMutation(endpoints.disableBroker, {
        onError: error => {
            setSnackBarMessage(t(errorResponseHelper(error as any), tErrorsContext), 'error');
        },
    });

    return {
        mutate,
        isLoading,
    };
};

export const useCreateUsersBroker = (close: Function, setSnackBarMessage: Function) => {
    const { t } = useTranslation('usersBroker');
    const { mutate, isLoading } = useMutation(endpoints.createUsersBroker, {
        onSuccess: () => {
            setSnackBarMessage(t('broker_users_form_modal_create_success'));
            close();
        },
        onError: error => {
            setSnackBarMessage(errorResponseHelper(error as any), 'error');
        },
    });
    return {
        mutate,
        isLoading,
    };
};
export const useEditUsersBroker = (close: Function, setSnackBarMessage: Function) => {
    const { t } = useTranslation('usersBroker');
    const { mutate, isLoading, error } = useMutation(endpoints.editUsersBroker, {
        onSuccess: () => {
            setSnackBarMessage(t('broker_users_form_modal_edit_success'));
            close();
        },
    });
    return {
        mutate,
        isLoading,
        errorMessage: t((error as any)?.response?.data?.detail ?? '', tErrorsContext),
    };
};
