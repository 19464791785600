import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { CommonGridColumnsProps } from 'types/common/CommonGrid/CommonGridColumnProps.types';
import CommonGrid from 'components/common/CommonGrid';
import { useDeleteInstrumentRule, useRules } from 'hooks/api/settingsInstrumentRules';
import {
    ActionColumn,
    ActionHeader,
    HeaderFiltersProps,
} from 'types/common/CommonGrid/CommonGridFormModal.types';
import { InstrumentRule } from 'types/settingsInstrumentRules/settingsInstrumentRules.types';

const MarketInstrumentRulePage = (props: Props) => {
    const { t, columns, headerActions, actionColumns, headerFilters } = props;
    return (
        <CommonGrid
            title={t('rules_list_title')}
            columns={columns}
            resource="market-instrument-rules"
            useGetData={useRules}
            deleteHook={useDeleteInstrumentRule}
            defaultSortFieldId="createdAt"
            canExport
            extraActionsInHeader={headerActions}
            customActionColumns={actionColumns}
            headerFilters={headerFilters}
        />
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

const defaultProps = {};

interface extraProps {
    columns: CommonGridColumnsProps<InstrumentRule>[];
    headerActions: ActionHeader[];
    actionColumns: ActionColumn[];
    headerFilters: HeaderFiltersProps[];
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
MarketInstrumentRulePage.propTypes = propTypes;
MarketInstrumentRulePage.defaultProps = defaultProps;

export default MarketInstrumentRulePage;
