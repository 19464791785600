import { useMutation, useQuery } from '@tanstack/react-query';
import endpoints from '../../api/clientImportClientVB/clientImportClientVB.api';
import { clientByIdAdapter } from 'adapters/api/clientImportClientVB.adapter';
import { tErrorsContext } from 'constants/appConstants';
import { errorResponseHelper } from 'utils/helpers/errorsHelper';
import { useTranslation } from 'react-i18next';

export const useGetClientVBById = (id: number | false) => {
    const { t } = useTranslation();
    const { data, isLoading, isFetching, isRefetching, remove, error } = useQuery(
        ['clientById', id],
        () => endpoints.clientById(id as number),
        {
            enabled: !!id,
            select: data => clientByIdAdapter(data.data),
        },
    );

    return {
        data,
        isLoading: (isLoading && isFetching) || isRefetching,
        remove,
        error: error ? t(errorResponseHelper(error as any), tErrorsContext) : false,
    };
};

export const useSendCredentials = () => {
    const { mutate, isLoading } = useMutation(endpoints.sendCredentials);
    return {
        isLoading,
        mutate,
    };
};
export const useRegister = () => {
    const { mutate, isLoading } = useMutation(endpoints.register);
    return {
        isLoading,
        mutate,
    };
};
