import { Box, styled } from '@mui/material';

export default {
    DetailTitle: styled(Box)(({ theme }) => ({
        width: '100%',
        fontSize: '16px',
        fontWeight: 400,
        marginBottom: '1rem',
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        textAlign: 'center',
        borderRadius: '0.5em',
        padding: '5px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    })),
};
