import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { statesToShowActions } from 'utils/helpers/channelsCommonHelper';
import {
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Radio,
    RadioGroup,
} from '@mui/material';
import ChannelsModalActionButton from 'components/common/ChannelsModalActionButton';
import ChannelsModalActions from 'components/common/ChannelsModalActions';
import RejectForm from 'components/common/RejectForm';
import { COMPLETED } from 'constants/appConstants';

const ChannelsFundDetailActions = ({
    id,
    status,
    isLoading,
    isLoadingReject,
    reason,
    action,
    setApprovedApi,
    setAction,
    setReason,
    handleAcquireOrReleaseOrder,
    handleSubmit,
    close,
    t,
}: Props) => (
    <>
        {statesToShowActions.includes(status) && (
            <>
                {status !== COMPLETED && (
                    <>
                        <Divider sx={{ width: '100%', mt: 1.25 }} />

                        <ChannelsModalActionButton status={status} setAction={setAction} />
                    </>
                )}

                <Grid
                    container
                    item
                    direction="column"
                    alignSelf="baseline"
                    xs={6}
                    spacing={2}
                    ml={1.25}>
                    {action === 'approve' && (
                        <Grid item xs>
                            <FormControl>
                                <FormLabel
                                    id="approve-options"
                                    sx={{ fontSize: 16, fontWeight: 600, color: '#333' }}>
                                    {t('funding_select_option')}
                                </FormLabel>
                                <RadioGroup
                                    aria-labelledby="approve-options"
                                    defaultValue="female"
                                    name="radio-buttons-group">
                                    <FormControlLabel
                                        value="api"
                                        control={<Radio />}
                                        onChange={() => setApprovedApi(true)}
                                        label={t('funding_via_api')}
                                    />
                                    <FormControlLabel
                                        value="manual"
                                        control={<Radio />}
                                        onChange={() => setApprovedApi(false)}
                                        label={t('funding_via_manual')}
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    )}

                    {action === 'reject' && <RejectForm setReason={setReason} reason={reason} />}
                </Grid>
            </>
        )}

        <Divider sx={{ width: '100%', mt: 2 }} />

        <ChannelsModalActions
            id={id}
            type="FUND"
            status={status}
            loading={isLoading || isLoadingReject}
            disabled={isLoading || isLoadingReject}
            close={close}
            handleSubmit={handleSubmit}
            handleAcquireOrReleaseOrder={handleAcquireOrReleaseOrder}
        />
    </>
);

const propTypes = {
    t: PropTypes.func.isRequired,
};

const defaultProps = {};

interface extraProps {
    id: string;
    status: string;
    isLoading: boolean;
    isLoadingReject: boolean;
    reason?: string;
    action: string;
    setApprovedApi(approvedApi: boolean): void;
    setAction(action: string): void;
    close(): void;
    setReason(reason: string): void;
    handleAcquireOrReleaseOrder({ id, action }: { id: string; action: string }): void;
    handleSubmit(): void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ChannelsFundDetailActions.propTypes = propTypes;
ChannelsFundDetailActions.defaultProps = defaultProps;

export default ChannelsFundDetailActions;
