import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { IconButton, Tooltip } from '@mui/material';
import DeleteDialog from '../DeleteDialog';
import { tCommonGrid } from 'constants/appConstants';
import CustomIconSelector from 'components/common/CustomIconSelector';

const CanDisable = (props: Props) => {
    const { isDisabled, id, resource, t, openModal, setOpenModal, isLoading, onDisable } = props;

    return (
        <>
            <Tooltip
                title={t(!isDisabled ? 'can_disable_modal_title' : 'can_enable_modal_title', {
                    ns: 'cdtModal',
                })}>
                <IconButton onClick={() => setOpenModal(true)}>
                    <CustomIconSelector type={isDisabled ? 'enable' : 'disable'} />
                </IconButton>
            </Tooltip>
            <DeleteDialog
                title={t(
                    isDisabled ? 'enabling_dialog_form_title' : 'disabling_dialog_form_title',
                    {
                        resource: `${resource.replace('-', '_')}_modal_text`,
                        tCommonGrid,
                    },
                )}
                contentText={t(
                    isDisabled ? 'enabling_dialog_form_content' : 'disabling_dialog_form_content',
                    {
                        resource: `${resource.replace('-', '_')}_modal_text`,
                        ns: 'commonGrid',
                    },
                )}
                open={openModal}
                setOpen={setOpenModal}
                onDelete={() => onDisable(id as number)}
                deleteStatus={isLoading}
            />
        </>
    );
};

const propTypes = {
    resource: PropTypes.string.isRequired,
    isDisabled: PropTypes.bool.isRequired,
    fake: PropTypes.bool,
    isLoading: PropTypes.bool.isRequired,
    openModal: PropTypes.bool.isRequired,
    t: PropTypes.any.isRequired,
    onDisable: PropTypes.any.isRequired,
};

const defaultProps = {};

type extraProps = {
    id: number | string;
    setOpenModal: (state: boolean) => void;
};

type Props = InferPropsExtended<typeof propTypes, extraProps>;
CanDisable.propTypes = propTypes;
CanDisable.defaultProps = defaultProps;

export default CanDisable;
