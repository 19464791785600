import { useMutation, useQuery } from '@tanstack/react-query';
import endpoints from '../../api/marketDollarQuotes/marketDollarQuotes.api';
import { GridHookParams } from 'types/common/CommonGrid/CommonGridFormModal.types';
import { dollarQuotesAdapter } from 'adapters/api/marketDollarQuotes.adapter';
import { tErrorsContext } from 'constants/appConstants';
import { useTranslation } from 'react-i18next';
import { errorResponseHelper } from 'utils/helpers/errorsHelper';

export const useDollarQuotes = ({ forceDisable, filterQueryString }: GridHookParams) => {
    const enabledCondition = !forceDisable;

    const { data, isLoading, isFetching, isRefetching, refetch } = useQuery(
        ['dollar-quotes', filterQueryString],
        () => endpoints.getDollarQuotes(filterQueryString),
        {
            enabled: enabledCondition,
            select: data => {
                return dollarQuotesAdapter(data?.data);
            },
        },
    );

    return {
        data,
        isLoading: (isLoading && isFetching) || isRefetching,
        refetch,
    };
};

export const useDeleteQuotes = () => {
    const { t } = useTranslation('marketDollarQuotes');
    const { mutate, isLoading, error, isError } = useMutation(endpoints.deleteQuote, {
        onSuccess: () => {
            // close();
        },
    });
    return {
        mutate,
        isLoading,
        isError,
        errorMessage: t(
            (error as any)?.response?.data?.error_message[0].code ?? '',
            tErrorsContext,
        ),
        successfulMessage: t('quotes_modal_deleated_success'),
    };
};

export const useCreateDollarQuotes = (close: Function, setSnackBarMessage: Function) => {
    const { t } = useTranslation('marketDollarQuotes');
    const { mutate, isLoading } = useMutation(endpoints.createDollarQuotes, {
        onSuccess: () => {
            setSnackBarMessage(t('dollarQuotes_create_success'));
            close();
        },
        onError(err: any) {
            setSnackBarMessage(errorResponseHelper(err), 'error');
        },
    });
    return {
        mutate,
        isLoading,
    };
};

export const useEditDollarQuotes = (close: Function, setSnackBarMessage: Function) => {
    const { t } = useTranslation('marketDollarQuotes');
    const { mutate, isLoading, error } = useMutation(endpoints.editDollarQuotes, {
        onSuccess: () => {
            setSnackBarMessage(t('dollarQuotes_edit_success'));
            close();
        },
    });
    return {
        mutate,
        isLoading,
        errorMessage: t((error as any)?.response?.data?.error_messages[0].tErrorsContext),
    };
};
