import {
    Box,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
    styled,
} from '@mui/material';
import ActionButton from 'components/common/ActionButton';
import AlertNoData from 'components/common/AlertNoData';

export default {
    Paper: styled(Paper)(() => ({})),
    Title: styled(Typography)(() => ({
        flex: '1 1 100%',
        padding: '10px 8px 0',
    })) as typeof Typography,
    TableContainer: styled(TableContainer)(({ theme }) => ({
        height: 'fit-content',
        '#noData': {
            div: {
                width: 'fit-content !important',
                margin: 'auto',
                gap: '.5rem',
            },
        },
    })) as typeof TableContainer,
    Table: styled(Table)(() => ({})),
    TableBody: styled(TableBody)(() => ({})),
    TableCell: styled(TableCell)(() => ({})),
    TableHead: styled(TableHead)(() => ({})),
    TablePagination: styled(TablePagination)(({ theme }) => ({})) as typeof TablePagination,
    TableRow: styled(TableRow)(() => ({})),
    AlertNoData: styled(AlertNoData)(() => ({})),
    ButtonWrapper: styled(Box)(() => ({
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '.5rem',
        padding: '.5rem',
        button: { width: 'max-content', margin: 'auto 0' },
    })),
    ActionButton: styled(ActionButton)(() => ({})),
    IconButton: styled(IconButton)(() => ({})),
};
