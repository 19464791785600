import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import MenuItem from './MenuItem';
import { SubRouteInfo } from 'utils/helpers/routesHelper';

const MenuItemContainer = (props: Props) => {
    const { itemData, selectedMenu } = props;
    const hasSubroutes = (itemData.subRoutes && itemData.subRoutes?.length > 0) ?? false;

    const selectedMenuSegment = selectedMenu.split('/')[0];

    const isSubmenuSelected =
        itemData.subRoutes?.some(subRoute => {
            const subRouteNameWithHyphen = subRoute.name.replace(/_/g, '-');
            return `${selectedMenuSegment}/${subRouteNameWithHyphen}` === selectedMenu;
        }) ?? false;

    const menuIsSelected =
        selectedMenuSegment === itemData.name.replace(/_/g, '-') || isSubmenuSelected;

    const [isCollapseOpen, setIsCollapseOpen] = React.useState<boolean>(isSubmenuSelected);

    const childProps = {
        ...props,
        isCollapseOpen,
        setIsCollapseOpen,
        hasSubroutes,
        menuIsSelected,
        isSubmenuSelected,
        selectedMenu,
        selectedMenuSegment,
    };

    return <MenuItem {...childProps} />;
};

const propTypes = {
    drawerOpened: PropTypes.bool.isRequired,
    handleNavigate: PropTypes.func.isRequired,
    selectedMenu: PropTypes.string.isRequired,
};

const defaultProps = {};

interface extraProps {
    itemData: SubRouteInfo;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
MenuItemContainer.propTypes = propTypes;
MenuItemContainer.defaultProps = defaultProps;

export default MenuItemContainer;
