import { useMutation } from '@tanstack/react-query';
import { axiosAnonInstance, axiosDataInstance } from 'api/axiosInstances';

const useDisable = (resource: string, isDisabled: boolean, fake?: boolean) => {
    const instance = fake ? axiosAnonInstance : axiosDataInstance;
    const disableRecord = (id: number) =>
        instance.put(`accounts/v1/${resource}/${id}/set-availability`, { disabled: !isDisabled });
    const { mutate: mutateDisable, isLoading } = useMutation(disableRecord);

    return { mutateDisable, isLoading };
};

export default useDisable;
