import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './LoginPage.styled';
import LoginForm from './LoginForm';

const LoginPage = (props: Props) => {
    return (
        <>
            <St.SectionWrapper component={'section'} data-testid="login-section">
                <St.ArticleWrapper component={'article'}>
                    <LoginForm />
                </St.ArticleWrapper>
            </St.SectionWrapper>
        </>
    );
};

const propTypes = {};

const defaultProps = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
LoginPage.propTypes = propTypes;
LoginPage.defaultProps = defaultProps;

export default LoginPage;
