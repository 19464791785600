import * as React from 'react';
// import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import SettingsNonWorkingDaysDetail from './SettingsNonWorkingDaysDetail';
import { SettingsNonWorkingDays } from 'types/settingsNonWorkingDays/settingsNonWorking.types';
import { formatDate } from 'utils/helpers/dateHelper';

const SettingsNonWorkingDaysDetailContainer = (props: Props) => {
    const { row } = props;

    const markets = row?.markets ?? [];

    const calendar = {
        dateFrom: formatDate(row?.dateFrom as string),
        dateTo: formatDate(row?.dateTo as string),
    };

    const childProps = {
        ...props,
        markets,
        calendar,
    };

    return <SettingsNonWorkingDaysDetail {...childProps} />;
};

const propTypes = {};

const defaultProps = {};

interface extraProps {
    row?: SettingsNonWorkingDays;
    close?: () => void;
    setSnackBarMessage?: (message: string) => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
SettingsNonWorkingDaysDetailContainer.propTypes = propTypes;
SettingsNonWorkingDaysDetailContainer.defaultProps = defaultProps;

export default SettingsNonWorkingDaysDetailContainer;
