import { BrokerUsersDefinition } from 'types/usersBroker/usersBroker.types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import UsersBrokerDetail from './UsersBrokerDetail';
import { useTranslation } from 'react-i18next';

export const UsersBrokerDetailContainer = (props: Props) => {
    const { row } = props;
    const { t } = useTranslation('usersBroker');

    const childProps = { ...props, t, detailData: row };

    return <UsersBrokerDetail {...childProps} />;
};

const propTypes = {};

const defaultProps = {};

interface extraProps {
    row: BrokerUsersDefinition;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
UsersBrokerDetailContainer.propTypes = propTypes;
UsersBrokerDetailContainer.defaultProps = defaultProps;

export default UsersBrokerDetailContainer;
