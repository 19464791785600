import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { Box } from '@mui/material';
import { ICON_CELL_SIZE } from 'constants/appConstants';
import CustomIconSelector from '../CustomIconSelector/CustomIconSelector';
import { AffidavitObject } from 'types/channelsAffidavit/channelsAffidavit.types';

const ChannelsDDJJCellInfo = ({ declaration, t }: Props) => (
    <Box component="span" display="flex" alignItems="center">
        {t(declaration?.filePath ? 'yes' : 'no')} &nbsp;
        <CustomIconSelector
            type={declaration?.filePath ? 'check' : 'close'}
            sx={{
                color: declaration?.filePath ? 'success.main' : 'secondary.main',
                ...ICON_CELL_SIZE,
            }}
        />
    </Box>
);

const propTypes = {
    t: PropTypes.func.isRequired,
};

const defaultProps = {};

interface extraProps {
    declaration?: AffidavitObject | null;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ChannelsDDJJCellInfo.propTypes = propTypes;
ChannelsDDJJCellInfo.defaultProps = defaultProps;

export default ChannelsDDJJCellInfo;
