import { useState } from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import UsersBusinessAssistantReferralList from './UsersBusinessAssistantReferralList';
import { useTranslation } from 'react-i18next';

const UsersBusinessAssistantReferralListContainer = (props: Props) => {
    const { value, setFieldValue } = props;
    const { t } = useTranslation('usersBusinessAssistant');
    const [referralCodes, setReferralCodes] = useState(value);
    const [referralCode, setReferralCode] = useState('');

    const handleAddReferral = () => {
        setReferralCodes(referralCodes.concat(referralCode));
        setReferralCode('');
        setFieldValue('referralCodes', referralCodes.concat(referralCode));
    };

    const deleteUser = (index: number) => {
        referralCodes.splice(index, 1);
        setReferralCodes(referralCodes);
        setFieldValue('referralCodes', referralCodes);
    };

    const childProps = {
        ...props,
        referralCode,
        referralCodes,
        handleAddReferral,
        setReferralCode,
        deleteUser,
        t,
    };

    return <UsersBusinessAssistantReferralList {...childProps} />;
};

const propTypes = {};

const defaultProps = {};

interface extraProps {
    value: string[];
    setFieldValue: any;
    error?: string;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
UsersBusinessAssistantReferralListContainer.propTypes = propTypes;
UsersBusinessAssistantReferralListContainer.defaultProps = defaultProps;

export default UsersBusinessAssistantReferralListContainer;
