import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './EditClientAddressForm.styled';
import { Form, FormikProvider } from 'formik';
import ActionButton from 'components/common/ActionButton';
import { ARGENTINA_STATES, tCommon } from 'constants/appConstants';
import { Alert, FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import CustomInput from 'components/common/CustomInput';

const EditClientAddressForm = ({ formik, isEdit, disabledForm, close, t }: Props) => (
    <FormikProvider value={formik} data-testid="EditClientAddressForm-form">
        <Form>
            <Grid container spacing={2} py={1.25}>
                <Grid item xs={6}>
                    <CustomInput
                        field="streetName"
                        label={t('clients_address_street')}
                        placeholder={t('clients_address_street_placeholder')}
                        values={formik.values}
                        errors={formik.errors}
                        setFieldValue={formik.setFieldValue}
                    />
                </Grid>
                <Grid item xs={6}>
                    <CustomInput
                        field="streetNum"
                        label={t('clients_address_number')}
                        placeholder={t('clients_address_number_placeholder')}
                        values={formik.values}
                        errors={formik.errors}
                        setFieldValue={formik.setFieldValue}
                    />
                </Grid>
                <Grid item xs={6}>
                    <CustomInput
                        field="addressExtra"
                        label={t('clients_address_addressExtra')}
                        placeholder={t('clients_address_addressExtra_placeholder')}
                        values={formik.values}
                        errors={formik.errors}
                        setFieldValue={formik.setFieldValue}
                    />
                </Grid>
                <Grid item xs={6}>
                    <CustomInput
                        field="floor"
                        label={t('clients_address_floor')}
                        placeholder={t('clients_address_floor_placeholder')}
                        values={formik.values}
                        errors={formik.errors}
                        setFieldValue={formik.setFieldValue}
                    />
                </Grid>
                <Grid item xs={6}>
                    <CustomInput
                        field="apartment"
                        label={t('clients_address_apartment')}
                        placeholder={t('clients_address_apartment_placeholder')}
                        values={formik.values}
                        errors={formik.errors}
                        setFieldValue={formik.setFieldValue}
                    />
                </Grid>
                <Grid item xs={6}>
                    <CustomInput
                        field="sector"
                        label={t('clients_address_sector')}
                        placeholder={t('clients_address_sector_placeholder')}
                        values={formik.values}
                        errors={formik.errors}
                        setFieldValue={formik.setFieldValue}
                    />
                </Grid>
                <Grid item xs={6}>
                    <CustomInput
                        field="tower"
                        label={t('clients_address_tower')}
                        placeholder={t('clients_address_tower_placeholder')}
                        values={formik.values}
                        errors={formik.errors}
                        setFieldValue={formik.setFieldValue}
                    />
                </Grid>
                <Grid item xs={6}>
                    <CustomInput
                        field="square"
                        label={t('clients_address_square')}
                        placeholder={t('clients_address_square_placeholder')}
                        values={formik.values}
                        errors={formik.errors}
                        setFieldValue={formik.setFieldValue}
                    />
                </Grid>
                <Grid item xs={6}>
                    <CustomInput
                        field="city"
                        label={t('clients_address_city')}
                        placeholder={t('clients_address_city_placeholder')}
                        values={formik.values}
                        errors={formik.errors}
                        setFieldValue={formik.setFieldValue}
                    />
                </Grid>
                <Grid item xs={6}>
                    <CustomInput
                        field="zipCode"
                        label={t('clients_address_zipCode')}
                        placeholder={t('clients_address_zipCode_placeholder')}
                        values={formik.values}
                        errors={formik.errors}
                        setFieldValue={formik.setFieldValue}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth size="small">
                        <InputLabel id="label-state">{t('clients_address_state')}</InputLabel>
                        <Select
                            labelId="label-state"
                            value={formik.values.state}
                            label={t('clients_address_state')}
                            onChange={({ target: { value } }) =>
                                formik.setFieldValue('state', value)
                            }>
                            {ARGENTINA_STATES.map(state => (
                                <MenuItem key={state} value={state}>
                                    {state}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>

            <Alert severity="warning" sx={{ my: 2 }}>
                {t('esco_warning')}
            </Alert>

            <St.ActionSectionForm direction="row" spacing={1}>
                <ActionButton onClick={close} variant="outlined">
                    {t('cancel', tCommon)}
                </ActionButton>
                <ActionButton
                    size="medium"
                    type="submit"
                    disabled={isEdit ? disabledForm : false}
                    color="primary"
                    variant="contained">
                    {isEdit ? t('edit', tCommon) : t('add', tCommon)}
                </ActionButton>
            </St.ActionSectionForm>
        </Form>
    </FormikProvider>
);

const propTypes = {
    isEdit: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    disabledForm: PropTypes.bool.isRequired,
};

const defaultProps = {};

interface extraProps {
    formik: any;
    close: () => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
EditClientAddressForm.propTypes = propTypes;
EditClientAddressForm.defaultProps = defaultProps;

export default EditClientAddressForm;
