import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import DisableFilter from './DisableFilter';
import { useTranslation } from 'react-i18next';
import useBreakpoints from 'hooks/common/breakpoints.hooks';

const DisableFilterContainer = (props: Props) => {
    const { disabled } = props;
    const { t } = useTranslation();
    const { matchesSm } = useBreakpoints();

    const childProps = {
        ...props,
        t,
        matchesSm,
        disabled: disabled ?? false,
    };

    return <DisableFilter {...childProps} />;
};

const propTypes = {
    disabled: PropTypes.bool,
    resource: PropTypes.string.isRequired,
};

const defaultProps = {};

interface extraProps {
    isLoading?: boolean;
    setShowDisabled: (value: boolean) => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
DisableFilterContainer.propTypes = propTypes;
DisableFilterContainer.defaultProps = defaultProps;

export default DisableFilterContainer;
