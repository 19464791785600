import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import DeleteDialog from './DeleteDialog';
import { useTranslation } from 'react-i18next';

const DeleteDialogContainer = (props: Props) => {
    const { setOpen, onDelete } = props;
    const { t } = useTranslation();

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = () => {
        onDelete();
        setOpen(false);
    };
    const childProps = {
        t,
        handleClose,
        handleDelete,
        ...props,
    };

    return <DeleteDialog {...childProps} />;
};

const propTypes = {
    title: PropTypes.string.isRequired,
    contentText: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    deleteStatus: PropTypes.bool.isRequired,
};

const defaultProps = {};

interface extraProps {
    onDelete: () => void;
    setOpen: (state: boolean) => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
DeleteDialogContainer.propTypes = propTypes;
DeleteDialogContainer.defaultProps = defaultProps;

export default DeleteDialogContainer;
