import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import TableHeaderActions from './TableHeaderActions';
import {
    ActionHeader,
    ExtraQueryFiltersProps,
} from 'types/common/CommonGrid/CommonGridFormModal.types';
import useBreakpoints from 'hooks/common/breakpoints.hooks';
import { CommonGridContext } from 'context/common/commonGrid.context';

const TableHeaderActionsContainer = (props: Props) => {
    const { setSearchText } = props;
    const { matchesSm } = useBreakpoints();
    const { showDisabled, setShowDisabled } = React.useContext(CommonGridContext);

    const handleSearchChange = async (e: any) => {
        setTimeout(() => {
            setSearchText(e.target.value);
        }, 1000);
    };

    const childProps = {
        ...props,
        matchesSm,
        showDisabled,
        setShowDisabled,
        handleSearchChange,
    };

    return <TableHeaderActions {...childProps} />;
};

const propTypes = {
    resource: PropTypes.string.isRequired,
    canDisable: PropTypes.bool,
    canExport: PropTypes.bool,
    canSearch: PropTypes.bool,
    loading: PropTypes.bool.isRequired,
    setSearchText: PropTypes.func.isRequired,
    columns: PropTypes.arrayOf(PropTypes.any).isRequired,
    exportName: PropTypes.string,
    totalRows: PropTypes.number.isRequired,
    clientSidePagination: PropTypes.bool,
};

const defaultProps = {};

interface extraProps {
    extraActionsInHeader?: ActionHeader[];
    useGetData: any;
    extraFilters?: ExtraQueryFiltersProps[];
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
TableHeaderActionsContainer.propTypes = propTypes;
TableHeaderActionsContainer.defaultProps = defaultProps;

export default TableHeaderActionsContainer;
