import { Box, styled } from '@mui/material';

export default {
    DetailTitle: styled(Box)(({ theme }) => ({
        width: '100%',
        fontSize: '18px',
        fontWeight: 400,
        marginBottom: '1em',
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        borderRadius: '0.5em',
        padding: '0.3em 1em',
        textAlign: 'center',
    })),
};
