import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { CircularProgress } from '@mui/material';
import St from './CanExport.styled';
import { ExtraQueryFiltersProps } from 'types/common/CommonGrid/CommonGridFormModal.types';
import ActionButton from 'components/common/ActionButton';

const CanExport = (props: Props) => {
    const { loading, handleExportData, pageLoading, t } = props;
    return (
        <>
            {loading ? (
                <St.LoaderContainer>
                    <CircularProgress color="primary" size="1rem" />
                </St.LoaderContainer>
            ) : (
                <ActionButton
                    variant="outlined"
                    disabled={pageLoading}
                    aria-label="export"
                    onClick={handleExportData}>
                    {t('export_text')}
                </ActionButton>
            )}
        </>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    resource: PropTypes.string.isRequired,
    columns: PropTypes.arrayOf(PropTypes.any).isRequired,
    pageLoading: PropTypes.bool.isRequired,
};

const defaultProps = {};

interface extraProps {
    loading: boolean;
    handleExportData: () => void;
    extraFilters?: Array<ExtraQueryFiltersProps>;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CanExport.propTypes = propTypes;
CanExport.defaultProps = defaultProps;

export default CanExport;
