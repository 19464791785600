import { withFakeData } from 'api/fakedata';
import { axiosDataInstance } from 'api/axiosInstances';
import {
    CreateDocumentRequest,
    CreateDocumentResponse,
    DownloadFileResponse,
} from 'types/utils/utils.api.types';

const apiName = 'utils';

export default {
    createDocument: (req: CreateDocumentRequest) =>
        withFakeData<CreateDocumentResponse>(
            () => axiosDataInstance.post('/files/v1_0/bo/', req),
            apiName,
            'createNewDocument',
            false,
            500,
        ),
    downloadFile: (downloadFileName: string) =>
        withFakeData<DownloadFileResponse>(
            () => axiosDataInstance.get(`/files/v1_0/bo/?downloadFileName=${downloadFileName}`),
            apiName,
            'download',
            false,
            500,
        ),
};
