import { useQuery } from '@tanstack/react-query';
import { depositsDefinitionsAdapter } from 'adapters/api/clientDeposits.adapter';
import { GridHookParams } from 'types/common/CommonGrid/CommonGridFormModal.types';
import endpoints from '../../api/clientDeposits/clientDeposits.api';

export const useDepositsDefinitions = ({ forceDisable, filterQueryString }: GridHookParams) => {
    const enabledCondition = !forceDisable;
    const { data, isLoading, isFetching, isRefetching, refetch } = useQuery(
        ['deposits-definitions', filterQueryString],
        () => endpoints.getDepositsDefinitions(filterQueryString),
        {
            enabled: enabledCondition,
            select: data => {
                return depositsDefinitionsAdapter(data?.data);
            },
        },
    );
    return {
        data,
        isLoading: (isLoading && isFetching) || isRefetching,
        refetch,
    };
};
