import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { DialogActions, DialogContent, DialogContentText } from '@mui/material';
import ActionButton from 'components/common/ActionButton';
import { tCommon } from 'constants/appConstants';

const ClientConfirmationAction = ({ isLoading, action, handleSubmit, close, t }: Props) => (
    <DialogContent>
        <DialogContentText>{t(`client_${action}_modal_question`)}</DialogContentText>
        <DialogActions>
            <ActionButton
                type="button"
                size="small"
                variant="outlined"
                onClick={close}
                color="primary">
                {t('cancel', tCommon)}
            </ActionButton>

            <ActionButton
                type="button"
                size="small"
                variant="contained"
                onClick={handleSubmit}
                loading={isLoading}
                color="primary">
                {t('confirm', tCommon)}
            </ActionButton>
        </DialogActions>
    </DialogContent>
);

const propTypes = {
    t: PropTypes.func.isRequired,
};

const defaultProps = {};

interface extraProps {
    isLoading: boolean;
    action: string;
    handleSubmit(): void;
    close(): void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ClientConfirmationAction.propTypes = propTypes;
ClientConfirmationAction.defaultProps = defaultProps;

export default ClientConfirmationAction;
