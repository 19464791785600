import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import HeaderFilterDate from './HeaderFilterDate';
import { formatDate } from 'utils/helpers/dateHelper';

const HeaderFilterDateContainer = (props: Props) => {
    const { filterstring: filterString, query, setfilterstring: setFilterString } = props;

    const value = React.useMemo(() => {
        const queries = filterString.split('&');

        for (const element of queries) {
            const [eQuery, value] = element.split('=');

            if (eQuery === query) {
                return value;
            }
        }
        return '';
    }, [filterString, query]);

    const onChange = (field: string, value: any) => {
        if (!value) {
            setFilterString((prev: string) =>
                prev
                    .split('&')
                    .filter((variable: string) => !variable.includes(query))
                    .join('&'),
            );
            return;
        }

        setFilterString((prev: string) => {
            const selectedValue = `&${field}=${formatDate(value, 'YYYY-MM-DD')}`;
            return prev.includes(field)
                ? prev
                      .split('&')
                      .map((variable: string) =>
                          variable.includes(query) ? selectedValue : `&${variable}`,
                      )
                      .join('')
                : prev.concat(selectedValue);
        });
    };

    const childProps = {
        ...props,
        onChange,
        value,
    };

    return <HeaderFilterDate {...childProps} />;
};

const propTypes = {
    query: PropTypes.string.isRequired,
    filterstring: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
};

const defaultProps = {};

interface extraProps {
    setfilterstring: React.Dispatch<React.SetStateAction<string>>;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
HeaderFilterDateContainer.propTypes = propTypes;
HeaderFilterDateContainer.defaultProps = defaultProps;

export default HeaderFilterDateContainer;
