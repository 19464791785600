import { useMutation, useQuery } from '@tanstack/react-query';
import endpoints from '../../api/usersBackoffice/usersBackoffice.api';
import { GridHookParams } from 'types/common/CommonGrid/CommonGridFormModal.types';
import {
    userBackofficeAdapter,
    userBackofficeProfileAdapter,
} from 'adapters/api/usersBackoffice.adapter';
import { useTranslation } from 'react-i18next';
import { tErrorsContext } from 'constants/appConstants';
import { errorResponseHelper } from 'utils/helpers/errorsHelper';

export const useBackoffice = ({ forceDisable, filterQueryString }: GridHookParams) => {
    const enabledCondition = !forceDisable;

    const { data, isLoading, isFetching, isRefetching, refetch } = useQuery(
        ['backoffice-users', filterQueryString],
        () => endpoints.getBackofficeUsers(filterQueryString),
        {
            enabled: enabledCondition,
            select: data => {
                return userBackofficeAdapter(data?.data);
            },
        },
    );

    return {
        data,
        isLoading: (isLoading && isFetching) || isRefetching,
        refetch,
    };
};

export const useGetPersonalProfileData = () => {
    const { data, isLoading, isFetching, isRefetching, refetch } = useQuery(
        ['backoffice-personal-profile'],
        () => endpoints.getBackofficeUserProfile(),
        {
            select: data => {
                return userBackofficeProfileAdapter(data?.data);
            },
        },
    );
    return {
        data,
        isLoading: (isLoading && isFetching) || isRefetching,
        refetch,
    };
};

export const useEnableUsersBackoffice = (setSnackBarMessage: Function) => {
    const { t } = useTranslation();
    const { mutate, isLoading } = useMutation(endpoints.enableBackoffice, {
        onError: error => {
            setSnackBarMessage(t(errorResponseHelper(error as any), tErrorsContext), 'error');
        },
    });

    return {
        mutate,
        isLoading,
    };
};
export const useDisableUsersBackoffice = (setSnackBarMessage: Function) => {
    const { t } = useTranslation();
    const { mutate, isLoading } = useMutation(endpoints.deleteBackoffice, {
        onError: error => {
            setSnackBarMessage(t(errorResponseHelper(error as any), tErrorsContext), 'error');
        },
    });

    return {
        mutate,
        isLoading,
    };
};

export const useChangePassword = (handleClose: Function, setSnackBarMessage: Function) => {
    const { t } = useTranslation('usersBackoffice');
    const { mutate, isLoading } = useMutation(endpoints.changePassword, {
        onSuccess: () => {
            setSnackBarMessage(t('backoffice_users_change_password_success'));
            handleClose();
        },
        onError: error => {
            setSnackBarMessage(t(errorResponseHelper(error as any), tErrorsContext), 'error');
        },
    });

    return {
        mutate,
        isLoading,
    };
};

export const useCreateUsersBackoffice = (close: Function, setSnackBarMessage: Function) => {
    const { t } = useTranslation('usersBackoffice');
    const { mutate, isLoading } = useMutation(endpoints.createUsersBackoffice, {
        onSuccess: () => {
            setSnackBarMessage(t('backoffice_users_form_modal_create_success'));
            close();
        },
        onError: error => {
            setSnackBarMessage(errorResponseHelper(error as any), 'error');
        },
    });
    return {
        mutate,
        isLoading,
    };
};
export const useEditUsersBackoffice = (close: Function, setSnackBarMessage: Function) => {
    const { t } = useTranslation('usersBackoffice');
    const { mutate, isLoading, error } = useMutation(endpoints.editUsersBackoffice, {
        onSuccess: () => {
            setSnackBarMessage(t('backoffice_users_form_modal_edit_success'));
            close();
        },
    });
    return {
        mutate,
        isLoading,
        errorMessage: t((error as any)?.response?.data?.detail ?? '', tErrorsContext),
    };
};
