import { Box, styled } from '@mui/material';
import loginBackground from 'assets/img/general/loginBackground.jpg';

export default {
    ForgotPasswordContainerBox: styled(Box)(() => ({
        width: '100%',
        height: '100%',
        display: 'flex',
        backgroundImage: `url(${loginBackground})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
    })),
};
