import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './RejectForm.styled';
import { Typography } from '@mui/material';
import CustomInput from '../CustomInput';

const RejectForm = (props: Props) => {
    const { t, setReason, reason, errorMessage, disabled } = props;

    return (
        <St.Container>
            <Typography fontSize={16} fontWeight={600} mb="10px">
                {t('reason')}
            </Typography>
            <CustomInput
                multiline
                rows={5}
                disabled={disabled}
                field="reason"
                values={{ reason }}
                label={t('reason')}
                errors={{ reason: errorMessage ?? undefined }}
                handleChange={value => setReason(value)}
            />
        </St.Container>
    );
};

const propTypes = {
    reason: PropTypes.string,
    setReason: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
};

const defaultProps = {};

interface extraProps {
    errorMessage?: string | null;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
RejectForm.propTypes = propTypes;
RejectForm.defaultProps = defaultProps;

export default RejectForm;
