import { useQuery, useMutation } from '@tanstack/react-query';
import endpoints from '../../api/clients/clients.api';
import { GridHookParams } from 'types/common/CommonGrid/CommonGridFormModal.types';
import {
    clientsDefinitionsAdapter,
    prospectsDefinitionsAdapter,
} from 'adapters/api/clients.adapter';
import { errorResponseHelper } from 'utils/helpers/errorsHelper';
import { tErrorsContext } from 'constants/appConstants';
import { AlertColor } from '@mui/material';
import { t } from 'i18next';

export const useProspectsDefinitions = ({ forceDisable, filterQueryString }: GridHookParams) => {
    const enabledCondition = !forceDisable;
    const { data, isLoading, isFetching, isRefetching, refetch } = useQuery(
        ['clients-definitions', filterQueryString],
        () => endpoints.getProspectsDefinitions(filterQueryString),
        {
            enabled: enabledCondition,
            select: data => {
                return prospectsDefinitionsAdapter(data?.data);
            },
        },
    );
    return {
        data,
        isLoading: (isLoading && isFetching) || isRefetching,
        refetch,
    };
};
export const useClientsDefinitions = ({ forceDisable, filterQueryString }: GridHookParams) => {
    const enabledCondition = !forceDisable;
    const { data, isLoading, isFetching, isRefetching, refetch } = useQuery(
        ['clients-definitions', filterQueryString],
        () => endpoints.getClientsDefinitions(filterQueryString),
        {
            enabled: enabledCondition,
            select: data => {
                return clientsDefinitionsAdapter(data?.data);
            },
        },
    );
    return {
        data,
        isLoading: (isLoading && isFetching) || isRefetching,
        refetch,
    };
};

export const useGetClientDetail = () => {
    const { data, mutate, isLoading } = useMutation(endpoints.getClientDetail);

    return {
        getClientDetail: mutate,
        isLoading,
        clientDetail: data?.data,
    };
};

export const useGetProspectDetail = () => {
    const { data, mutate, isLoading } = useMutation(endpoints.getProspectsDetail);
    return {
        isLoading,
        getProspectDetail: mutate,
        prospectDetail: data?.data,
    };
};

export const useEnableOrDisableClient = (setSnackBarMessage: Function) => {
    const { mutate, isLoading } = useMutation(endpoints.enableOrDisableClient, {
        onError: error => {
            setSnackBarMessage(t(errorResponseHelper(error as any), tErrorsContext), 'error');
        },
    });

    return {
        mutate,
        isLoading,
    };
};

export const useGetClientPDF = (setSnackBarMessage: (msj: string, sever?: AlertColor) => void) => {
    const { mutate, isLoading } = useMutation(endpoints.getClientPdf, {
        onError: error => {
            setSnackBarMessage(t(errorResponseHelper(error as any), tErrorsContext), 'error');
        },
    });

    return {
        mutate,
        isLoading,
    };
};

export const useApproveProspect = (
    setSnackBarMessage: (msj: string, sever?: AlertColor) => void,
    close: () => void,
    navigate: any,
) => {
    const { mutate, isLoading } = useMutation(endpoints.approveProspect, {
        onSuccess: () => {
            setSnackBarMessage(t('prospect_approved_succesfully', { ns: 'clients' }));
            close();
            navigate('/client/clients');
        },
        onError: error => {
            setSnackBarMessage(errorResponseHelper(error as any), 'error');
        },
    });
    return {
        mutate,
        isLoading,
    };
};
export const useRejectProspect = (
    setSnackBarMessage: (msj: string, sever?: AlertColor) => void,
    close: () => void,
    refetch: () => void,
) => {
    const { mutate, isLoading } = useMutation(endpoints.rejectProspect, {
        onSuccess: () => {
            setSnackBarMessage(t('prospect_rejected_succesfully', { ns: 'clients' }));
            refetch();
            close();
        },
        onError: error => {
            setSnackBarMessage(t(errorResponseHelper(error as any), tErrorsContext), 'error');
        },
    });
    return {
        mutate,
        isLoading,
    };
};

export const useUpdateProspectInfo = (setSnackBarMessage: Function) => {
    const { mutate, isLoading } = useMutation(endpoints.updateProspectInformation, {
        onError: error => {
            setSnackBarMessage(t(errorResponseHelper(error as any), tErrorsContext), 'error');
        },
    });

    return {
        mutate,
        isLoading,
    };
};

export const useUpdateClientInfo = (setSnackBarMessage: Function) => {
    const { mutate, isLoading } = useMutation(endpoints.updateClientInformation, {
        onError: error => {
            setSnackBarMessage(t(errorResponseHelper(error as any), tErrorsContext), 'error');
        },
    });

    return {
        mutate,
        isLoading,
    };
};

export const useGetProspectMarketData = (id: string) => {
    const { data, isLoading, isFetching, isRefetching, refetch } = useQuery(
        ['market-data', id],
        () => endpoints.getProspectMarketData(id),
        {
            enabled: !!id,
            select: data => data?.data,
        },
    );
    return {
        data,
        isLoading: (isLoading && isFetching) || isRefetching,
        refetch,
    };
};
export const useRelateAccount = (
    setSnackBarMessage: (msj: string, sever?: AlertColor) => void,
    close: () => void,
    navigate: any,
) => {
    const { mutate, isLoading } = useMutation(endpoints.relateAcount, {
        onSuccess: () => {
            setSnackBarMessage(t('account_related_succesfully', { ns: 'clients' }));
            close();
            navigate('/client/clients');
        },
        onError: error => {
            setSnackBarMessage(t(errorResponseHelper(error as any), tErrorsContext), 'error');
        },
    });

    return {
        mutate,
        isLoading,
    };
};

export const useGetStatusHistory = () => {
    const { data, mutateAsync, isLoading } = useMutation(endpoints.getStatusHistory);

    return {
        data,
        mutateAsync,
        isLoading,
    };
};
