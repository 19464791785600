import { useQuery, useMutation } from '@tanstack/react-query';
import endpoints from '../../api/channelsAffidavit/channelsAffidavit.api';
import channelsOrdersEndpoints from '../../api/channelsOrders/channelsOrders.api';
import { GridHookParams } from 'types/common/CommonGrid/CommonGridFormModal.types';
import { channelAffidavitsAdapter } from 'adapters/api/channelsAffidavit.adapter';
import { AlertColor } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { errorResponseHelper } from 'utils/helpers/errorsHelper';
import { tErrorsContext } from 'constants/appConstants';
import { GetAffidavitCountersResponse } from 'types/channelsAffidavit/channelsAffidavit.api.types';

export const useChannnelsAffidavit = ({ forceDisable, filterQueryString }: GridHookParams) => {
    const enabledCondition = !forceDisable;
    const { data, isLoading, isFetching, isRefetching, refetch } = useQuery(
        ['sworn-declaration-definitions', filterQueryString],
        () => channelsOrdersEndpoints.getOrders(filterQueryString),
        {
            enabled: enabledCondition,
            select: data => {
                return channelAffidavitsAdapter(data?.data);
            },
        },
    );

    return {
        data,
        isLoading: (isLoading && isFetching) || isRefetching,
        refetch,
    };
};
export const useGetChannnelsAffidavitCounters = () => {
    const { data, isLoading, isFetching, isRefetching, refetch } = useQuery(
        ['get-affidavit-counters'],
        () => endpoints.getAffidavitCounters(),
        {
            select: data => data.data as GetAffidavitCountersResponse,
        },
    );

    return {
        countPending: data?.countPending,
        countRejected: data?.countRejected,
        isLoading: (isLoading && isFetching) || isRefetching,
        refetch,
    };
};

export const useApproveAffidavit = (
    setSnackBarMessage: (msj: string, sever?: AlertColor) => void,
    close: () => void,
) => {
    const { t } = useTranslation('channelsAffidavit');
    const { mutate, isLoading } = useMutation(endpoints.approveDeclaration, {
        onSuccess: () => {
            setSnackBarMessage(t(`affidavit_approved_successfully`) as string);
            close();
        },
        onError: error => {
            setSnackBarMessage(t(errorResponseHelper(error as any), tErrorsContext), 'error');
        },
    });

    return {
        approveDeclaration: mutate,
        isLoading,
    };
};
export const useRejectAffidavit = (
    setSnackBarMessage: (msj: string, sever?: AlertColor) => void,
    close: () => void,
) => {
    const { t } = useTranslation('channelsAffidavit');
    const { mutate, isLoading } = useMutation(endpoints.rejectDeclaration, {
        onSuccess: () => {
            setSnackBarMessage(t(`affidavit_rejected_successfully`) as string);
            close();
        },
        onError: error => {
            setSnackBarMessage(t(errorResponseHelper(error as any), tErrorsContext), 'error');
        },
    });

    return {
        rejectDeclaration: mutate,
        isLoading,
    };
};
