import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import PasswordField from 'components/common/PasswordField';
import { FormikContextType, Form, FormikProvider } from 'formik';
import St from './ConfirmationForm.styled';
import CustomInput from 'components/common/CustomInput';
import FadeIn from 'components/common/FadeIn';
import ErrorText from 'components/common/ErrorText';
import { NUMBER_REGEX } from 'utils/helpers/constants';

const ConfirmationForm = (props: Props) => {
    const { t, isLoading, formik, errorMessage } = props;

    const { errors, values, setFieldValue } = formik;
    return (
        <FormikProvider value={formik}>
            <St.Form>
                <FadeIn>
                    <Stack spacing={1} rowGap={'1rem'}>
                        <St.Title variant="h5">{t('enter_code')}</St.Title>
                        <Stack gap={'0.5rem'}>
                            <CustomInput
                                field="confirmationCode"
                                errors={errors}
                                label={t('code')}
                                placeholder={t('code')}
                                setFieldValue={setFieldValue}
                                values={values}
                                autoFocus
                                maxLength={6}
                                regex={NUMBER_REGEX}
                            />
                            <PasswordField
                                name="newPassword"
                                error={errors.newPassword !== undefined}
                                helperText={errors.newPassword?.toString()}
                                label={t('new_password')}
                                placeholder={t('enter_new_password')}
                                onChange={({ target }: any) => {
                                    setFieldValue('newPassword', target.value);
                                }}
                                value={values.newPassword}
                            />
                            <PasswordField
                                name="newPasswordConfirmation"
                                error={errors.newPasswordConfirmation !== undefined}
                                helperText={errors.newPasswordConfirmation?.toString()}
                                label={t('change_password_repeat')}
                                placeholder={t('enter_new_password')}
                                onChange={({ target }: any) => {
                                    setFieldValue('newPasswordConfirmation', target.value);
                                }}
                                value={values.newPasswordConfirmation}
                            />
                        </Stack>
                        {errorMessage && <ErrorText text={errorMessage} />}
                        <Stack gap={'0.5rem'}>
                            <LoadingButton
                                type="submit"
                                variant="contained"
                                fullWidth
                                loading={isLoading}>
                                {t('change_password')}
                            </LoadingButton>
                            <Button
                                variant="outlined"
                                color="primary"
                                fullWidth
                                disabled={isLoading}
                                component={RouterLink}
                                to="/auth/login">
                                {t('back')}
                            </Button>
                        </Stack>
                    </Stack>
                </FadeIn>
            </St.Form>
        </FormikProvider>
    );
};

const propTypes = {
    isLoading: PropTypes.bool.isRequired,
    t: PropTypes.any.isRequired,
};

const defaultProps = {};

interface extraProps {
    formik: FormikContextType<any>;
    errorMessage?: string;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ConfirmationForm.propTypes = propTypes;
ConfirmationForm.defaultProps = defaultProps;

export default ConfirmationForm;
