import { paginationAdapter } from 'adapters/common/pagination.adapter';
import { UsersBrokerApi, getBrokerUsersResponse } from 'types/usersBroker/usersBroker.api.types';

import { BrokerUsersDefinition } from 'types/usersBroker/usersBroker.types';

export const userBrokerAdapter = (data: getBrokerUsersResponse) => {
    return {
        ...paginationAdapter(data),
        data: data.items?.map((userBroker: UsersBrokerApi) => {
            return {
                id: userBroker.id,
                username: userBroker.username,
                createdAt: userBroker.createdAt,
                updatedAt: userBroker.updatedAt,
                lastName: userBroker.lastName,
                name: userBroker.name,
                enabled: userBroker.enabled,
                email: userBroker.email,
                channelInfo: userBroker.channelInfo,
            } as BrokerUsersDefinition;
        }),
    };
};
