import { withFakeData } from 'api/fakedata';
import { axiosDataInstance } from 'api/axiosInstances';
import { ORDERS_BASE_URL } from 'constants/appConstants';
import {
    ApproveExchangeRequest,
    RejectExchangeRequest,
    EditExchangeRequest,
} from 'types/channelsExchange/channelsExchange.api.types';

const apiName = 'channelsExchange';

export default {
    getExchangeDefinitions: (filterQueryString: string) =>
        withFakeData<any>(
            () => axiosDataInstance.get(`${ORDERS_BASE_URL}/exchange?${filterQueryString}`),
            apiName,
            'exchange',
            false,
            2000,
        ),
    lockOrUnlockExchange: ({ id, action }: { id: string; action: string }) =>
        withFakeData(
            () => axiosDataInstance.patch(`${ORDERS_BASE_URL}/exchange/${id}/${action}`),
            apiName,
            'exchange',
            false,
            500,
        ),
    approveExchange: ({ id, body }: ApproveExchangeRequest) =>
        withFakeData(
            () => axiosDataInstance.patch(`${ORDERS_BASE_URL}/exchange/${id}/accept`, body),
            apiName,
            'orders',
            false,
            500,
        ),
    rejectExchange: ({ id, body }: RejectExchangeRequest) =>
        withFakeData(
            () => axiosDataInstance.patch(`${ORDERS_BASE_URL}/exchange/${id}/reject`, body),
            apiName,
            'orders',
            false,
            500,
        ),
    editExchange: ({ id, body }: EditExchangeRequest) =>
        withFakeData(
            () => axiosDataInstance.patch(`${ORDERS_BASE_URL}/exchange/${id}`, body),
            apiName,
            'orders',
            false,
            500,
        ),
};
