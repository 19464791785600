import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { DialogActions, DialogContent } from '@mui/material';
import ActionButton from 'components/common/ActionButton';
import { tCommon } from 'constants/appConstants';
import { DialogContentText } from './UsersBrokerConfirmationAction.styled';

const UsersBrokerConfirmationAction = ({
    isLoadingEnable,
    isLoadingDisable,
    handleSubmit,
    t,
    action,
    close,
}: Props) => (
    <DialogContent>
        <DialogContentText>
            {t(`broker_users_confirmation_${action}_modal_question`)}
        </DialogContentText>
        <DialogActions>
            <ActionButton
                type="button"
                size="small"
                variant="outlined"
                onClick={close}
                color="primary">
                {t('cancel', tCommon)}
            </ActionButton>

            <ActionButton
                type="button"
                size="small"
                variant="contained"
                onClick={handleSubmit}
                loading={isLoadingDisable || isLoadingEnable}
                color="primary">
                {t('confirm', tCommon)}
            </ActionButton>
        </DialogActions>
    </DialogContent>
);

const propTypes = {
    t: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
};

const defaultProps = {};

interface extraProps {
    isLoadingEnable: boolean;
    isLoadingDisable: boolean;
    handleSubmit(): void;
    action: string;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
UsersBrokerConfirmationAction.propTypes = propTypes;
UsersBrokerConfirmationAction.defaultProps = defaultProps;

export default UsersBrokerConfirmationAction;
