import { useMutation, useQuery } from '@tanstack/react-query';
import endpoints from '../../api/settingsBlocks/settingsBlocks.api';
import { GridHookParams } from 'types/common/CommonGrid/CommonGridFormModal.types';
import { blocksAdapter } from 'adapters/api/settingsBlocks.adapter';
import { useTranslation } from 'react-i18next';
import { errorResponseHelper } from 'utils/helpers/errorsHelper';
import { tErrorsContext } from 'constants/appConstants';

export const useGetBlocks = ({ forceDisable, filterQueryString }: GridHookParams) => {
    const enabledCondition = !forceDisable;

    const { data, isLoading, isFetching, isRefetching, refetch } = useQuery(
        ['blocks', filterQueryString],
        () => endpoints.getBlocks(filterQueryString),
        {
            enabled: enabledCondition,
            select: data => {
                return blocksAdapter(data?.data);
            },
        },
    );

    return {
        data,
        isLoading: (isLoading && isFetching) || isRefetching,
        refetch,
    };
};

export const useUpdateBlocksFunctionality = (setSnackBarMessage: Function, close: Function) => {
    const { t } = useTranslation('settingsBlocks');
    const { mutate, isLoading } = useMutation(endpoints.updateBlocks, {
        onSuccess: () => {
            setSnackBarMessage(t('settings_blocks_updated_successfully'));
            close();
        },
        onError: error => {
            setSnackBarMessage(t(errorResponseHelper(error as any), tErrorsContext), 'error');
        },
    });

    return {
        mutate,
        isLoading,
    };
};
