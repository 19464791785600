import { withFakeData } from 'api/fakedata';
import { axiosDataInstance } from 'api/axiosInstances';
import {
    GetClientVBByIdResponse,
    RegisterBody,
    SendCredentialsBody,
} from 'types/clientImportClientVB/clientImportClientVB.api.types';
import { AUTH_BASE, MS_MARKET_ACCOUNT } from 'constants/appConstants';

const apiName = 'clientImportClientVB';

export default {
    clientById: (id: number) =>
        withFakeData<GetClientVBByIdResponse>(
            () => axiosDataInstance.get(`${MS_MARKET_ACCOUNT}/core-account/${id}`),
            apiName,
            'clientById',
            false,
            500,
        ),
    sendCredentials: (req: SendCredentialsBody) =>
        withFakeData<{}>(
            () => axiosDataInstance.post(`${AUTH_BASE}password-recovery`, req),
            apiName,
            'sendCredentials',
            false,
            500,
        ),
    register: (req: RegisterBody) =>
        withFakeData<{}>(
            () => axiosDataInstance.post(`${MS_MARKET_ACCOUNT}/core-account`, req),
            apiName,
            'register',
            false,
            500,
        ),
};
