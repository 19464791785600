import { paginationAdapter } from 'adapters/common/pagination.adapter';
import {
    ChannnelsAffidavitApi,
    ChannnelsAffidavitResponse,
} from 'types/channelsAffidavit/channelsAffidavit.api.types';
import { ChannnelsAffidavit } from 'types/channelsAffidavit/channelsAffidavit.types';

export const channelAffidavitsAdapter = (data: ChannnelsAffidavitResponse) => {
    return {
        ...paginationAdapter(data),
        data: data.items?.map((channelAffidavit: ChannnelsAffidavitApi) => {
            return {
                id: channelAffidavit.id,
                channel: channelAffidavit.channel,
                customerCode: channelAffidavit.customerCode,
                instrument: channelAffidavit.instrument,
                term: channelAffidavit.term,
                amount: channelAffidavit.amount,
                price: channelAffidavit.price,
                status: channelAffidavit.status,
                operator: channelAffidavit.operator,
                createdAt: channelAffidavit.createdAt,
                updatedAt: channelAffidavit.updatedAt,
                buySell: channelAffidavit.buySell,
                expenses: channelAffidavit.expenses,
                isManual: channelAffidavit.isManual,
                isMarketPrice: channelAffidavit.isMarketPrice,
                quantity: channelAffidavit.quantity,
                declaration: channelAffidavit.declaration,
                lastModified: channelAffidavit.lastModified,
                ticker: channelAffidavit.ticker,
                user: channelAffidavit.user,
                reason: channelAffidavit.reason,
                decisionAt: channelAffidavit.decisionAt,
            } as ChannnelsAffidavit;
        }),
    };
};
