import * as React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import ChannelsModalItemGrid from 'components/common/ChannelsModalItemGrid';
import { tCommon } from 'constants/appConstants';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { ClientExtraInfo } from 'types/clients/clients.types';
import { formatDate } from 'utils/helpers/dateHelper';

const ClientSectionExtraInfo = ({
    PEP,
    isUSA,
    OCDE,
    isUIF,
    investorProfileExpirationDate,
    economicData,
    t,
    investmentProfile,
    isProspect,
}: Props) => (
    <Grid container>
        <ChannelsModalItemGrid
            label={t('clients_extra_info_PEP')}
            text={t(PEP ? 'yes' : 'no', tCommon)}
            column={6}
        />
        <ChannelsModalItemGrid
            label={t('clients_extra_info_isUSA')}
            text={t(isUSA ? 'yes' : 'no', tCommon)}
            column={6}
        />
        <ChannelsModalItemGrid
            label={t('clients_extra_info_OCDE')}
            text={t(OCDE ? 'yes' : 'no', tCommon)}
            column={6}
        />
        <ChannelsModalItemGrid
            label={t('clients_extra_info_isUIF')}
            text={t(isUIF ? 'yes' : 'no', tCommon)}
            column={6}
        />
        <ChannelsModalItemGrid
            label={t('investmentProfile', tCommon)}
            text={investmentProfile ? t(`investment_profile_${investmentProfile}`, tCommon) : '-'}
            column={6}
        />
        {isProspect ? null : (
            <ChannelsModalItemGrid
                label={t('clients_extra_info_investorProfileExpirationDate')}
                text={formatDate(investorProfileExpirationDate)}
                column={6}
            />
        )}
        <ChannelsModalItemGrid
            label={t('clients_extra_info_nse')}
            text={economicData?.nse}
            column={6}
        />
        <ChannelsModalItemGrid
            label={t('clients_extra_info_transactionalProfile')}
            text={economicData?.transactionalProfile}
            column={6}
        />
        <ChannelsModalItemGrid
            label={t('clients_extra_info_riskProfile')}
            text={
                economicData?.riskProfile
                    ? t(`clients_extra_info_riskProfile_${economicData?.riskProfile}`)
                    : '-'
            }
            column={6}
        />
        <ChannelsModalItemGrid
            label={t('clients_extra_info_workActivity')}
            text={economicData?.workActivity}
            column={6}
        />
    </Grid>
);

const propTypes = {
    t: PropTypes.func.isRequired,
};

const defaultProps = {};

interface extraProps extends ClientExtraInfo {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ClientSectionExtraInfo.propTypes = propTypes;
ClientSectionExtraInfo.defaultProps = defaultProps;

export default ClientSectionExtraInfo;
