import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './NoPermissionsPage.styled';
import consultatioLogo from 'assets/img/general/consultatioInvestmentsLogo.jpg';

const NoPermissionsPage = (props: Props) => {
    const { t, backToHome } = props;

    return (
        <St.ContainerPage>
            <St.LogoContainer>
                <St.Logo src={consultatioLogo} alt="Consultatio Plus" />
            </St.LogoContainer>
            <St.Title variant="h1">{t('no_permission_title')}</St.Title>
            <St.Subtitle variant="h2">{t('no_permission_description')}</St.Subtitle>
            <St.Message variant="body1">{t('no_permission_help')}</St.Message>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <St.HomeLink onClick={backToHome}>{t('no_permission_button_text')}</St.HomeLink>
            </div>
        </St.ContainerPage>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    backToHome: () => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
NoPermissionsPage.propTypes = propTypes;

export default NoPermissionsPage;
