import { Box, DialogActions, styled } from '@mui/material';

export default {
    DialogContent: styled(Box)(({ theme }) => ({
        padding: '3px 5px',
    })),
    DialogActions: styled(DialogActions)(({ theme }) => ({
        display: 'flex',
        justifyContent: 'flex-end',
    })),
};
