import { withFakeData } from 'api/fakedata';
import { axiosDataInstance } from 'api/axiosInstances';
import { getAlertsResponse, AlertBody } from 'types/alerts/alerts.api.types';
import { ALERTS_BASE_URL } from 'constants/appConstants';

const apiName = 'alerts';

export default {
    getAlerts: (filterQueryString: string) =>
        withFakeData<getAlertsResponse>(
            () => axiosDataInstance.get(`${ALERTS_BASE_URL}?${filterQueryString}`),
            apiName,
            'alerts',
            false,
            500,
        ),
    createAlert: (notification: AlertBody) =>
        withFakeData<{}>(
            () => axiosDataInstance.post(ALERTS_BASE_URL, notification),
            apiName,
            'alerts',
            false,
            500,
        ),
    editAlert: ({ id, body }: { id: number; body: AlertBody }) =>
        withFakeData<{}>(
            () => axiosDataInstance.put(`${ALERTS_BASE_URL}${id}`, body),
            apiName,
            'alerts',
            false,
            500,
        ),
    deleteAlert: (id: number) =>
        withFakeData<{}>(
            () => axiosDataInstance.delete(`${ALERTS_BASE_URL}${id}`),
            apiName,
            'alerts',
            false,
            500,
        ),
};
