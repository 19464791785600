import { paginationAdapter } from 'adapters/common/pagination.adapter';
import {
    ClientBankAccountApi,
    GetClientBankAccountsDefinitionsResponse,
} from 'types/clientBankAccounts/clientBankAccounts.api.types';
export const ClientBankAccountsDefinitionsAdapter = (
    data: GetClientBankAccountsDefinitionsResponse,
) => {
    return {
        ...paginationAdapter(data),
        data: data.items?.map((clientBankAccountDefinition: ClientBankAccountApi) => {
            return {
                id: clientBankAccountDefinition.id,
                bankName: clientBankAccountDefinition.bankName,
                accountNumber: clientBankAccountDefinition.accountNumber,
                currency: clientBankAccountDefinition.currency,
                description: clientBankAccountDefinition.description,
                status: clientBankAccountDefinition.status,
                type: clientBankAccountDefinition.type,
                reason: clientBankAccountDefinition.reason,
                decisionAt: clientBankAccountDefinition.decisionAt,
                deletedAt: clientBankAccountDefinition.deletedAt,
                createdAt: clientBankAccountDefinition.createdAt,
                user: clientBankAccountDefinition.user,
                operator: clientBankAccountDefinition.operator,
                bankAccountExtraData: clientBankAccountDefinition.bankAccountExtraData,
            };
        }),
    };
};
