import { Alert, AlertColor, Snackbar } from '@mui/material';
import { useCommonGrid } from 'context/common/commonGrid.context';
import React, { useCallback } from 'react';

const useSnackBar = () => {
    const {
        setNeedRefresh,
        openSnackBar,
        setOpenSnackBar,
        message,
        setMessage,
        severity,
        setSeverity,
    } = useCommonGrid();

    const setSnackBarMessage = useCallback((msj: string, sever: AlertColor = 'success') => {
        setOpenSnackBar(true);
        setSeverity(sever);
        setMessage(msj);
        if (sever === 'success') setNeedRefresh(true);
    }, []);

    const closeModal = () => {
        setOpenSnackBar(false);
    };

    const SnackBar = React.useMemo(() => {
        const SnackBarComponent = () => (
            <Snackbar
                open={openSnackBar}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                autoHideDuration={6000}
                onClose={closeModal}
                message={message}>
                <Alert onClose={closeModal} severity={severity} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        );
        return SnackBarComponent;
    }, [openSnackBar, severity, message]);

    return {
        SnackBar,
        setSnackBarMessage,
    };
};

export default useSnackBar;
