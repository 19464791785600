import { LoadingButton } from '@mui/lab';
import { Box, Button, Grid, Typography, styled } from '@mui/material';
import BackToBaseButton from 'components/common/CommonGrid/Extra/BackToBaseButton';
import { transientOptions } from 'utils/helpers/stylesHelper';

export default {
    GridWrapper: styled(
        Box,
        transientOptions,
    )<{ $drawerWidth: number }>(({ theme, $drawerWidth }) =>
        theme.unstable_sx({
            width: {
                xs: `calc(${window.innerWidth}px - 2rem)`,
                md: `calc(${window.innerWidth}px - ${$drawerWidth === 260 ? '20rem' : '7rem'})`,
            },
        }),
    ),
    TitleWrapper: styled(Box)(() => ({
        display: 'flex',
        button: { width: 'fit-content' },
    })),
    BackToBaseButton: styled(BackToBaseButton)(() => ({})),
    Grid: styled(Grid)(({ theme }) => ({})) as typeof Grid,
    Title: styled(Typography)(({ theme }) =>
        theme.unstable_sx({
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '1.4rem',
            color: theme.palette.primary.main,
            letterSpacing: '0.05rem',
            alignSelf: 'center',
        }),
    ),
    SearchWrapper: styled(Grid)(() => ({})),
    InfoWrapper: styled(Grid)(({ theme }) =>
        theme.unstable_sx({
            display: 'grid',
            gridTemplateColumns: { xs: 'auto', sm: 'max-content auto' },
            gap: '1rem',
        }),
    ),
    Button: styled(Button)(({ theme }) =>
        theme.unstable_sx({ width: { xs: '50%', sm: 'auto' }, maxHeight: '40px' }),
    ),
    SubmitButton: styled(LoadingButton)(({ theme }) =>
        theme.unstable_sx({ width: { xs: '50%', sm: 'auto' }, maxHeight: '40px' }),
    ),
};
