import { styled as styledMui } from '@mui/material/styles';
import { Box, Stack } from '@mui/material';
export default {
    UsersBackofficeForm: styledMui(Box)(({ theme }) => ({
        padding: '1em',
        borderRadius: '0.5em',
        gap: '8px',
        height: 'auto',
        maxWidth: '100%',
        marginTop: '1em',
    })) as typeof Box,
    ActionSectionForm: styledMui(Stack)(({ theme }) => ({
        display: 'flex',
        justifyContent: 'end',
        marginTop: '1.3em',
    })) as typeof Stack,
};
