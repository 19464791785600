import { AlertColor } from '@mui/material';
import { useApproveOrder, useEditOrder, useRejectOrder } from 'hooks/api/channelsOrders.hooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Order } from 'types/channelsOrders/channelsOrders.types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ChannelsOrdersActions from './ChannelsOrdersActions';
import { tRequiredFieldError } from 'constants/appConstants';

const ChannelsOrdersActionsContainer = (props: Props) => {
    const { setSnackBarMessage, row, close } = props;
    const { t } = useTranslation('channelsOrders');
    const [action, setAction] = useState('');
    const [price, setPrice] = useState(row?.price ?? 0);
    const [rejectReason, setRejectReason] = useState('');
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const { isLoading: isLoadingApprove, approveOrder } = useApproveOrder(
        setSnackBarMessage,
        close,
    );
    const { isLoading: isLoadingReject, rejectOrder } = useRejectOrder(setSnackBarMessage, close);
    const { mutate: mutateEdit, isLoading: isLoadingEdit } = useEditOrder(
        setSnackBarMessage,
        close,
    );

    const handleAction = (type: string) => {
        setAction(type);
        setErrorMessage(null);
    };

    const handleSubmit = () => {
        if (price === 0 && !rejectReason) {
            setErrorMessage(tRequiredFieldError);
            return;
        }
        setErrorMessage(null);
        switch (action) {
            case 'approve':
                return approveOrder({ id: row.id, body: { price } });
            case 'edit':
                return mutateEdit({ id: row.id, body: { price } });
            case 'reject':
                return rejectOrder({ id: row.id, body: { reason: rejectReason } });

            default:
                return close();
        }
    };

    const childProps = {
        ...props,
        rejectReason,
        action,
        isLoading: isLoadingApprove || isLoadingEdit || isLoadingReject,
        handleAction,
        setRejectReason,
        handleSubmit,
        t,
        price,
        setPrice,
        id: row.id,
        status: row.status,
        declaration: row.declaration,
        errorMessage,
    };

    return <ChannelsOrdersActions {...childProps} />;
};

const propTypes = {};

const defaultProps = {};

interface extraProps {
    row: Order;
    close(): void;
    setSnackBarMessage: (msj: string, sever?: AlertColor) => void;
    handleAcquireOrReleaseOrder({ id, action }: { id: string; action: string }): void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ChannelsOrdersActionsContainer.propTypes = propTypes;
ChannelsOrdersActionsContainer.defaultProps = defaultProps;

export default ChannelsOrdersActionsContainer;
