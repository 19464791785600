import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ClientConfirmationAction from './ClientConfirmationAction';
import { useTranslation } from 'react-i18next';
import { useEnableOrDisableClient } from 'hooks/api/clients.hooks';
import { AlertColor } from '@mui/material';

const ClientConfirmationActionContainer = (props: Props) => {
    const {
        row: { id },
        action,
        setSnackBarMessage,
    } = props;
    const { t } = useTranslation('clients');
    const { mutate, isLoading } = useEnableOrDisableClient(setSnackBarMessage);

    const handleSubmit = () =>
        mutate(
            { id, action },
            {
                onSuccess: () => setSnackBarMessage(t(`clients_${action}_successfully`)),
            },
        );

    const childProps = {
        ...props,
        isLoading,
        handleSubmit,
        t,
    };

    return <ClientConfirmationAction {...childProps} />;
};

const propTypes = {};

const defaultProps = {};

interface extraProps {
    row: any;
    action: string;
    close(): void;
    setSnackBarMessage: (msj: string, sever?: AlertColor) => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ClientConfirmationActionContainer.propTypes = propTypes;
ClientConfirmationActionContainer.defaultProps = defaultProps;

export default ClientConfirmationActionContainer;
