import { Box, styled } from '@mui/material';

export default {
    MarketDollarQuotesDetail: styled(Box)(({ theme }) => ({
        borderRadius: '0.5rem',
        boxShadow: '4px 5px 36px -9px rgba(0, 0, 0, 0.6)',
        marginTop: '1rem',
        padding: '1rem',
    })),

    DetailTitle: styled(Box)(({ theme }) => ({
        fontSize: '1.2rem',
        fontWeight: 'bold',
        marginBottom: '1em',
        display: 'flex',
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        borderRadius: '0.5rem',
        padding: '0.3em 1em',
        justifyContent: 'center',
    })),

    QuoteTitle: styled(Box)(({ theme }) => ({
        display: 'flex',
        fontSize: '1.2rem',
        marginBottom: '1.5rem',
    })),

    Label: styled(Box)(({ theme }) => ({
        marginRight: '0.5em',
        fontWeight: 'bold',
    })),

    QuotesTypeBox: styled(Box)(({ theme }) => ({
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        gap: '1rem',
        padding: '0.5rem',
        textAlign: 'center',
    })),

    QuoteTypeName: styled(Box)(({ theme }) => ({
        width: '8rem',
        backgroundColor: theme.palette.primary.main,
        borderRadius: '0.5rem',
        color: 'white',
        fontSize: '0.9rem',
        fontWeight: 'bold',
        margin: '0rem 0.3rem',
        padding: '0.5rem 1.2rem',
    })),

    QuoteTypeValue: styled(Box)(({ theme }) => ({
        marginTop: '0.5em',
        backgroundColor: '#f5f5f5',
        color: 'black',
        border: '1px solid #e0e0e0',
        borderRadius: '0.5em',
        fontSize: '1em',
        fontWeight: 'bold',
        padding: '0.4em 2em',
    })),

    QuoteUpdateContainer: styled(Box)(({ theme }) => ({
        borderTop: '2px solid #e0e0e0',
        display: 'flex',
        justifyContent: 'center',
        marginTop: '2em',
        padding: '0.5rem',
    })),

    QuoteUpdateBox: styled(Box)(({ theme }) => ({
        display: 'flex',
        gap: '1rem',
        // backgroundColor: theme.palette.primary.main,
        // color: theme.palette.primary.contrastText,
        borderRadius: '0.5em',
        marginTop: '1em',
        padding: '0.5em 1.3em',
        fontSize: '1em',
    })),

    QuoteUpdateTitle: styled(Box)(({ theme }) => ({
        color: 'black',
        fontWeight: 'bold',
    })),
};
