import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ClientSectionDocuments from './ClientSectionDocuments';
import { useTranslation } from 'react-i18next';
import { UserDocuments } from 'types/users/users.api.types';
import { useDownloadFile } from 'hooks/api/utils.hooks';

const ClientSectionDocumentsContainer = (props: Props) => {
    const { t } = useTranslation();
    const { downloadFile, isLoading, SnackBar } = useDownloadFile();

    const childProps = {
        ...props,
        downloadFile,
        t,
        isLoading,
        SnackBar,
    };

    return <ClientSectionDocuments {...childProps} />;
};

const propTypes = {};

const defaultProps = {};

interface extraProps {
    documents: UserDocuments[];
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ClientSectionDocumentsContainer.propTypes = propTypes;
ClientSectionDocumentsContainer.defaultProps = defaultProps;

export default ClientSectionDocumentsContainer;
