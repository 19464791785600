import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ClientVBDetail from './ClientVBDetail';
import { useTranslation } from 'react-i18next';
import { ClientVB } from 'types/clientImportClientVB/clientImportClientVB.types';

const ClientVBDetailContainer = (props: Props) => {
    // const {} = props;
    const { t } = useTranslation('clientImportClientVB');

    const childProps = {
        ...props,
        t,
    };

    return <ClientVBDetail {...childProps} />;
};

const propTypes = {};

const defaultProps = {};

interface extraProps extends ClientVB {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ClientVBDetailContainer.propTypes = propTypes;
ClientVBDetailContainer.defaultProps = defaultProps;

export default ClientVBDetailContainer;
