import { withFakeData } from 'api/fakedata';
import { axiosDataInstance } from 'api/axiosInstances';
import {
    GetDollarQuotesResponse,
    CreateDollarQuotesRequest,
    EditDollarQuotesRequest,
    DeleteQuoteRequest,
} from 'types/marketDollarQuotes/marketDollarQuotes.api.types';
import { INSTRUMENTS_BASE_URL } from 'constants/appConstants';

const apiName = 'dollarQuotes';
const baseApiUrl = `${INSTRUMENTS_BASE_URL}/bo/quotations`;

export default {
    getDollarQuotes: (filterQueryString: string) =>
        withFakeData<GetDollarQuotesResponse>(
            () => axiosDataInstance.get(`${baseApiUrl}?${filterQueryString}`),
            apiName,
            'dollarQuotes',
            false,
            500,
        ),
    deleteQuote: (req: DeleteQuoteRequest) =>
        withFakeData<{}>(
            () => axiosDataInstance.delete(`${baseApiUrl}/${req}`),
            apiName,
            'delete-dollarQuotes',
            false,
            500,
        ),
    createDollarQuotes: (req: CreateDollarQuotesRequest) =>
        withFakeData<CreateDollarQuotesRequest>(
            () => axiosDataInstance.post(baseApiUrl, req),
            apiName,
            'create-dollarQuotes',
            false,
            500,
        ),
    editDollarQuotes: (req: EditDollarQuotesRequest) => {
        const { id, ...requestData } = req;

        return withFakeData<{}>(
            () => axiosDataInstance.put(`${baseApiUrl}/${id}`, requestData),
            apiName,
            'edit-dollarQuotes',
            false,
            500,
        );
    },
};
