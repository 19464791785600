import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { Alert, Box, Button, Stack, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { FormikContextType, FormikProvider } from 'formik';
import St from './EmailVerificationForm.styled';
import FadeIn from 'components/common/FadeIn';
import ErrorText from 'components/common/ErrorText';
import CustomInput from 'components/common/CustomInput';
import { NUMBER_REGEX } from 'utils/helpers/constants';

const EmailVerificationForm = (props: Props) => {
    const {
        t,
        isLoading,
        formik,
        email,
        resentVerificationCode,
        resendVerificationCodeSuccessMessage,
        emailVerificationError,
    } = props;

    const { errors, values, setFieldValue } = formik;
    return (
        <FormikProvider value={formik}>
            <St.Form data-testid="EmailVerification-form">
                <FadeIn>
                    <Stack spacing={1} gap="1rem">
                        <St.Title variant="h5">{t('email_verification_title')}</St.Title>
                        <Typography>{t('email_verification_code_sent', { email })}</Typography>
                        <CustomInput
                            field="code"
                            errors={errors}
                            label={t('code')}
                            placeholder={t('email_verification_code_placeholder')}
                            setFieldValue={setFieldValue}
                            values={values}
                            autoFocus
                            maxLength={6}
                            regex={NUMBER_REGEX}
                        />
                        <Box alignSelf="flex-end">
                            <St.ResendCode onClick={() => resentVerificationCode()}>
                                {t('resend_code')}
                            </St.ResendCode>
                        </Box>
                        {resendVerificationCodeSuccessMessage && (
                            <Alert>{resendVerificationCodeSuccessMessage}</Alert>
                        )}
                        {emailVerificationError && <ErrorText text={emailVerificationError} />}
                        <Stack width={'100%'} rowGap={'0.5rem'}>
                            <LoadingButton
                                fullWidth
                                type="submit"
                                variant="contained"
                                loading={isLoading}>
                                {t('verify_email')}
                            </LoadingButton>
                            <Button
                                variant="outlined"
                                color="primary"
                                fullWidth
                                disabled={isLoading}
                                component={RouterLink}
                                to="/login">
                                {t('back')}
                            </Button>
                        </Stack>
                    </Stack>
                </FadeIn>
            </St.Form>
        </FormikProvider>
    );
};

const propTypes = {
    mainLogo: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    t: PropTypes.any.isRequired,
    email: PropTypes.string,
    resentVerificationCode: PropTypes.func.isRequired,
    resendVerificationCodeSuccessMessage: PropTypes.string.isRequired,
    emailVerificationError: PropTypes.string.isRequired,
};

const defaultProps = {};

interface extraProps {
    formik: FormikContextType<any>;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
EmailVerificationForm.propTypes = propTypes;
EmailVerificationForm.defaultProps = defaultProps;

export default EmailVerificationForm;
