import { paginationAdapter } from 'adapters/common/pagination.adapter';
import { GetOrderResponse, OrderApi } from 'types/channelsOrders/channelsOrders.api.types';
import { Order } from 'types/channelsOrders/channelsOrders.types';

export const ordersAdapter = (data: GetOrderResponse) => {
    return {
        ...paginationAdapter(data),
        data: data.items?.map((order: OrderApi) => {
            return {
                id: order.id,
                channel: order.channel,
                customerCode: order.customerCode,
                instrument: order.instrument,
                term: order.term,
                amount: order.amount,
                price: order.price,
                status: order.status,
                operator: order.operator,
                updatedAt: order.updatedAt,
                createdAt: order.createdAt,
                buySell: order.buySell,
                expenses: order.expenses,
                isManual: order.isManual,
                isMarketPrice: order.isMarketPrice,
                quantity: order.quantity,
                declaration: order.declaration,
                lastModified: order.lastModified,
                ticker: order.ticker,
                user: order.user,
                reason: order.reason,
                decisionAt: order.decisionAt,
                operatedPrice: order.operatedPrice,
                operatedAmount: order.operatedAmount,
            } as Order;
        }),
    };
};
