import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CustomDatePicker from 'components/common/CustomDatePicker';

const HeaderFilterDate = (props: Props) => {
    const { onChange, query, value, label } = props;

    return (
        <CustomDatePicker
            placeholder={label}
            fieldValue={query}
            setFieldValue={(field, value) => onChange(field, value)}
            value={value as number | string}
        />
    );
};

const propTypes = {
    onChange: PropTypes.func.isRequired,
    query: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
};

const defaultProps = {};

interface extraProps {
    value: number | string | null;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
HeaderFilterDate.propTypes = propTypes;
HeaderFilterDate.defaultProps = defaultProps;

export default HeaderFilterDate;
