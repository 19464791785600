import { paginationAdapter } from 'adapters/common/pagination.adapter';
import {
    GetDollarQuotesResponse,
    DollarQuotesApi,
} from 'types/marketDollarQuotes/marketDollarQuotes.api.types';
import { DollarQuotes } from 'types/marketDollarQuotes/marketDollarQuotes.types';

export const dollarQuotesAdapter = (data: GetDollarQuotesResponse) => {
    return {
        ...paginationAdapter(data),
        data: data.items?.map((dollarQuote: DollarQuotesApi) => {
            return {
                id: dollarQuote.id,
                instrument: dollarQuote.instrument,
                title: dollarQuote.title,
                updatedAt: dollarQuote.updatedAt,
            } as DollarQuotes;
        }),
    };
};
