import { useQuery, useMutation } from '@tanstack/react-query';
import endpoints from '../../api/clientBankAccounts/clientBankAccounts.api';
import { GridHookParams } from 'types/common/CommonGrid/CommonGridFormModal.types';
import { ClientBankAccountsDefinitionsAdapter } from 'adapters/api/ClientBankAccounts.adapter';
import { tErrorsContext } from 'constants/appConstants';
import { useTranslation } from 'react-i18next';
import { AlertColor } from '@mui/material';
import { errorResponseHelper } from 'utils/helpers/errorsHelper';

export const useClientBankAccountsDefinitions = ({
    forceDisable,
    filterQueryString,
}: GridHookParams) => {
    const enabledCondition = !forceDisable;
    const { data, isLoading, isFetching, isRefetching, refetch } = useQuery(
        ['clientBankAccounts-definitions', filterQueryString],
        () => endpoints.getClientBankAccountsDefinition(filterQueryString),
        {
            enabled: enabledCondition,
            select: data => {
                return ClientBankAccountsDefinitionsAdapter(data?.data);
            },
        },
    );

    return {
        data,
        isLoading: (isLoading && isFetching) || isRefetching,
        refetch,
    };
};

export const useApproveBankAccount = (setSnackBarMessage: Function, close: Function) => {
    const { t } = useTranslation('clientBankAccounts');
    const { mutate, isLoading } = useMutation(endpoints.approveAccount, {
        onSuccess: () => {
            setSnackBarMessage(t('client_bank_accounts_approved_success'));
            close();
        },
        onError: error => {
            setSnackBarMessage(t(errorResponseHelper(error as any), tErrorsContext), 'error');
            close();
        },
    });
    return {
        isLoading,
        mutate,
    };
};

export const useEditBankAccount = (
    setSnackBarMessage: (msj: string, sever: AlertColor) => void,
    close: () => void,
) => {
    const { t } = useTranslation('clientBankAccounts');
    const { mutate, isLoading } = useMutation(endpoints.editAccount, {
        onSuccess: () => {
            setSnackBarMessage(t('client_bank_accounts_edit_success'), 'success');
            close();
        },
        onError: error => {
            setSnackBarMessage(t(errorResponseHelper(error as any), tErrorsContext), 'error');
        },
    });
    return {
        mutate,
        isLoading,
    };
};

export const useRejectBankAccount = (setSnackBarMessage: Function, close: Function) => {
    const { t } = useTranslation('clientBankAccounts');
    const { mutate, isLoading } = useMutation(endpoints.rejectAccount, {
        onSuccess: () => {
            setSnackBarMessage(t('client_bank_accounts_reject_success'));
            close();
        },
        onError: error => {
            setSnackBarMessage(t(errorResponseHelper(error as any), tErrorsContext), 'error');
            close();
        },
    });
    return {
        mutate,
        isLoading,
    };
};
