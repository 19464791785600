import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import DrawerMenuList from './DrawerMenuList';
import { useTheme } from '@emotion/react';
import { useLogout } from 'hooks/api/auth.hooks';
import { logoutItemData } from 'router/routeList';
import { useTranslation } from 'react-i18next';
import { useGetFilteredRoutes } from 'hooks/common/roles.hooks';

const DrawerMenuListContainer = (props: Props) => {
    const { open } = props;
    const theme = useTheme();
    const { mutate: onLogout } = useLogout();
    const { t } = useTranslation();
    const { filterMenuRoutes } = useGetFilteredRoutes();

    const [currentRoutes, logoutItemDataResolved] = React.useMemo(
        () => [filterMenuRoutes() ?? [], logoutItemData()],
        [t, filterMenuRoutes],
    );
    const childProps = {
        ...props,
        open,
        theme,
        menusList: currentRoutes,
        onLogout,
        t,
        logoutItemData: logoutItemDataResolved,
    };

    return <DrawerMenuList {...childProps} />;
};

const propTypes = {
    open: PropTypes.bool.isRequired,
    handleNavigate: PropTypes.func.isRequired,
    selectedMenu: PropTypes.string.isRequired,
};

const defaultProps = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
DrawerMenuListContainer.propTypes = propTypes;
DrawerMenuListContainer.defaultProps = defaultProps;

export default DrawerMenuListContainer;
