import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { MutableRefObject } from 'react';
import JoditEditor from 'jodit-react';

const CustomTextEditor = ({ editorRef, config, value, onBlur }: Props) => (
    <JoditEditor ref={editorRef} config={config} value={value} onBlur={onBlur} />
);

const propTypes = {};

const defaultProps = {};

interface extraProps {
    editorRef: MutableRefObject<any>;
    config: Object;
    value: string;
    onBlur(val: string): void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CustomTextEditor.propTypes = propTypes;
CustomTextEditor.defaultProps = defaultProps;

export default CustomTextEditor;
