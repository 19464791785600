import { paginationAdapter } from 'adapters/common/pagination.adapter';
import {
    GetRulesResponse,
    InstrumentRuleConditionsApi,
    RulesApi,
} from 'types/settingsInstrumentRules/settingsInstrumentRules.api.types';
import {
    InstrumentRule,
    InstrumentRuleConditions,
} from 'types/settingsInstrumentRules/settingsInstrumentRules.types';

export const rulesAdapter = (data: GetRulesResponse) => {
    return {
        ...paginationAdapter(data),
        data: data.items?.map((rule: RulesApi) => {
            return {
                id: rule.id,
                createdAt: rule.createdAt,
                updatedAt: rule.updatedAt,
                reason: rule.reason,
                description: rule.description,
                action: rule.action,
                conditions: rule.conditions.map(
                    (c: InstrumentRuleConditionsApi) =>
                        ({
                            currencies: c.currencies,
                            instrumentType: c.instrumentType,
                            terms: c.terms,
                            ticker: c.ticker,
                        }) as InstrumentRuleConditions,
                ),
                exceptions: rule.exceptions,
                hasExceptions: rule.hasExceptions,
            } as InstrumentRule;
        }),
    };
};
