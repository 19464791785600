import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import RefreshButton from './RefreshButton';

const RefreshButtonContainer = (props: Props) => {
    // const {} = props;

    const childProps = {
        ...props,
    };

    return <RefreshButton {...childProps} />;
};

const propTypes = {};

const defaultProps = {};

interface extraProps {
    refresh: (value: boolean) => void;
    disabled?: boolean;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
RefreshButtonContainer.propTypes = propTypes;
RefreshButtonContainer.defaultProps = defaultProps;

export default RefreshButtonContainer;
