import { useQuery, useMutation } from '@tanstack/react-query';
import endpoints from '../../api/settingsNonWorkingDays/settingsNonWorkingDays.api';
import { GridHookParams } from 'types/common/CommonGrid/CommonGridFormModal.types';
import {
    SettingsNonWorkingDaysAdapter,
    marketsAdapter,
} from 'adapters/api/settingsNonWorkingDays.adapter';
import { useTranslation } from 'react-i18next';
import { tErrorsContext } from 'constants/appConstants';

export const useSettingsNonWorkingDays = ({ forceDisable, filterQueryString }: GridHookParams) => {
    const enabledCondition = !forceDisable;

    const { data, isLoading, isFetching, isRefetching, refetch } = useQuery(
        ['market-non-working-days', filterQueryString],
        () => endpoints.getSettingsNonWorkingDays(filterQueryString),
        {
            enabled: enabledCondition,
            select: data => {
                return SettingsNonWorkingDaysAdapter(data?.data);
            },
        },
    );

    return {
        data,
        isLoading: (isLoading && isFetching) || isRefetching,
        refetch,
    };
};

export const useGetMarkets = () => {
    const { data, isLoading, isFetching, isRefetching, refetch } = useQuery(
        ['markets'],
        () => endpoints.getMarkets(),
        {
            select: data => {
                return marketsAdapter(data?.data);
            },
        },
    );

    return {
        data,
        isLoading: (isLoading && isFetching) || isRefetching,
        refetch,
    };
};

export const useCreateMarketnonWorkingDay = (close: Function, setSnackBarMessage: Function) => {
    const { t } = useTranslation('SettingsNonWorkingDays');
    const { mutate, isLoading, error } = useMutation(endpoints.createMarketnonWorkingDay, {
        onSuccess: () => {
            setSnackBarMessage(t('market_non_working_days_modal_create_success'));
            close();
        },
    });

    return {
        mutate,
        isLoading,
        errorMessage: t(
            (error as any)?.response?.data?.error_messages[0].code ?? '',
            tErrorsContext,
        ),
    };
};

export const useEditMarketnonWorkingDay = (close: Function, setSnackBarMessage: Function) => {
    const { t } = useTranslation('SettingsNonWorkingDays');
    const { mutate, isLoading, error } = useMutation(endpoints.editMarketnonWorkingDay, {
        onSuccess: () => {
            setSnackBarMessage(t('market_non_working_days_modal_edit_success'));
            close();
        },
    });
    return {
        mutate,
        isLoading,
        errorMessage: t(
            (error as any)?.response?.data?.error_messages[0].code ?? '',
            tErrorsContext,
        ),
    };
};

export const useDeleteMarketnonWorkingDay = () => {
    const { t } = useTranslation('SettingsNonWorkingDays');
    const { mutate, isLoading, error, isError } = useMutation(endpoints.deleteMarketnonWorkingDay, {
        onSuccess: () => {
            // setSnackBarMessage(t('non_working_days_modal_edit_success'));
            // close();
        },
    });
    return {
        mutate,
        isLoading,
        isError,
        errorMessage: t(
            (error as any)?.response?.data?.error_messages[0].code ?? '',
            tErrorsContext,
        ),
        successfulMessage: t('market_non_working_days_modal_delete_success'),
    };
};
