import { withFakeData } from 'api/fakedata';
import { axiosDataInstance } from 'api/axiosInstances';
import {
    ApproveOrderRequest,
    EditOrderRequest,
    GetOrderResponse,
    RejectOrderRequest,
} from 'types/channelsOrders/channelsOrders.api.types';

const apiName = 'orders';

export default {
    getOrders: (filterQueryString: string) =>
        withFakeData<GetOrderResponse>(
            () => axiosDataInstance.get(`/orders/v1_0/bo/market/?${filterQueryString}`),
            apiName,
            'orders',
            false,
            500,
        ),
    lockOrUnlockOrder: ({ id, action }: { id: string; action: string }) =>
        withFakeData(
            () => axiosDataInstance.patch(`/orders/v1_0/bo/market/${id}/${action}`),
            apiName,
            'orders',
            false,
            500,
        ),
    approveOrder: (req: ApproveOrderRequest) =>
        withFakeData(
            () => axiosDataInstance.patch(`/orders/v1_0/bo/market/${req.id}/accept`, req.body),
            apiName,
            'orders',
            false,
            500,
        ),
    rejectOrder: (req: RejectOrderRequest) =>
        withFakeData(
            () => axiosDataInstance.patch(`/orders/v1_0/bo/market/${req.id}/reject`, req.body),
            apiName,
            'orders',
            false,
            500,
        ),
    editOrder: (req: EditOrderRequest) =>
        withFakeData(
            () => axiosDataInstance.patch(`/orders/v1_0/bo/market/${req.id}`, req.body),
            apiName,
            'orders',
            false,
            500,
        ),
};
