import { createTheme, responsiveFontSizes } from '@mui/material';
import { green, red } from '@mui/material/colors';
import { CustomThemeOptions } from './types/theme.types';
declare module '@mui/material/styles/createPalette' {
    interface Palette {
        custom: {
            [key: string]: any;
        };
    }
}

export const defaultTheme = createTheme({
    palette: {
        background: {
            default: '#FDFDFD',
        },
        error: {
            main: red.A400,
        },
        // mode: 'dark',
        primary: {
            light: '#484848',
            main: '#307FE2',
            // main: '#2a86e4',
            contrastText: '#fff',
        },
        secondary: {
            light: '#e5ceff',
            main: '#173254',
            dark: '#476293',
            contrastText: '#fff',
        },
        custom: {
            sidebar: '#F2F2F2',
            whiteSmoke: '#f0f0f0',
        },
    },
    typography: {
        fontFamily: [
            'Roboto',
            '"Montserrat"',
            '-apple-system, system-ui',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
        ].join(','),
    },
} as CustomThemeOptions);

export default responsiveFontSizes(defaultTheme);
