import { ADMIN } from 'constants/abilities.constants';
import { AuthContext } from 'context/auth.context';
import React from 'react';
import { menuMainRoutes } from 'router/routeList';

export const useGetActionsReestrictions = () => {
    const { userAbilities } = React.useContext(AuthContext);

    const isHiddenByAbility = React.useCallback(
        (ability: string) => {
            if (!userAbilities || userAbilities.includes(ADMIN)) return false;
            return !userAbilities.includes(ability);
        },
        [userAbilities],
    );

    return { isHiddenByAbility, userAbilities };
};

export const useGetFilteredRoutes = () => {
    const { userAbilities } = React.useContext(AuthContext);

    const filterMenuRoutes = React.useCallback(() => {
        const initialRoutes = menuMainRoutes();

        if (!userAbilities) return [];

        return initialRoutes
            .filter(r => {
                const isUserAbilityAvailable =
                    r.abilities &&
                    !!r?.abilities.filter(element => userAbilities?.includes(element)).length;
                return isUserAbilityAvailable;
            })
            .map(r => {
                const validSubroutes = r.subRoutes?.filter(sr => {
                    const isUserAbilityAvailable =
                        sr.abilities &&
                        !!sr?.abilities.filter(element => userAbilities?.includes(element)).length;
                    return isUserAbilityAvailable;
                });
                return {
                    ...r,
                    subRoutes: validSubroutes,
                };
            });
    }, [userAbilities]);

    return { filterMenuRoutes };
};
