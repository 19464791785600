import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './DeleteDialog.styled';
import { Dialog, DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material';
import { Tprop } from 'types/common/general.types';
import ActionButton from 'components/common/ActionButton/ActionButton';
import CustomIconSelector from 'components/common/CustomIconSelector';
import defaultTheme from 'utils/theme/defaultTheme';

const DeleteDialog = (props: Props) => {
    const { title, contentText, open, setOpen, handleClose, deleteStatus, handleDelete, t } = props;
    return (
        <Dialog
            open={open || deleteStatus}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <St.DialogContent>
                <DialogTitle id="alert-dialog-title">
                    {title}
                    <IconButton
                        aria-label="close"
                        onClick={() => setOpen(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                        }}>
                        <CustomIconSelector
                            type="close"
                            sx={{ color: defaultTheme.palette.grey[500] }}
                        />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ py: 1, px: 3 }}>
                    <DialogContentText id="alert-dialog-description">
                        {contentText}
                    </DialogContentText>
                </DialogContent>
                <St.DialogActions sx={{ px: 2, pb: 2 }}>
                    <ActionButton
                        type="button"
                        size="small"
                        variant="outlined"
                        onClick={handleClose}
                        color="primary">
                        {t('cancel')}
                    </ActionButton>

                    <ActionButton
                        type="button"
                        size="small"
                        onClick={handleDelete}
                        loading={deleteStatus}
                        color="primary">
                        {t('confirm')}
                    </ActionButton>
                </St.DialogActions>
            </St.DialogContent>
        </Dialog>
    );
};

const propTypes = {
    title: PropTypes.string.isRequired,
    contentText: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    deleteStatus: PropTypes.bool.isRequired,
};

const defaultProps = {};

type extraProps = InferPropsExtended<
    {
        handleClose: () => void;
        handleDelete: () => void;
        setOpen: (state: boolean) => void;
    },
    Tprop
>;

type Props = InferPropsExtended<typeof propTypes, extraProps>;
DeleteDialog.propTypes = propTypes;
DeleteDialog.defaultProps = defaultProps;

export default DeleteDialog;
