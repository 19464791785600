import * as React from 'react';
// import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import MainLayout from './MainLayout';
import { AuthContext } from 'context/auth.context';

const MainLayoutContainer = (props: Props) => {
    // const {} = props;
    const { isAuthenticated } = React.useContext(AuthContext);

    const childProps = {
        ...props,
        isAuthenticated,
    };

    return <MainLayout {...childProps} />;
};

const propTypes = {};

const defaultProps = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
MainLayoutContainer.propTypes = propTypes;
MainLayoutContainer.defaultProps = defaultProps;

export default MainLayoutContainer;
