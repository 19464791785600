import { useMutation, useQuery } from '@tanstack/react-query';
import endpoints from '../../api/channelsFundings/channelsFundings.api';
import { GridHookParams } from 'types/common/CommonGrid/CommonGridFormModal.types';
import { channelsFundingsAdapter } from 'adapters/api/channelsFundings.adapter';
import { useTranslation } from 'react-i18next';
import { errorResponseHelper } from 'utils/helpers/errorsHelper';

export const useFundings = ({ forceDisable, filterQueryString }: GridHookParams) => {
    const enabledCondition = !forceDisable;

    const { data, isLoading, isFetching, isRefetching, refetch } = useQuery(
        ['fundings', filterQueryString],
        () => endpoints.getFundings(filterQueryString),
        {
            enabled: enabledCondition,
            select: data => {
                return channelsFundingsAdapter(data?.data);
            },
        },
    );

    return {
        data,
        isLoading: (isLoading && isFetching) || isRefetching,
        refetch,
    };
};

export const useApproveFunds = (close: Function, setSnackBarMessage: Function) => {
    const { t } = useTranslation('channelsFundings');
    const { mutate, isLoading, error, isError } = useMutation(endpoints.approveFunds, {
        onSuccess: () => {
            setSnackBarMessage(t('funding_approved_successfully'));
            close();
        },
    });

    return {
        mutate,
        isLoading,
        isError,
        errorMessage: errorResponseHelper(error),
    };
};

export const useRejectFunds = (close: Function, setSnackBarMessage: Function) => {
    const { t } = useTranslation('channelsFundings');
    const { mutate, isLoading, error, isError } = useMutation(endpoints.rejectFunds, {
        onSuccess: () => {
            setSnackBarMessage(t('funding_rejected_successfully'));
            close();
        },
    });

    return {
        mutate,
        isLoading,
        isError,
        errorMessage: errorResponseHelper(error),
    };
};

export const useLockOrUnlockFunds = () => {
    const { mutate, isLoading, error, isError } = useMutation(endpoints.lockOrUnlockFunds);
    return {
        mutate,
        isLoading,
        isError,
        errorMessage: errorResponseHelper(error),
    };
};
