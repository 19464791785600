import { withFakeData } from 'api/fakedata';
import { axiosDataInstance } from 'api/axiosInstances';
import { GetExtractionsResponse } from 'types/clientExtractions/clientExtractions.api.types';
import { CASHFLOW_BASE_URL } from 'constants/appConstants';

const apiName = 'extractions';

export default {
    getExtractions: (filterQueryString: string) => {
        const API_URL = `${CASHFLOW_BASE_URL}/withdrawal?${filterQueryString}`;

        return withFakeData<GetExtractionsResponse>(
            () => axiosDataInstance.get(API_URL),
            apiName,
            'extractions',
            false,
            500,
        );
    },
    changeExtractionStatus: ({
        id,
        action,
        body,
    }: {
        id: number;
        action: string;
        body: {
            operatorUuid?: string;
            reason?: string;
        };
    }) =>
        withFakeData<{}>(
            () => axiosDataInstance.patch(`${CASHFLOW_BASE_URL}/withdrawal/${action}/${id}`, body),
            apiName,
            'status-extractions',
            false,
            500,
        ),
};
