import { useMutation, useQuery } from '@tanstack/react-query';
import endpoints from '../../api/settingsInstrumentRules/settingsInstrumentRules.api';
import { GridHookParams } from 'types/common/CommonGrid/CommonGridFormModal.types';
import { rulesAdapter } from 'adapters/api/settingsInstrumentRules.adapter';
import { AlertColor } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { errorResponseHelper } from 'utils/helpers/errorsHelper';
import { tErrorsContext } from 'constants/appConstants';

export const useRules = ({ forceDisable, filterQueryString }: GridHookParams) => {
    const enabledCondition = !forceDisable;

    const { data, isLoading, isFetching, isRefetching, refetch } = useQuery(
        ['rules', filterQueryString],
        () => endpoints.getRules(filterQueryString),
        {
            enabled: enabledCondition,
            select: data => {
                return rulesAdapter(data?.data);
            },
        },
    );

    return {
        data,
        isLoading: (isLoading && isFetching) || isRefetching,
        refetch,
    };
};
export const useCreateInstrumentRule = (
    setSnackBarMessage: (msj: string, sever: AlertColor) => void,
    close: () => void,
) => {
    const { t } = useTranslation('settingsInstrumentRules');
    const { mutate, isLoading } = useMutation(endpoints.createInstrumentRule, {
        onSuccess() {
            setSnackBarMessage(t('create_instrument_rule_success_message'), 'success');
            close();
        },
        onError(err: any) {
            setSnackBarMessage(errorResponseHelper(err), 'error');
        },
    });

    return {
        isLoading,
        mutate,
    };
};

export const useEditInstrumentRule = (
    setSnackBarMessage: (msj: string, sever: AlertColor) => void,
    close: () => void,
) => {
    const { t } = useTranslation('settingsInstrumentRules');
    const { mutate, isLoading } = useMutation(endpoints.editInstrumentRule, {
        onSuccess() {
            setSnackBarMessage(t('edit_instrument_rule_success_message'), 'success');
            close();
        },
        onError(err) {
            setSnackBarMessage(errorResponseHelper(err), 'error');
        },
    });

    return {
        isLoading,
        mutate,
    };
};

export const useDeleteInstrumentRule = () => {
    const { t } = useTranslation('settingsInstrumentRules');
    const { mutate, isLoading, isError, error } = useMutation(endpoints.deleteInstrumentRule);

    return {
        mutate,
        isLoading,
        isError,
        errorMessage: t(errorResponseHelper(error as any), tErrorsContext),
        successfulMessage: t('market_instrument_rule_modal_delete_success'),
    };
};
