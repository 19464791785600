import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './ChannelsAffidavitCounters.styled';
import CircularSpinner from 'components/common/CircularSpinner';

const ChannelsAffidavitCounters = (props: Props) => {
    const { t, isLoading, countPending, countRejected } = props;

    return (
        <St.Container>
            <St.Wrapper>
                <St.Label color="rgb(255,183,0)">
                    {t('channels_affidavit_counter_pending')}
                </St.Label>
                {isLoading ? (
                    <CircularSpinner size={14} />
                ) : (
                    <St.Label>{countPending ?? 0}</St.Label>
                )}
            </St.Wrapper>
            <St.Wrapper>
                <St.Label color="rgb(255,94,54)">
                    {t('channels_affidavit_counter_rejected')}
                </St.Label>
                {isLoading ? (
                    <CircularSpinner size={14} />
                ) : (
                    <St.Label>{countRejected ?? 0}</St.Label>
                )}
            </St.Wrapper>
        </St.Container>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

const defaultProps = {};

interface extraProps {
    countPending?: number;
    countRejected?: number;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ChannelsAffidavitCounters.propTypes = propTypes;
ChannelsAffidavitCounters.defaultProps = defaultProps;

export default ChannelsAffidavitCounters;
