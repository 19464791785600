import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CommonGrid from 'components/common/CommonGrid';
import { CommonGridColumnsProps } from 'types/common/CommonGrid/CommonGridColumnProps.types';
import { CollateralDefinition } from 'types/channelsCollateral/channelsCollateral.types';
import {
    ActionColumn,
    HeaderFiltersProps,
} from 'types/common/CommonGrid/CommonGridFormModal.types';
import { useCollateralDefinitions } from 'hooks/api/channelsCollateral.hooks';

const ChannelsCollateralPage = (props: Props) => {
    const { t, SnackBar, columns, actionColumns, refreshTable, headerFilters } = props;
    return (
        <>
            <CommonGrid
                title={t('collateral_list_title')}
                columns={columns}
                resource="channels_collateral"
                useGetData={useCollateralDefinitions}
                defaultSortFieldId="customerCode"
                canExport
                customActionColumns={actionColumns}
                headerFilters={headerFilters}
                refreshTable={refreshTable}
            />
            <SnackBar />
        </>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

const defaultProps = {};

interface extraProps {
    columns: CommonGridColumnsProps<CollateralDefinition>[];
    actionColumns: ActionColumn[];
    headerFilters?: HeaderFiltersProps[];
    refreshTable: boolean;
    SnackBar: any;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ChannelsCollateralPage.propTypes = propTypes;
ChannelsCollateralPage.defaultProps = defaultProps;

export default ChannelsCollateralPage;
