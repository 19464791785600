import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './Drawer.styled';
import DrawerMenuList from './DrawerMenuList';
import { Avatar } from '@mui/material';

const Drawer = (props: Props) => {
    const {
        open,
        mainLogo,
        smallLogo,
        matchesMd,
        menuCollapsed,
        drawerWidth,
        selectedMenu,
        handleMenuCollapse,
        handleNavigate,
        handleMobileNavigate,
        handleDrawerClose,
        handleDrawerOpen,
    } = props;

    const DesktopDrawer = () => (
        <St.Drawer
            variant="permanent"
            ownerState={{
                drawerWidth,
            }}
            open={open}
            onMouseEnter={handleDrawerOpen}
            onMouseLeave={handleDrawerClose}>
            <St.DrawerHeader>
                {open ? (
                    <St.DrawerLogo inheritViewBox component={mainLogo} open={open} />
                ) : (
                    <Avatar src={smallLogo} />
                )}
            </St.DrawerHeader>
            <DrawerMenuList
                open={open}
                selectedMenu={selectedMenu}
                handleNavigate={handleNavigate}
            />
            {open && !matchesMd && (
                <St.DrawerHolderBox>
                    <St.RoundIconSvgWrapper onClick={handleMenuCollapse}>
                        {menuCollapsed ? (
                            <St.CircleSvg r={10} />
                        ) : (
                            <>
                                <St.CircleSvg r={10} />
                                <St.CircleSvg r={3} />
                            </>
                        )}
                    </St.RoundIconSvgWrapper>
                </St.DrawerHolderBox>
            )}
        </St.Drawer>
    );

    const MobileDrawer = () => (
        <St.FlexedDialog open={open} onClose={handleDrawerClose} fullScreen>
            <St.MenuTextWrapper>
                <St.MenuText>Menu</St.MenuText>
                <DrawerMenuList
                    open={open}
                    selectedMenu={selectedMenu}
                    handleNavigate={handleMobileNavigate}
                />
            </St.MenuTextWrapper>
        </St.FlexedDialog>
    );
    return matchesMd ? <MobileDrawer /> : <DesktopDrawer />;
};

const propTypes = {
    open: PropTypes.bool.isRequired,
    drawerWidth: PropTypes.number.isRequired,
    menuCollapsed: PropTypes.bool.isRequired,
    matchesMd: PropTypes.bool.isRequired,
    selectedMenu: PropTypes.string.isRequired,
    setMenuCollapsed: PropTypes.func,
    handleDrawerClose: PropTypes.func.isRequired,
    handleDrawerOpen: PropTypes.func.isRequired,
    handleMenuCollapse: PropTypes.func.isRequired,
    handleNavigate: PropTypes.func.isRequired,
    handleMobileNavigate: PropTypes.func.isRequired,
};

const defaultProps = {};

interface extraProps {
    mainLogo: any;
    smallLogo: any;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
Drawer.propTypes = propTypes;
Drawer.defaultProps = defaultProps;

export default Drawer;
