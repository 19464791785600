import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import PageTitle from './PageTitle';

const PageTitleContainer = (props: Props) => {
    const { children } = props;

    const childProps = {
        ...props,
        children,
    };

    return <PageTitle {...childProps} />;
};

const propTypes = {
    children: PropTypes.string.isRequired,
};

const defaultProps = {
    children: '',
};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}

PageTitleContainer.propTypes = propTypes;
PageTitleContainer.defaultProps = defaultProps;

export default PageTitleContainer;
