import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { Form, FormikProvider } from 'formik';
import { Checkbox, FormControlLabel, Stack } from '@mui/material';
import { tCommon } from 'constants/appConstants';
import CustomInput from 'components/common/CustomInput';
import ActionButton from 'components/common/ActionButton';
import { RegisterModalProps } from 'types/clientImportClientVB/clientImportClientVB.types';
import St from './RegisterModal.styled';

const RegisterModal = ({ formik, isLoading, t, close }: Props) => {
    const { errors, values, setFieldValue } = formik;

    return (
        <St.Modal
            open
            onClose={close}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <St.Box>
                <FormikProvider value={formik} data-testid="RegisterModal-form">
                    <Form>
                        <St.Grid container rowSpacing={2} py={2}>
                            <St.Typography
                                id="modal-modal-title"
                                variant="h5"
                                component="h2"
                                my={1}>
                                {t('register_modal_title')}
                            </St.Typography>
                            <St.Grid item xs={12}>
                                <CustomInput
                                    required
                                    field="email"
                                    label={t('email', tCommon)}
                                    placeholder={t('enter_email', tCommon)}
                                    values={values}
                                    errors={errors}
                                    setFieldValue={setFieldValue}
                                />
                            </St.Grid>
                            <St.Grid item xs={12} pl={2}>
                                <FormControlLabel
                                    control={<Checkbox defaultChecked />}
                                    label={t('label_check')}
                                    disabled
                                />
                            </St.Grid>
                        </St.Grid>
                        <Stack direction="row" display="flex" spacing={1} justifyContent="flex-end">
                            <ActionButton onClick={close} variant="outlined">
                                {t('cancel', tCommon)}
                            </ActionButton>
                            <ActionButton type="submit" disabled={isLoading} loading={isLoading}>
                                {t('accept')}
                            </ActionButton>
                        </Stack>
                    </Form>
                </FormikProvider>
            </St.Box>
        </St.Modal>
    );
};

const propTypes = {};

const defaultProps = {};

interface extraProps extends RegisterModalProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
RegisterModal.propTypes = propTypes;
RegisterModal.defaultProps = defaultProps;

export default RegisterModal;
