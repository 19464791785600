import { withFakeData } from 'api/fakedata';
import { axiosDataInstance } from 'api/axiosInstances';
import {
    ApproveDeclarationRequest,
    GetAffidavitCountersResponse,
    RejectDeclarationRequest,
} from 'types/channelsAffidavit/channelsAffidavit.api.types';
import { DECLARATIONS_BASE_URL } from 'constants/appConstants';

const apiName = 'channelsAffidavit';

export default {
    approveDeclaration: (req: ApproveDeclarationRequest) =>
        withFakeData(
            () => axiosDataInstance.put(`${DECLARATIONS_BASE_URL}/${req.id}/approve`, req.body),
            apiName,
            'channelsAffidavit',
            false,
            500,
        ),
    rejectDeclaration: (req: RejectDeclarationRequest) =>
        withFakeData(
            () => axiosDataInstance.put(`${DECLARATIONS_BASE_URL}/${req.id}/reject`, req.body),
            apiName,
            'channelsAffidavit',
            false,
            500,
        ),
    getAffidavitCounters: () =>
        withFakeData<GetAffidavitCountersResponse>(
            () => axiosDataInstance.get(`${DECLARATIONS_BASE_URL}/`),
            apiName,
            'channelsAffidavit',
            false,
            500,
        ),
};
