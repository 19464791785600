import { paginationAdapter } from 'adapters/common/pagination.adapter';
import { DepositsApi, GetDepositsResponse } from 'types/clientDeposits/clientDeposits.api.types';
import { Deposits } from 'types/clientDeposits/clientDeposits.types';

export const depositsDefinitionsAdapter = (data: GetDepositsResponse) => {
    return {
        ...paginationAdapter(data),
        data: data.items?.map((deposit: DepositsApi) => {
            return {
                transferDate: deposit.transferDate,
                customerCode: deposit.customerCode,
                amount: deposit.amount,
                fileUrl: deposit.fileUrl,
                id: deposit.id,
                user: deposit.user,
                channel: deposit.channel,
                bankAccount: deposit.bankAccount,
            } as Deposits;
        }),
    };
};
