import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { Alert, Typography } from '@mui/material';

const AlertNoData = (props: Props) => {
    const { title } = props;

    return (
        <Alert
            variant="standard"
            severity="info"
            sx={{
                marginY: '1rem',
                color: 'primary',
                backgroundColor: 'rgb(243 242 247)',
                borderRadius: '70px',
            }}>
            <Typography variant="body1" color="primary">
                {title}
            </Typography>
        </Alert>
    );
};

const propTypes = {
    title: PropTypes.string.isRequired,
};

const defaultProps = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
AlertNoData.propTypes = propTypes;
AlertNoData.defaultProps = defaultProps;

export default AlertNoData;
