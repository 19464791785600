import { useMutation, useQuery } from '@tanstack/react-query';
import endpoints from '../../api/marketExteriorInstruments/marketExteriorInstruments.api';
import { GridHookParams } from 'types/common/CommonGrid/CommonGridFormModal.types';
import { externalInstrumentsAdapter } from 'adapters/api/marketExteriorInstruments.adapter';
import { AlertColor } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { tErrorsContext } from 'constants/appConstants';
import { errorResponseHelper } from 'utils/helpers/errorsHelper';

export const useExternalInstruments = ({ forceDisable, filterQueryString }: GridHookParams) => {
    const enabledCondition = !forceDisable;

    const { data, isLoading, isFetching, isRefetching, refetch } = useQuery(
        ['external-instruments', filterQueryString],
        () => endpoints.getInstruments(filterQueryString),
        {
            enabled: enabledCondition,
            select: data => {
                return externalInstrumentsAdapter(data?.data);
            },
        },
    );

    return {
        data,
        isLoading: (isLoading && isFetching) || isRefetching,
        refetch,
    };
};

export const useCreateExternalInstruments = (
    setSnackBarMessage: (msj: string, sever: AlertColor) => void,
    close: () => void,
) => {
    const { t } = useTranslation('marketExteriorInstruments');
    const { mutate, isLoading } = useMutation(endpoints.createExternalInstrument, {
        onSuccess() {
            setSnackBarMessage(t('create_external_instrument_success_message'), 'success');
            close();
        },
        onError(err: any) {
            setSnackBarMessage(errorResponseHelper(err), 'error');
        },
    });

    return {
        isLoading,
        mutate,
    };
};

export const useEditExternalInstruments = (
    setSnackBarMessage: (msj: string, sever: AlertColor) => void,
    close: () => void,
) => {
    const { t } = useTranslation('marketExteriorInstruments');
    const { mutate, isLoading } = useMutation(endpoints.editExternalInstrument, {
        onSuccess() {
            setSnackBarMessage(t('edit_external_instrument_success_message'), 'success');
            close();
        },
        onError(err) {
            setSnackBarMessage(errorResponseHelper(err), 'error');
        },
    });

    return {
        isLoading,
        mutate,
    };
};
export const useDeleteExternalInstruments = () => {
    const { t } = useTranslation('marketExteriorInstruments');
    const { mutate, isLoading, isError, error } = useMutation(endpoints.deleteExternalInstrument);

    return {
        mutate,
        isLoading,
        isError,
        errorMessage: t(errorResponseHelper(error as any), tErrorsContext),
        successfulMessage: t('market_exterior_instruments_modal_delete_success'),
    };
};
