import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { RolesDefinition } from 'types/usersRoles/usersRoles.types';
import St from './UsersRolesFormDetail.styled';
import { SimpleTreeView, TreeItem } from '@mui/x-tree-view';

const UserRolesFormDetail = (props: Props) => {
    const { t, row } = props;

    return (
        <St.UserRolesDetail data-testid="UserRolesDetail-page">
            <St.RoleDescription>
                <St.Label>{t('users_roles_list_name')}:</St.Label>
                {row?.name}
            </St.RoleDescription>
            <St.RoleDescription>
                <St.Label>{t('users_roles_list_description')}:</St.Label>
                {row?.description}
            </St.RoleDescription>
            <St.RoleDescription>
                <SimpleTreeView>
                    <TreeItem itemId={row.name} label={t('users_roles_list_abilities')}>
                        {row?.abilities?.map((ability: any) => {
                            return (
                                <TreeItem
                                    key={ability?.id}
                                    itemId={ability?.id.toString()!}
                                    label={t(`users_abilities_${ability?.name.toLowerCase()}`)}
                                />
                            );
                        })}
                    </TreeItem>
                </SimpleTreeView>
            </St.RoleDescription>
        </St.UserRolesDetail>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

const defaultProps = {};

interface extraProps {
    row: RolesDefinition;
    close: () => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
UserRolesFormDetail.propTypes = propTypes;
UserRolesFormDetail.defaultProps = defaultProps;

export default UserRolesFormDetail;
