import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import SettingsInstrumentRulesForm from './SettingsInstrumentRulesForm';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { ModalActionProps } from 'types/common/CommonGrid/CommonGridFormModal.types';
import {
    InstrumentRule,
    RulesTabsTypes,
} from 'types/settingsInstrumentRules/settingsInstrumentRules.types';
import { tRequiredFieldError } from 'constants/appConstants';
import { useTranslation } from 'react-i18next';
import { useCreateInstrumentRule, useEditInstrumentRule } from 'hooks/api/settingsInstrumentRules';
import { InstrumentRuleBody } from 'types/settingsInstrumentRules/settingsInstrumentRules.api.types';
import { UserException } from 'types/common/users.types';

// ---------------------------------------------//
// ------------------- FORMIK ------------------//
// ---------------------------------------------//

const rulesTypesOptions = [
    { id: 1, value: 'BLOCK BUY', name: 'Bloquear compra' },
    { id: 2, value: 'BLOCK SELL', name: 'Bloquear venta' },
    { id: 3, value: 'SOFT DELETE', name: 'Ocultar' },
];

const getInitialValues = (row: InstrumentRule) => ({
    description: row?.description ?? '',
    reason: row?.reason ?? '',
    action: row?.action ? rulesTypesOptions.find(r => r.value === row?.action) : null,
    conditions: row?.conditions ?? [],
    exceptions: row?.exceptions ? row.exceptions : [],
});

const getValidationSchema = () =>
    Yup.object().shape({
        reason: Yup.string().required(tRequiredFieldError),
        action: Yup.object()
            .shape({
                id: Yup.string(),
                name: Yup.string(),
                value: Yup.string(),
            })
            .required(tRequiredFieldError)
            .nullable(),
        description: Yup.string().required(tRequiredFieldError),
        conditions: Yup.array()
            .of(
                Yup.object().shape({
                    instrumentId: Yup.string().nullable(),
                    instrumentType: Yup.string().nullable(),
                    terms: Yup.array(),
                    currencies: Yup.array(),
                }),
            )
            .nullable(),
        exceptions: Yup.array()
            .of(
                Yup.object().shape({
                    uuid: Yup.string().nullable(),
                    username: Yup.string().nullable(),
                }),
            )
            .nullable(),
    });

// ---------------------------------------------//
// ---------------------------------------------//
// ---------------------------------------------//

const SettingsInstrumentRulesFormContainer = (props: Props) => {
    const { close, isEdit, row, setSnackBarMessage } = props;

    const { t } = useTranslation('settingsInstrumentRules');

    const [tabValue, setTabValue] = React.useState<RulesTabsTypes>('rule');

    const handleSelectTab = (event: React.SyntheticEvent, newValue: RulesTabsTypes) => {
        setTabValue(newValue);
    };

    const { mutate: createInstrumentRule, isLoading: isLoadingCreate } = useCreateInstrumentRule(
        setSnackBarMessage,
        close,
    );
    const { mutate: editInstrumentRule, isLoading: isLoadingEdit } = useEditInstrumentRule(
        setSnackBarMessage,
        close,
    );
    const handleSubmit = React.useCallback(async (data: any) => {
        const body: InstrumentRuleBody = {
            action: data.action.value,
            conditions: data.conditions,
            description: data.description,
            exceptions: data.exceptions.map((e: UserException) => e.id),
            reason: data.reason,
        };
        isEdit ? editInstrumentRule({ id: row.id, body }) : createInstrumentRule(body);
    }, []);

    const formikInitProps = React.useMemo(
        () => ({
            initialValues: getInitialValues(row),
            validateOnChange: false,
            validateOnBlur: false,
            validateOnMount: false,
            validationSchema: getValidationSchema(),
            onSubmit: handleSubmit,
        }),
        [handleSubmit, row],
    );

    const formik = useFormik(formikInitProps);
    const childProps = {
        ...props,
        formik,
        t,
        close,
        tabValue,
        handleSelectTab,
        isLoading: isLoadingCreate || isLoadingEdit,
    };

    return <SettingsInstrumentRulesForm {...childProps} />;
};

const propTypes = {};

const defaultProps = {};

interface extraProps extends ModalActionProps {
    isEdit: boolean;
    row: InstrumentRule;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
SettingsInstrumentRulesFormContainer.propTypes = propTypes;
SettingsInstrumentRulesFormContainer.defaultProps = defaultProps;

export default SettingsInstrumentRulesFormContainer;
