import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
// import St from './ClientSectionProspectEscoInfo.styled';
import { Grid } from '@mui/material';
import InfoLabel from 'components/common/InfoLabel';
import { ProspectMarketData } from 'types/clients/clients.types';
import { getFullName } from 'utils/helpers/commonHelper';

const ClientSectionProspectEscoInfo = (props: Props) => {
    const { t, marketInfo } = props;

    return (
        <Grid container>
            <Grid item xs={6}>
                <InfoLabel
                    fontSize={'14px'}
                    withoutSeparation
                    label={t('client_section_prospect_esco_info_full_name_field')}
                    text={getFullName(marketInfo)}
                />
            </Grid>
            <Grid item xs={6}>
                <InfoLabel
                    fontSize={'14px'}
                    withoutSeparation
                    label={t('client_section_prospect_esco_info_tax_doc_number_label')}
                    text={marketInfo.taxDocNumber}
                />
            </Grid>
            <Grid item xs={6}>
                <InfoLabel
                    fontSize={'14px'}
                    withoutSeparation
                    label={t('client_section_prospect_esco_info_doc_number_label')}
                    text={marketInfo.docNumber}
                />
            </Grid>
            <Grid item xs={6}>
                <InfoLabel
                    fontSize={'14px'}
                    withoutSeparation
                    label={t('client_section_prospect_esco_info_address_label')}
                    text={marketInfo?.marketAccounts[0]?.address ?? '-'}
                />
            </Grid>
        </Grid>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

const defaultProps = {};

interface extraProps {
    marketInfo: ProspectMarketData;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ClientSectionProspectEscoInfo.propTypes = propTypes;
ClientSectionProspectEscoInfo.defaultProps = defaultProps;

export default ClientSectionProspectEscoInfo;
