import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ClientProspectRelateAccountModal from './ClientProspectRelateAccountModal';
import { ProspectMarketAccounts } from 'types/clients/clients.types';
import { useTranslation } from 'react-i18next';
import { useRelateAccount } from 'hooks/api/clients.hooks';
import { useNavigate } from 'react-router-dom';

const ClientProspectRelateAccountModalContainer = (props: Props) => {
    const { id, marketAccounts, setSnackBarMessage, close } = props;
    const { t } = useTranslation('clients');
    const [selectedCustomer, setSelectedCustomer] = React.useState(marketAccounts[0]);
    const navigate = useNavigate();
    const { mutate, isLoading } = useRelateAccount(setSnackBarMessage, close, navigate);

    const onSubmit = () => {
        mutate({
            id,
            body: {
                customerCode: selectedCustomer.customerCode,
                customerVb: selectedCustomer.customerVb,
            },
        });
    };

    const childProps = {
        ...props,
        t,
        selectedCustomer,
        setSelectedCustomer,
        onSubmit,
        isLoading,
    };

    return <ClientProspectRelateAccountModal {...childProps} />;
};

const propTypes = {
    id: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    setSnackBarMessage: PropTypes.func.isRequired,
};

const defaultProps = {};

interface extraProps {
    marketAccounts: ProspectMarketAccounts[];
    close: () => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ClientProspectRelateAccountModalContainer.propTypes = propTypes;
ClientProspectRelateAccountModalContainer.defaultProps = defaultProps;

export default ClientProspectRelateAccountModalContainer;
