import { Chip, Typography } from '@mui/material';
import CustomIconSelector from 'components/common/CustomIconSelector/CustomIconSelector';
import { INSTRUMENTS_BASE_URL, tChannelsCommon, tCommon, tDate } from 'constants/appConstants';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ChannnelsAffidavit } from 'types/channelsAffidavit/channelsAffidavit.types';
import { CommonGridColumnsProps } from 'types/common/CommonGrid/CommonGridColumnProps.types';
import {
    ActionColumn,
    ActionHeader,
    HeaderFiltersProps,
} from 'types/common/CommonGrid/CommonGridFormModal.types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ChannelsAffidavitPage from './ChannelsAffidavitPage';
import ChannelsAffidavitDetail from './ChannelsAffidavitDetail';
import ChannelsModalHeader from 'components/common/ChannelsModalHeader';
import { getFullName, getColorByStatus } from 'utils/helpers/commonHelper';
import ChannelsAffidavitCounters from './ChannelsAffidavitCounters';
import { formatDateWithHour } from 'utils/helpers/dateHelper';

const ChannelsAffidavitPageContainer = (props: Props) => {
    const { t } = useTranslation('channelsAffidavit');

    const columns: CommonGridColumnsProps<ChannnelsAffidavit>[] = [
        {
            id: 'date',
            name: t('channels_affidavit_list_date_column'),
            selector: row => formatDateWithHour(row.createdAt),
            sortField: 'createdAt',
            sortable: true,
            center: true,
        },
        {
            id: 'channel',
            name: t('channels_affidavit_list_channel_column'),
            selector: row => getFullName(row.channel),
            sortField: 'channel',
            sortable: false,
            center: true,
        },
        {
            id: 'customer_code',
            name: t('channels_affidavit_list_customer_code_column'),
            selector: row => row.customerCode,
            sortField: 'customerCode',
            sortable: true,
            center: true,
        },
        {
            id: 'client',
            name: t('channels_affidavit_list_client_column'),
            selector: row => getFullName(row.user),
            sortField: 'client',
            sortable: false,
            center: true,
        },
        {
            id: 'instrument',
            name: t('channels_affidavit_list_instrument_column'),
            selector: row => row?.ticker?.ticker || '-',
            cell: row => (
                <Typography fontSize={14} fontWeight={700} textAlign="center">
                    {row?.ticker?.ticker || '-'}
                </Typography>
            ),
            sortField: 'instrument',
            sortable: false,
            center: true,
        },
        {
            id: 'currency',
            name: t('channels_affidavit_list_currency_column'),
            selector: row => row.ticker.currency,
            sortField: 'currency',
            sortable: false,
            center: true,
        },
        {
            id: 'status',
            name: t('channels_affidavit_list_status_column'),
            selector: row => row.declaration?.status as string,
            cell: row => (
                <Chip
                    sx={{
                        m: 0.625,
                        minWidth: '100%',
                        ...getColorByStatus(row.declaration?.status as string),
                    }}
                    label={t(row.declaration?.status as string, tCommon)}
                />
            ),
            sortField: 'status',
            sortable: false,
            center: true,
        },
        {
            id: 'buySell',
            name: t('channels_affidavit_list_operation_type_column'),
            selector: row => `orders_${row.buySell}`,
            sortField: 'buySell',
            sortable: true,
            center: true,
            cell: row => (
                <Chip
                    variant="outlined"
                    color="secondary"
                    sx={{ m: 0.625, minWidth: '100%' }}
                    label={t(row.buySell, tChannelsCommon)}
                />
            ),
        },
    ];

    const actionColumns: ActionColumn[] = [
        {
            id: 'detail',
            icon: (props: any) => <CustomIconSelector type="detail" sx={props.sx} />,
            component: (props: any) => <ChannelsAffidavitDetail {...props} />,
            customizedTitle: ({ declaration, buySell }: ChannnelsAffidavit) => (
                <ChannelsModalHeader
                    modalName="affidavit"
                    status={declaration?.status as string}
                    operationType={`orders_${buySell}`}
                />
            ),
        },
    ];

    const headerActions: ActionHeader[] = [
        {
            id: 'counters',
            component: () => <ChannelsAffidavitCounters />,
        },
    ];

    const operationsTypeOptions = [
        { id: 'BUY', name: 'Compra' },
        { id: 'SELL', name: 'Venta' },
    ];

    const headerFilters: HeaderFiltersProps[] = [
        {
            id: 'customer-code',
            label: t('channels_affidavit_customer_code_filter_label'),
            type: 'text',
            placeholder: t('channels_affidavit_customer_code_filter_placeholder'),
            query: 'customerCode',
            regex: /^\d{0,13}$/,
        },
        {
            id: 'instruments',
            label: t('channels_affidavit_instruments_filter_label'),
            type: 'requestAutocomplete',
            placeholder: t('channels_affidavit_instruments_filter_placeholder'),
            query: 'ticker',
            requestAutocomplete: {
                requestUrl: `${INSTRUMENTS_BASE_URL}/bo/`,
                param: 'ticker',
                optionLabel: 'name',
                keyTofilter: 'tickers[0].ticker',
            },
        },
        {
            id: 'operationsType',
            label: t('channels_affidavit_operations_type_filter_label'),
            type: 'select',
            placeholder: t('channels_affidavit_operations_type_filter_placeholder'),
            query: 'buySell',
            options: operationsTypeOptions,
        },
        {
            id: 'dateFrom',
            label: t('from', tDate),
            type: 'date',
            query: 'dateFrom',
        },
        {
            id: 'dateTo',
            label: t('to', tDate),
            type: 'date',
            query: 'dateTo',
        },
    ];

    const childProps = {
        ...props,
        columns,
        actionColumns,
        t,
        headerFilters,
        headerActions,
    };

    return <ChannelsAffidavitPage {...childProps} />;
};

const propTypes = {};

const defaultProps = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ChannelsAffidavitPageContainer.propTypes = propTypes;
ChannelsAffidavitPageContainer.defaultProps = defaultProps;

export default ChannelsAffidavitPageContainer;
