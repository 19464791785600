import * as React from 'react';
// import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './SettingsNonWorkingDaysDetail.styled';
import { MarketOptions } from 'types/settingsNonWorkingDays/settingsNonWorking.types';

const SettingsNonWorkingDaysDetail = (props: Props) => {
    const { calendar, markets } = props;

    return (
        <St.MarketnonWorkingDayDetail data-testid="SettingsNonWorkingDaysDetail-default">
            <St.DateContainer>
                <St.DateBox>
                    <St.DateTitle>Fecha desde:</St.DateTitle>
                    <St.DateValue>{calendar.dateFrom}</St.DateValue>
                </St.DateBox>
                <St.DateBox>
                    <St.DateTitle>Fecha hasta:</St.DateTitle>
                    <St.DateValue>{calendar.dateTo}</St.DateValue>
                </St.DateBox>
            </St.DateContainer>

            <St.MarketContainer>
                <St.MarketTitle>Mercados:</St.MarketTitle>
                <St.MarketBox>
                    {markets.map((market, index) => (
                        <St.MarketName key={index}>{market.name}</St.MarketName>
                    ))}
                </St.MarketBox>
            </St.MarketContainer>
        </St.MarketnonWorkingDayDetail>
    );
};

const propTypes = {};

const defaultProps = {};

interface extraProps {
    markets: MarketOptions[];
    calendar: {
        dateFrom: string;
        dateTo: string;
    };
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
SettingsNonWorkingDaysDetail.propTypes = propTypes;
SettingsNonWorkingDaysDetail.defaultProps = defaultProps;

export default SettingsNonWorkingDaysDetail;
