import { Box, Typography, styled } from '@mui/material';
import { transientOptions } from 'utils/helpers/stylesHelper';

export default {
    Wrapper: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            display: 'flex',
            alignItems: 'baseline',
            marginBottom: '0.8em',
            width: '100%',
        }),
    ),

    Label: styled(
        Typography,
        transientOptions,
    )<{ $withoutSeparation: boolean }>(({ theme, fontSize, $withoutSeparation }) =>
        theme.unstable_sx({
            fontSize: fontSize ?? '1em',
            fontWeight: 'bold',
            color: 'black',
            marginRight: $withoutSeparation ? '5px' : '1em',
        }),
    ),

    Data: styled(Typography)(({ theme, fontSize }) =>
        theme.unstable_sx({
            fontSize: fontSize ?? '1em',
        }),
    ),
};
