import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { Form, FormikProvider } from 'formik';
import CustomInput from 'components/common/CustomInput';
import { ClientImportClientVBPageProps } from 'types/clientImportClientVB/clientImportClientVB.types';
import { handleChangeNumber } from 'utils/helpers/commonHelper';
import ClientVBDetail from './ClientVBDetail';
import AssociatedPersonTableContainer from './AssociatedPersonTable';
import CustomLoader from 'components/common/CustomLoader';
import ErrorText from 'components/common/ErrorText';
import St from './ClientImportClientVBPage.styled';

const ClientImportClientVBPage = (props: Props) => {
    const {
        t,
        isLoading,
        btnDisable,
        formik,
        drawerWidth,
        error,
        data,
        removeData,
        setSnackBarMessage,
        returnPreviousPage,
        SnackBar,
    } = props;
    const { values, setFieldValue, errors } = formik;

    return (
        <St.GridWrapper $drawerWidth={drawerWidth}>
            <St.TitleWrapper>
                <St.BackToBaseButton disabled={btnDisable} backToBase={returnPreviousPage} />
                <St.Title>{t('title')}</St.Title>
            </St.TitleWrapper>
            <FormikProvider value={formik} data-testid="ClientImportClientVB-form">
                <Form>
                    <St.Grid container spacing={2} py={1.25}>
                        <St.Grid item xs={12} sm={5} md={4}>
                            <CustomInput
                                field="clientNumber"
                                values={values}
                                label={t('client_number_label')}
                                placeholder={t('client_number_placeholder')}
                                errors={errors}
                                handleChange={value =>
                                    handleChangeNumber(
                                        'clientNumber',
                                        value,
                                        setFieldValue,
                                        removeData,
                                    )
                                }
                            />
                        </St.Grid>
                        <St.Grid item xs={12} sm={7} md={8} display="flex" gap={1}>
                            <St.Button
                                variant="outlined"
                                type="button"
                                disabled={isLoading}
                                onClick={() => {
                                    setFieldValue('clientNumber', '');
                                    removeData();
                                }}>
                                {t('delete_button')}
                            </St.Button>
                            <St.SubmitButton
                                loading={isLoading}
                                variant="contained"
                                type="submit"
                                disabled={isLoading}>
                                {t('submit_button')}
                            </St.SubmitButton>
                        </St.Grid>
                        <St.Grid item xs={12}>
                            {isLoading ? <CustomLoader /> : null}
                            {error && <ErrorText text={error as string} />}
                            {data && !isLoading && (
                                <St.InfoWrapper>
                                    <ClientVBDetail {...data} />
                                    <AssociatedPersonTableContainer
                                        list={data?.persons}
                                        customerCode={data.customerCode}
                                        email={data.email}
                                        setSnackBarMessage={setSnackBarMessage}
                                        enableSubmitCredentials={
                                            data.lastLogin == null && data.isClient
                                        }
                                        enableRegister={data.lastLogin == null && !data.isClient}
                                    />
                                </St.InfoWrapper>
                            )}
                        </St.Grid>
                    </St.Grid>
                </Form>
            </FormikProvider>
            <SnackBar />
        </St.GridWrapper>
    );
};

const propTypes = {};

const defaultProps = {};

interface extraProps extends ClientImportClientVBPageProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ClientImportClientVBPage.propTypes = propTypes;
ClientImportClientVBPage.defaultProps = defaultProps;

export default ClientImportClientVBPage;
