import { paginationAdapter } from 'adapters/common/pagination.adapter';
import {
    CollateralDefinitionApi,
    GetCollateralDefinitionsResponse,
} from 'types/channelsCollateral/channelsCollateral.api.types';
import { CollateralDefinition } from 'types/channelsCollateral/channelsCollateral.types';

export const collateralDefinitionsAdapter = (data: GetCollateralDefinitionsResponse) => {
    return {
        ...paginationAdapter(data),
        data: data.items?.map((collateralDefinition: CollateralDefinitionApi) => {
            return {
                id: collateralDefinition.id,
                customerCode: collateralDefinition.customerCode,
                buySell: collateralDefinition.buySell,
                amount: collateralDefinition.amount,
                isManual: collateralDefinition.isManual,
                user: collateralDefinition.user,
                currency: collateralDefinition.currency,
                rate: collateralDefinition.rate,
                isMarketRate: collateralDefinition.isMarketRate,
                term: collateralDefinition.term,
                status: collateralDefinition.status,
                collaterals: collateralDefinition.collaterals,
                channel: collateralDefinition.channel,
                operator: collateralDefinition.operator,
                lastModified: collateralDefinition.lastModified,
                reason: collateralDefinition.reason,
                createdAt: collateralDefinition.createdAt,
                updatedAt: collateralDefinition.updatedAt,
            } as CollateralDefinition;
        }),
    };
};
