import { Box, Stack, styled } from '@mui/material';

export default {
    LoadingContainer: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            display: { sm: 'none', lg: 'flex' },
            justifyContent: 'center',
            alignItems: 'center',
        }),
    ),
    Container: styled(Stack)(({ theme }) =>
        theme.unstable_sx({
            display: { sm: 'block', lg: 'flex' },
            justifyContent: 'flex-end',
            alignItems: 'center',
            marginBottom: '16px',
        }),
    ),
};
