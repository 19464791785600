import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './MainLayout.styled';
import { Outlet } from 'react-router-dom';
import HelmetLayout from '../HelmetLayout';
import SidebarLayout from '../SidebarLayout';

const MainLayout = (props: Props) => {
    const { isAuthenticated } = props;

    return (
        <>
            <HelmetLayout withOutlet={false} />
            <St.MainWrapper component="main">
                <SidebarLayout />
                <St.PageWrapper>
                    {/* REVISAR */}
                    {isAuthenticated === true ? <Outlet /> : <Outlet />}
                </St.PageWrapper>
            </St.MainWrapper>
        </>
    );
};

const propTypes = {
    isAuthenticated: PropTypes.bool,
};

const defaultProps = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
MainLayout.propTypes = propTypes;
MainLayout.defaultProps = defaultProps;

export default MainLayout;
