import * as React from 'react';
import defaultTheme from 'utils/theme/defaultTheme';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import EditIcon from '../../../assets/icons/pencil.svg?react';
import VisibilityOnIcon from '../../../assets/icons/eye.svg?react';
import DeleteIcon from '../../../assets/icons/delete-bin-5.svg?react';
import CloseIcon from '../../../assets/icons/close.svg?react';
import CheckIcon from '../../../assets/icons/check.svg?react';
import LoginIcon from '../../../assets/icons/login-box.svg?react';
import LogoutIcon from '../../../assets/icons/logout-box-r.svg?react';
import ApproveIcon from '../../../assets/icons/thumb-up.svg?react';
import RejectIcon from '../../../assets/icons/thumb-down.svg?react';
import BackIcon from '../../../assets/icons/arrow-left.svg?react';
import DisableIcon from '../../../assets/icons/close-circle.svg?react';
import EnableIcon from '../../../assets/icons/checkbox-circle.svg?react';
import DownloadIcon from '../../../assets/icons/download-2.svg?react';
import AddIcon from '../../../assets/icons/add.svg?react';
import FilterOnIcon from '../../../assets/icons/filter.svg?react';
import FilterOffIcon from '../../../assets/icons/filter-off.svg?react';
import VisibilityOffIcon from '../../../assets/icons/eye-off.svg?react';
import RefreshIcon from '../../../assets/icons/refresh.svg?react';
import MenuIcon from '../../../assets/icons/menu.svg?react';
import DropUpIcon from '../../../assets/icons/arrow-drop-up.svg?react';
import DropDownIcon from '../../../assets/icons/arrow-drop-down.svg?react';
import SearchIcon from '../../../assets/icons/search.svg?react';
import FileUploadIcon from '../../../assets/icons/file-upload.svg?react';
import CopyIcon from '../../../assets/icons/file-copy-2.svg?react';
import GenerateIcon from '../../../assets/icons/file-add.svg?react';
import PersonIcon from '../../../assets/icons/person.svg?react';
import ArrowLeftIcon from '../../../assets/icons/arrowLeft.svg?react';
import ArrowRightIcon from '../../../assets/icons/arrowRight.svg?react';
import AlertIcon from '../../../assets/icons/alert-line.svg?react';

// Mapa de tipos de iconos a los componentes de iconos correspondientes
const IconComponents: { [key: string]: JSX.Element } = {
    enable: <EnableIcon />,
    disable: <DisableIcon />,
    detail: <VisibilityOnIcon />,
    edit: <EditIcon />,
    delete: <DeleteIcon />,
    approve: <ApproveIcon />,
    reject: <RejectIcon />,
    assing: <LoginIcon />,
    unassing: <LogoutIcon />,
    check: <CheckIcon />,
    close: <CloseIcon />,
    back: <BackIcon />,
    download: <DownloadIcon />,
    add: <AddIcon />,
    filterOn: <FilterOnIcon />,
    filterOff: <FilterOffIcon />,
    visibilityOff: <VisibilityOffIcon />,
    refresh: <RefreshIcon />,
    menu: <MenuIcon />,
    dropUp: <DropUpIcon />,
    dropDown: <DropDownIcon />,
    search: <SearchIcon />,
    fileUpload: <FileUploadIcon />,
    copy: <CopyIcon />,
    generate: <GenerateIcon />,
    person: <PersonIcon />,
    arrowL: <ArrowLeftIcon />,
    arrowR: <ArrowRightIcon />,
    alert: <AlertIcon />,
};

const CustomIconSelector = (props: Props) => {
    const { type, sx } = props;
    return React.cloneElement(IconComponents[type], {
        ...sx,
        fill: sx?.color || defaultTheme.palette.secondary.main,
    });
};

const propTypes = {};

const defaultProps = {};

interface extraProps {
    type:
        | 'enable'
        | 'disable'
        | 'detail'
        | 'edit'
        | 'delete'
        | 'approve'
        | 'reject'
        | 'assing'
        | 'unassing'
        | 'check'
        | 'close'
        | 'back'
        | 'download'
        | 'add'
        | 'filterOn'
        | 'filterOff'
        | 'visibilityOff'
        | 'refresh'
        | 'menu'
        | 'dropUp'
        | 'dropDown'
        | 'search'
        | 'fileUpload'
        | 'copy'
        | 'generate'
        | 'person'
        | 'arrowL'
        | 'arrowR'
        | 'alert';
    sx?: any;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CustomIconSelector.propTypes = propTypes;
CustomIconSelector.defaultProps = defaultProps;

export default CustomIconSelector;
