import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './UsersBackofficeForm.styled';
import { Form, FormikContextType, FormikProvider } from 'formik';
import { tCommon } from 'constants/appConstants';
import ActionButton from 'components/common/ActionButton';
import { Grid } from '@mui/material';
import CustomInput from 'components/common/CustomInput';
import PasswordField from 'components/common/PasswordField';
import { RoleDefinition } from 'types/usersRoles/usersRoles.types';
import CustomAutoComplete from 'components/common/CustomAutoComplete';

const UsersBackofficeForm = (props: Props) => {
    const { t, close, formik, isEdit, rolesData, isRolesLoading, disableButton } = props;
    const { errors, values, setFieldValue } = formik;

    return (
        <FormikProvider value={formik} data-testid="UsersBackofficeForm-form">
            <Form>
                <St.UsersBackofficeForm>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={12}>
                            <CustomInput
                                required
                                field="name"
                                label={t('backoffice_users_form_name_input')}
                                placeholder={t('backoffice_users_form_name_placeholder') as string}
                                values={values}
                                errors={errors}
                                setFieldValue={setFieldValue}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <CustomInput
                                required
                                field="lastName"
                                label={t('backoffice_users_form_last_name_input')}
                                placeholder={
                                    t('backoffice_users_form_last_name_placeholder') as string
                                }
                                values={values}
                                errors={errors}
                                setFieldValue={setFieldValue}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <CustomInput
                                required
                                field="email"
                                label={t('backoffice_users_form_email_input')}
                                placeholder={t('backoffice_users_form_email_placeholder') as string}
                                values={values}
                                errors={errors}
                                setFieldValue={setFieldValue}
                            />
                        </Grid>
                        {!isEdit && (
                            <>
                                <Grid item xs={12} md={12}>
                                    <CustomInput
                                        required
                                        field="username"
                                        label={t('backoffice_users_form_user_name_input')}
                                        placeholder={
                                            t(
                                                'backoffice_users_form_user_name_placeholder',
                                            ) as string
                                        }
                                        values={values}
                                        errors={errors}
                                        setFieldValue={setFieldValue}
                                    />
                                </Grid>

                                <Grid item xs={12} md={12}>
                                    <PasswordField
                                        name="password"
                                        error={errors.password !== undefined}
                                        helperText={errors.password?.toString()}
                                        label={t('backoffice_users_form_password_input')}
                                        placeholder={t(
                                            'backoffice_users_form_password_placeholder',
                                        )}
                                        onChange={({ target }: any) => {
                                            setFieldValue('password', target.value);
                                        }}
                                        value={values.password}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <PasswordField
                                        name="repeatPassword"
                                        error={errors.repeatPassword !== undefined}
                                        helperText={errors.repeatPassword?.toString()}
                                        label={t('backoffice_users_form_repeat_password_input')}
                                        placeholder={t(
                                            'backoffice_users_form_repeat_password_placeholder',
                                        )}
                                        onChange={({ target }: any) => {
                                            setFieldValue('repeatPassword', target.value);
                                        }}
                                        value={values.repeatPassword}
                                        fullWidth
                                    />
                                </Grid>
                            </>
                        )}
                        <Grid item xs={12} md={12}>
                            <CustomAutoComplete
                                id="roles"
                                options={
                                    rolesData?.data === undefined ? [] : (rolesData?.data as [])
                                }
                                value={values?.roles}
                                onChange={(e, value) => {
                                    setFieldValue('roles', value);
                                }}
                                isLoading={isRolesLoading === undefined ? false : isRolesLoading} // sacarlo rompe el codigo
                                multiple
                                filterSelectedOptions={true}
                                label={t('backoffice_users_form_roles_input')}
                                placeholder={t('backoffice_users_form_roles_placeholder')}
                                renderOption={(props, option) => (
                                    <li {...props}>
                                        <div>{option.name}</div>
                                    </li>
                                )}
                            />
                        </Grid>
                    </Grid>
                    <St.ActionSectionForm direction="row" spacing={1}>
                        <ActionButton onClick={close} variant="outlined">
                            {t('cancel', tCommon)}
                        </ActionButton>
                        <ActionButton
                            size="medium"
                            type="submit"
                            disabled={!disableButton}
                            color="primary"
                            variant="contained">
                            {isEdit ? t('edit', tCommon) : t('add', tCommon)}
                        </ActionButton>
                    </St.ActionSectionForm>
                </St.UsersBackofficeForm>
            </Form>
        </FormikProvider>
    );
};

const propTypes = {
    isEdit: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
};

const defaultProps = {};

interface extraProps {
    formik: FormikContextType<any>;
    close: () => void;
    rolesData:
        | {
              data: RoleDefinition[];
              total_count: number;
          }
        | undefined;
    isRolesLoading: boolean;
    actionSelector: { id: number; name: string } | null;
    setActionSelector: React.Dispatch<React.SetStateAction<null>>;
    disableButton: boolean;
}
interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
UsersBackofficeForm.propTypes = propTypes;
UsersBackofficeForm.defaultProps = defaultProps;
export default UsersBackofficeForm;
