import { withFakeData } from 'api/fakedata';
import { axiosDataInstance } from 'api/axiosInstances';
import {
    BusinessAssistantAdapted,
    getBusinessAssistantUsersResponse,
} from 'types/usersBusinessAssistant/usersBusinessAssistant.api.types';
import { AUTH_BASE_URL } from 'constants/appConstants';

const apiName = 'usersBusinessAssistant';

export default {
    getBusinessAssistants: (filterQueryString: string) =>
        withFakeData<getBusinessAssistantUsersResponse>(
            () =>
                axiosDataInstance.get(
                    `${AUTH_BASE_URL}/app_users/search?userType=ASSISTANT${filterQueryString}`,
                ),
            apiName,
            'usersBusinessAssistant',
            false,
            2000,
        ),
    createBusinessAssistant: (body: BusinessAssistantAdapted) =>
        withFakeData(
            () => axiosDataInstance.post(`${AUTH_BASE_URL}/app_users/assistant`, body),
            apiName,
            'usersBusinessAssistant',
            false,
            2000,
        ),
    editBusinessAssistant: ({ id, body }: { id: string; body: BusinessAssistantAdapted }) =>
        withFakeData(
            () => axiosDataInstance.patch(`${AUTH_BASE_URL}/app_users/assistant/${id}`, body),
            apiName,
            'usersBusinessAssistant',
            false,
            2000,
        ),
};
