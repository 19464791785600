import { styled as styledMui } from '@mui/material/styles';
import { Box, keyframes } from '@mui/material';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export default {
    FadeIn: styledMui(Box)(({ duration }: { duration?: string }) => ({
        animation: fadeIn,
        animationDuration: duration || '0.5s',
        animationFillMode: 'both',
    })),
};
