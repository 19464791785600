import { paginationAdapter } from 'adapters/common/pagination.adapter';
import {
    UsersBackofficeApi,
    GetBackofficeUserProfileResponse,
    GetBackofficeUsersResponse,
} from 'types/usersBackoffice/usersBackoffice.api.types';

import {
    BackofficeUserProfileDefinition,
    BackofficeUsersDefinition,
} from 'types/usersBackoffice/usersBackoffice.types';

export const userBackofficeAdapter = (data: GetBackofficeUsersResponse) => {
    return {
        ...paginationAdapter(data),
        data: data.items?.map((userBackoffice: UsersBackofficeApi) => {
            return {
                assignedAbilities: userBackoffice?.assignedAbilities ?? [],
                assignedRoles: userBackoffice?.assignedRoles ?? [],
                createdAt: userBackoffice?.createdAt ?? '-',
                email: userBackoffice.email,
                enabled: userBackoffice?.enabled ?? false,
                id: userBackoffice.id,
                lastLogin: userBackoffice.lastLogin,
                lastName: userBackoffice.lastName,
                name: userBackoffice.name,
                updatedAt: userBackoffice?.updatedAt ?? '-',
                username: userBackoffice.username,
            } as BackofficeUsersDefinition;
        }),
    };
};

export const userBackofficeProfileAdapter = (data: GetBackofficeUserProfileResponse) => {
    return {
        name: data.name,
        lastName: data.lastName,
        email: data.email,
        userName: data.username,
        assignedRoles: data.assignedRoles,
        id: data.id,
    } as BackofficeUserProfileDefinition;
};
