import { Box, styled } from '@mui/material';

export default {
    UsersBackofficeDetail: styled(Box)(({ theme }) => ({
        borderRadius: '0.5rem',
        boxShadow: '4px 5px 36px -9px rgba(0, 0, 0, 0.6)',
        marginTop: '1rem',
        padding: '1rem',
    })),
    DetailTitle: styled(Box)(({ theme }) => ({
        display: 'flex',
        fontSize: '1.2rem',
        marginBottom: '1.5rem',
    })),
    Label: styled(Box)(({ theme }) => ({
        marginRight: '0.5em',
        fontWeight: 'bold',
    })),
};
