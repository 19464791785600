/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {
    ExtraQueryFiltersProps,
    GridHookParams,
} from 'types/common/CommonGrid/CommonGridFormModal.types';
import { useCommonGrid } from 'context/common/commonGrid.context';
import useBreakpoints from '../breakpoints.hooks';

const useCustomFetch = (
    useGetData: (params: GridHookParams) => any,
    canDisable: boolean,
    clientSidePagination: boolean,
    adjustHeight: number,
    canSearch: boolean,
    rowHeight: number,
    extraFilters?: Array<ExtraQueryFiltersProps>,
    defaultSortFieldId?: string,
    defaultSortAsc?: boolean,
    backTableId?: number,
) => {
    const { showDisabled, setNeedRefresh, needRefresh } = useCommonGrid();
    const { matchesSm } = useBreakpoints();
    const pageSize: number =
        Math.ceil((window.innerHeight - adjustHeight - (matchesSm ? 350 : 300)) / rowHeight) - 1;
    const [page, setPage] = React.useState<number>(1);
    const [searchText, setSearchText] = React.useState('');
    const [orderField, setOrderField] = React.useState(defaultSortFieldId);
    const [sortOrder, setSortOrder] = React.useState<'ASC' | 'DESC'>(
        defaultSortAsc ? 'ASC' : 'DESC',
    );
    const [filterHeaderString, setFilterHeaderString] = React.useState<string>('');
    const [showHeaderFilters, setShowHeaderFilters] = React.useState(false);

    const filterQueryString = React.useMemo(() => {
        let extraQueryFilters = '';
        if (!clientSidePagination)
            extraQueryFilters = `&page=${page}&pageSize=${pageSize}&sortField=${orderField}&sort=${sortOrder}`;
        if (canDisable) extraQueryFilters += `&showDisabled=${showDisabled}`;
        if (canSearch) extraQueryFilters += `&searchText=${searchText}`;
        extraQueryFilters += filterHeaderString;

        extraFilters?.forEach((element: ExtraQueryFiltersProps) => {
            extraQueryFilters += `&${element.query}=${element.queryValue}`;
        });

        return extraQueryFilters;
    }, [
        page,
        pageSize,
        orderField,
        sortOrder,
        showDisabled,
        extraFilters,
        searchText,
        filterHeaderString,
    ]);

    const {
        data: dataFromQuery,
        isLoading: loading,
        refetch,
    } = useGetData({
        filterQueryString,
        extraFilters: extraFilters ?? [],
        forceDisable: false,
        backTableId,
    } as GridHookParams);

    const [data, totalRows] = React.useMemo(() => {
        if (dataFromQuery)
            return [dataFromQuery?.data ?? dataFromQuery, dataFromQuery?.total_count];
        else return [[], 0];
    }, [dataFromQuery]);

    const handlePageChange = (pag: number) => {
        setPage(pag);
    };

    const handleSort = (column: any, sortDirection: string) => {
        if (column?.sortField !== undefined) {
            setOrderField(column.sortField);
        }
        setSortOrder(sortDirection === 'desc' ? 'DESC' : 'ASC');
    };

    React.useEffect(() => {
        if (needRefresh) {
            setNeedRefresh(false);
            refetch();
        }
    }, [needRefresh]);
    return {
        data,
        handlePageChange,
        handleSort,
        loading,
        pageSize,
        setSearchText,
        totalRows,
        refetch,
        filterHeaderString,
        setFilterHeaderString,
        showHeaderFilters,
        setShowHeaderFilters,
    };
};

export default useCustomFetch;
