import { Box, Stack, styled, Typography } from '@mui/material';
import { styled as styledMui } from '@mui/material/styles';

export default {
    MarketDollarQuotesForm: styledMui(Box)(({ theme }) => ({
        padding: '1em',
        borderRadius: '0.5em',
        gap: '8px',
        height: 'auto',
        maxWidth: '100%',
        marginTop: '1em',
    })) as typeof Box,

    ActionSectionForm: styledMui(Stack)(({ theme }) => ({
        display: 'flex',
        justifyContent: 'end',
        marginTop: '1.3em',
    })) as typeof Stack,

    OptionContainer: styled('li')(({ theme }) => ({})),

    OptionTitle: styled(Typography)(({ theme }) => ({
        fontWeight: '500',
    })),

    ErrorText: styledMui(Typography)(({ theme }) => ({
        color: theme.palette.error.main,
        fontSize: '0.8em',
    })) as typeof Typography,
};
