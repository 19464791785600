import React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import FileUploader from './FileUploader';
import { FormikErrors, FormikValues } from 'formik';
import { useTranslation } from 'react-i18next';
import { useCreateDocument, useGetFiles } from 'hooks/api/utils.hooks';
import PdfIcon from 'assets/img/general/pdfFile.svg?react';
import TXTFileIcon from 'assets/img/general/txtFile.svg?react';
import DocFileIcon from 'assets/img/general/docFile.svg?react';
import JPGFileIcon from 'assets/img/general/jpgFile.svg?react';
import { PresignedUrlData } from 'types/utils/utils.api.types';
import axios from 'axios';
import { tErrorsContext } from 'constants/appConstants';

const FileUploaderContainer = (props: Props) => {
    const { values, field, setFieldValue, setDisableButton, accept, errors, isEdit } = props;
    const [file, setFile] = React.useState<File | null>(null);
    const [openDocumentReader, setOpenDocumentReader] = React.useState<boolean>(false);
    const [formatError, setFormatError] = React.useState<string | null>(null);
    const [presignedUrls, setPresignedUrls] = React.useState<{
        [key: string]: PresignedUrlData;
    } | null>(null);
    const { t } = useTranslation();
    const { fileUrl, isLoading: isLoadingFetch } = useGetFiles(
        isEdit ? !!values[field] : false,
        values[field],
    );

    const extensionsMapper: { [key: string]: string } = {
        'text/csv': 'csv',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
        'application/pdf': 'pdf',
        'text/plain': 'txt',
        'image/*': 'jpg, png',
    };

    const fileExtension = React.useMemo(() => {
        if (file) {
            if (file.type.includes('image')) return file.type.split('/')[0];
            return file.type.split('/')[1];
        } else if (fileUrl) {
            const route = fileUrl?.split('?')[0];
            const key = route.split('/').pop() ?? '';
            const res = key?.split('.').pop() ?? 'default';
            if (extensionsMapper['image/*'].includes(res)) return 'image';
            return res;
        }
        return 'default';
    }, [file, fileUrl]);

    const fileIconMapper = {
        pdf: PdfIcon,
        txt: TXTFileIcon,
        image: JPGFileIcon,
        default: DocFileIcon,
    };

    const validExtensions = React.useMemo(() => {
        let formattedString = '';
        accept?.split(',').forEach((mimeType: string) => {
            formattedString += `${extensionsMapper[mimeType]}, `;
        });
        formattedString = formattedString.slice(0, -2);
        return formattedString;
    }, [accept]);

    const config = {
        onSuccess: async (response: any) => {
            const { presignedUrls } = response.data;
            setPresignedUrls(presignedUrls);
        },
    };

    const { mutate: createDocument, isLoading, error: apiError } = useCreateDocument(config);
    const handleFileSelectorChange = async (e: any) => {
        setFormatError('');
        if (accept) {
            const allowedTypes = accept.split(',').map(x => x.trim());
            const fileType = e.target.files[0].type ?? '';
            if (
                !allowedTypes.includes(fileType) &&
                !allowedTypes.includes(fileType.split('/')[0] + '/*')
            ) {
                setFormatError(t('file_uploader_invalid_format', tErrorsContext) ?? '');
                return;
            }
        }
        setFile(e.target.files[0]);
        const index = file?.name.indexOf('.');
        setDisableButton && setDisableButton(true);
        createDocument({
            uploadFilesNames: [
                e.target.files[0].name.slice(0, index).replaceAll(' ', '_').toLowerCase(),
            ],
        });
    };

    const cleanerFunction = () => {
        setFile(null);
        setFieldValue(field, null);
        setFormatError(null);
    };

    const handleOpenDocumentReader = () => setOpenDocumentReader(true);

    const handleCloseDocumentReader = () => setOpenDocumentReader(false);

    const handleUploadFile = (
        file: File,
        presignedUrls: {
            [key: string]: PresignedUrlData;
        },
    ) => {
        Object.keys(presignedUrls).forEach(async presignedUrl => {
            const { url, fields }: PresignedUrlData = presignedUrls[presignedUrl];
            const formData = new FormData();
            formData.append('key', fields.key);
            formData.append('AWSAccessKeyId', fields.AWSAccessKeyId);
            formData.append('x-amz-security-token', fields['x-amz-security-token']);
            formData.append('policy', fields.policy);
            formData.append('signature', fields.signature);
            file && formData.append('file', file);

            setFieldValue(field, fields.key);
            await axios.post(url, formData);
        });
        setDisableButton && setDisableButton(false);
    };

    const getFileNameFromFileUrl = (fileUrl: string) => {
        const route = fileUrl?.split('?')[0];
        return route.split('/').pop() ?? '';
    };

    React.useEffect(() => {
        if (presignedUrls && file) handleUploadFile(file, presignedUrls);
    }, [file, presignedUrls]);

    const childProps = {
        ...props,
        t,
        openDocumentReader,
        handleCloseDocumentReader,
        handleOpenDocumentReader,
        isLoading: isLoading || isLoadingFetch,
        cleanerFunction,
        fileName: file?.name ?? '',
        handleFileSelectorChange,
        error: formatError || (errors[field] as string) || apiError,
        fileIconMapper,
        validExtensions,
        file,
        fileUrl,
        fileExtension,
        canOpenPdfReader: ['pdf'].includes(fileExtension),
        canOpenImgReader: ['image'].includes(fileExtension),
        filename: file?.name ?? getFileNameFromFileUrl(fileUrl ?? ''),
    };

    return <FileUploader {...childProps} />;
};

const propTypes = {
    field: PropTypes.string.isRequired,
    isEdit: PropTypes.bool.isRequired,
};

const defaultProps = {
    field: 'filePath',
    isEdit: false,
};

interface extraProps {
    values: FormikValues;
    errors: FormikErrors<FormikValues>;
    setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined,
    ) => void | Promise<void> | Promise<FormikErrors<FormikValues>>;
    displayTitle?: boolean;
    displayCleanerFunction?: boolean;
    setDisableButton?: (x: boolean) => void;
    accept?: string;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
FileUploaderContainer.propTypes = propTypes;
FileUploaderContainer.defaultProps = defaultProps;

export default FileUploaderContainer;
