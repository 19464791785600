import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CommonGrid from 'components/common/CommonGrid';
import {
    ActionColumn,
    HeaderFiltersProps,
} from 'types/common/CommonGrid/CommonGridFormModal.types';
import { CommonGridColumnsProps } from 'types/common/CommonGrid/CommonGridColumnProps.types';
import { useFundings } from 'hooks/api/channelsFundings.hooks';
import { FundingApi } from 'types/channelsFundings/channelsFundings.api.types';

const ChannelsFundingPage = (props: Props) => {
    const { t, columns, actionColumns, headerFilters, refreshTable, SnackBar } = props;
    return (
        <>
            <CommonGrid
                title={t('funding_list_title')}
                columns={columns}
                resource="channels_funding"
                useGetData={useFundings}
                defaultSortFieldId="createdAt"
                canExport
                customActionColumns={actionColumns}
                headerFilters={headerFilters}
                refreshTable={refreshTable}
            />
            <SnackBar />
        </>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

const defaultProps = {};

interface extraProps {
    columns: CommonGridColumnsProps<FundingApi>[];
    actionColumns: ActionColumn[];
    headerFilters?: HeaderFiltersProps[];
    refreshTable: boolean;
    SnackBar: any;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ChannelsFundingPage.propTypes = propTypes;
ChannelsFundingPage.defaultProps = defaultProps;

export default ChannelsFundingPage;
