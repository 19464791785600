import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import { ClientActivity } from 'types/clients/clients.types';
import { formatDateWithHour } from 'utils/helpers/dateHelper';
import AlertNoData from 'components/common/AlertNoData';
import { tChannelsCommon } from 'constants/appConstants';

const ClientSectionActivity = ({ prospectHistory, t }: Props) => (
    <>
        {prospectHistory?.length > 0 ? (
            <TableContainer component={Paper}>
                <Table aria-label="user's exception table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('date', tChannelsCommon)}</TableCell>
                            <TableCell>{t('operator', tChannelsCommon)}</TableCell>
                            <TableCell>{t('status', tChannelsCommon)}</TableCell>
                            <TableCell>{t('reason', tChannelsCommon)}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {prospectHistory.map(
                            ({ status, reason, updatedAt, changedByUser }, index: number) => (
                                <TableRow key={index}>
                                    <TableCell>{formatDateWithHour(updatedAt)}</TableCell>
                                    <TableCell>{changedByUser}</TableCell>
                                    <TableCell>{t(status)}</TableCell>
                                    <TableCell>{reason}</TableCell>
                                </TableRow>
                            ),
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        ) : (
            <AlertNoData title={t('no_data_was_found')} />
        )}
    </>
);

const propTypes = {
    t: PropTypes.func.isRequired,
};

const defaultProps = {};

interface extraProps extends ClientActivity {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ClientSectionActivity.propTypes = propTypes;
ClientSectionActivity.defaultProps = defaultProps;

export default ClientSectionActivity;
