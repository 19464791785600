import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CircularSpinner from './CircularSpinner';

const CircularSpinnerContainer = (props: Props) => {
    // const {} = props;

    const childProps = {
        ...props,
    };

    return <CircularSpinner {...childProps} />;
};

const propTypes = {};

const defaultProps = {};

interface extraProps {
    size?: number;
    color?:
        | 'inherit'
        | 'primary'
        | 'secondary'
        | 'error'
        | 'info'
        | 'success'
        | 'warning'
        | undefined;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CircularSpinnerContainer.propTypes = propTypes;
CircularSpinnerContainer.defaultProps = defaultProps;

export default CircularSpinnerContainer;
