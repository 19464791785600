import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './HelmetLayout.styled';
import { Outlet, useLocation } from 'react-router';
import { V_PROJECT_HEAD } from 'constants/envVarConstants';

const HelmetLayout = (props: Props) => {
    const { withOutlet, t } = props;
    const location = useLocation();
    const titleTag = location.pathname.substring(1).replace('-', ' ').replace('/', ' ');
    const index = titleTag.indexOf(' ');
    const objKey = index === -1 ? titleTag : titleTag.slice(0, index);
    // const title = CapitalizeFirstLetter(titleTag);
    const title = titleTag;
    // const { headLogo } = useExportAssets();

    return (
        <>
            {/* <Helmet> */}
            {/* <link rel="icon" type="image/svg+xml" href={headLogo} /> */}
            <link rel="icon" type="image/svg+xml" />
            <title>
                {V_PROJECT_HEAD} | {title}
            </title>
            <meta property="og:title" name={title} content={t('layout_helmet_title')} />
            {/* </Helmet> */}
            {withOutlet && <Outlet />}
        </>
    );
};

const propTypes = {
    withOutlet: PropTypes.bool,
    t: PropTypes.func.isRequired,
};

const defaultProps = {
    withOutlet: true,
};

interface extraTypes {}

interface Props extends InferPropsExtended<typeof propTypes, extraTypes> {}
HelmetLayout.propTypes = propTypes;
HelmetLayout.defaultProps = defaultProps;

export default HelmetLayout;
