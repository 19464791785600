import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './SidebarLayout.styled';
import Drawer from './Drawer';
import AppBar from './AppBar';

const SidebarLayout = (props: Props) => {
    const {
        isOpen,
        drawerWidth,
        matchesMd,
        matchesSm,
        menuCollapsed,
        selectedMenu,
        setIsOpen,
        setMenuCollapsed,
    } = props;

    const DrawerComponent = () => (
        <Drawer
            open={!matchesMd ? !menuCollapsed || isOpen : isOpen}
            drawerWidth={drawerWidth}
            menuCollapsed={menuCollapsed}
            setIsOpen={setIsOpen}
            setMenuCollapsed={setMenuCollapsed}
            selectedMenu={selectedMenu}
        />
    );

    const MobileLayout = () => (
        <St.SidebarMobileLayoutMain
            component="main"
            width={matchesSm ? '100%' : `calc(100% - ${drawerWidth}px)`}>
            <>
                <AppBar setIsOpen={setIsOpen} />
                <DrawerComponent />
            </>
        </St.SidebarMobileLayoutMain>
    );

    const DesktopLayout = () => (
        <St.SidebarLayoutNav component="nav" aria-label="mailbox folders">
            <DrawerComponent />
        </St.SidebarLayoutNav>
    );

    return (
        <St.SideBarLayoutContainer
            component="aside"
            data-testid="sidebar-aside"
            width={!matchesMd ? drawerWidth : ''}>
            {!matchesMd ? <DesktopLayout /> : <MobileLayout />}
        </St.SideBarLayoutContainer>
    );
};

const propTypes = {
    isOpen: PropTypes.bool.isRequired,
    drawerWidth: PropTypes.number.isRequired,
    matchesMd: PropTypes.bool.isRequired,
    matchesSm: PropTypes.bool.isRequired,
    menuCollapsed: PropTypes.bool.isRequired,
    setIsOpen: PropTypes.func.isRequired,
    setMenuCollapsed: PropTypes.func.isRequired,
    selectedMenu: PropTypes.string.isRequired,
};

const defaultProps = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
SidebarLayout.propTypes = propTypes;
SidebarLayout.defaultProps = defaultProps;

export default SidebarLayout;
