import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { useTranslation } from 'react-i18next';
import ChannelsDDJJCellInfo from './ChannelsDDJJCellInfo';
import { AffidavitObject } from 'types/channelsAffidavit/channelsAffidavit.types';

const ChannelsDDJJCellInfoContainer = (props: Props) => {
    const { t } = useTranslation('common');

    const childProps = {
        ...props,
        t,
    };

    return <ChannelsDDJJCellInfo {...childProps} />;
};

const propTypes = {};

const defaultProps = {};

interface extraProps {
    declaration?: AffidavitObject | null;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ChannelsDDJJCellInfoContainer.propTypes = propTypes;
ChannelsDDJJCellInfoContainer.defaultProps = defaultProps;

export default ChannelsDDJJCellInfoContainer;
