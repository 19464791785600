import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ClientDeleteAccountRequestPage from './ClientDeleteAccountRequestPage';
import { useTranslation } from 'react-i18next';
import { ActionColumn } from 'types/common/CommonGrid/CommonGridFormModal.types';
import { CommonGridColumnsProps } from 'types/common/CommonGrid/CommonGridColumnProps.types';
import { DeleteAccountRequest } from 'types/clientDeleteAccountRequest/clientDeleteAccountRequest.types';
import ClientAcceptDeleteAccount from './ClientAcceptDeleteAccount';
import { formatDateWithHour } from 'utils/helpers/dateHelper';
import { Chip } from '@mui/material';
import { PENDING, tCommon } from 'constants/appConstants';
import CustomIconSelector from 'components/common/CustomIconSelector';
import ClientDeleteOrRejectAccontRequestForm from './DeleteOrRejectClientAccountRequestForm';
import { getFullName, getMarketAccounts, getColorByStatus } from 'utils/helpers/commonHelper';
import { User } from 'types/users/users.api.types';
import BankAccountsDetails from 'components/common/BankAccountsDetails';
import { useGetActionsReestrictions } from 'hooks/common/roles.hooks';
import {
    DELETE_APP_USERS_REMOVE_REQUESTS,
    UPDATE_APP_USERS_REMOVE_REQUESTS,
} from 'constants/abilities.constants';

const ClientDeleteAccountRequestPageContainer = (props: Props) => {
    const { t } = useTranslation('clientDeleteAccountRequest');
    const { isHiddenByAbility } = useGetActionsReestrictions();

    const columns: CommonGridColumnsProps<DeleteAccountRequest>[] = [
        {
            id: 'requestDate',
            name: t('delete_account_request_list_creation_date_column'),
            selector: row => formatDateWithHour(row.requestDate),
            sortField: 'requestDate',
            sortable: true,
            center: true,
        },
        {
            id: 'user',
            name: t('delete_account_request_list_customer_column'),
            selector: ({ user }) => getFullName(user as User),
            sortField: 'user',
            sortable: false,
            center: true,
        },
        {
            id: 'customerCode',
            name: t('delete_account_request_list_customer_number_column'),
            selector: ({ user: { marketAccounts } }) =>
                marketAccounts?.length > 0 ? getMarketAccounts(marketAccounts) : '-',
            sortField: 'customerCode',
            sortable: false,
            center: true,
        },
        {
            id: 'docNumber',
            name: t('delete_account_request_list_document_number_column'),
            selector: ({ user: { info } }) => info?.docNumber || '-',
            sortField: 'docNumber',
            sortable: false,
            center: true,
        },
        {
            id: 'status',
            name: t('delete_account_request_list_state_column'),
            selector: row => row.status,
            cell: row => (
                <Chip
                    sx={{ m: 0.625, minWidth: '100%', ...getColorByStatus(row.status) }}
                    label={t(row.status, tCommon)}
                />
            ),
            sortField: 'status',
            sortable: true,
            center: true,
        },
        {
            id: 'motive',
            name: t('delete_account_request_list_reason_column'),
            selector: row => t(`delete_account_request_reason_${row?.motive}`) as string,
            sortField: 'motive',
            sortable: true,
            center: true,
            wrap: true,
        },
        {
            id: 'comment',
            name: t('delete_account_request_list_comment_column'),
            selector: row => row.comment,
            sortField: 'comment',
            sortable: true,
            center: true,
        },
    ];

    const actionColumns: ActionColumn[] = [
        {
            id: 'detail',
            icon: (props: any) => <CustomIconSelector type="detail" sx={props.sx} />,
            component: (props: any) => (
                <BankAccountsDetails id={props.row.user.id} showBankDetails />
            ),
            width: 'xl',
        },
        {
            id: 'approve',
            width: 'md',
            icon: (props: any) => <CustomIconSelector type="approve" sx={props.sx} />,
            isHidden: ({ status }) =>
                status !== PENDING && !isHiddenByAbility(UPDATE_APP_USERS_REMOVE_REQUESTS),
            component: (props: any) => <ClientAcceptDeleteAccount {...props} />,
        },
        {
            id: 'reject',
            icon: (props: any) => <CustomIconSelector type="reject" sx={props.sx} />,
            isHidden: ({ status }) =>
                status !== PENDING && !isHiddenByAbility(UPDATE_APP_USERS_REMOVE_REQUESTS),
            component: (props: any) => <ClientDeleteOrRejectAccontRequestForm {...props} />,
        },
        {
            id: 'delete_bin',
            icon: (props: any) => <CustomIconSelector type="delete" sx={props.sx} />,
            isHidden: ({ status }) =>
                status !== PENDING && !isHiddenByAbility(DELETE_APP_USERS_REMOVE_REQUESTS),
            component: (props: any) => (
                <ClientDeleteOrRejectAccontRequestForm isDelete {...props} />
            ),
        },
    ];

    const childProps = {
        ...props,
        t,
        columns,
        actionColumns,
    };

    return <ClientDeleteAccountRequestPage {...childProps} />;
};

const propTypes = {};

const defaultProps = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ClientDeleteAccountRequestPageContainer.propTypes = propTypes;
ClientDeleteAccountRequestPageContainer.defaultProps = defaultProps;

export default ClientDeleteAccountRequestPageContainer;
