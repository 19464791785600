import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CustomTextEditor from './CustomTextEditor';
import { useRef } from 'react';

const CustomTextEditorContainer = (props: Props) => {
    const editorRef = useRef(null);
    const config = {
        defaultMode: 1,
        askBeforePasteHTML: false,
        askBeforePasteFromWord: false,
        spellcheck: true,
        readOnly: false,
        hidePoweredByJodit: true,
        buttons:
            'bold,italic,underline,strikethrough,eraser,ul,ol,font,fontsize,paragraph,lineHeight,spellcheck,link,indent,outdent,left,source',
        buttonsMD:
            'bold,italic,underline,strikethrough,eraser,ul,ol,font,fontsize,paragraph,lineHeight,spellcheck,link,indent,outdent,left,source',
        placeholder: props?.placeholder,
        height: props?.height || 300,
    };

    const childProps = {
        editorRef,
        config,
        ...props,
    };

    return <CustomTextEditor {...childProps} />;
};

const propTypes = {
    value: PropTypes.string,
    placeholder: PropTypes.string,
};

const defaultProps = {
    placeholder: '',
};

interface extraProps {
    value: string;
    onBlur(val: string): void;
    placeholder?: string;
    height?: number;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CustomTextEditorContainer.propTypes = propTypes;
CustomTextEditorContainer.defaultProps = defaultProps;

export default CustomTextEditorContainer;
