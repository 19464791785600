import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import * as Yup from 'yup';
import { FormikConfig, FormikValues, useFormik } from 'formik';
import mainLogo from 'assets/img/general/consultatio_big_logo.svg?react';
import LoginPage from './LoginForm';
import { useLogin } from 'hooks/api/auth.hooks';
import { LoginRequest } from 'types/auth/auth.api.types';
import { useTranslation } from 'react-i18next';
import { tRequiredFieldError } from 'constants/appConstants';
import useSnackBar from 'hooks/common/snackbar.hooks';
import { useLocation } from 'react-router-dom';

// ---------------------------------------------//
// ------------------- FORMIK ------------------//
// ---------------------------------------------//

const getInitialValues = () => ({
    username: '',
    password: '',
});

const getValidationSchema = () =>
    Yup.lazy(() =>
        Yup.object().shape({
            username: Yup.string().required(tRequiredFieldError).trim(),
            password: Yup.string().required(tRequiredFieldError),
        }),
    );

// ---------------------------------------------//
// ---------------------------------------------//
// ---------------------------------------------//

const LoginFormContainer = (props: Props) => {
    const { isLoading, mutate: onLogin, errorMessage: apiError, isError } = useLogin();
    const { t } = useTranslation('login');
    const location = useLocation();
    const { SnackBar, setSnackBarMessage } = useSnackBar();

    React.useEffect(() => {
        if (location.state && location.state.isPasswordChanged) {
            setSnackBarMessage(t('password_changed_message'));
        }
        if (location.state && location.state.verifiedEmail) {
            setSnackBarMessage(t('email_verified_message'));
        }
        return () => {
            window.history.replaceState({}, document.title);
        };
    }, [location, setSnackBarMessage, t]);

    const handleSubmit = React.useCallback(
        async (values: FormikValues) => {
            onLogin({
                authUser: values.username.trim(),
                authSecret: values.password,
            } as LoginRequest);
        },
        [onLogin],
    );

    const formikInitProps = React.useMemo(
        () =>
            ({
                initialValues: getInitialValues(),
                validateOnChange: false,
                validateOnBlur: false,
                validateOnMount: false,
                validationSchema: getValidationSchema(),
                enableReinitialize: true,
                onSubmit: handleSubmit,
            }) as FormikConfig<FormikValues>,
        [handleSubmit],
    );

    const formik = useFormik(formikInitProps);

    const childProps = {
        ...props,
        t,
        mainLogo,
        isLoading,
        formik,
        apiError,
        isError,
        SnackBar,
    };

    return <LoginPage {...childProps} />;
};

const propTypes = {};

const defaultProps = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
LoginFormContainer.propTypes = propTypes;
LoginFormContainer.defaultProps = defaultProps;

export default LoginFormContainer;
