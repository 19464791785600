import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CustomAutoComplete from './CustomAutoComplete';

const CustomAutoCompleteContainer = (props: Props) => {
    // const {} = props;

    const childProps = {
        ...props,
    };

    return <CustomAutoComplete {...childProps} />;
};

const propTypes = {
    isLoading: PropTypes.bool.isRequired,
};

const defaultProps = {
    multiple: false,
    filterSelectedOptions: false,
    isLoading: false,
};

interface extraProps {
    multiple?: boolean;
    id: string;
    value: any;
    options: any[];
    filterSelectedOptions?: boolean;
    onChange: (e: any, value: any) => void;
    onInputChange?: (e: any, value: any) => void;
    placeholder?: string;
    label?: string;
    helperText?: string;
    error?: string;
    renderOption: (props: any, option: any, { selected }: any) => React.ReactNode;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CustomAutoCompleteContainer.propTypes = propTypes;
CustomAutoCompleteContainer.defaultProps = defaultProps;

export default CustomAutoCompleteContainer;
