import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import EditClientModal from './EditClientModal';
import { useTranslation } from 'react-i18next';
import {
    InitialGeneralValuesToEdit,
    InitialContactValuesToEdit,
} from 'types/clients/clients.types';
import { AlertColor } from '@mui/material';

const EditClientModalContainer = (props: Props) => {
    const { setOpenModalEdit } = props;
    const { t } = useTranslation('clients');

    const handleClose = () => setOpenModalEdit({ show: false, section: '' });

    const childProps = {
        ...props,
        handleClose,
        t,
    };

    return <EditClientModal {...childProps} />;
};

const propTypes = {};

const defaultProps = {};

interface extraProps {
    id: string;
    initialValues: InitialGeneralValuesToEdit & InitialContactValuesToEdit;
    openModalEdit: { show: boolean; section: string };
    isProspect?: boolean;
    setOpenModalEdit(openModalEdit: { show: boolean; section: string }): void;
    setSnackBarMessage: (msj: string, sever?: AlertColor) => void;
    refetch: () => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
EditClientModalContainer.propTypes = propTypes;
EditClientModalContainer.defaultProps = defaultProps;

export default EditClientModalContainer;
