import { InstrumentObj, InstrumentObjApi } from 'types/common/instrument.types';

export const instrumentsAdapter = (data: InstrumentObjApi[]) => {
    return data
        ?.filter(instrument => !!instrument.tickers.length)
        .map((instrument: InstrumentObjApi) => {
            const arsTicker = instrument.tickers.find(ticker => ticker.currency === 'ARS');
            return {
                id: instrument.id,
                name: arsTicker ? arsTicker.ticker : instrument?.tickers[0]?.ticker,
            } as InstrumentObj;
        });
};
