import { styled as styledMui } from '@mui/material/styles';
import { Box, Stack, styled, Typography } from '@mui/material';

export default {
    Box: styled(Box)(({ theme }) => ({})),
    MarketnonWorkingDayForm: styledMui(Box)(({ theme }) => ({
        padding: '1em',
        borderRadius: '0.5em',
        gap: '8px',
        height: 'auto',
        maxWidth: '100%',
        // boxShadow: '4px 5px 36px -9px rgba(0, 0, 0, 0.6)',
        marginTop: '1em',
    })) as typeof Box,
    ActionSectionForm: styledMui(Stack)(({ theme }) => ({
        display: 'flex',
        justifyContent: 'end',
        marginTop: '1.3em',
    })) as typeof Stack,
    OptionContainer: styled('li')(({ theme }) => ({
        '&:hover': {
            backgroundColor: '#e0e0e0 !important',
        },
    })),
    OptionTitle: styled(Typography)(({ theme }) => ({
        fontWeight: '500',
    })),
    OptionDescription: styled(Typography)(({ theme }) => ({
        fontWeight: 'normal',
    })),
};
