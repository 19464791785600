import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './CustomDatePicker.styled';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import i18next from 'i18next';
import { Tprop } from 'types/common/general.types';
import { disableCurrentDate } from 'utils/helpers/dateHelper';
import CustomIconSelector from '../CustomIconSelector';
import defaultTheme from 'utils/theme/defaultTheme';

const CustomDatePicker = (props: Props) => {
    const {
        isReadOnly,
        lang,
        CustomToolbar,
        t,
        handlerDateSelector,
        placeholder,
        required,
        fieldValue,
        minDate,
        width,
        minWidth,
        errorMessage,
        handleClearDate,
        validDate,
        dateValue,
        disablePast,
        disableCurrentDay,
    } = props;

    return (
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={i18next.language}>
            <MobileDatePicker
                disabled={isReadOnly}
                inputFormat={lang === 'es' ? 'DD/MM/YYYY' : 'MM/DD/YYYY'}
                dayOfWeekFormatter={(day: string) => day.charAt(0).toUpperCase()}
                ToolbarComponent={() => CustomToolbar(dateValue as Date)}
                label={placeholder || `${t(fieldValue, { ns: 'date' })}${required ? ' * ' : ''}`}
                value={dateValue}
                minDate={minDate}
                disablePast={disablePast}
                shouldDisableDate={(date: any) =>
                    disableCurrentDay ? disableCurrentDate(date) : false
                }
                onChange={newValue => {
                    handlerDateSelector(newValue);
                }}
                renderInput={(params: any) => (
                    <St.InputField style={{ display: 'flex', alignItems: 'center' }}>
                        <St.TextField
                            {...params}
                            readOnly={isReadOnly}
                            width={width}
                            minwidth={minWidth}
                            fullWidth={!width}
                            size="small"
                            id="outlined-size-small"
                            variant="outlined"
                            error={!!errorMessage}
                            helperText={errorMessage}
                        />
                        {validDate && !isReadOnly && (
                            <St.CloseIconButton onClick={handleClearDate}>
                                <CustomIconSelector
                                    type="close"
                                    sx={{
                                        color: defaultTheme.palette.grey[500],
                                        width: 20,
                                        height: 20,
                                    }}
                                />
                            </St.CloseIconButton>
                        )}
                    </St.InputField>
                )}
            />
        </LocalizationProvider>
    );
};

const propTypes = {};

const defaultProps = {};

interface extraProps extends Tprop {
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
    fieldValue: string;
    isReadOnly?: boolean;
    minDate?: number | Date;
    errorMessage?: string;
    placeholder?: string;
    required?: boolean;
    width?: string;
    minWidth?: string;
    lang: string;
    CustomToolbar: (value: Date) => JSX.Element;
    handlerDateSelector: (newValue: any) => void;
    handleClearDate: () => void;
    validDate: boolean;
    dateValue: Date | string;
    disablePast?: boolean;
    disableCurrentDay?: boolean;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CustomDatePicker.propTypes = propTypes;
CustomDatePicker.defaultProps = defaultProps;

export default CustomDatePicker;
