import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CommonGrid from 'components/common/CommonGrid';
import {
    ActionColumn,
    HeaderFiltersProps,
} from 'types/common/CommonGrid/CommonGridFormModal.types';
import { CommonGridColumnsProps } from 'types/common/CommonGrid/CommonGridColumnProps.types';
import { useExtractions } from 'hooks/api/clientExtractions.hooks';
import { ExtractionsApi } from 'types/clientExtractions/clientExtractions.api.types';

const ClientExtractionsPage = (props: Props) => {
    const { t, columns, actionColumns, headerFilters } = props;
    return (
        <CommonGrid
            title={t('extractions_list_title')}
            columns={columns}
            resource="extractions"
            useGetData={useExtractions}
            defaultSortFieldId="entryDate"
            canExport
            customActionColumns={actionColumns}
            headerFilters={headerFilters}
        />
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

const defaultProps = {};

interface extraProps {
    columns: CommonGridColumnsProps<ExtractionsApi>[];
    headerFilters: HeaderFiltersProps[];
    actionColumns: ActionColumn[];
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ClientExtractionsPage.propTypes = propTypes;
ClientExtractionsPage.defaultProps = defaultProps;

export default ClientExtractionsPage;
