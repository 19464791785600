import { paginationAdapter } from 'adapters/common/pagination.adapter';
import {
    GetSettingsNonWorkingDaysResponse,
    SettingsNonWorkingDaysApi,
    GetMarketsResponse,
} from 'types/settingsNonWorkingDays/settingsNonWorking.api.types';
import {
    MarketOptions,
    SettingsNonWorkingDays,
} from 'types/settingsNonWorkingDays/settingsNonWorking.types';

export const SettingsNonWorkingDaysAdapter = (data: GetSettingsNonWorkingDaysResponse) => {
    return {
        ...paginationAdapter(data),
        data: data.items?.map((marketnonWorkingDay: SettingsNonWorkingDaysApi) => {
            return {
                id: marketnonWorkingDay.id,
                dateFrom: marketnonWorkingDay.dateFrom,
                dateTo: marketnonWorkingDay.dateTo,
                markets: marketnonWorkingDay.markets,
            } as SettingsNonWorkingDays;
        }),
    };
};

export const marketsAdapter = (data: GetMarketsResponse) => {
    return {
        // ...paginationAdapter(data),
        data: Array.isArray(data)
            ? data.map((market: MarketOptions) => {
                  return {
                      id: market.id,
                      name: market.name,
                      description: market.description,
                  } as MarketOptions;
              })
            : [],
    };
};
