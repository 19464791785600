import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import SettingsNonWorkingPage from './SettingsNonWorkingPage';
import { useTranslation } from 'react-i18next';
import { CommonGridColumnsProps } from 'types/common/CommonGrid/CommonGridColumnProps.types';
import { SettingsNonWorkingDays } from 'types/settingsNonWorkingDays/settingsNonWorking.types';
import {
    ActionColumn,
    ActionHeader,
    HeaderFiltersProps,
} from 'types/common/CommonGrid/CommonGridFormModal.types';
import CustomTableAction from 'components/common/CommonGrid/Extra/CustomTableAction';
import SettingsNonWorkingDaysForm from './SettingsNonWorkingDaysForm';
import { formatDate } from 'utils/helpers/dateHelper';
import St from './SettingsNonWorkingPage.styled';
import SettingsNonWorkingDaysDetail from './SettingsNonWorkingDaysDetail';
import { useGetMarkets } from 'hooks/api/settingsNonWorkingDays.hooks';
import CustomIconSelector from 'components/common/CustomIconSelector';
import { useGetActionsReestrictions } from 'hooks/common/roles.hooks';
import { UPDATE_DATES } from 'constants/abilities.constants';

const SettingsNonWorkingPageContainer = (props: Props) => {
    const { t } = useTranslation('SettingsNonWorkingDays');
    const { data: marketsList, isLoading } = useGetMarkets();
    const { isHiddenByAbility } = useGetActionsReestrictions();

    const columns: CommonGridColumnsProps<SettingsNonWorkingDays>[] = [
        {
            id: 'dateFrom',
            name: t('market_non_working_days_list_day_from_column'),
            selector: row => formatDate(row.dateFrom),
            sortField: 'dateFrom',
            sortable: true,
            center: true,
        },
        {
            id: 'dateTo',
            name: t('market_non_working_days_list_day_to_column'),
            selector: row => formatDate(row.dateTo),
            sortField: 'dateTo',
            sortable: true,
            center: true,
        },
        {
            id: 'market',
            name: t('market_non_working_days_list_market_column'),
            selector: row => row.markets.map(market => market.name).join(', '),
            cell: row => (
                <St.MarketBox>
                    {row.markets.length === 1 ? (
                        <St.MarketName>{row.markets[0].name}</St.MarketName>
                    ) : (
                        <St.MarketName>{`[ ... ]`}</St.MarketName>
                    )}
                </St.MarketBox>
            ),
            sortField: 'market',
            sortable: false,
            center: true,
        },
    ];

    const headerActions: ActionHeader[] = [
        {
            id: 'create',
            component: () => (
                <CustomTableAction
                    type="create"
                    Icon={props => <></>}
                    modalTitle={'market-non-working-days'}
                    component={props => <SettingsNonWorkingDaysForm {...props} />}
                />
            ),
            isHidden: isHiddenByAbility(UPDATE_DATES),
        },
    ];

    const actionColumns: ActionColumn[] = [
        {
            id: 'detail',
            icon: (props: any) => <CustomIconSelector type="detail" sx={props.sx} />,
            component: (props: any) => <SettingsNonWorkingDaysDetail {...props} />,
        },
        {
            id: 'edit',
            icon: (props: any) => <CustomIconSelector type="edit" sx={props.sx} />,
            component: (props: any) => <SettingsNonWorkingDaysForm isEdit {...props} />,
            isHidden: () => isHiddenByAbility(UPDATE_DATES),
        },
    ];

    const headerFilters: HeaderFiltersProps[] = [
        {
            id: 'dateFrom',
            label: t('market_non_working_days_list_day_from_column'),
            type: 'date',
            placeholder: t('market_non_working_days_modal_create_day_from_input_placeholder'),
            query: 'dateFrom',
        },
        {
            id: 'dateTo',
            label: t('market_non_working_days_list_day_to_column'),
            type: 'date',
            placeholder: t('market_non_working_days_modal_create_day_to_input_placeholder'),
            query: 'dateTo',
        },
        {
            id: 'market',
            query: 'marketId',
            label: t('market_non_working_days_list_market_column'),
            placeholder: t('market_non_working_days_modal_create_market_input_placeholder'),
            type: 'multiple',
            options: !isLoading ? marketsList?.data : [],
        },
    ];

    const childProps = {
        ...props,
        t,
        columns,
        headerActions,
        actionColumns,
        headerFilters,
    };

    return <SettingsNonWorkingPage {...childProps} />;
};

const propTypes = {};

const defaultProps = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
SettingsNonWorkingPageContainer.propTypes = propTypes;
SettingsNonWorkingPageContainer.defaultProps = defaultProps;

export default SettingsNonWorkingPageContainer;
