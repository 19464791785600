import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ForgotPasswordPage from './ForgotPasswordPage';

const ForgotPasswordPageContainer = (props: Props) => {
    // const {} = props;
    const [emailSent, setEmailSent] = React.useState('');

    const childProps = {
        ...props,
        emailSent,
        setEmailSent,
    };

    return <ForgotPasswordPage {...childProps} />;
};

const propTypes = {};

const defaultProps = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ForgotPasswordPageContainer.propTypes = propTypes;
ForgotPasswordPageContainer.defaultProps = defaultProps;

export default ForgotPasswordPageContainer;
