import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import EditClientAddressForm from './EditClientAddressForm';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { InitialContactValuesToEdit } from 'types/clients/clients.types';
import { useUpdateClientInfo, useUpdateProspectInfo } from 'hooks/api/clients.hooks';
import { useCallback } from 'react';
import { AlertColor } from '@mui/material';

// ---------------------------------------------//
// ------------------- FORMIK ------------------//
// ---------------------------------------------//

const getInitialValues = ({
    streetName,
    streetNum,
    addressExtra,
    floor,
    square,
    apartment,
    tower,
    sector,
    zipCode,
    city,
    state,
    country,
    contactId,
}: InitialContactValuesToEdit) => ({
    streetName: streetName || '',
    streetNum: streetNum || '',
    addressExtra: addressExtra || '',
    floor: floor || '',
    square: square || '',
    apartment: apartment || '',
    tower: tower || '',
    sector: sector || '',
    zipCode: zipCode || '',
    city: city || '',
    state: state || '',
    country: country || '',
    contactId: contactId || '',
});

const getValidationSchema = () =>
    Yup.object().shape({
        streetName: Yup.string(),
        streetNum: Yup.string(),
        addressExtra: Yup.string(),
        floor: Yup.string(),
        square: Yup.string(),
        apartment: Yup.string(),
        tower: Yup.string(),
        sector: Yup.string(),
        zipCode: Yup.string(),
        city: Yup.string(),
        state: Yup.string(),
        country: Yup.string(),
        contactId: Yup.string(),
    });

// ---------------------------------------------//
// ---------------------------------------------//
// ---------------------------------------------//

const EditClientAddressFormContainer = (props: Props) => {
    const { close, setSnackBarMessage, refetch } = props;
    const { t } = useTranslation('clients');
    const { mutate, isLoading } = useUpdateProspectInfo(setSnackBarMessage);
    const { mutate: mutateClient, isLoading: isLoadingClient } =
        useUpdateClientInfo(setSnackBarMessage);

    const handleSubmit = useCallback(async (data: any) => {
        if (props.isProspect) {
            mutate(
                { id: props.id, body: data },
                {
                    onSuccess: () => {
                        setSnackBarMessage(t('prospect_edited_successfully'));
                        close();
                        refetch();
                    },
                },
            );
        } else {
            mutateClient(
                { id: props.id, body: { contacts: [data] } },
                {
                    onSuccess: () => {
                        setSnackBarMessage(t('client_edited_successfully'));
                        close();
                        refetch();
                    },
                },
            );
        }
    }, []);

    const formikInitProps = React.useMemo(
        () => ({
            initialValues: getInitialValues(props.initialValues),
            validateOnChange: false,
            validateOnBlur: false,
            validateOnMount: false,
            validationSchema: getValidationSchema(),
            enableReinitialize: true,
            onSubmit: handleSubmit,
        }),
        [handleSubmit],
    );

    const formik = useFormik(formikInitProps);

    const childProps = {
        ...props,
        formik,
        t,
        disabledForm:
            isLoading ||
            isLoadingClient ||
            JSON.stringify(formik.values) === JSON.stringify(getInitialValues(props.initialValues)),
    };

    return <EditClientAddressForm {...childProps} />;
};

const propTypes = {
    isEdit: PropTypes.bool.isRequired,
};

const defaultProps = {
    isEdit: true,
};

interface extraProps {
    id: string;
    initialValues: InitialContactValuesToEdit;
    isProspect?: boolean;
    close: () => void;
    refetch: () => void;
    setSnackBarMessage: (msj: string, sever?: AlertColor) => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
EditClientAddressFormContainer.propTypes = propTypes;
EditClientAddressFormContainer.defaultProps = defaultProps;

export default EditClientAddressFormContainer;
