import { Typography, styled } from '@mui/material';

export default {
    GridTitle: styled(Typography)(({ theme }) =>
        theme.unstable_sx({
            fontFamily: `Roboto, -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI',
'Helvetica Neue', Arial, sans-serif`,
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '1.4rem',
            color: theme.palette.primary.main,
            letterSpacing: '0.05rem',
            alignSelf: 'center',
        }),
    ),
};
