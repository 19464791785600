import { paginationAdapter } from 'adapters/common/pagination.adapter';
import {
    ExchangeDefinition,
    GetExchangeDefinitionsResponse,
} from 'types/channelsExchange/channelsExchange.api.types';

export const exchangeDefinitionsAdapter = (data: GetExchangeDefinitionsResponse) => {
    return {
        ...paginationAdapter(data),
        data: data.items?.map((exchange: ExchangeDefinition) => {
            return {
                id: exchange.id,
                customerCode: exchange.customerCode,
                buySell: exchange.buySell,
                amount: exchange.amount,
                isManual: exchange.isManual,
                term: exchange.term,
                createdAt: exchange.createdAt,
                updatedAt: exchange.updatedAt,
                decisionAt: exchange.decisionAt,
                reason: exchange.reason,
                channel: exchange.channel,
                currencyStart: exchange.currencyStart,
                currencyEnd: exchange.currencyEnd,
                isMarketPrice: exchange.isMarketPrice,
                buyPrice: exchange.buyPrice,
                sellPrice: exchange.sellPrice,
                status: exchange.status,
                ticker: exchange.ticker,
                instrument: exchange.instrument,
                user: exchange.user,
                operator: exchange.operator,
                lastModified: exchange.lastModified,
                declaration: exchange.declaration,
                operatedBuyPrice: exchange.operatedBuyPrice,
                operatedSellPrice: exchange.operatedSellPrice,
                operatedAmount: exchange.operatedAmount,
            };
        }),
    };
};
