import * as React from 'react';
// import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ActionButton from './ActionButton';

const ActionButtonContainer = (props: Props) => {
    // const {} = props;

    const childProps = {
        ...props,
    };

    return <ActionButton {...childProps} />;
};

const propTypes = {};

const defaultProps = {
    size: 'small',
    color: 'primary',
    variant: 'contained',
    loading: false,
    loadingPosition: 'center',
    disabled: false,
    type: 'button',
};

interface extraProps {
    variant?: 'text' | 'outlined' | 'contained';
    size?: 'small' | 'medium' | 'large';
    color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
    type?: 'button' | 'submit' | 'reset';
    onClick?: () => void;
    // title: string;
    loading?: boolean;
    loadingPosition?: 'start' | 'center' | 'end';
    children: React.ReactNode;
    disabled?: boolean;
    sx?: Object;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ActionButtonContainer.propTypes = propTypes;
ActionButtonContainer.defaultProps = defaultProps;

export default ActionButtonContainer;
