import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { Divider, Grid, Typography } from '@mui/material';
import ChannelsModalActionButton from 'components/common/ChannelsModalActionButton';
import CustomInput from 'components/common/CustomInput';
import { tChannelsCommon } from 'constants/appConstants';
import RejectForm from 'components/common/RejectForm';
import ChannelsModalActions from 'components/common/ChannelsModalActions';
import { statesToShowActions } from 'utils/helpers/channelsCommonHelper';
import { AffidavitObject } from 'types/channelsAffidavit/channelsAffidavit.types';

const ChannelsExchangeDetailActions = ({
    id,
    status,
    action,
    rejectReason,
    isLoading,
    errorMessage,
    ticker,
    declaration,
    price,
    setPrice,
    handleAction,
    setRejectReason,
    handleSubmit,
    close,
    handleAcquireOrReleaseOrder,
    t,
}: Props) => (
    <>
        {statesToShowActions.includes(status) && (
            <>
                <Divider sx={{ width: '100%', mt: 1.25 }} />
                <ChannelsModalActionButton status={status} setAction={type => handleAction(type)} />
            </>
        )}
        <Grid container item direction="column" alignSelf="baseline" xs={6} spacing={2} ml={1.25}>
            {(action === 'approve' || action === 'edit') && (
                <Grid item container xs gap={2} direction="column">
                    <Typography fontSize={16} fontWeight={600}>
                        {t(action === 'approve' ? 'for_approval' : 'to_edit', tChannelsCommon)}
                    </Typography>
                    <Typography fontSize={14} fontWeight={600}>
                        Ticker: {ticker}
                    </Typography>
                    <CustomInput
                        label={t('operated_amount', tChannelsCommon)}
                        errors={{ price: errorMessage ?? undefined }}
                        field="operatedAmount"
                        values={{ operatedAmount: price.operatedAmount }}
                        setFieldValue={(field, value) => setPrice({ ...price, [field]: value })}
                    />
                    <CustomInput
                        label={t('operated_buy_price', tChannelsCommon)}
                        errors={{ price: errorMessage ?? undefined }}
                        field="buyPrice"
                        values={{ buyPrice: price.buyPrice }}
                        setFieldValue={(field, value) => setPrice({ ...price, [field]: value })}
                    />
                    <CustomInput
                        label={t('operated_sell_price', tChannelsCommon)}
                        errors={{ price: errorMessage ?? undefined }}
                        field="sellPrice"
                        values={{ sellPrice: price.sellPrice }}
                        setFieldValue={(field, value) => setPrice({ ...price, [field]: value })}
                    />
                </Grid>
            )}

            {action === 'reject' && (
                <RejectForm
                    setReason={setRejectReason}
                    reason={rejectReason}
                    errorMessage={errorMessage}
                />
            )}
        </Grid>
        <Divider sx={{ width: '100%', mt: 2 }} />

        <ChannelsModalActions
            id={id}
            type="MARKET"
            status={status}
            loading={isLoading}
            disabled={isLoading}
            declaration={declaration}
            close={close}
            handleSubmit={handleSubmit}
            handleAcquireOrReleaseOrder={handleAcquireOrReleaseOrder}
        />
    </>
);

const propTypes = {
    t: PropTypes.func.isRequired,
    setPrice: PropTypes.func.isRequired,
};

const defaultProps = {};

interface extraProps {
    id: string;
    status: string;
    action: string;
    ticker: string;
    isLoading: boolean;
    rejectReason?: string;
    price: { buyPrice: number; sellPrice: number; operatedAmount: number };
    errorMessage: string | null;
    declaration?: AffidavitObject;
    close(): void;
    handleAction(type: string): void;
    setRejectReason(rejectReason: string): void;
    handleSubmit(): void;
    handleAcquireOrReleaseOrder({ id, action }: { id: string; action: string }): void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ChannelsExchangeDetailActions.propTypes = propTypes;
ChannelsExchangeDetailActions.defaultProps = defaultProps;

export default ChannelsExchangeDetailActions;
