import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { Divider, Grid } from '@mui/material';
import ChannelsModalItemGrid from 'components/common/ChannelsModalItemGrid';
import ChannelsFundDetailActions from '../ChannelsFundDetailActions';
import { statesToShowActionsContainer } from 'utils/helpers/channelsCommonHelper';
import { tCommon } from 'constants/appConstants';
import { FundingApi } from 'types/channelsFundings/channelsFundings.api.types';
import { convertCurrencyFormat, getFullName } from 'utils/helpers/commonHelper';
import { formatDateWithHour } from 'utils/helpers/dateHelper';

const ChannelsFundDetail = ({
    row: {
        id,
        customerCode,
        amount,
        user,
        quantity,
        status,
        shareholderCode,
        isSimple,
        price,
        createdAt,
        channel,
        operator,
        lastModified,
        decisionAt,
        isTotal,
        reason,
        ticker,
    },
    handleAcquireOrReleaseOrder,
    close,
    t,
}: Props) => (
    <Grid container spacing={2} px={3} py={2} alignItems="center">
        <ChannelsModalItemGrid column={6} label={t('date')} text={formatDateWithHour(createdAt)} />
        <ChannelsModalItemGrid column={6} label={t('channel')} text={getFullName(channel)} />
        <ChannelsModalItemGrid column={6} label={t('customer_number')} text={customerCode} />
        <ChannelsModalItemGrid column={6} label={t('customer')} text={getFullName(user)} />
        <ChannelsModalItemGrid
            column={6}
            label={t('type')}
            text={isSimple ? 'Simple' : 'No simple'}
        />
        <ChannelsModalItemGrid column={6} label={t('instrument')} text={ticker?.ticker} />
        <ChannelsModalItemGrid
            column={6}
            label={t('amount')}
            text={convertCurrencyFormat(amount, ticker.currency)}
        />
        <ChannelsModalItemGrid column={6} label={t('quantity')} text={quantity} />
        <ChannelsModalItemGrid
            column={6}
            label={t('price')}
            text={convertCurrencyFormat(price, ticker.currency)}
        />
        <ChannelsModalItemGrid column={6} label={t('operator')} text={getFullName(operator)} />
        <ChannelsModalItemGrid
            column={6}
            label={t('is_total')}
            text={t(isTotal ? 'yes' : 'no', tCommon)}
        />

        {isSimple && (
            <ChannelsModalItemGrid
                column={6}
                label={t('shareholder_number')}
                text={shareholderCode}
            />
        )}

        {lastModified && decisionAt && (
            <ChannelsModalItemGrid
                column={12}
                label={t('modified')}
                text={`${getFullName(lastModified)} - ${formatDateWithHour(decisionAt)}`}
            />
        )}
        {reason && (
            <>
                <Divider sx={{ width: '100%', mt: 1.25 }} />
                <ChannelsModalItemGrid column={12} label={t('reason')} text={reason} />
            </>
        )}

        {statesToShowActionsContainer.includes(status) && (
            <ChannelsFundDetailActions
                id={id}
                status={status}
                handleAcquireOrReleaseOrder={handleAcquireOrReleaseOrder}
                close={close}
            />
        )}
    </Grid>
);

const propTypes = {
    t: PropTypes.func.isRequired,
};

const defaultProps = {};

interface extraProps {
    row: FundingApi;
    close(): void;
    handleAcquireOrReleaseOrder({ id, action }: { id: string; action: string }): void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ChannelsFundDetail.propTypes = propTypes;
ChannelsFundDetail.defaultProps = defaultProps;

export default ChannelsFundDetail;
