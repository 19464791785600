import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ClientExtractionsDetail from './ClientExtractionsDetail';
import { SortedBankAccount } from 'types/clientExtractions/clientExtractions.types';
import { useChangeExtractionStatus } from 'hooks/api/clientExtractions.hooks';
import { useTranslation } from 'react-i18next';
import { useGetActionsReestrictions } from 'hooks/common/roles.hooks';
import { ExtractionsApi } from 'types/clientExtractions/clientExtractions.api.types';
import { useEffect, useState } from 'react';
import { LOCAL_ACCOUNTS } from 'constants/appConstants';
import { sortBankAccounts } from 'utils/helpers/commonHelper';

const ClientExtractionsDetailContainer = (props: Props) => {
    const { row, close, setSnackBarMessage } = props;
    const { t } = useTranslation('clientExtractions');
    const { isHiddenByAbility } = useGetActionsReestrictions();

    const [rejectReason, setRejectReason] = useState('');
    const [actionSelector, setActionSelector] = useState<any>('');
    const [accountsInfo, setAccountInfo] = useState<SortedBankAccount | undefined>(
        {} as SortedBankAccount,
    );
    const { mutate } = useChangeExtractionStatus(setSnackBarMessage);

    const handleSubmit = () =>
        mutate(
            {
                id: row.id,
                action: actionSelector,
                body: {
                    operatorUuid: row.operatorUuid,
                    reason: rejectReason || undefined,
                },
            },
            {
                onSuccess: () => {
                    setSnackBarMessage(
                        actionSelector === 'reject'
                            ? t('extractions_reject_success')
                            : t('extractions_approved_success'),
                    );
                    close();
                },
            },
        );

    useEffect(() => {
        if (!LOCAL_ACCOUNTS.includes(row.bankAccount.type)) {
            // Si la cuenta es del exterior, ordenar las cuentas destino, broker o intermediarios
            const accounts = sortBankAccounts(row.bankAccount.bankAccountExtraData);
            setAccountInfo(accounts);
        }
    }, []);

    const childProps = {
        ...props,
        t,
        row,
        accountsInfo,
        rejectReason,
        setRejectReason,
        handleSubmit,
        actionSelector,
        setActionSelector,
        isHiddenByAbility,
        close,
    };

    return <ClientExtractionsDetail {...childProps} />;
};

const propTypes = {};

const defaultProps = {};

interface extraProps {
    row: ExtractionsApi;
    close: () => void;
    setSnackBarMessage: (message: string) => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ClientExtractionsDetailContainer.propTypes = propTypes;
ClientExtractionsDetailContainer.defaultProps = defaultProps;

export default ClientExtractionsDetailContainer;
