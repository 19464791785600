import { withFakeData } from 'api/fakedata';
import { axiosDataInstance } from 'api/axiosInstances';
import { AUTH_BASE_URL } from 'constants/appConstants';
import {
    CreateUsersBrokerRequest,
    EditUsersBrokerRequest,
    getBrokerUsersResponse,
} from 'types/usersBroker/usersBroker.api.types';

const apiName = 'usersBroker';

export default {
    getBrokerUsers: (filterQueryString: string) => {
        return withFakeData<getBrokerUsersResponse>(
            () =>
                axiosDataInstance.get(
                    `${AUTH_BASE_URL}/app_users/search?${filterQueryString}&userType=CHANNEL`,
                ),
            apiName,
            'usersBroker',
            false,
            500,
        );
    },
    enableBroker: ({ id }: { id: string }) =>
        withFakeData<{}>(
            () => axiosDataInstance.patch(`${AUTH_BASE_URL}/app_users/${id}/enable/`),
            apiName,
            'enable-usersBroker',
            false,
            500,
        ),
    disableBroker: ({ id }: { id: string }) =>
        withFakeData<{}>(
            () => axiosDataInstance.delete(`${AUTH_BASE_URL}/app_users/${id}/disable/`),
            apiName,
            'delete-usersBroker',
            false,
            500,
        ),
    createUsersBroker: (req: CreateUsersBrokerRequest) =>
        withFakeData<{}>(
            () => axiosDataInstance.post(`${AUTH_BASE_URL}/app_users/channel`, req),
            apiName,
            'create-usersBroker',
            false,
            500,
        ),
    editUsersBroker: (req: EditUsersBrokerRequest) => {
        const { id, ...requestData } = req;
        return withFakeData<{}>(
            () => axiosDataInstance.patch(`${AUTH_BASE_URL}/app_users/channel/${id}`, requestData),
            apiName,
            'edit-usersBroker',
            false,
            500,
        );
    },
};
