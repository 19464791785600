import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ChannelsOrdersDetail from './ChannelsOrdersDetail';
import { useTranslation } from 'react-i18next';
import { Order } from 'types/channelsOrders/channelsOrders.types';
import { AlertColor } from '@mui/material';
import { convertCurrencyFormat } from 'utils/helpers/commonHelper';

const ChannelsOrdersDetailContainer = (props: Props) => {
    const { t } = useTranslation('channelsCommon');

    const getPriceByMarketStatus = (isMarketPrice: boolean, price: number, currency?: string) => {
        if (isMarketPrice && price)
            return `${convertCurrencyFormat(price, currency)} (${t('isMarket', { ns: 'channelsOrders' })})`;
        return `${price > 0 ? convertCurrencyFormat(price, currency) : ''} ${isMarketPrice ? t('isMarket', { ns: 'channelsOrders' }) : ''}`;
    };

    const childProps = {
        ...props,
        getPriceByMarketStatus,
        t,
    };

    return <ChannelsOrdersDetail {...childProps} />;
};

const propTypes = {};

const defaultProps = {};

interface extraProps {
    row: Order;
    close(): void;
    setSnackBarMessage: (msj: string, sever?: AlertColor) => void;
    handleAcquireOrReleaseOrder({ id, action }: { id: string; action: string }): void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ChannelsOrdersDetailContainer.propTypes = propTypes;
ChannelsOrdersDetailContainer.defaultProps = defaultProps;

export default ChannelsOrdersDetailContainer;
