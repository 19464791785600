import * as React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { SkChase, SkChaseDot } from './CustomLoader.styled';

const CustomLoader = (props: Props) => {
    // const { } = props;

    return (
        <Box
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            minHeight="200px">
            <SkChase>
                <SkChaseDot />
                <SkChaseDot />
                <SkChaseDot />
                <SkChaseDot />
                <SkChaseDot />
                <SkChaseDot />
            </SkChase>
        </Box>
    );
};

const propTypes = {};

const defaultProps = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CustomLoader.propTypes = propTypes;
CustomLoader.defaultProps = defaultProps;

export default CustomLoader;
