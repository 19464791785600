import { useMutation, useQuery } from '@tanstack/react-query';
import endpoints from '../../api/clientExtractions/clientExtractions.api';
import { GridHookParams } from 'types/common/CommonGrid/CommonGridFormModal.types';
import { extractionsAdapter } from 'adapters/api/clientExtractions.adapter';
import { errorResponseHelper } from 'utils/helpers/errorsHelper';

export const useExtractions = ({ forceDisable, filterQueryString }: GridHookParams) => {
    const enabledCondition = !forceDisable;

    const { data, isLoading, isFetching, isRefetching, refetch } = useQuery(
        ['extractions', filterQueryString],

        () => endpoints.getExtractions(filterQueryString),
        {
            enabled: enabledCondition,
            select: data => {
                return extractionsAdapter(data?.data);
            },
        },
    );

    return {
        data,
        isLoading: (isLoading && isFetching) || isRefetching,
        refetch,
    };
};

export const useChangeExtractionStatus = (setSnackBarMessage: Function) => {
    const { mutate, isLoading } = useMutation(endpoints.changeExtractionStatus, {
        onError(err: any) {
            setSnackBarMessage(errorResponseHelper(err), 'error');
        },
    });
    return {
        isLoading,
        mutate,
    };
};
