import { Box, DialogActions, styled } from '@mui/material';

export default {
    DialogContent: styled(Box)(({ theme }) => ({
        padding: '3px 5px',
    })),
    DialogActions: styled(DialogActions)(({ theme }) => ({
        display: 'flex',
        justifyContent: 'flex-end',
    })),
    ProfilePageForm: styled(Box)(({ theme }) => ({
        padding: '1em',
        borderRadius: '0.5em',
        gap: '8px',
        height: 'auto',
        maxWidth: '100%',
        marginTop: '1em',
    })) as typeof Box,
};
