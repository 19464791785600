import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { Box, Link } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import PasswordField from 'components/common/PasswordField';
import { FormikContextType, Form, FormikProvider } from 'formik';
import St from './LoginForm.styled';
import CustomInput from 'components/common/CustomInput';
import { Link as RouterLink } from 'react-router-dom';
import ErrorText from 'components/common/ErrorText';

const LoginForm = (props: Props) => {
    const { t, mainLogo, isLoading, formik, apiError, isError, SnackBar } = props;

    const { errors, values, setFieldValue } = formik;
    return (
        <FormikProvider value={formik}>
            <Form>
                <St.LoginForm data-testid="login-form">
                    <St.LogoContainer>
                        <St.Logo
                            color="primary"
                            inheritViewBox
                            component={mainLogo}
                            alt="consultatio_plus_logo"
                        />
                    </St.LogoContainer>
                    <SnackBar />
                    <St.Stack gap={'15px'}>
                        <CustomInput
                            field="username"
                            errors={errors}
                            label={t('username_label')}
                            placeholder={t('username_placeholder')}
                            setFieldValue={setFieldValue}
                            values={values}
                            autoFocus
                            maxLength={50}
                            required
                        />
                        <PasswordField
                            variant="outlined"
                            size="small"
                            name="password"
                            label={t('password_label')}
                            value={values.password}
                            error={errors.password !== undefined}
                            placeholder={t('password_placeholder')}
                            helperText={errors.password?.toString()}
                            onChange={({ target }: any) => {
                                setFieldValue('password', target.value);
                            }}
                        />
                    </St.Stack>
                    {/* <Box sx={{ alignSelf: 'flex-end' }}>
                        <Link
                            component={RouterLink}
                            to="/forgot-password"
                            align="right"
                            underline="none"
                            fontSize={'0.75rem'}>
                            {t('forgot_password_button')}
                        </Link>
                    </Box> Se comenta ya que hoy en dia no hay flujo armado de esta pagina */}
                    <Box>{isError && apiError && <ErrorText text={apiError} />}</Box>
                    <LoadingButton type="submit" variant="contained" loading={isLoading}>
                        {t('submit_button_label')}
                    </LoadingButton>
                </St.LoginForm>
            </Form>
        </FormikProvider>
    );
};

const propTypes = {
    isLoading: PropTypes.bool.isRequired,
    t: PropTypes.any.isRequired,
    isError: PropTypes.bool.isRequired,
};

const defaultProps = {};

interface extraProps {
    formik: FormikContextType<any>;
    apiError: string;
    SnackBar: any;
    mainLogo: any;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
LoginForm.propTypes = propTypes;
LoginForm.defaultProps = defaultProps;

export default LoginForm;
