import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { V_OFFLINE_MODE } from 'constants/envVarConstants';
import { later } from 'utils/helpers/commonHelper';
// type GenericAxiosReturnType<T> = Promise<AxiosResponse<T, any>>;

const fakeData: { [key: string]: any } =
    // process.env.NODE_ENV !== 'production'
    //     ?
    {
        auth: import('./auth/auth.api.fakedata'),
        users: import('./users/users.api.fakedata'),
        usersRoles: import('./usersRoles/usersRoles.api.fakedata'),
        usersBackoffice: import('./usersBackoffice/usersBackoffice.api.fakedata'),
        SettingsNonWorkingDays: import(
            './settingsNonWorkingDays/settingsNonWorkingDays.api.fakedata'
        ),
        marketExteriorInstruments: import(
            './marketExteriorInstruments/marketExteriorInstruments.api.fakedata'
        ),
        settingsInstrumentRules: import(
            './settingsInstrumentRules/settingsInstrumentRules.api.fakedata'
        ),
        clients: import('./clients/clients.api.fakedata'),
        marketDollarQuotes: import('./marketDollarQuotes/marketDollarQuotes.api.fakedata'),
        alerts: import('./alerts/alerts.api.fakedata'),
        clientBankAccounts: import('./clientBankAccounts/clientBankAccounts.api.fakedata'),
        clientDeleteAccountRequest: import(
            './clientDeleteAccountRequest/clientDeleteAccountRequest.api.fakedata'
        ),
        clientExtractions: import('./clientExtractions/clientExtractions.api.fakedata'),
        channelsAffidavit: import('./channelsAffidavit/channelsAffidavit.api.fakedata'),
        channelsCollateral: import('./channelsCollateral/channelsCollateral.api.fakedata'),
        clientDeposits: import('./clientDeposits/clientDeposits.api.fakedata'),
        clientImportClientVB: import('./clientImportClientVB/clientImportClientVB.api.fakedata'),
        orders: import('./channelsOrders/channelsOrders.api.fakedata'),
        channelsFundings: import('./channelsFundings/channelsFundings.api.fakedata'),
        usersBusinessAssistant: import(
            './usersBusinessAssistant/usersBusinessAssistant.api.fakedata'
        ),
    };
// : {};

export const withFakeData = <T>(
    request: () => any,
    api: string,
    call: string,
    alwaysFake = false,
    delay = 0,
): Promise<AxiosResponse<T, any>> => {
    if (V_OFFLINE_MODE || alwaysFake || process.env.NODE_ENV == 'test') {
        // Fakedata files not included
        if (fakeData[api] == null) {
            return Promise.resolve({
                status: 200,
                statusText: 'OK',
                headers: {},
                config: {},
                data: {},
            }) as Promise<AxiosResponse<T, any>>;
        }

        return fakeData[api].then((apiObject: any) => {
            const dataObject = (apiObject.default[call] ?? {}) as T;

            if (delay > 0) {
                return later(delay, {
                    status: 200,
                    statusText: 'OK',
                    headers: {},
                    config: {},
                    data: dataObject,
                }) as Promise<AxiosResponse<T, any>>;
            }

            return Promise.resolve({
                status: 200,
                statusText: 'OK',
                headers: {},
                config: {},
                data: dataObject,
            }) as Promise<AxiosResponse<T, any>>;
        });
    } else {
        return request() as Promise<AxiosResponse<T, any>>;
    }
};
