import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
// import St from './ClientProspectApproveModal.styled';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import ActionButton from 'components/common/ActionButton';
import { tCommon } from 'constants/appConstants';
import { Form, FormikProvider } from 'formik';
import CustomInput from 'components/common/CustomInput';
import { REGEX_0_TO_9 } from 'utils/helpers/constants';

const ClientProspectApproveModal = (props: Props) => {
    const { close, isLoading, open, t, formik } = props;

    return (
        <Dialog open={open} onClose={close}>
            <FormikProvider value={formik} data-testid="ClientProspectRejectModalForm-form">
                <Form>
                    <DialogTitle>{t('prospect_approve_modal_title')}</DialogTitle>
                    <DialogContent>
                        <Box sx={{ marginTop: '0.5rem', width: '400px' }}>
                            <CustomInput
                                field="customerCode"
                                values={formik?.values}
                                setFieldValue={formik?.setFieldValue}
                                errors={formik?.errors}
                                label={t('prospect_approve_modal_customer_code_label')}
                                required
                                autoFocus
                                regex={REGEX_0_TO_9}
                                disabled={isLoading}
                                helperText={t('prospect_approve_modal_confirmation_text')}
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <ActionButton variant="outlined" disabled={isLoading} onClick={close}>
                            {t('cancel', tCommon)}
                        </ActionButton>
                        <ActionButton type="submit" loading={isLoading} disabled={isLoading}>
                            {t('approve', tCommon)}
                        </ActionButton>
                    </DialogActions>
                </Form>
            </FormikProvider>
        </Dialog>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

const defaultProps = {};

interface extraProps {
    formik: any;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ClientProspectApproveModal.propTypes = propTypes;
ClientProspectApproveModal.defaultProps = defaultProps;

export default ClientProspectApproveModal;
