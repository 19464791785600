import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ImageReaderModal from './ImageReaderModal';

const ImageReaderModalContainer = (props: Props) => {
    // const {} = props;

    const childProps = {
        ...props,
    };

    return <ImageReaderModal {...childProps} />;
};

const propTypes = {
    open: PropTypes.bool.isRequired,
};

const defaultProps = {};

interface extraProps {
    handleClose: () => void;
    imgUrl: string | null;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ImageReaderModalContainer.propTypes = propTypes;
ImageReaderModalContainer.defaultProps = defaultProps;

export default ImageReaderModalContainer;
