import { Box, Stack, styled } from '@mui/material';

export default {
    Box: styled(Box)(({ theme }) => ({})),
    FooterButtons: styled(Stack)(({ theme }) => ({
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '15px',
    })) as typeof Stack,
};
