import { Box, styled } from '@mui/material';

export default {
    DetailTitle: styled(Box)(({ theme }) => ({
        fontSize: '1.2em',
        fontWeight: 'bold',
        marginBottom: '1em',
        display: 'flex',
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        borderRadius: '0.5em',
        padding: '0.3em 1em',
        justifyContent: 'center',
        width: '100%',
    })),
};
