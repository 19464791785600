import { Box, Typography, styled } from '@mui/material';

export default {
    InstrumentBox: styled(Box)(() => ({
        padding: '0.5rem',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        textAlign: 'center',
        gap: '5px',
    })) as typeof Box,
    InstrumentName: styled(Typography)(({ theme }) => ({
        width: 'auto', // Ancho de la Card
        fontWeight: 'bold',
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        borderRadius: '10px',
        padding: '0.4rem 1rem',
        margin: '0.1rem 0rem',
        fontSize: '0.85rem',
    })) as typeof Typography,
};
