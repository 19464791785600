import * as React from 'react';
import St from './ChannelsExchangeDetail.styled';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { tCommon, USD } from 'constants/appConstants';
import { ExchangeDefinition } from 'types/channelsExchange/channelsExchange.api.types';
import { AlertColor, Divider, Grid } from '@mui/material';
import ChannelsModalItemGrid from 'components/common/ChannelsModalItemGrid';
import { formatDateWithHour } from 'utils/helpers/dateHelper';
import { convertCurrencyFormat, getFullName } from 'utils/helpers/commonHelper';
import { statesToShowActionsContainer } from 'utils/helpers/channelsCommonHelper';
import ChannelsExchangeDetailActions from '../ChannelsExchangeDetailActions';
import ChannelsDDJJCellInfo from 'components/common/ChannelsDDJJCellInfo';

const ChannelsExchangeDetail = ({
    row: {
        id,
        customerCode,
        amount,
        user,
        status,
        createdAt,
        channel,
        operator,
        lastModified,
        decisionAt,
        reason,
        buyPrice,
        sellPrice,
        currencyStart,
        currencyEnd,
        term,
        ticker,
        declaration,
        operatedBuyPrice,
        operatedSellPrice,
        operatedAmount,
    },
    handleAcquireOrReleaseOrder,
    setSnackBarMessage,
    close,
    t,
}: Props) => (
    <Grid container spacing={2} px={3} py={2}>
        <Grid item xs={12} display="flex" gap="20px">
            <St.DetailTitle>{t('information')}</St.DetailTitle>
            <St.DetailTitle>{t('additional_information')}</St.DetailTitle>
        </Grid>
        <Grid item container xs={12} md={6}>
            <ChannelsModalItemGrid label={t('customer_number')} text={customerCode} />
            <ChannelsModalItemGrid label={t('instrument')} text={ticker?.ticker} />
            <ChannelsModalItemGrid label={t('term')} text={t(`term_${term}`)} />
            <ChannelsModalItemGrid label={t('is_market_price')} text={t('yes', tCommon)} />
            <ChannelsModalItemGrid label={t('amount')} text={convertCurrencyFormat(amount, USD)} />
            <ChannelsModalItemGrid
                label={t('operated_amount')}
                text={operatedAmount ? convertCurrencyFormat(operatedAmount, USD) : '-'}
            />
            <ChannelsModalItemGrid
                label={t('operated_buy_price')}
                text={operatedBuyPrice ? convertCurrencyFormat(operatedBuyPrice, currencyEnd) : '-'}
            />
            <ChannelsModalItemGrid
                label={t('operated_sell_price')}
                text={
                    operatedSellPrice ? convertCurrencyFormat(operatedSellPrice, currencyEnd) : '-'
                }
            />
        </Grid>
        <Grid item container xs={12} md={6}>
            <ChannelsModalItemGrid label={t('date')} text={formatDateWithHour(createdAt)} />
            <ChannelsModalItemGrid label={t('channel')} text={getFullName(channel)} />
            <ChannelsModalItemGrid label={t('currency_start')} text={currencyStart} />
            <ChannelsModalItemGrid label={t('currency_end')} text={currencyEnd} />
            <ChannelsModalItemGrid label={t('customer')} text={getFullName(user)} />
            <ChannelsModalItemGrid
                label={t('qualified_investor')}
                text={t(user?.isQualifiedInvestor ? 'yes' : 'no', tCommon)}
            />
            <ChannelsModalItemGrid label={t('operator')} text={getFullName(operator)} />

            <ChannelsModalItemGrid
                label={t('ddjj')}
                text={<ChannelsDDJJCellInfo declaration={declaration} />}
            />
        </Grid>

        {lastModified && decisionAt && (
            <ChannelsModalItemGrid
                column={12}
                label={t('modified')}
                text={`${getFullName(lastModified)} - ${formatDateWithHour(decisionAt)}`}
            />
        )}
        {reason && (
            <>
                <Divider sx={{ width: '100%', mt: 1.25 }} />
                <ChannelsModalItemGrid column={12} label={t('reason')} text={reason} />
            </>
        )}

        {statesToShowActionsContainer.includes(status) && (
            <ChannelsExchangeDetailActions
                id={id}
                status={status}
                buyPrice={operatedBuyPrice || buyPrice || 0}
                sellPrice={operatedSellPrice || sellPrice || 0}
                operatedAmount={operatedAmount || amount}
                ticker={ticker?.ticker || '-'}
                handleAcquireOrReleaseOrder={handleAcquireOrReleaseOrder}
                close={close}
                setSnackBarMessage={setSnackBarMessage}
                declaration={declaration}
            />
        )}
    </Grid>
);
const propTypes = {
    t: PropTypes.func.isRequired,
};

const defaultProps = {};

interface extraProps {
    row: ExchangeDefinition;
    close(): void;
    handleAcquireOrReleaseOrder({ id, action }: { id: string; action: string }): void;
    setSnackBarMessage(msj: string, sever?: AlertColor): void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ChannelsExchangeDetail.propTypes = propTypes;
ChannelsExchangeDetail.defaultProps = defaultProps;

export default ChannelsExchangeDetail;
