import { Box, Grid, Modal, styled, Typography } from '@mui/material';

export default {
    Modal: styled(Modal)(() => ({
        overflowY: 'auto',
        mt: '10px',
        mb: '10px',
    })),
    Grid: styled(Grid)(() => ({})) as typeof Grid,
    Typography: styled(Typography)(() => ({})) as typeof Typography,
    Box: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: '86%', sm: '400px' },
            height: 'max-content',
            maxHeight: { xs: '98%', sm: '95%' },
            bgcolor: 'background.paper',
            boxShadow: 24,
            borderRadius: '5px',
            p: 2,
            overflow: 'auto',
        }),
    ),
};
