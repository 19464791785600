import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import * as Yup from 'yup';
import UsersBrokerForm from './UsersBrokerForm';
import { useTranslation } from 'react-i18next';
import { tErrorsContext, tRequiredFieldError } from 'constants/appConstants';
import { TFunction } from 'i18next';
import { FormikValues, useFormik } from 'formik';
import { BrokerUsersRow } from 'types/usersBroker/usersBroker.types';
import { useCreateUsersBroker, useEditUsersBroker } from 'hooks/api/usersBroker.hooks';
import i18n from 'utils/i18n';
import { VALID_PASSWORD_REGEX } from 'utils/helpers/constants';
import { useState, useEffect } from 'react';

// ---------------------------------------------//
// ------------------- FORMIK ------------------//
// ---------------------------------------------//

const getInitialValues = (isEdit: boolean, row: any) => ({
    name: isEdit ? row?.name : '',
    lastName: isEdit ? row?.lastName : '',
    email: isEdit ? row?.email : '',
    username: isEdit ? row?.username : '',
    password: isEdit ? row?.password : '',
    repeatPassword: isEdit ? row?.repeatPassword : '',
    vbCode: isEdit ? row?.channelInfo?.vbCode : '',
    referralCode: isEdit ? row?.channelInfo?.referralCode : '',
    isInternal: isEdit ? row?.channelInfo?.isInternal : true,
});

const getValidationSchema = (t: TFunction<'usersBroker', undefined, 'usersBroker'>) =>
    Yup.lazy(() =>
        Yup.object().shape({
            name: Yup.string().required(tRequiredFieldError),
            email: Yup.string()
                .email(i18n.t('required_email', { ns: 'errors' }) as string)
                .required(tRequiredFieldError)
                .trim(),
            username: Yup.string().required(tRequiredFieldError),
            password: Yup.string()
                .min(8, String(i18n.t('password_field_min_length_validation', tErrorsContext)))
                .max(20, String(i18n.t('password_field_max_length_validation', tErrorsContext)))
                .when('isEdit', {
                    is: false,
                    then: Yup.string().required(tRequiredFieldError),
                })
                .matches(
                    VALID_PASSWORD_REGEX,
                    String(i18n.t('password_field_invalid_regex_validation', tErrorsContext)),
                ),
            repeatPassword: Yup.string()
                .oneOf(
                    [Yup.ref('password'), null],
                    String(
                        i18n.t(
                            'change_password_screen_passwords_field_do_not_match_validation',
                            tErrorsContext,
                        ),
                    ),
                )
                .when('isEdit', {
                    is: false,
                    then: Yup.string().required(tRequiredFieldError),
                }),
            isInternal: Yup.boolean(),
            vbCode: Yup.string().required(tRequiredFieldError),
            referralCode: Yup.string().required(tRequiredFieldError),
        }),
    );
// ---------------------------------------------//
// ---------------------------------------------//
// ---------------------------------------------//

const UsersBrokerFormContainer = (props: Props) => {
    const { isEdit, row, close, setSnackBarMessage } = props;
    const { t } = useTranslation('usersBroker');
    const [showButton, setShowButton] = useState(false);

    const { mutate: onCreateUsersBroker, isLoading: isLoadingCreate } = useCreateUsersBroker(
        close,
        setSnackBarMessage,
    );
    const { mutate: onEditUsersBroker, isLoading: isLoadingEdit } = useEditUsersBroker(
        close,
        setSnackBarMessage,
    );

    const handleSubmit = React.useCallback(
        async (data: FormikValues) => {
            const payload = {
                name: data?.name,
                lastName: data?.lastName,
                email: data?.email,
                username: data?.username,
                password: data?.password,
                channelInfo: {
                    isInternal: data?.isInternal,
                    vbCode: data?.vbCode,
                    referralCode: data?.referralCode,
                },
            };
            const payloadEdit = {
                name: data?.name,
                lastName: data?.lastName,
                email: data?.email,
                username: data?.username,
                channelInfo: {
                    isInternal: data?.isInternal,
                    vbCode: data?.vbCode,
                    referralCode: data?.referralCode,
                },
            };
            isEdit
                ? onEditUsersBroker({ ...payloadEdit, id: row!.id })
                : onCreateUsersBroker(payload);
        },
        [isEdit, onCreateUsersBroker, onEditUsersBroker, row],
    );
    const formikInitProps = React.useMemo(
        () => ({
            initialValues: getInitialValues(isEdit, row as BrokerUsersRow),
            validateOnChange: false,
            validateOnBlur: false,
            validateOnMount: false,
            validationSchema: getValidationSchema(t),
            enableReinitialize: true,
            onSubmit: handleSubmit,
        }),
        [handleSubmit, isEdit, row, t],
    );

    const formik = useFormik(formikInitProps);

    const { values } = formik;

    useEffect(() => {
        setShowButton(true);
        if (!isEdit) {
            Object.entries(values).forEach(([key, value]) => {
                if (!value && key !== 'isInternal' && key !== 'lastName') {
                    setShowButton(false);
                }
            });
        }
    }, [isEdit, values]);

    const childProps = {
        ...props,
        t,
        close,
        formik,
        isEdit,
        showButton,
        isLoadingSubmit: isLoadingCreate || isLoadingEdit,
    };

    return <UsersBrokerForm {...childProps} />;
};

const propTypes = {
    isEdit: PropTypes.bool.isRequired,
};

const defaultProps = {
    isEdit: false,
};
interface extraProps {
    row?: BrokerUsersRow;
    close: () => void;
    setSnackBarMessage: (message: string) => void;
    actionSelector: { id: number; name: string } | null;
    setActionSelector: React.Dispatch<React.SetStateAction<null>>;
}
interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
UsersBrokerFormContainer.propTypes = propTypes;
UsersBrokerFormContainer.defaultProps = defaultProps;
export default UsersBrokerFormContainer;
