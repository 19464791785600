import { BackofficeUsersDefinition } from 'types/usersBackoffice/usersBackoffice.types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './UsersBackofficeDetail.styled';
import PropTypes from 'prop-types';
import { RoleDefinition } from 'types/usersRoles/usersRoles.types';
import InfoLabel from 'components/common/InfoLabel';
import { formatDateWithHour } from 'utils/helpers/dateHelper';

export const UsersBackofficeDetail = (props: Props) => {
    const { t, detailData } = props;

    return (
        <St.UsersBackofficeDetail data-testid="UsersBackofficeDetail-page">
            <InfoLabel
                fontSize={'14px'}
                withoutSeparation
                label={t('backoffice_users_form_name_input') + ':'}
                text={detailData?.name}
            />
            <InfoLabel
                fontSize={'14px'}
                withoutSeparation
                label={t('backoffice_users_form_last_name_input') + ':'}
                text={detailData?.lastName}
            />
            <InfoLabel
                fontSize={'14px'}
                withoutSeparation
                label={t('backoffice_users_form_user_name_input') + ':'}
                text={detailData?.username}
            />
            <InfoLabel
                fontSize={'14px'}
                withoutSeparation
                label={t('backoffice_users_form_email_input') + ':'}
                text={detailData?.email}
            />
            <InfoLabel
                fontSize={'14px'}
                withoutSeparation
                label={t('backoffice_users_form_last_login_input') + ':'}
                text={formatDateWithHour(detailData?.lastLogin as string)}
            />
            <InfoLabel
                fontSize={'14px'}
                withoutSeparation
                label={t('backoffice_users_form_created_at_input') + ':'}
                text={formatDateWithHour(detailData?.createdAt as string)}
            />
            <InfoLabel
                fontSize={'14px'}
                withoutSeparation
                label={t('backoffice_users_form_updated_at_input') + ':'}
                text={formatDateWithHour(detailData?.updatedAt as string)}
            />
            <InfoLabel
                fontSize={'14px'}
                withoutSeparation
                label={t('backoffice_users_form_roles_input') + ':'}
                text={`
                    ${
                        detailData?.assignedRoles?.length === 0 ||
                        detailData?.assignedRoles?.length === undefined
                            ? '-'
                            : detailData?.assignedRoles?.map(
                                  (rol: RoleDefinition) => `${rol?.name} - `,
                              )
                    }
                `}
            />
        </St.UsersBackofficeDetail>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

const defaultProps = {};

interface extraProps {
    detailData?: BackofficeUsersDefinition;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
UsersBackofficeDetail.propTypes = propTypes;
UsersBackofficeDetail.defaultProps = defaultProps;

export default UsersBackofficeDetail;
