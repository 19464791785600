import { CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import {
    ActionHeader,
    ExtraQueryFiltersProps,
} from 'types/common/CommonGrid/CommonGridFormModal.types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CanExport from '../CanExport';
import DisableFilter from '../DisableFilter';
import Search from '../Search';
import St from './TableHeaderActions.styled';

const TableHeaderActions = (props: Props) => {
    const {
        resource,
        canDisable,
        canExport,
        canSearch,
        loading,
        matchesSm,
        extraActionsInHeader,
        handleSearchChange,
        setShowDisabled,
        showDisabled,
        useGetData,
        extraFilters,
        columns,
        clientSidePagination,
        exportName,
        totalRows,
    } = props;

    return (
        <St.Container direction={{ md: 'row', sm: 'column' }} spacing={1}>
            {canSearch && <Search handleSearchChange={handleSearchChange} disabled={loading} />}
            {canDisable && (
                <DisableFilter
                    resource={resource}
                    setShowDisabled={setShowDisabled}
                    isLoading={loading}
                    disabled={showDisabled}
                />
            )}
            {loading && extraActionsInHeader ? (
                <St.LoadingContainer>
                    <CircularProgress color="primary" size="1rem" />
                </St.LoadingContainer>
            ) : (
                extraActionsInHeader
                    ?.filter((item: ActionHeader) => !item.isHidden)
                    .map((item: ActionHeader) => <item.component key={item.id} />)
            )}
            {canExport && !matchesSm && (
                <CanExport
                    pageLoading={loading}
                    resource={exportName || resource}
                    columns={columns}
                    extraFilters={extraFilters}
                    useGetData={useGetData}
                    totalRows={totalRows}
                    clientSidePagination={clientSidePagination ?? false}
                />
            )}
        </St.Container>
    );
};

const propTypes = {
    resource: PropTypes.string.isRequired,
    canDisable: PropTypes.bool,
    canExport: PropTypes.bool,
    canSearch: PropTypes.bool,
    loading: PropTypes.bool.isRequired,
    matchesSm: PropTypes.bool.isRequired,
    handleSearchChange: PropTypes.func.isRequired,
    showDisabled: PropTypes.bool.isRequired,
    setShowDisabled: PropTypes.func.isRequired,
    columns: PropTypes.arrayOf(PropTypes.any).isRequired,
    exportName: PropTypes.string,
    totalRows: PropTypes.number.isRequired,
    clientSidePagination: PropTypes.bool,
};

const defaultProps = {};

interface extraProps {
    extraActionsInHeader?: ActionHeader[];
    useGetData: any;
    extraFilters?: ExtraQueryFiltersProps[];
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
TableHeaderActions.propTypes = propTypes;
TableHeaderActions.defaultProps = defaultProps;

export default TableHeaderActions;
