import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ClientRiskProfile, ProspectStateInfo } from 'types/clients/clients.types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ClientSectionProspectSteps from './ClientSectionProspectSteps';
import { ONBOARDING_STEPS_ORDERS } from 'constants/appConstants';

const ClientSectionProspectStepsContainer = (props: Props) => {
    const { steps, riskProfile } = props;
    const { t } = useTranslation('clients');
    const [firstSteps, lastSteps] = React.useMemo(() => {
        const filteredSteps =
            riskProfile === 'LOW' ? steps.filter(s => s.name != t('PROOF_OF_INCOME')) : steps;
        const sortedSteps = filteredSteps.sort(
            (a, b) =>
                ONBOARDING_STEPS_ORDERS.indexOf(a.name) - ONBOARDING_STEPS_ORDERS.indexOf(b.name),
        );
        return sortedSteps ? [sortedSteps.slice(0, 4), sortedSteps.slice(4)] : [];
    }, [steps, riskProfile, t]);

    const childProps = {
        t,
        firstSteps,
        lastSteps,
    };

    return <ClientSectionProspectSteps {...childProps} />;
};

const propTypes = {};

const defaultProps = {};

interface extraProps {
    steps: ProspectStateInfo[];
    riskProfile?: ClientRiskProfile | null;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ClientSectionProspectStepsContainer.propTypes = propTypes;
ClientSectionProspectStepsContainer.defaultProps = defaultProps;

export default ClientSectionProspectStepsContainer;
