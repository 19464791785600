import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { Form } from 'formik';

export default {
    Form: styled(Form)(({ theme }) =>
        theme.unstable_sx({
            width: { xs: 'calc(94% - 4em)', sm: '100%' },
            maxWidth: '320px',
            backgroundColor: theme.palette.custom.whiteSmoke,
            margin: 'auto',
            padding: '2em',
            borderRadius: '0.5rem',
            gap: '15px',
            rowGap: '3rem',
            boxShadow: '4px 5px 36px -9px rgba(0, 0, 0, 0.98)',
        }),
    ) as typeof Box,
    Title: styled(Typography)(() => ({
        textAlign: 'center',
    })) as typeof Typography,
};
