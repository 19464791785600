import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CustomLoader from '../CustomLoader';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from '@mui/material';
import { UserBankAccount } from 'types/users/users.api.types';
import { tChannelsCommon } from 'constants/appConstants';
import { formatDateWithHour } from 'utils/helpers/dateHelper';

const BankAccountsDetails = ({
    bankAccountsDetails,
    isLoading,
    pagination: { total, page, pageSize },
    onPageChange,
    t,
}: Props) => (
    <>
        {isLoading ? (
            <CustomLoader />
        ) : (
            <TableContainer component={Paper} sx={{ my: 2 }}>
                <Table aria-label="user's bank accounts">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">
                                {t('client_bank_accounts_list_account_type', {
                                    ns: 'clientBankAccounts',
                                })}
                            </TableCell>
                            <TableCell align="center">{t('account_number')}</TableCell>
                            <TableCell align="center">{t('currency', tChannelsCommon)}</TableCell>
                            <TableCell align="center">{t('approval_date')}</TableCell>
                            <TableCell align="center">{t('bank_name')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {bankAccountsDetails?.map(
                            ({ id, accountNumber, currency, type, decisionAt, bankName }) => (
                                <TableRow key={id}>
                                    <TableCell align="center">
                                        {t(type, { ns: 'clientBankAccounts' })}
                                    </TableCell>
                                    <TableCell align="center">{accountNumber}</TableCell>
                                    <TableCell align="center">{currency}</TableCell>
                                    <TableCell align="center">
                                        {formatDateWithHour(decisionAt)}
                                    </TableCell>
                                    <TableCell align="center">{bankName || '-'}</TableCell>
                                </TableRow>
                            ),
                        )}
                    </TableBody>
                </Table>
                <TablePagination
                    component="div"
                    count={total || 0}
                    rowsPerPage={pageSize || 1}
                    rowsPerPageOptions={[pageSize || 1]}
                    page={page - 1 || 0}
                    onPageChange={(
                        _: React.MouseEvent<HTMLButtonElement> | null,
                        newPage: number,
                    ) => onPageChange(newPage)}
                    labelDisplayedRows={({ from, to, count }) => from + '-' + to + ' de ' + count}
                />
            </TableContainer>
        )}
    </>
);

const propTypes = {
    t: PropTypes.func.isRequired,
};

const defaultProps = {};

interface extraProps {
    bankAccountsDetails: UserBankAccount[];
    isLoading: boolean;
    pagination: {
        total: number;
        page: number;
        pageSize: number;
    };
    onPageChange(newPage: number): void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
BankAccountsDetails.propTypes = propTypes;
BankAccountsDetails.defaultProps = defaultProps;

export default BankAccountsDetails;
