import { useMutation, useQuery } from '@tanstack/react-query';
import endpoints from '../../api/channelsExchange/channelsExchange.api';
import { exchangeDefinitionsAdapter } from 'adapters/api/channelsExchange.adapter';
import { GridHookParams } from 'types/common/CommonGrid/CommonGridFormModal.types';
import { useTranslation } from 'react-i18next';
import { AlertColor } from '@mui/material';
import { errorResponseHelper } from 'utils/helpers/errorsHelper';
import { tErrorsContext } from 'constants/appConstants';

export const useExchangeDefinitions = ({ forceDisable, filterQueryString }: GridHookParams) => {
    const enabledCondition = !forceDisable;
    const { data, isLoading, isFetching, isRefetching, refetch } = useQuery(
        ['exchange', filterQueryString],
        () => endpoints.getExchangeDefinitions(filterQueryString),
        {
            enabled: enabledCondition,
            select: data => {
                return exchangeDefinitionsAdapter(data?.data);
            },
        },
    );
    return {
        data,
        isLoading: (isLoading && isFetching) || isRefetching,
        refetch,
    };
};

export const useLockOrUnlockExchange = (
    setSnackBarMessage: (msj: string, sever?: AlertColor) => void,
    close?: () => void,
) => {
    const { t } = useTranslation('channelsExchange');
    const { mutate } = useMutation(endpoints.lockOrUnlockExchange, {
        onSuccess: () => {
            setSnackBarMessage(t(`order_approved_successfully`));
            if (close) close();
        },
        onError: error => {
            setSnackBarMessage(t(errorResponseHelper(error as any), tErrorsContext), 'error');
        },
    });
    return {
        mutate,
    };
};

export const useApproveExchange = (
    setSnackBarMessage: (msj: string, sever?: AlertColor) => void,
    close: () => void,
) => {
    const { t } = useTranslation('channelsExchange');
    const { mutate, isLoading } = useMutation(endpoints.approveExchange, {
        onSuccess: () => {
            setSnackBarMessage(t(`exchange_approved_successfully`));
            close();
        },
        onError: error => {
            setSnackBarMessage(t(errorResponseHelper(error as any), tErrorsContext), 'error');
        },
    });

    return {
        approveExchange: mutate,
        isLoading,
    };
};
export const useRejectExchange = (
    setSnackBarMessage: (msj: string, sever?: AlertColor) => void,
    close: () => void,
) => {
    const { t } = useTranslation('channelsExchange');
    const { mutate, isLoading } = useMutation(endpoints.rejectExchange, {
        onSuccess: () => {
            setSnackBarMessage(t(`exchange_rejected_successfully`));
            close();
        },
        onError: error => {
            setSnackBarMessage(t(errorResponseHelper(error as any), tErrorsContext), 'error');
        },
    });

    return {
        rejectExchange: mutate,
        isLoading,
    };
};

export const useEditExchange = (
    setSnackBarMessage: (msj: string, sever?: AlertColor) => void,
    close: () => void,
) => {
    const { t } = useTranslation('channelsExchange');
    const { mutate, isLoading } = useMutation(endpoints.editExchange, {
        onSuccess: () => {
            setSnackBarMessage(t('exchange_edited_successfully'));
            close();
        },
        onError: error => {
            setSnackBarMessage(t(errorResponseHelper(error as any), tErrorsContext), 'error');
        },
    });

    return {
        EditExchange: mutate,
        isLoading,
    };
};
