import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './TableHeader.styled';
import { Box, IconButton } from '@mui/material';
import RefreshButton from 'components/common/RefreshButton';
import { HeaderFiltersProps } from 'types/common/CommonGrid/CommonGridFormModal.types';
import defaultTheme from 'utils/theme/defaultTheme';
import CustomIconSelector from 'components/common/CustomIconSelector/CustomIconSelector';

const TableHeader = (props: Props) => {
    const { loading, title, refetch, setShowHeaderFilters, showHeaderFilters, headerFilters } =
        props;

    return (
        <Box display="flex">
            <St.GridTitle>{title}</St.GridTitle>
            <RefreshButton refresh={refetch} disabled={loading || !refetch} />
            {!!headerFilters && (
                <IconButton
                    color="primary"
                    disabled={loading}
                    onClick={() => setShowHeaderFilters((prevState: boolean) => !prevState)}>
                    <CustomIconSelector
                        type={showHeaderFilters ? 'filterOn' : 'filterOff'}
                        sx={{ color: defaultTheme.palette.primary.main }}
                    />
                </IconButton>
            )}
        </Box>
    );
};

const propTypes = {
    title: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    setShowHeaderFilters: PropTypes.func.isRequired,
    showHeaderFilters: PropTypes.bool.isRequired,
};

const defaultProps = {};

interface extraProps {
    refetch: (value: boolean) => void;
    headerFilters?: HeaderFiltersProps[];
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
TableHeader.propTypes = propTypes;
TableHeader.defaultProps = defaultProps;

export default TableHeader;
