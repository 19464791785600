import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { Autocomplete, Divider, Grid, TextField, Typography } from '@mui/material';
import ActionButton from 'components/common/ActionButton';
import {
    BANK_ACCOUNTS_TYPES,
    currenciesBaseArray,
    tChannelsCommon,
    tCommon,
} from 'constants/appConstants';
import RejectForm from 'components/common/RejectForm';
import ChannelsModalActionButton from 'components/common/ChannelsModalActionButton';

const ClientBankAccountsDetailActions = ({
    status,
    action,
    disabled,
    loading,
    approveInformation: { currency, type },
    reason,
    setReason,
    setAction,
    handleSubmit,
    handleChange,
    close,
    t,
}: Props) => (
    <>
        <Divider sx={{ width: '100%', mt: 1.25 }} />

        <ChannelsModalActionButton status={status} setAction={setAction} />

        <Grid container item direction="column" alignSelf="baseline" xs={6} spacing={2} ml={1.25}>
            {action === 'approve' && <Typography>{t('client_bank_accounts_selection')}</Typography>}
            {action === 'edit' && (
                <>
                    <Grid item xs>
                        <Typography fontSize={16} fontWeight={600}>
                            {t('to_edit', tChannelsCommon)}
                        </Typography>
                    </Grid>
                    <Grid item xs>
                        <Autocomplete
                            id="type"
                            size="small"
                            value={type}
                            options={BANK_ACCOUNTS_TYPES}
                            getOptionLabel={option => t(option)}
                            onChange={(_, value) => handleChange('type', value)}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    size="small"
                                    placeholder={t('type', tChannelsCommon)}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs>
                        <Autocomplete
                            id="currency"
                            size="small"
                            value={currency}
                            options={currenciesBaseArray}
                            getOptionLabel={option => t(option)}
                            onChange={(_, value) => handleChange('currency', value)}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    size="small"
                                    placeholder={t('currency', tChannelsCommon)}
                                />
                            )}
                        />
                    </Grid>
                </>
            )}

            {action === 'reject' && <RejectForm setReason={setReason} reason={reason} />}
        </Grid>
        <Divider sx={{ width: '100%', mt: 2 }} />
        <Grid container alignItems="center" justifyContent="end" pt={2} spacing={1}>
            <Grid item display="flex" alignItems="center" xs={3}>
                <ActionButton
                    variant="outlined"
                    onClick={close}
                    sx={{ mt: 0 }}
                    disabled={disabled}
                    loading={loading}>
                    {t('back', tCommon)}
                </ActionButton>
            </Grid>
            <Grid item display="flex" alignItems="center" xs={3}>
                <ActionButton
                    disabled={disabled}
                    loading={loading}
                    sx={{ mt: 0 }}
                    onClick={handleSubmit}>
                    {t('save', tCommon)}
                </ActionButton>
            </Grid>
        </Grid>
    </>
);

const propTypes = {
    t: PropTypes.func.isRequired,
};

const defaultProps = {};

interface extraProps {
    status: string;
    action: string;
    disabled: boolean;
    loading: boolean;
    approveInformation: { type: string; currency: string };
    reason: string;
    setReason(reason: string): void;
    setAction(action: string): void;
    handleChange(name: string, value: string | null): void;
    handleSubmit(): void;
    close(): void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ClientBankAccountsDetailActions.propTypes = propTypes;
ClientBankAccountsDetailActions.defaultProps = defaultProps;

export default ClientBankAccountsDetailActions;
