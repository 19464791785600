import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import MarketDollarQuotesDetail from './MarketDollarQuotesDetail';
import { useTranslation } from 'react-i18next';
import { DollarQuotes } from 'types/marketDollarQuotes/marketDollarQuotes.types';

const MarketDollarQuotesDetailContainer = (props: Props) => {
    const { row } = props;
    const { t } = useTranslation('marketDollarQuotes');

    const childProps = {
        ...props,
        t,
        detailData: row,
    };

    return <MarketDollarQuotesDetail {...childProps} />;
};

const propTypes = {};

const defaultProps = {};

interface extraProps {
    row: DollarQuotes;
    close: () => void;
    setSnackBarMessage: (message: string) => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
MarketDollarQuotesDetailContainer.propTypes = propTypes;
MarketDollarQuotesDetailContainer.defaultProps = defaultProps;

export default MarketDollarQuotesDetailContainer;
