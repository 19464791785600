import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { FunctionComponent } from 'react';
import * as React from 'react';
import { Navigate, Route } from 'react-router-dom';
import { UserRole } from 'types/auth/auth.types';

export type AvailabityType = 'logged_out' | 'logged_in' | 'public';
export interface RouteInfo {
    // Identificador interno. Debe ser único
    name: string;
    // URL de la ruta
    path?: string;
    // Nombre visible de la ruta
    title?: string;
    // Componente de la pagina, en caso de ser de tipo "screen"
    component?: FunctionComponent<any> | null;
    // Subrutas del navigator (drawer, bottomTabs, topTabs, stack)
    subRoutes?: SubRouteInfo[] | null;
    // Indica si la ruta es accesible estando anonimo, autenticado o ambos
    availability?: AvailabityType;
    // Indica los abilidides que permiten mostrar ese sitio.
    abilities?: string[];
    // Indica si redirecciona a una url externa (No va a tener una external url si es un desplegable con subrutas)
    externalUrl?: string;
}

export interface SubRouteInfo extends RouteInfo {
    // Icono del punto de menu
    icon?:
        | (OverridableComponent<SvgIconTypeMap<Record<string, unknown>, 'svg'>> & {
              muiName: string;
          })
        | (() => JSX.Element)
        | any
        | null;
    roles?: UserRole[];
}

const authenticatedDefaultRoute = (route: string) => (
    <Route key="default" path="/" element={<Navigate to={route} replace />} />
);

export const processRoutesRecursively = (
    routes: RouteInfo[],
    isAuthenticated: boolean,
    userAbilities: string[] | null,
) => {
    const result = [];
    for (let index = 0; index < routes.length; index++) {
        const r = routes[index];
        // const isUserRoleAvailable = r.roles && r.roles?.filter(v => v === userRole).length;

        const isUserAbilityAvailable =
            (r.abilities &&
                !!r?.abilities.filter(element => userAbilities?.includes(element)).length) ??
            false;
        const isRouteWithoutRoles =
            (!isAuthenticated && r.availability === 'logged_out') ||
            r.availability == null ||
            r.availability == 'public';

        if (
            isRouteWithoutRoles ||
            (isAuthenticated && r.availability === 'logged_in' && isUserAbilityAvailable)
        ) {
            let subRoutes = null;

            if (r.subRoutes != null && r.subRoutes.length > 0) {
                subRoutes = processRoutesRecursively(r.subRoutes, isAuthenticated, userAbilities);
            }
            const Comp = r.component ?? undefined;

            const routeProps = {
                key: r.name,
                element: Comp ? <Comp /> : undefined,
                path: r.path ?? undefined,
            };

            const routeComp = <Route {...routeProps}>{subRoutes}</Route>;
            result.push(routeComp);
        }
    }
    result.push(isAuthenticated && authenticatedDefaultRoute(result[0]?.props.path));
    return result;
};
