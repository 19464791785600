import { withFakeData } from 'api/fakedata';
import { axiosDataInstance } from 'api/axiosInstances';
import {
    CreateUsersRoleRequest,
    EditUsersRoleRequest,
    DeleteRolRequest,
    GetAllUsersRolesResponse,
    GetUsersRolesResponse,
} from 'types/usersRoles/usersRoles.api.types';
import { AUTH_BASE_URL } from 'constants/appConstants';
import { UsersAbilitiesDefinition } from 'types/usersRoles/usersRoles.types';

const apiName = 'usersRoles';

export default {
    getUsersRoles: (param: string) => {
        const API_URL = `${AUTH_BASE_URL}/roles?${param}`;
        return withFakeData<GetUsersRolesResponse>(
            () => axiosDataInstance.get(API_URL),
            apiName,
            'usersRoles',
            false,
            500,
        );
    },
    getUsersAbilities: () => {
        const API_URL = `${AUTH_BASE_URL}/abilities`;
        return withFakeData<UsersAbilitiesDefinition[]>(
            () => axiosDataInstance.get(API_URL),
            apiName,
            'usersAbilities',
            false,
            500,
        );
    },
    getEnabledRoles: () => {
        const API_URL = `${AUTH_BASE_URL}/roles?enabled=true`;
        return withFakeData<GetAllUsersRolesResponse>(
            () => axiosDataInstance.get(API_URL),
            apiName,
            'usersRoles',
            false,
            500,
        );
    },
    deleteRol: (req: DeleteRolRequest) => {
        return withFakeData<{}>(
            () => axiosDataInstance.delete(`${AUTH_BASE_URL}/roles/${req.id}`),
            apiName,
            'delete-usersRoles',
            false,
            500,
        );
    },
    createUsersRole: (req: CreateUsersRoleRequest) =>
        withFakeData<{}>(
            () => axiosDataInstance.post(`${AUTH_BASE_URL}/roles`, req),
            apiName,
            'create-usersRoles',
            false,
            500,
        ),
    editUsersRole: (req: EditUsersRoleRequest) => {
        const { id, ...requestData } = req;

        return withFakeData<{}>(
            () => axiosDataInstance.put(`${AUTH_BASE_URL}/roles/${id}`, requestData),
            apiName,
            'edit-usersRoles',
            false,
            500,
        );
    },
};
