import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { ClientGeneral } from 'types/clients/clients.types';
import ChannelsModalItemGrid from 'components/common/ChannelsModalItemGrid';
import { tCommon } from 'constants/appConstants';
import { Alert, Grid } from '@mui/material';
import { formatDate, formatDateWithHour } from 'utils/helpers/dateHelper';

const ClientSectionGeneral = ({
    id,
    email,
    fullName,
    username,
    userType,
    lastLogin,
    gender,
    termsAndConditions,
    docNumber,
    docType,
    referred,
    nationality,
    countryTaxResidence,
    ssn,
    updatedAt,
    userTitleByType,
    birthdate,
    phoneNumber,
    t,
    rejectedPersonalInfo,
    rejectedPersonalReason,
}: Props) => (
    <Grid container>
        <ChannelsModalItemGrid column={6} label={t('clients_general_id')} text={id} />
        <ChannelsModalItemGrid column={6} label={t('clients_general_username')} text={username} />
        <ChannelsModalItemGrid column={6} label={t('clients_general_fullName')} text={fullName} />
        <ChannelsModalItemGrid
            column={6}
            label={t('clients_general_userType')}
            text={userTitleByType[userType]}
        />
        <ChannelsModalItemGrid column={6} label={t('clients_general_gender')} text={gender} />
        <ChannelsModalItemGrid
            column={6}
            label={t('clients_general_birthdate')}
            text={formatDate(birthdate)}
        />
        <ChannelsModalItemGrid column={6} label={t('clients_general_docNumber')} text={docNumber} />
        <ChannelsModalItemGrid column={6} label={t('clients_general_docType')} text={docType} />
        <ChannelsModalItemGrid
            column={6}
            label={t('clients_general_phone_number')}
            text={phoneNumber}
        />
        <ChannelsModalItemGrid
            column={6}
            label={t('clients_general_termsAndConditions')}
            text={t(termsAndConditions ? 'yes' : 'no', tCommon)}
        />
        <ChannelsModalItemGrid column={6} label={t('clients_general_email')} text={email} />
        <ChannelsModalItemGrid column={6} label={t('clients_general_referred')} text={referred} />
        <ChannelsModalItemGrid
            column={6}
            label={t('clients_general_nationality')}
            text={nationality}
        />
        <ChannelsModalItemGrid
            column={6}
            label={t('clients_general_countryTaxResidence')}
            text={countryTaxResidence}
        />
        {ssn && <ChannelsModalItemGrid column={6} label={t('clients_general_ssn')} text={ssn} />}
        <ChannelsModalItemGrid
            column={6}
            label={t('clients_list_updatedAt_column')}
            text={updatedAt ? formatDateWithHour(updatedAt) : '-'}
        />
        {lastLogin && (
            <ChannelsModalItemGrid
                column={6}
                label={t('clients_general_lastLogin')}
                text={formatDateWithHour(lastLogin)}
            />
        )}
        {rejectedPersonalInfo && (
            <Grid item xs={12}>
                <Alert severity="warning" sx={{ my: 2 }}>
                    {t('client_disagree_personal_data', { reason: rejectedPersonalReason })}
                </Alert>
            </Grid>
        )}
    </Grid>
);

const propTypes = {
    t: PropTypes.func.isRequired,
};

const defaultProps = {};

interface extraProps extends ClientGeneral {
    userTitleByType: { [key: string]: string };
    rejectedPersonalInfo?: boolean;
    rejectedPersonalReason?: string;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ClientSectionGeneral.propTypes = propTypes;
ClientSectionGeneral.defaultProps = defaultProps;

export default ClientSectionGeneral;
