import { ListItem, ListItemButton, ListItemText, Collapse } from '@mui/material';
import { styled as styledMui } from '@mui/material/styles';

export default {
    /* ESTE ESTILO ES PARA LOS MENU PADRE QUE TENGAN SUB MENUS */
    ListItem: styledMui(ListItem)(({ theme }) => ({
        display: 'block',
        padding: '6px 4px 2px 0px',
        backgroundColor: '#F2F2F2',

        '.Mui-selected': {
            backgroundColor: '#E0E0E0 !important',
            borderTopLeftRadius: '0px',
            borderBottomLeftRadius: '0px',
            borderTopRightRadius: '2px',
            borderBottomRightRadius: '2px',
            '& .icon': { color: '#BDBDBD' },

            '&:hover': {
                color: 'white',
                backgroundColor: '#E0E0E0 !important',
            },
        },
        transition: '.2s ease-in-out',
    })),

    Collapse: styledMui(Collapse)(({ theme }) => ({})),

    /* ESTE ESTILO ES PARA LOS ICONOS DE LAS OPCIONES DE LOS MENUS */
    MenuItemIcon: styledMui('div')<{
        ownerState: { drawerOpened: boolean; isSelected?: boolean; isArrowIcon?: boolean | null };
    }>(({ theme, ownerState }) => {
        const { drawerOpened, isArrowIcon } = ownerState;
        return {
            justifyContent: 'center',
            minWidth: 0,
            padding: 0,
            marginRight: drawerOpened && !isArrowIcon ? 40 : 10,
            flex: 0,

            '&.icon': {
                color: '#BDBDBD',
                fill: '#BDBDBD',
                fillOpacity: 2,
                marginTop: drawerOpened ? '10px !important' : '4px !important',
                marginLeft: -15,
            },
        };
    }),

    /* ESTE ESTILO ES PARA LOS TEXTOS DE LAS OPCIONES DE LOS MENUS */
    MenuItemText: styledMui(ListItemText, {
        shouldForwardProp: prop => prop !== 'drawerOpened',
    })(({ drawerOpened }: { drawerOpened: boolean }) => ({
        opacity: drawerOpened ? 1 : 0,
        color: '#828282',
        whiteSpace: 'normal', // Evitar que el texto se divida en varias líneas
    })),

    /* ESTE ESTILO ES PARA LOS BOTONES DE LAS OPCIONES PRINCIPALES DEL SIDEBAR */
    DrawerMenuButton: styledMui(ListItemButton)<{
        ownerState: { pl: string; justify: string; drawerOpened: boolean; menuIsSelected: boolean };
    }>(({ theme, ownerState }) => {
        const { pl, justify, drawerOpened } = ownerState;
        return {
            height: '43px',
            width: drawerOpened ? '100%' : '52px',
            left: drawerOpened ? '0px' : '6px',
            backgroundColor: drawerOpened ? 'transparent' : 'rgba(0, 0, 0, 0.03)',

            paddingTop: drawerOpened ? '0px' : '8px',
            paddingLeft: drawerOpened ? '32px' : '28px',
            paddingRight: drawerOpened ? '20px' : '0px',

            justifyContent: `${justify}`,
            alignItems: 'center',
            transition: '.2s ease-in-out',

            span: {
                marginTop: 5,
            },

            '&:hover': {
                paddingLeft: `${pl}`,
                color: 'white',
                backgroundColor: '#E0E0E0',
                borderTopRightRadius: '2px',
                borderBottomRightRadius: '2px',
            },
        };
    }),

    /* ESTE ESTILO ES PARA LOS BOTONES DE LAS OPCIONES SECUNDARIAS (SUBMENU) DEL SIDEBAR */
    DrawerSubmenuButton: styledMui(ListItemButton)<{
        ownerState: { pl: string; drawerOpened: boolean };
    }>(({ theme, ownerState }) => {
        const { pl, drawerOpened } = ownerState;
        return {
            height: '43px',
            width: drawerOpened ? '100%' : '52px',
            left: drawerOpened ? '0px' : '6px',
            paddingLeft: `${pl}`,
            borderTopRightRadius: drawerOpened ? '2px !important' : '0px !important',
            borderBottomRightRadius: drawerOpened ? '2px !important' : '0px !important',
            margin: '4px 0px',
            paddingTop: '0.5rem',
            paddingBottom: '0.5rem',
            transition: '.2s ease-in-out',

            svg: {
                color: '#BDBDBD',
                fill: '#BDBDBD',
                marginTop: 8,
            },

            '& .sub_icon': {
                marginLeft: -18,
            },

            '&:hover': {
                paddingLeft: '52px',
                color: 'white',
                backgroundColor: '#E0E0E0',
            },

            '&.Mui-selected': {
                backgroundColor: '#6EA5E7 !important',

                svg: {
                    color: 'white',
                    fill: 'white',
                },

                span: {
                    color: 'white',
                },

                '&:hover': {
                    backgroundColor: '#6EA5E7 !important',
                },
            },
        };
    }),
};
