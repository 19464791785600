import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { AssociatedPersonTableProps } from 'types/clientImportClientVB/clientImportClientVB.types';
import { tCommon } from 'constants/appConstants';
import RegisterModal from './RegisterModal';
import St from './AssociatedPersonTable.styled';

const AssociatedPersonTable = ({
    list,
    sendCredentials,
    isLoadingSendCredentials,
    enableSubmitCredentials,
    enableRegister,
    registeModal,
    setRegisterModal,
    email,
    customerCode,
    setSnackBarMessage,
    showRegisterButton,
    setShowRegisterButton,
    t,
}: Props) => {
    return (
        <St.TableContainer component={St.Paper}>
            <St.Title variant="h6" id="tableTitle" component="div">
                {t('table_title')}
            </St.Title>
            <St.Table aria-label="associated person">
                <St.TableHead>
                    <St.TableRow>
                        <St.TableCell>{t('table_column_full_name')}</St.TableCell>
                        <St.TableCell>{t('table_column_CUIT_CUIL')}</St.TableCell>
                        <St.TableCell>{t('table_column_person')}</St.TableCell>
                        <St.TableCell>{t('table_column_email')}</St.TableCell>
                    </St.TableRow>
                </St.TableHead>
                <St.TableBody>
                    {list?.length > 0 ? (
                        list.map(({ fullName, email, personType, taxDocNumber }, index) => (
                            <St.TableRow key={index}>
                                <St.TableCell>{fullName}</St.TableCell>
                                <St.TableCell>{taxDocNumber}</St.TableCell>
                                <St.TableCell>{personType}</St.TableCell>
                                <St.TableCell>{email}</St.TableCell>
                            </St.TableRow>
                        ))
                    ) : (
                        <St.TableRow>
                            <St.TableCell colSpan={4} id="noData">
                                <St.AlertNoData title={t('no_data_was_found', tCommon)} />
                            </St.TableCell>
                        </St.TableRow>
                    )}
                </St.TableBody>
            </St.Table>

            {(enableSubmitCredentials || enableRegister) && (
                <St.ButtonWrapper>
                    {enableSubmitCredentials && (
                        <St.ActionButton
                            loading={isLoadingSendCredentials}
                            disabled={isLoadingSendCredentials}
                            onClick={sendCredentials}>
                            {t('btn_submit_credentials')}
                        </St.ActionButton>
                    )}
                    {showRegisterButton && (
                        <St.ActionButton onClick={() => setRegisterModal(true)}>
                            {t('btn_register')}
                        </St.ActionButton>
                    )}
                </St.ButtonWrapper>
            )}
            {registeModal && (
                <RegisterModal
                    {...{
                        email,
                        customerCode,
                        setSnackBarMessage,
                        close: () => setRegisterModal(false),
                        setShowRegisterButton,
                    }}
                />
            )}
        </St.TableContainer>
    );
};

const propTypes = {};

const defaultProps = {};

interface extraProps extends AssociatedPersonTableProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
AssociatedPersonTable.propTypes = propTypes;
AssociatedPersonTable.defaultProps = defaultProps;

export default AssociatedPersonTable;
