import CommonGrid from 'components/common/CommonGrid';
import { CommonGridColumnsProps } from 'types/common/CommonGrid/CommonGridColumnProps.types';
import {
    ActionColumn,
    ActionHeader,
    HeaderFiltersProps,
} from 'types/common/CommonGrid/CommonGridFormModal.types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import PropTypes from 'prop-types';
import { useBroker } from 'hooks/api/usersBroker.hooks';
import { BrokerUsersDefinition } from 'types/usersBroker/usersBroker.types';

const BrokerPage = (props: Props) => {
    const { t, columns, headerActions, actionColumns, headerFilters } = props;

    return (
        <CommonGrid
            title={t('broker_users_list_title')}
            columns={columns}
            resource="broker"
            useGetData={useBroker}
            defaultSortFieldId="createdAt"
            canExport
            extraActionsInHeader={headerActions}
            customActionColumns={actionColumns}
            rowHeight={40}
            headerFilters={headerFilters}
        />
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

const defaultProps = {};

interface extraProps {
    columns: CommonGridColumnsProps<BrokerUsersDefinition>[];
    headerActions: ActionHeader[];
    actionColumns: ActionColumn[];
    headerFilters: HeaderFiltersProps[];
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
BrokerPage.propTypes = propTypes;
BrokerPage.defaultProps = defaultProps;

export default BrokerPage;
