import * as React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import Search from './Search';

const SearchContainer = (props: Props) => {
    const { disabled } = props;
    const { t } = useTranslation();
    const childProps = {
        ...props,
        t,
        disabled: disabled ?? false,
    };

    return <Search {...childProps} />;
};

const propTypes = {
    handleSearchChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

const defaultProps = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
SearchContainer.propTypes = propTypes;
SearchContainer.defaultProps = defaultProps;

export default SearchContainer;
