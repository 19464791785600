import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import UserExceptionsTable from './UserExceptionsTable';
import { useSearchUser } from 'hooks/api/users.hooks';
import { useTranslation } from 'react-i18next';
import { tErrorsContext } from 'constants/appConstants';
import { UserException } from 'types/common/users.types';

const UserExceptionsTableContainer = (props: Props) => {
    const { exceptions, handleChange } = props;
    const [userException, setUserException] = React.useState('');
    const [errorMessage, setErrorMessage] = React.useState<string | null>('');
    const { t } = useTranslation();
    const {
        mutate: mutateUser,
        isLoading: isLoadingUser,
        errorMessage: errorMessageUser,
    } = useSearchUser();

    const handleSearchUser = async () => {
        setErrorMessage(null);
        const filterQueryString = `username=${userException}`;
        mutateUser(filterQueryString, {
            onSuccess: ({ data }) => {
                if (!data.items.length)
                    return setErrorMessage(t('invalid_username', tErrorsContext));
                handleChange({
                    target: {
                        name: 'exceptions',
                        value: exceptions?.concat({
                            id: data.items[0].id,
                            username: data.items[0].username,
                        }),
                    },
                });
                setUserException('');
            },
        });
    };

    const deleteUserException = (index: number) => {
        exceptions?.splice(index, 1);

        handleChange({
            target: {
                name: 'exceptions',
                value: exceptions,
            },
        });
    };

    const childProps = {
        ...props,
        exceptions,
        userException,
        isLoadingUser,
        errorMessageUser,
        handleSearchUser,
        deleteUserException,
        setUserException,
        t,
        errorMessage,
        setErrorMessage,
    };

    return <UserExceptionsTable {...childProps} />;
};

const propTypes = {};

const defaultProps = {};

interface extraProps {
    exceptions?: UserException[];
    handleChange(change: { target: { name: string; value: any } }): void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
UserExceptionsTableContainer.propTypes = propTypes;
UserExceptionsTableContainer.defaultProps = defaultProps;

export default UserExceptionsTableContainer;
