import { paginationAdapter } from 'adapters/common/pagination.adapter';
import { FundingApi, GetFundingResponse } from 'types/channelsFundings/channelsFundings.api.types';

export const channelsFundingsAdapter = (data: GetFundingResponse) => {
    return {
        ...paginationAdapter(data),
        data: data.items?.map((funding: FundingApi) => {
            return {
                id: funding.id,
                customerCode: funding.customerCode,
                buySell: funding.buySell,
                amount: funding.amount,
                isManual: funding.isManual,
                user: funding.user,
                ticker: funding.ticker,
                instrument: funding.instrument,
                quantity: funding.quantity,
                status: funding.status,
                shareholderCode: funding.shareholderCode,
                isSimple: funding.isSimple,
                price: funding.price,
                createdAt: funding.createdAt,
                updatedAt: funding.updatedAt,
                channel: funding.channel,
                operator: funding.operator,
                reason: funding.reason,
                lastModified: funding.lastModified,
                decisionAt: funding.decisionAt,
                isTotal: funding.isTotal,
            };
        }),
    };
};
