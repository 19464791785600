import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ChannelsExchangeDetail from './ChannelsExchangeDetail';
import { useTranslation } from 'react-i18next';
import { ExchangeDefinition } from 'types/channelsExchange/channelsExchange.api.types';
import { AlertColor } from '@mui/material';

const ChannelsExchangeDetailContainer = (props: Props) => {
    const { t } = useTranslation('channelsCommon');

    const childProps = {
        ...props,
        t,
    };

    return <ChannelsExchangeDetail {...childProps} />;
};

const propTypes = {
    isEdit: PropTypes.bool.isRequired,
};

const defaultProps = {
    isEdit: false,
};

interface extraProps {
    row: ExchangeDefinition;
    close(): void;
    handleAcquireOrReleaseOrder({ id, action }: { id: string; action: string }): void;
    setSnackBarMessage(msj: string, sever?: AlertColor): void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ChannelsExchangeDetailContainer.propTypes = propTypes;
ChannelsExchangeDetailContainer.defaultProps = defaultProps;

export default ChannelsExchangeDetailContainer;
