import React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import InstrumentsAutocomplete from './InstrumentsAutocomplete';
import { useGetInstruments } from 'hooks/api/utils.hooks';
import { useTranslation } from 'react-i18next';

const InstrumentsAutocompleteContainer = (props: Props) => {
    const { value, initialQueryString, setFieldValue, handleChange } = props;
    const [filterQueryString, setFilterQueryString] = React.useState('');
    const { t } = useTranslation();
    const { data: instrumentsOptions, isLoading } = useGetInstruments(
        `pageSize=7&${initialQueryString || ''}&ticker=${filterQueryString}`,
        !!filterQueryString,
    );

    const onChange = (value: { id: number; name: string }) => {
        if (handleChange) handleChange(value);
        else if (setFieldValue) setFieldValue('instrument', value);
    };

    const childProps = {
        ...props,
        instrumentsOptions,
        value,
        t,
        isLoading,
        onChange,
        filterQueryString,
        setFilterQueryString,
    };
    return <InstrumentsAutocomplete {...childProps} />;
};

const propTypes = {
    setFieldValue: PropTypes.func,
    handleChange: PropTypes.func,
    initialQueryString: PropTypes.string,
};

const defaultProps = {};

interface extraProps {
    value?: number;
    errors?: any;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
InstrumentsAutocompleteContainer.propTypes = propTypes;
InstrumentsAutocompleteContainer.defaultProps = defaultProps;

export default InstrumentsAutocompleteContainer;
