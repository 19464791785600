// import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CommonGrid from 'components/common/CommonGrid';
import { CommonGridColumnsProps } from 'types/common/CommonGrid/CommonGridColumnProps.types';
import { ExternalInstrument } from 'types/marketExteriorInstruments/marketExteriorInstruments.types';
import {
    useExternalInstruments,
    useDeleteExternalInstruments,
} from 'hooks/api/marketExteriorInstruments.hooks';
import {
    ActionColumn,
    ActionHeader,
    HeaderFiltersProps,
} from 'types/common/CommonGrid/CommonGridFormModal.types';

const MarketExteriorInstrumentPage = (props: Props) => {
    const { t, columns, actionColumns, headerActions, headerFilters } = props;

    return (
        <CommonGrid
            title={t('market_exterior_instruments_list_title')}
            columns={columns}
            resource="market-exterior-instruments"
            useGetData={useExternalInstruments}
            deleteHook={useDeleteExternalInstruments}
            defaultSortFieldId="createdAt"
            canExport
            extraActionsInHeader={headerActions}
            customActionColumns={actionColumns}
            headerFilters={headerFilters}
            extraFilters={[{ query: 'type', queryValue: 'EXTERIOR' }]}
        />
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

const defaultProps = {};

interface extraProps {
    columns: CommonGridColumnsProps<ExternalInstrument>[];
    actionColumns: ActionColumn[];
    headerActions: ActionHeader[];
    headerFilters: HeaderFiltersProps[];
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
MarketExteriorInstrumentPage.propTypes = propTypes;
MarketExteriorInstrumentPage.defaultProps = defaultProps;

export default MarketExteriorInstrumentPage;
