import { Box, styled } from '@mui/material';

export default {
    UsersBrokerDetail: styled(Box)(({ theme }) => ({
        padding: '1rem',
    })),
    DetailTitle: styled(Box)(({ theme }) => ({
        display: 'flex',
        fontSize: '1.2rem',
        marginBottom: '1.5rem',
    })),
    Label: styled(Box)(({ theme }) => ({
        marginRight: '0.5em',
        fontWeight: 'bold',
    })),
};
