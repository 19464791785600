import { GetClientVBByIdResponse } from 'types/clientImportClientVB/clientImportClientVB.api.types';
import { ClientVB } from 'types/clientImportClientVB/clientImportClientVB.types';
import i18n from 'utils/i18n';

const tClientImportClientVB = { ns: 'clientImportClientVB' };

export const clientByIdAdapter = (data: GetClientVBByIdResponse) => {
    const legal = i18n.t('physical', tClientImportClientVB);
    const physical = i18n.t('physical', tClientImportClientVB);

    return {
        ...data,
        fullAddress: data.fullAddress ?? '-',
        lastLogin: data.lastLogin,
        email: data.persons[0]?.email ?? '-',
        personType: data.isPhysical ? physical : legal,
        persons: data.persons.map(el => ({
            ...el,
            personType: el.isPhysical ? physical : legal,
        })),
    } as ClientVB;
};
