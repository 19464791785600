import * as React from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import UsersBusinessAssistantForm from './UsersBusinessAssistantForm';
import i18n from 'utils/i18n';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { tErrorsContext, tRequiredFieldError } from 'constants/appConstants';
import { BusinessAssistantAdapted } from 'types/usersBusinessAssistant/usersBusinessAssistant.api.types';
import { VALID_PASSWORD_REGEX } from 'utils/helpers/constants';
import {
    useCreateBusinessAssistant,
    useEditBusinessAssistant,
} from 'hooks/api/usersBusinessAssistant.hooks';

// ---------------------------------------------//
// ------------------- FORMIK ------------------//
// ---------------------------------------------//

const getInitialValues = (data: BusinessAssistantAdapted, isEdit: boolean) => ({
    name: data?.name,
    lastName: data?.lastName,
    email: data?.email,
    username: data?.username,
    referralCodes: data?.referralCodes || [],
    password: '',
    passwordConfirmation: '',
    isEdit,
});

const getValidationSchema = () =>
    Yup.object().shape({
        name: Yup.string().required(tRequiredFieldError),
        lastName: Yup.string().required(tRequiredFieldError),
        username: Yup.string().required(tRequiredFieldError),
        email: Yup.string()
            .email(i18n.t('required_email', { ns: 'errors' }) as string)
            .required(tRequiredFieldError)
            .trim(),
        referralCodes: Yup.array().min(1, tRequiredFieldError),
        isEdit: Yup.boolean(),
        password: Yup.string()
            .min(8, String(i18n.t('password_field_min_length_validation', tErrorsContext)))
            .max(20, String(i18n.t('password_field_max_length_validation', tErrorsContext)))
            .matches(
                VALID_PASSWORD_REGEX,
                String(i18n.t('password_field_invalid_regex_validation', tErrorsContext)),
            )
            .when('isEdit', {
                is: false,
                then: Yup.string().required(tRequiredFieldError),
            }),
        passwordConfirmation: Yup.string()
            .oneOf(
                [Yup.ref('password'), null],
                String(
                    i18n.t(
                        'change_password_screen_passwords_field_do_not_match_validation',
                        tErrorsContext,
                    ),
                ),
            )
            .when('isEdit', {
                is: false,
                then: Yup.string().required(tRequiredFieldError),
            }),
    });

// ---------------------------------------------//
// ---------------------------------------------//
// ---------------------------------------------//

const UsersBusinessAssistantFormContainer = (props: Props) => {
    const { row, close, setSnackBarMessage } = props;
    const { t } = useTranslation('usersBusinessAssistant');
    const { mutate, isLoading } = useCreateBusinessAssistant(close, setSnackBarMessage);
    const { mutate: mutateEdit, isLoading: isLoadingEdit } = useEditBusinessAssistant(
        close,
        setSnackBarMessage,
    );

    const handleSubmit = React.useCallback(async (data: any) => {
        const { isEdit, passwordConfirmation, ...userData } = data;
        isEdit ? mutateEdit({ id: row.id, body: userData }) : mutate(userData);
    }, []);

    const formikInitProps = React.useMemo(
        () => ({
            initialValues: getInitialValues(row, props.isEdit),
            validateOnChange: false,
            validateOnBlur: false,
            validateOnMount: false,
            validationSchema: getValidationSchema(),
            enableReinitialize: true,
            onSubmit: handleSubmit,
        }),
        [handleSubmit],
    );

    const formik = useFormik(formikInitProps);

    const childProps = {
        ...props,
        formik,
        t,
        disabledForm:
            isLoading ||
            isLoadingEdit ||
            JSON.stringify(formik.values) === JSON.stringify(getInitialValues(row, props.isEdit)),
    };

    return <UsersBusinessAssistantForm {...childProps} />;
};

const propTypes = {
    isEdit: PropTypes.bool.isRequired,
};

const defaultProps = {
    isEdit: false,
};

interface extraProps {
    row: BusinessAssistantAdapted;
    close: () => void;
    setSnackBarMessage: any;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
UsersBusinessAssistantFormContainer.propTypes = propTypes;
UsersBusinessAssistantFormContainer.defaultProps = defaultProps;

export default UsersBusinessAssistantFormContainer;
