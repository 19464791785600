import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './ConditionsTab.styled';
import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import InstrumentsAutocomplete from 'components/common/InstrumentsAutocomplete';
import CustomAutoComplete from 'components/common/CustomAutoComplete';
import ActionButton from 'components/common/ActionButton';
import { InstrumentRuleConditions } from 'types/settingsInstrumentRules/settingsInstrumentRules.types';
import InstrumentConditionsTable from './InstrumentConditionsTable';

const ConditionsTab = (props: Props) => {
    const {
        t,
        handleConditionsType,
        handleAddInstrument,
        conditionsType,
        instrumentType,
        instrumentTypesOptions,
        instrument,
        handleSelectInstrument,
        conditions,
        handleChange,
        handleAddConditionInstrumentType,
        isSoftDelete,
    } = props;

    return (
        <St.Container>
            {isSoftDelete ? null : (
                <FormControl>
                    <FormLabel>
                        {t('market_instrument_rules_condition_tab_condition_type_label_text')}
                    </FormLabel>
                    <RadioGroup
                        row
                        name="row-radio-buttons-group"
                        value={conditionsType}
                        onChange={handleConditionsType}>
                        <FormControlLabel
                            value={'instrument'}
                            control={<Radio />}
                            label={t('market_instrument_rules_condition_tab_instrument_label_text')}
                        />
                        <FormControlLabel
                            value={'custom'}
                            control={<Radio />}
                            label={t('market_instrument_rules_condition_tab_custom_label_text')}
                        />
                    </RadioGroup>
                </FormControl>
            )}
            {conditionsType === 'instrument' ? (
                <St.AutocompleteWrapper>
                    <CustomAutoComplete
                        id="action"
                        value={instrumentType ?? null}
                        onChange={(e, value) => {
                            handleAddConditionInstrumentType(value);
                        }}
                        options={instrumentTypesOptions ?? []}
                        label={t('market_instrument_rules_condition_tab_instrument_type_label')}
                        placeholder={t(
                            'market_instrument_rules_condition_tab_instrument_type_placeholder',
                        )}
                        renderOption={(props, option) => <Box {...props}>{option.name}</Box>}
                    />
                </St.AutocompleteWrapper>
            ) : (
                <St.FormContainer>
                    <St.AutocompleteWrapper>
                        <InstrumentsAutocomplete
                            initialQueryString={'type=BOND&type=CERTIFICATE&type=EQUITY&type=FUND'}
                            value={instrument}
                            handleChange={value => {
                                handleSelectInstrument(value);
                            }}
                        />
                    </St.AutocompleteWrapper>
                    <ActionButton
                        disabled={!instrument}
                        onClick={handleAddInstrument}
                        type="button"
                        color="secondary"
                        sx={{ width: { md: '200px', xs: '100%' } }}>
                        {t('add_instrument_button_text')}
                    </ActionButton>
                </St.FormContainer>
            )}
            {conditions.length > 0 && (
                <St.InstrumentsTableContainer>
                    <InstrumentConditionsTable
                        instrumentTypesOptions={instrumentTypesOptions}
                        conditions={conditions}
                        handleChange={handleChange}
                        hasDeleteAction={conditionsType === 'custom'}
                    />
                </St.InstrumentsTableContainer>
            )}
        </St.Container>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    isSoftDelete: PropTypes.bool.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleAddInstrument: PropTypes.func.isRequired,
    handleConditionsType: PropTypes.func.isRequired,
    handleSelectInstrument: PropTypes.func.isRequired,
    handleAddConditionInstrumentType: PropTypes.func.isRequired,
};

const defaultProps = {};

interface extraProps {
    instrumentType: { id: number; name: string } | null;
    conditionsType: 'instrument' | 'custom';
    instrumentTypesOptions: { id: number; name: string; value: string }[] | null;
    instrument?: any;
    conditions: InstrumentRuleConditions[];
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ConditionsTab.propTypes = propTypes;
ConditionsTab.defaultProps = defaultProps;

export default ConditionsTab;
